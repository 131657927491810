import { Card, Grid, Typography } from "@material-ui/core";
import * as React from "react";
import { abbreviateNumber } from "../../services/UtilService";
import { IDashboardTime, IKeyValue } from "../../vm";
import SecondaryLoading from "../common/SecondaryLoading";

export interface TimeEntriesStatsProps {
  timesheetCountDic: IKeyValue<number>;
  hoursAndFeeCount: { hours: number; fee: number };
  navigateToTimeSheets: Function;
  search: IDashboardTime;
  isLoading: boolean;
}

const TimeEntriesStats: React.FC<TimeEntriesStatsProps> = ({
  hoursAndFeeCount,
  timesheetCountDic,
  navigateToTimeSheets,
  search,
  isLoading
}) => {
  return (
    <React.Fragment>
      <Grid container spacing={2} className="position-relative">
        {isLoading && <SecondaryLoading />}
        <Grid item xs={12} sm={12} md={6} lg={9} className="dashed-border">
          <Typography variant="h6">Time Entries Count</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} lg={3}>
              <Card className="p-16 count-card">
                <Typography variant="h4" className="fw-600">
                  {abbreviateNumber(timesheetCountDic?.total || 0)}
                </Typography>
                <Typography variant="h6">Total</Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={8} lg={9}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("new")}
                  >
                    <Typography variant="h4" className="fw-600">
                      {abbreviateNumber(timesheetCountDic?.new || 0)}
                    </Typography>
                    <Typography variant="h6">New</Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("attention")}
                  >
                    <Typography
                      variant="h4"
                      className="fw-600"
                      color="secondary"
                    >
                      {abbreviateNumber(timesheetCountDic?.attention || 0)}
                    </Typography>
                    <Typography variant="h6">Attention</Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("reviewed")}
                  >
                    <Typography variant="h4" className="fw-600">
                      {abbreviateNumber(timesheetCountDic?.reviewed || 0)}
                    </Typography>
                    <Typography variant="h6">Reviewed</Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("adjusted")}
                  >
                    <Typography variant="h4" className="fw-600">
                      {abbreviateNumber(timesheetCountDic?.adjusted || 0)}
                    </Typography>
                    <Typography variant="h6">Adjusted</Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("approved")}
                  >
                    <Typography variant="h4" className="fw-600">
                      {abbreviateNumber(timesheetCountDic?.approved || 0)}
                    </Typography>
                    <Typography variant="h6">Approved</Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className={`p-16 count-card ${(search.showDisapproved || search.showOnlyDisapproved) ? 'pointer' : ''}`}
                    onClick={() => search.showDisapproved ? navigateToTimeSheets("disapproved", true) : search.showOnlyDisapproved ? navigateToTimeSheets("disapproved", false, true) : null}
                  >
                    {(search.showDisapproved || search.showOnlyDisapproved) ? (
                      <Typography variant="h4" className="fw-600">
                        {abbreviateNumber(timesheetCountDic["disapproved"] || 0)}
                      </Typography>
                    ) : (
                      <Typography variant="subtitle1" className="fw-600">
                        Not shown
                      </Typography>
                    )}
                    <Typography variant="h6">Disapproved</Typography>
                  </Card>
                </Grid>
                {/* <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("submitted")}
                  >
                    <Typography variant="h4" className="fw-600">
                      {abbreviateNumber(timesheetCountDic?.submitted || 0)}
                    </Typography>
                    <Typography variant="h6">Submitted</Typography>
                  </Card>
                </Grid> */}
                {/* <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("granted")}
                  >
                    <Typography variant="h4" className="fw-600">
                      {abbreviateNumber(timesheetCountDic?.granted || 0)}
                    </Typography>
                    <Typography variant="h6">Granted</Typography>
                  </Card>
                </Grid> */}
                {/* <Grid item xs={6} sm={6} lg={4}>
                  <Card
                    className="p-16 count-card pointer"
                    onClick={() => navigateToTimeSheets("not-granted")}
                  >
                    <Typography variant="h4" className="fw-600">
                      {abbreviateNumber(timesheetCountDic["not-granted"] || 0)}
                    </Typography>
                    <Typography variant="h6">Not Granted</Typography>
                  </Card>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} className="dashed-border">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} lg={12}>
              <Card className="p-16 count-card">
                <Typography variant="h4" className="fw-600 fs-50">
                  {abbreviateNumber(hoursAndFeeCount.hours)}
                </Typography>
                <Typography variant="h6">Hours</Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Card className="p-16 count-card">
                <Typography variant="h4" className="fw-600 fs-50">
                  ${abbreviateNumber(hoursAndFeeCount.fee)}
                </Typography>
                <Typography variant="h6">Fees</Typography>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default TimeEntriesStats;
