import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import moment from "moment";
import {
  IFilter,
  IFirmDetails,
  ITimesheets,
  IKeyValue,
  IPerson,
  ISelectedFirms,
  ITimeEntry,
  IConfirmDialog,
  ITimeFilter,
  IProfessionalHourStats,
} from "../../vm";
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  MenuDown,
  OpenInNew,
} from "mdi-material-ui";
import { useHistory, useLocation } from "react-router-dom";
import Filter from "../common/Filter";
import { ToastContext } from "../common/ToastProvider";
import {
  getTimesheetPersons,
  getAllTimesheets,
  getTimesheetFiltersCount,
  getAllProfessionalLevels,
  getAllCategories,
  getAllWorkTypes,
  bulkEditTimeEntries,
  disApproveEntriesAPI,
  timesheetReviewed,
  editTimeSheet,
  approveTimesheets,
  getTimeDetailsOfTimesheet,
  getAllApprovedListForTimeEntry,
  getAllEmployeeListDetails,
} from "../../services/TimesheetService";
import Loading from "../common/Loading";
import { FirmContext } from "../common/FirmProvider";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import {
  convertToFixed,
  currencyFormat,
  getCeilValueForTotalCount,
  getFileName,
  getSubDomain,
  getToken,
  hoursFormat,
  numberFormat,
  onlyUnique,
  removeNulls,
  StyledTableCell,
  StyledTableRow,
} from "../../services/UtilService";
import {
  API_URL,
  DESCRIPTION_FILTER_ENTRY,
  styles,
  TIMESHEET_STATUS,
  TIME_ENTRY_KEY_WORDS,
  MAX_PAGINATION_OPTIONS,
} from "../../Constant";
import CustomDrawer from "../common/CustomDrawer";
import ExportDialog from "../common/ExportDialog";
import ManageTimeEntryDialog from "./ManageTimeEntryDialog";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import { getManagementRuleFilters } from "../../services/ManagementDashboardService";
import ToggleFilterSection from "../dashboard/ToggleFilter";
import ViewTimesheetTable from "./ViewTimesheetTable";
import ManageSplitTimeEntryDialog from "./ManageSplitTimeEntryDialog";
import ManageNotesDialog from "../notes/ManageNotesDialog";
import LogDialog from "./LogDialog";
import RuleFailedDialog from "./RuleFailedDialog";
import TimeSheetHeader from "./TimeSheetHeader";
import { getAllNotes } from "../../services/NoteService";
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";

export interface TimesheetsDetailsMainProps extends IConfirmDialog {}

const TimesheetsDetailsMain: React.FC<TimesheetsDetailsMainProps> = ({
  confirmDialog,
}) => {
  const history = useHistory();
  const location = useLocation();
  const fromManagementDashboard = React.useRef(false);
  const managementDashboardId = React.useRef(undefined);
  const [isFilterOpen, setFilterOpen] = React.useState(true);
  const [manageSplitDialog, setManageSplitDialog] = React.useState({
    isOpen: false,
    index: undefined,
    data: undefined,
  });
  const [loadedFilter, setLoadedFilter] = React.useState(
    undefined as ITimeFilter
  );
  let searchObj: any = {};
  if (location.state) {
    let searchState = { ...(location.state as any) };
    // if (searchState.status) {
    //   searchObj.statuses = Array.isArray(searchObj.status) ? searchObj.status : [searchState.status];
    // }
    if (searchState.statuses) {
      searchObj.statuses = searchState.statuses;
    }
    // if (searchState.professionalLevel) {
    //   searchObj.professionalLevel = [searchState.professionalLevel];
    // }
    // if (searchState.categoryCode) {
    //   searchObj.categoryCode = [searchState.categoryCode];
    // }
    if (searchState.startDate) {
      searchObj.startDate = searchState.startDate;
    }
    if (searchState.endDate) {
      searchObj.endDate = searchState.endDate;
    }
    // if (searchState.selectedFirm?.length > 0) {
    //   searchObj.firms = searchState.selectedFirm;
    // }
    if (searchState.firms?.length > 0) {
      searchObj.firms = searchState.firms;
    }
    if (searchState.professionalLevel?.length > 0) {
      searchObj.professionalLevel = searchState.professionalLevel;
    }
    if (searchState.categoryCode?.length > 0) {
      searchObj.categoryCode = searchState.categoryCode;
    }
    if (searchState.workType?.length > 0) {
      searchObj.workType = searchState.workType;
    }
    if (searchState.keyword?.length > 0) {
      searchObj.keyword = searchState.keyword;
    }
    if (searchState.min) {
      searchObj.min = searchState.min;
    }
    if (searchState.max) {
      searchObj.max = searchState.max;
    }
    if (searchState.minWordCount) {
      searchObj.minWordCount = searchState.minWordCount;
    }
    if (searchState.maxWordCount) {
      searchObj.maxWordCount = searchState.maxWordCount;
    }
    if (
      searchState.descriptionSearchFilters &&
      searchState.descriptionSearchFilters.length > 0
    ) {
      searchObj.descriptionSearchFilters = searchState.descriptionSearchFilters;
    }
    if (searchState.showDisapproved) {
      searchObj.showDisapproved = searchState.showDisapproved;
    }
    if (searchState.persons && searchState.persons.length > 0) {
      searchObj.persons = searchState.persons;
    }
    // if (searchState.isFromManagementDashboard) {
    //   fromManagementDashboard.current = true;
    //   managementDashboardId.current = searchState.timeManagementDashboardId;
    // }
    // if (searchState.showMultipleBillingRates) {
    //   searchObj.showMultipleBillingRates = true;
    // }
    if (searchState.approvedBy?.length > 0) {
      searchObj.approvedBy = searchState.approvedBy;
    }
    // if (searchState.attentionNames?.length > 0) {
    //     searchObj.attentionNames = searchState.attentionNames;
    // }
    if (searchState.showOnlyDisapproved) {
      searchObj.showOnlyDisapproved = searchState.showOnlyDisapproved;
    }
    // storing filter name in search obj
    if (searchState.filterName) {
      searchObj.filterName = searchState.filterName;
    }
    if (searchState.noteId) {
      searchObj.noteId = searchState.noteId;
    }
  }
  const [state, setState] = React.useState({
    timeEntries: [],
    selectedTimeEntries: [],
    unselectedTimeEntries: [],
    selectedFirmDetails: [],
    hasAllSelectionChanged: false,
    isAllSelected: false,
    manageTimeEntryDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
      isBulkEdit: false,
    },
    search: {
      from: 0,
      size: 10,
      statuses: searchObj?.statuses ? searchObj.statuses : [],
      firms: searchObj.firms ? searchObj.firms : [],
      persons: searchObj.persons ? searchObj.persons : [],
      // startDate: searchObj.startDate
      //   ? searchObj.startDate
      //   : moment("2017-01-01", "YYYY-MM-DD").subtract(1, "year").toDate(),
      // endDate: searchObj.endDate ? searchObj.endDate : moment().toDate(),
      startDate: searchObj.startDate ? searchObj.startDate : undefined,
      endDate: searchObj.endDate ? searchObj.endDate : undefined,
      sortBy: "firmName",
      sortOrder: "asc",
      keyword: searchObj?.keyword || [],
      min: searchObj.min || "",
      max: searchObj.max || "",
      minWordCount: searchObj.minWordCount || "",
      maxWordCount: searchObj.maxWordCount || "",
      showDisapproved: searchObj.showDisapproved || false,
      descriptionSearchFilters: searchObj.descriptionSearchFilters || [],
      categoryCode: searchObj?.categoryCode ? searchObj.categoryCode : [],
      workType: searchObj?.workType ? searchObj.workType : [],
      professionalLevel: searchObj?.professionalLevel
        ? searchObj.professionalLevel
        : [],
      showMultipleBillingRates: searchObj.showMultipleBillingRates || false,
      approvedBy: searchObj?.approvedBy ? searchObj.approvedBy : [],
      attentionNames: searchObj?.attentionNames ? searchObj.attentionNames : [],
      showOnlyDisapproved: searchObj.showOnlyDisapproved || false,
      noteId: searchObj.noteId || "",
    },
    timesheetCount: 0,
    filterData: [],
    rowsList: [10],
    manageRuleFailedDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
    },
    viewLogsDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
    },
  } as {
    search: {
      from: number;
      size: number;
      firms: string[];
      persons: string[];
      statuses: string[];
      startDate?: Date;
      endDate?: Date;
      sortBy?:
        | ""
        | "firm"
        | "firmName"
        | "person"
        | "new"
        | "reviewed"
        | "adjusted"
        | "attention"
        | "approved"
        | "disapproved"
        | "submitted"
        | "granted"
        | "not-granted";
      sortOrder?: "asc" | "desc";
      keyword: string[];
      categoryCode: any;
      workType: any;
      professionalLevel: any;
      min?: any;
      max?: any;
      minWordCount?: any;
      maxWordCount?: any;
      showDisapproved?: boolean;
      descriptionSearchFilters: Array<{ search: string; isExcluded: boolean }>;
      showMultipleBillingRates: boolean;
      approvedBy: string[];
      attentionNames: string[];
      showOnlyDisapproved?: boolean;
      noteId: any; // can be number or string
    };
    timeEntries: ITimeEntry[];
    selectedTimeEntries: number[];
    unselectedTimeEntries: number[];
    hasAllSelectionChanged: boolean;
    isAllSelected: boolean;
    selectedFirmDetails: ISelectedFirms[];
    manageTimeEntryDialog: {
      isOpen: boolean;
      data?: ITimeEntry;
      index?: number;
      isBulkEdit: boolean;
    };
    timesheetCount: number;
    filterData: IFilter[];
    rowsList: number[];
    options: { value: string; label: string }[];
    manageRuleFailedDialog: { isOpen: boolean; data?: any; index?: number };
    viewLogsDialog: { isOpen: boolean; data?: any; index?: number };
  });

  const [isExportDialogOpen, setExportDialogOpen] = React.useState(false);
  const [isFilterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("");
  const [firmsDic, setFirmsDic] = React.useState({} as IKeyValue<IFirmDetails>);

  const [personsDic, setPersonsDic] = React.useState({} as IKeyValue<IPerson>);
  const { showToast } = React.useContext(ToastContext);
  const firmProvider = React.useContext(FirmContext);
  const [filterData, setFilterData] = React.useState([] as IFilter[]);
  const [initialFirms, setInitialFirms] = React.useState(
    {} as IKeyValue<IFirmDetails>
  );
  const [initialAttentionNames, setInitialAttentionNames] = React.useState(
    {} as string[]
  );
  const [initialProfessionalLevels, setInitialProfessionalLevels] =
    React.useState({} as IKeyValue<{ label: string; value: string }>);
  const [initialCategoryCodes, setInitialCategoryCodes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialWorkTypes, setInitialWorkTypes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialApprovedBy, setInitialApprovedBy] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [noteList, setNoteList] = React.useState(
    [] as Array<{ label: string; value: string }>
  );
  const [, setProfessionalLevels] = React.useState([]);
  const [, setCategories] = React.useState([]);
  const [keyWordsList] = React.useState(TIME_ENTRY_KEY_WORDS);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isCountsLoaded, setCountsLoaded] = React.useState(false);
  const [filterCountsDic, setFilterCountsDic] = React.useState({});
  const [actionAnchorEl, setActionAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [manageNoteDialog, setManageNoteDialog] = React.useState({
    isOpen: false,
    timeEntryIds: [],
    isAddToNote: false,
  } as {
    isOpen: boolean;
    timeEntryIds: string[];
    isAddToNote: boolean;
  });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [allEmployeeDetails, setAllEmployeeDetails] = React.useState(
    {} as IProfessionalHourStats
  );
  const [lastPageSize, setLastPageSize] = React.useState(
    state.search.size || 10
  );
  const { isReadOnly, updateStatus } = React.useContext(IsReadOnlyContext);
  const [dateRangePickerMinMaxObj, setDateRangePickerMinMaxObj] =
    React.useState({
      minDate: undefined,
      maxDate: undefined,
    } as {
      minDate?: Date;
      maxDate?: Date;
    });

  React.useEffect(() => {
    if (searchObj.filterName) {
      setLoadedFilter({ name: searchObj.filterName } as any);
    }
    getAllRecordsFromStarting();
  }, []);

  const getAllRecordsFromStarting = async (search?) => {
    setLoading(true);
    let professionalLevelsDic = await getAllProfessionalLevelsList();
    let categoriesCodeDic = await getAllCategoriesList();
    let workTypesDic = await getAllWorkTypesList();
    let approvedByDic = await getAllApprovedByList();
    let noteList = await getAllNoteList();
    // employee hours details
    // await getEmployeeDetails();
    await getUserFirms({
      professionalLevelsDic,
      categoriesCodeDic,
      search,
      workTypesDic,
      approvedByDic,
      noteList,
    });
    if (managementDashboardId.current) {
      await getCurrentSelectedFilter();
    }
    setLoading(false);
  };

  const getCurrentSelectedFilter = async () => {
    const result = await getManagementRuleFilters(
      managementDashboardId.current
    );
    if (result?.isSuccess && result.data?.length > 0) {
      try {
        let details = result.data[0];
        let filterSelected = JSON.parse(details.filters);
        setLoadedFilter({ ...details, filters: filterSelected });
      } catch (error) {
        showToast(
          "Error while loading the management dashboard filter.",
          "error"
        );
      }
    } else {
      showToast(
        result.message ||
          "Error while loading the management dashboard filter.",
        "error"
      );
    }
  };

  const getAllProfessionalLevelsList = async () => {
    const result = await getAllProfessionalLevels();
    let professionalLevelDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return {
            label: x.professionalLevelName,
            value: x.professionalLevelCode,
          };
        });
        professionalLevelDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialProfessionalLevels(professionalLevelDic);
        setProfessionalLevels(data || []);
      }
    } else {
      showToast(
        result.message || "Error while fetching professional levels",
        "error"
      );
    }
    return professionalLevelDic;
  };
  const getAllCategoriesList = async () => {
    const result = await getAllCategories();
    let categoryCodeDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.timeCategoryName, value: x.timeCategoryCode };
        });
        categoryCodeDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialCategoryCodes(categoryCodeDic);
        setCategories(data || []);
      }
    } else {
      showToast(result.message || "Error while fetching categories", "error");
    }
    return categoryCodeDic;
  };
  const getAllWorkTypesList = async () => {
    const result = await getAllWorkTypes();
    let workTypesDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.workTypeLongDescription, value: x.workTypeCode };
        });
        workTypesDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialWorkTypes(workTypesDic);
      }
    } else {
      showToast(result.message || "Error while fetching work types", "error");
    }
    return workTypesDic;
  };
  const getAllApprovedByList = async () => {
    const result = await getAllApprovedListForTimeEntry();
    let approvedByDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.timeApprovedByName, value: x.timeApprovedByCode };
        });
        approvedByDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialApprovedBy(approvedByDic);
      }
    } else {
      showToast(result.message || "Error while fetching approved by", "error");
    }
    return approvedByDic;
  };

  const getAllNoteList = async () => {
    const result = await getAllNotes({});
    let noteList = [];
    if (result?.isSuccess) {
      if (result?.data) {
        noteList = result.data.results.map((x) => {
          return { label: x.description, value: x.id };
        });
        setNoteList(noteList);
      }
    } else {
      showToast(result.message || "Error while fetching notes", "error");
    }
    return noteList;
  };

  const getEmployeeDetails = async (searchObj?: any) => {
    setLoading(true);
    let tempSearch = { ...state.search };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }

    const result = await getAllEmployeeListDetails(tempSearch);
    if (result?.isSuccess) {
      setAllEmployeeDetails(result.data || ({} as any));
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching employee hours details",
        "error"
      );
    }
  };

  const getTimesheetDetailsFilters = async (
    search?: any,
    firmsDic?: any,
    personsDic?: any,
    changedProperty?: any,
    professionCategoriesData?: any,
    resetSelectAll?: boolean,
    shouldBeSameSize?: boolean
  ) => {
    let tempSearch = { ...state.search };
    if (search) {
      tempSearch = { ...state.search, ...search };
      if (changedProperty === "load-filters") {
        tempSearch = { ...search, from: 0, size: tempSearch.size };
      }
    }
    if (!tempSearch.endDate) {
      delete tempSearch.endDate;
    }
    if (!tempSearch.startDate) {
      delete tempSearch.startDate;
    }
    let initialFirmsTemp = { ...firmsDic };
    if (firmsDic) {
      initialFirmsTemp = {
        ...initialFirms,
        ...firmsDic,
      };
    }
    let initialProfessionalLevelTemp = { ...initialProfessionalLevels };
    if (professionCategoriesData?.professionalLevelsDic) {
      initialProfessionalLevelTemp = {
        ...initialProfessionalLevels,
        ...professionCategoriesData.professionalLevelsDic,
      };
    }
    let initialCategoryCodeTemp = { ...initialCategoryCodes };
    if (professionCategoriesData?.categoriesCodeDic) {
      initialCategoryCodeTemp = {
        ...initialCategoryCodes,
        ...professionCategoriesData.categoriesCodeDic,
      };
    }
    let initialWorkTypesTemp = { ...initialWorkTypes };
    if (professionCategoriesData?.workTypesDic) {
      initialWorkTypesTemp = {
        ...initialWorkTypes,
        ...professionCategoriesData.workTypesDic,
      };
    }
    let initialApprovedByTemp = { ...initialApprovedBy };
    if (professionCategoriesData?.approvedByDic) {
      initialApprovedByTemp = {
        ...initialApprovedBy,
        ...professionCategoriesData.approvedByDic,
      };
    }
    let initialNoteListTemp = [...noteList];
    if (professionCategoriesData?.noteList) {
      initialNoteListTemp = [
        ...initialNoteListTemp,
        ...professionCategoriesData.noteList,
      ];
    }
    // let searchForFilter = { ...tempSearch, isAttentionPage: true };
    let searchForFilter = { ...tempSearch, isAttentionPage: false };
    let keyWords = [...keyWordsList];
    // if (changedProperty) {
    //   switch (changedProperty) {
    //     case "firmCounts":
    //       searchForFilter.statuses = [];
    //       break;
    //     case "statusCounts":
    //       searchForFilter.firms = [];
    //       break;
    //     default:
    //       break;
    //   }
    // }
    delete tempSearch.size;
    delete tempSearch.from;
    setLoading(true);
    let result = {
      isSuccess: true,
      success: true,
      message: "",
      data: {
        firmCounts: {},
        statusCounts: {},
        professionalCounts: {},
        attentionCounts: [],
        categoryCodeCounts: {},
        workTypeCounts: {},
        keywordCounts: {},
        personCounts: {},
        approvedByCounts: {},
      } as any,
    };
    if (changedProperty !== "add-descriptions") {
      await getEmployeeDetails(searchForFilter);
    }
    if (isCountsLoaded === false || changedProperty == "clear-filters") {
      result = await getTimesheetFiltersCount(searchForFilter, false, {
        isAttentionPage: false,
      });
    }
    setLoading(false);
    if (result?.isSuccess) {
      console.log("result", result.data);
      let filterCountDic = filterCountsDic;
      if (isCountsLoaded === false || changedProperty == "clear-filters") {
        let keyParams = {
          workTypeCounts: "workType",
          categoryCodeCounts: "categoryCode",
          firmCounts: "firmCode",
          attentionCounts: "attentionCounts",
          keywordCounts: "keyword",
          statusCounts: "status",
          professionalCounts: "professionalLevel",
          personCounts: "personId",
          approvedByCounts: "timeApprovedByCode",
        };
        filterCountDic = Object.keys(result.data).reduce(
          (acc, key) => {
            if (acc[key]) {
              result.data[key].forEach((data) => {
                acc[key][data[keyParams[key]]] = data;
              });
            }
            return acc;
          },
          {
            workTypeCounts: {},
            categoryCodeCounts: {},
            firmCounts: {},
            attentionCounts: [],
            keywordCounts: {},
            statusCounts: {},
            professionalCounts: {},
            personCounts: {},
            approvedByCounts: {},
          }
        );
        // if(result.data?.startDate){
        //   tempSearch.startDate = result.data.startDate
        // }
        // if(result.data?.endDate){
        //   tempSearch.endDate = result.data.endDate
        // }
        setFilterCountsDic(filterCountDic);
      }
      let data = { ...result.data };
      // updating state as it will use as min, max for date range picker
      setDateRangePickerMinMaxObj({
        minDate: data.startDate || dateRangePickerMinMaxObj.minDate,
        maxDate: data.endDate || dateRangePickerMinMaxObj.maxDate,
      });

      if (!tempSearch.startDate) {
        tempSearch.startDate = data?.startDate || tempSearch.startDate;
      }
      if (!tempSearch.endDate) {
        tempSearch.endDate = data?.endDate || tempSearch.endDate;
      }
      if (!tempSearch.max && data?.maxTimeSpentHours) {
        tempSearch.max = data?.maxTimeSpentHours;
      }
      let filterOrder = [
        "descriptionSearchFilters",
        "workTypeCounts",
        "time",
        "statusCounts",
        "showDisapproved",
        "showOnlyDisapproved",
        "firmCounts",
        "personCounts",
        "professionalCounts",
        "categoryCodeCounts",
        // "attentionCounts",
        "approvedBy",
        "noteId",
        "keyword",
        "min-max",
        "word-counts",
      ];
      let customFilters = [
        "time",
        "keyword",
        "min-max",
        "descriptionSearchFilters",
        "word-counts",
        "showDisapproved",
        "showOnlyDisapproved",
      ];
      let filter: IFilter[] = [];

      filterOrder.forEach((filterType) => {
        if (customFilters.indexOf(filterType) > -1) {
          switch (filterType) {
            case "time":
              filter.push({
                header: "Time Period",
                name: "time",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "time")?.isHidden) ||
                  false,
                items: [
                  {
                    type: "date-range",
                    value: [
                      {
                        startDate: tempSearch.startDate,
                        endDate: tempSearch.endDate,
                        key: "selection",
                      },
                    ],
                    label: "",
                  },
                ],
                // items: [
                //     {
                //         value: tempSearch.startDate,
                //         type: "date",
                //         label: "Start Date",
                //         name: "startDate",
                //     },
                //     {
                //         value: tempSearch.endDate,
                //         type: "date",
                //         label: "End Date",
                //         name: "endDate",
                //     },
                // ],
              });
              break;
            case "keyword":
              filter.push({
                header: "Keywords",
                name: "keyword",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "keyword")?.isHidden) ||
                  false,
                items: keyWords.map((ele) => {
                  return {
                    isSelected: tempSearch.keyword
                      ? tempSearch.keyword.includes(ele.value)
                      : false,
                    label: ele.label,
                    type: "checkbox",
                    name: ele.value,
                    // count:
                    //   changedProperty !== undefined &&
                    //   changedProperty === ele.label &&
                    //   tempSearch.statuses.length > 0
                    //     ? filterData
                    //         .find((ed) => ed.name === 'professionalLevel')
                    //         ?.items.find((ed) => ed.name === ele.label)?.count || 0
                    //     : statusCounts.find((e) => e.status === eds)?.count || 0,
                  };
                }),
              });
              break;
            // case "approvedBy":
            //     filter.push({
            //         header: "Approved By",
            //         name: "approvedBy",
            //         isHidden:
            //             (filterData.length > 0 &&
            //                 filterData.find((e) => e.name === "approvedBy")?.isHidden) ||
            //             false,
            //         items: Object.keys(initialApprovedByTemp).map((ele) => {
            //             return {
            //                 isSelected: tempSearch.approvedBy.includes(ele),
            //                 label: initialApprovedByTemp[ele].label,
            //                 type: "checkbox",
            //                 name: ele,
            //             };
            //         }),
            //     });
            //     break;
            case "min-max":
              filter.push({
                header: "Total Time Spent for Day",
                name: "min-max",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "min-max")?.isHidden) ||
                  false,
                items: [
                  {
                    label: "Min",
                    type: "number",
                    name: "min",
                    value: tempSearch.min,
                  },
                  {
                    label: "Max",
                    type: "number",
                    name: "max",
                    value: tempSearch.max,
                  },
                ],
              });
              break;
            case "descriptionSearchFilters":
              filter.push({
                header: "Description Keyword Search",
                name: "descriptionSearchFilters",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find(
                      (e) => e.name === "descriptionSearchFilters"
                    ).isHidden) ||
                  false,
                items:
                  tempSearch.descriptionSearchFilters.length > 0
                    ? tempSearch.descriptionSearchFilters.map((d) => {
                        let itemObj = {
                          type: "text-checkbox",
                          value: d.search,
                          isSelected: d.isExcluded,
                          name: "description",
                          label: "",
                        };
                        return itemObj;
                      })
                    : [{ ...DESCRIPTION_FILTER_ENTRY }],
              });
              break;
            case "word-counts":
              filter.push({
                header: "Word Count",
                name: "word-counts",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "word-counts")
                      ?.isHidden) ||
                  false,
                items: [
                  {
                    label: "Min Words",
                    type: "number",
                    name: "minWordCount",
                    value: tempSearch.minWordCount,
                  },
                  {
                    label: "Max Words",
                    type: "number",
                    name: "maxWordCount",
                    value: tempSearch.maxWordCount,
                  },
                ],
              });
              break;
            case "showDisapproved":
              filter.push({
                className: "disapproved-section",
                header: "",
                name: "showDisapproved",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "showDisapproved")
                      ?.isHidden) ||
                  false,
                items: [
                  {
                    isSelected: tempSearch.showDisapproved,
                    label: "Show Disapproved",
                    type: "switch",
                    name: "showDisapproved",
                    isDisabled: tempSearch.showOnlyDisapproved,
                  },
                ],
              });
              break;
            case "showOnlyDisapproved":
              filter.push({
                className: "only-disapproved-section",
                header: "",
                name: "showOnlyDisapproved",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "showOnlyDisapproved")
                      ?.isHidden) ||
                  false,
                items: [
                  {
                    isSelected: tempSearch.showOnlyDisapproved,
                    label: "Show Only Disapproved",
                    type: "switch",
                    name: "showOnlyDisapproved",
                  },
                ],
              });
              break;
            default:
              break;
          }
        } else {
          let ele = filterType;
          let a: IFilter = { header: "", items: [], name: ele };
          switch (ele) {
            case "statusCounts":
              a.header = "Status";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let statusCounts = data["statusCounts"] || [];
              Object.keys(TIMESHEET_STATUS).map((eds) => {
                a.items.push({
                  isSelected: tempSearch.statuses
                    ? tempSearch.statuses.includes(eds)
                    : false,
                  label: TIMESHEET_STATUS[eds],
                  type: "checkbox",
                  name: eds,
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.statuses.length > 0
                        ? filterData
                            .find((ed) => ed.name === ele)
                            ?.items.find((ed) => ed.name === eds)?.count || 0
                        : statusCounts.find((e) => e.status === eds)?.count || 0
                      : filterCountDic?.["statusCounts"]?.[eds]?.count || 0,
                  isDisabled: tempSearch.showOnlyDisapproved,
                });
              });
              break;
            case "firmCounts":
              a.header = "Firm";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let firmCounts = data["firmCounts"] || [];
              Object.keys(initialFirmsTemp).map((element) => {
                a.items.push({
                  label: firmsDic[element]
                    ? firmsDic[element].lawFirmShortName
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.firms.length > 0
                        ? filterData
                            .find((ed) => ed.name === ele)
                            ?.items.find((ed) => ed.name === String(element))
                            ?.count || 0
                        : firmCounts.find((e) => e.firmCode === element)
                            ?.count || 0
                      : filterCountDic?.["firmCounts"]?.[element]?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.firms
                    ? tempSearch.firms.includes(element)
                    : false,
                });
              });
              // sorting based on label i.e. firm short name
              a.items.sort((a, b) => (a.label > b.label ? 1 : -1));
              break;
            case "attentionCounts":
              a.header = "Attention";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              if (
                data["attentionCounts"] &&
                data["attentionCounts"].length > 0
              ) {
                setInitialAttentionNames(data["attentionCounts"]);
              }
              let attentionCounts = data["attentionCounts"];
              if (initialAttentionNames.length > 0) {
                attentionCounts = initialAttentionNames;
              }
              attentionCounts.forEach((attention) => {
                a.items.push({
                  label: attention,
                  type: "checkbox",
                  name: attention,
                  isSelected: tempSearch.attentionNames
                    ? tempSearch.attentionNames.includes(attention)
                    : false,
                });
              });
              break;
            case "personCounts":
              a.header = "";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let personsCount: Array<any> = filterCountDic?.["personCounts"]
                ? Object.values(filterCountDic?.["personCounts"])
                : [];
              let options = [];
              let selectedItems = [];
              personsCount.map((ele) => {
                if (
                  tempSearch.persons.length > 0 &&
                  tempSearch.persons.includes(ele.personId)
                ) {
                  selectedItems.push({
                    value: ele.personId,
                    label: personsDic[ele.personId]?.name,
                  });
                }
                options.push({
                  value: ele.personId,
                  label: personsDic[ele.personId]?.name,
                });
              });
              a.items = [
                {
                  label: "Resources",
                  type: "dropdown",
                  name: ele,
                  selectedItems: selectedItems || [],
                  options:
                    changedProperty !== undefined &&
                    changedProperty === ele &&
                    tempSearch.persons.length > 0
                      ? filterData.find((ed) => ed.name === ele)?.items[0]
                          .options
                      : options,
                },
              ];
              break;
            case "professionalCounts":
              a.header = "Professional Level";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let professionalCounts = data["professionalCounts"] || [];
              Object.keys(initialProfessionalLevelTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.professionalLevelsDic[element]
                    ? professionCategoriesData.professionalLevelsDic[element]
                        .label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.professionalLevel.length > 0
                        ? filterData
                            .find((ed) => ed.name === ele)
                            ?.items.find((ed) => ed.name === element)?.count ||
                          0
                        : professionalCounts.find(
                            (e) => e.professionalLevel === element
                          )?.count || 0
                      : filterCountDic?.["professionalCounts"]?.[element]
                          ?.count || 0,
                  name: element,
                  isSelected: tempSearch.professionalLevel
                    ? tempSearch.professionalLevel.includes(element)
                    : false,
                });
              });
              break;
            case "workTypeCounts":
              a.header = "Work Types";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let workTypeCounts = data["workTypeCounts"] || [];
              Object.keys(initialWorkTypesTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.workTypesDic[element]
                    ? professionCategoriesData.workTypesDic[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.workType.length > 0
                        ? filterData
                            .find((ed) => ed.name === ele)
                            ?.items.find((ed) => ed.name === String(element))
                            ?.count || 0
                        : workTypeCounts.find((e) => e.workType === element)
                            ?.count || 0
                      : filterCountDic?.["workTypeCounts"]?.[element]?.count ||
                        0,
                  name: String(element),
                  isSelected: tempSearch.workType
                    ? tempSearch.workType.includes(element)
                    : false,
                });
              });
              break;
            case "categoryCodeCounts":
              a.header = "Time Category";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let categoryCodeCounts = data["categoryCodeCounts"] || [];
              Object.keys(initialCategoryCodeTemp)
                .sort((a, b) =>
                  initialCategoryCodeTemp[a].label >
                  initialCategoryCodeTemp[b].label
                    ? 1
                    : -1
                )
                .map((element) => {
                  a.items.push({
                    label: professionCategoriesData.categoriesCodeDic[element]
                      ? professionCategoriesData.categoriesCodeDic[element]
                          .label
                      : "",
                    type: "checkbox",
                    count:
                      isCountsLoaded === false
                        ? changedProperty !== undefined &&
                          changedProperty === ele &&
                          tempSearch.categoryCode.length > 0
                          ? filterData
                              .find((ed) => ed.name === ele)
                              ?.items.find((ed) => ed.name === String(element))
                              ?.count || 0
                          : categoryCodeCounts.find(
                              (e) => e.categoryCode === element
                            )?.count || 0
                        : filterCountDic?.["categoryCodeCounts"]?.[element]
                            ?.count || 0,
                    name: String(element),
                    isSelected: tempSearch.categoryCode
                      ? tempSearch.categoryCode.includes(element)
                      : false,
                  });
                });
              break;
            case "approvedBy":
              a.header = "Approved By";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let approvedByCounts = data["approvedByCounts"] || [];
              Object.keys(initialApprovedByTemp).map((element) => {
                a.items.push({
                  label: initialApprovedByTemp[element]
                    ? initialApprovedByTemp[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.approvedBy.length > 0
                        ? filterData
                            .find((ed) => ed.name === ele)
                            ?.items.find((ed) => ed.name === String(element))
                            ?.count || 0
                        : approvedByCounts.find((e) => e.approvedBy === element)
                            ?.count || 0
                      : filterCountDic?.["approvedByCounts"]?.[element]
                          ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.approvedBy
                    ? tempSearch.approvedBy.includes(element)
                    : false,
                });
              });
              break;
            case "noteId":
              a.header = "";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let noteOptions = [];
              let selectedNoteItems = [];
              initialNoteListTemp.map((ele) => {
                if (tempSearch.noteId === ele.value) {
                  selectedNoteItems.push(ele);
                }
                noteOptions.push(ele);
              });
              a.items = [
                {
                  label: "Notes",
                  type: "dropdown",
                  name: ele,
                  selectedItems: selectedNoteItems || [],
                  options:
                    changedProperty !== undefined &&
                    changedProperty === ele &&
                    tempSearch.noteId
                      ? filterData.find((ed) => ed.name === ele)?.items[0]
                          .options
                      : noteOptions,
                  isMulti: false,
                },
              ];
              break;
            default:
              a = undefined;
              break;
          }
          if (a) {
            filter.push(a);
          }
        }
      });

      // filter.push({
      //   header: "",
      //   name: "extra-filters",
      //   isHidden: false,
      //   items: [
      //     {
      //       isSelected: tempSearch.showMultipleBillingRates,
      //       label: "Show Multiple Bill Rates",
      //       type: "checkbox",
      //       name: "showMultipleBillingRates",
      //     },
      //   ],
      // });
      setCountsLoaded(true);
      setFilterData(filter);
      // setInitialFirms(initialFirmsTemp);
      // setInitialProfessionalLevels(initialProfessionalLevelTemp);
      // setInitialCategoryCodes(initialCategoryCodeTemp);
      if (changedProperty !== "add-descriptions") {
        // tempSearch.from = 0;
        // tempSearch.size = 10;

        await getTimesheetsList(
          tempSearch,
          resetSelectAll,
          shouldBeSameSize,
          changedProperty,
          filter
        );
      }
    } else {
      showToast(
        result.message || "Error while fetching timesheets counts",
        "error"
      );
    }
  };

  const getUserFirms = async (professionCategoriesData) => {
    let userFirms = firmProvider.userFirms;
    if (userFirms.length === 0) {
      userFirms = await firmProvider.getFirmsList();
    }
    let firmsDic = userFirms.reduce((acc: any, ele) => {
      acc[ele.lawFirmCode] = ele;
      return acc;
    }, {});
    setFirmsDic(firmsDic);
    setInitialFirms(firmsDic);
    await getPersonsList(firmsDic, professionCategoriesData);
  };

  const getPersonsList = async (firmsDic, professionCategoriesData) => {
    let result = await getTimesheetPersons();
    if (result?.isSuccess) {
      if (result.data.length > 0) {
        let personsDic = result.data.reduce((acc: any, ele: any) => {
          acc[ele.resourceName] = {
            personId: ele.resourceName,
            name: (ele.firstName || "") + " " + (ele.lastName || ""),
          };
          return acc;
        }, {});
        setPersonsDic(personsDic);
        await getTimesheetDetailsFilters(
          professionCategoriesData?.search || undefined,
          firmsDic,
          personsDic,
          undefined,
          professionCategoriesData
        );
      }
    }
  };

  const getTimesheetsList = async (
    search?: any,
    resetSelectAll?: boolean,
    shouldBeSameSize?: boolean,
    changedProperty?: string,
    filter?: IFilter[],
    isAllSelectedFromRowsPerPage?: boolean
  ) => {
    let tempSearch = { ...state.search };
    if (search) {
      tempSearch = { ...state.search, ...search };
    }
    if (!tempSearch.endDate) {
      delete tempSearch.endDate;
    }
    if (!tempSearch.startDate) {
      delete tempSearch.startDate;
    }
    if (shouldBeSameSize) {
      tempSearch.size = rowsPerPage;
    }
    let searchObj = { ...tempSearch };
    setLoading(true);
    if (
      searchObj.descriptionSearchFilters &&
      searchObj.descriptionSearchFilters.length
    ) {
      searchObj.descriptionSearchFilters =
        searchObj.descriptionSearchFilters.filter((x) => x.search);
    }
    // if size and total data count is same then changing the size to nearest value multiply by 10
    // if (!isAllSelectedFromRowsPerPage && searchObj.size === state.timesheetCount) {
    if (
      !isAllSelectedFromRowsPerPage &&
      searchObj.size === getCeilValueForTotalCount(state.timesheetCount)
    ) {
      // let updatedSize = Math.round(searchObj.size / 10) * 10;
      // if (updatedSize === 0) updatedSize = 10;
      // searchObj.size = updatedSize;
      // tempSearch.size = updatedSize;
      // last saved size from another variable
      searchObj.size = lastPageSize;
      tempSearch.size = lastPageSize;
    }
    // if (isAllSelectedFromRowsPerPage) {
    //     setLastPageSize(state.search.size);
    // }
    const result = await getTimeDetailsOfTimesheet(searchObj);
    if (result?.isSuccess) {
      let isAllSelected = state.isAllSelected;
      let selectedTimeEntries = [...state.selectedTimeEntries];
      let unselectedTimeEntries = [...state.unselectedTimeEntries];
      let hasAllSelectionChanged = state.hasAllSelectionChanged;
      if (hasAllSelectionChanged) {
        selectedTimeEntries = [
          ...selectedTimeEntries,
          ...result.data.results
            .filter(
              (x) =>
                state.unselectedTimeEntries.indexOf(x.timeCommonBenefitId) ===
                -1
            )
            .map((x) => x.timeCommonBenefitId),
        ];
      }
      if (resetSelectAll) {
        isAllSelected = false;
        hasAllSelectionChanged = false;
        selectedTimeEntries = [];
        unselectedTimeEntries = [];
      }
      let menuItems = [];
      for (let index = 10; index <= result.data.count; index = index + 10) {
        menuItems.push(index);
      }
      if (menuItems[menuItems.length - 1] < result.data.count) {
        menuItems.push(getCeilValueForTotalCount(result.data.count));
        // menuItems.push(result.data.count);
      }
      if (menuItems.length > MAX_PAGINATION_OPTIONS) {
        menuItems = menuItems.slice(0, MAX_PAGINATION_OPTIONS);
      }
      // else {
      //     let totalCount = menuItems.pop();
      //     if (totalCount) {
      //         menuItems.unshift({ label: "All", value: totalCount });
      //     }
      // }
      // checking is the size is more than the data count
      if (tempSearch.size > result.data.count) {
        setLastPageSize(tempSearch.size);
        tempSearch.size = getCeilValueForTotalCount(result.data.count) || 10;
        // tempSearch.size = result.data.count || 10;
      }
      if (
        changedProperty === "noteId" &&
        result.data.statuses &&
        tempSearch.noteId
      ) {
        tempSearch.statuses = result.data.statuses.filter(
          (x) => x !== "disapproved"
        );
        let statusIndexInFilter = filter.findIndex(
          (x) => x.name === "statusCounts"
        );
        if (statusIndexInFilter > -1) {
          filter[statusIndexInFilter].items.forEach((item) => {
            item.isSelected = tempSearch.statuses
              ? tempSearch.statuses.includes(item.name)
              : false;
          });
        }
        if (
          result.data.statuses.includes("disapproved") &&
          !tempSearch.showDisapproved
        ) {
          let showDisapprovedIndexInFilter = filter.findIndex(
            (x) => x.name === "showDisapproved"
          );
          if (showDisapprovedIndexInFilter > -1) {
            filter[showDisapprovedIndexInFilter].items[0].isSelected = result
              .data.statuses
              ? result.data.statuses.includes("disapproved")
              : false;
          }
          tempSearch.showDisapproved = true;
          confirmDialog.show(
            "Disapproved",
            "Show Disapproved enabled",
            undefined,
            undefined,
            undefined,
            undefined,
            true
          );
        }
        setFilterData(filter);
      }
      setState({
        ...state,
        timesheetCount: result.data.count,
        manageTimeEntryDialog: {
          isOpen: false,
          data: undefined,
          index: undefined,
          isBulkEdit: false,
        },
        timeEntries: result.data.results.map((ele) => {
          ele.isEdit = false;
          if (
            hasAllSelectionChanged &&
            unselectedTimeEntries.indexOf(ele.timeCommonBenefitId) === -1
          ) {
            ele.isSelected = true;
          }
          if (selectedTimeEntries.indexOf(ele.timeCommonBenefitId) > -1) {
            ele.isSelected = true;
          }
          return ele;
        }),
        rowsList: [...menuItems],
        search: { ...state.search, ...tempSearch },
        isAllSelected,
        hasAllSelectionChanged,
        selectedTimeEntries: selectedTimeEntries.filter(onlyUnique),
        unselectedTimeEntries,
        manageRuleFailedDialog: {
          isOpen: false,
          data: undefined,
          index: undefined,
        },
      });
    } else {
      showToast(
        result?.message ? result.message : "Error while fetching timesheets",
        "error"
      );
    }
    setLoading(false);
  };

  const onSearch = async (
    searchObj?: any,
    isAllSelectedFromRowsPerPage?: boolean
  ) => {
    let tempSearch = { ...state.search };
    if (searchObj) {
      tempSearch = { ...state.search, ...searchObj };
    }
    await getTimesheetsList(
      tempSearch,
      undefined,
      undefined,
      undefined,
      undefined,
      isAllSelectedFromRowsPerPage
    );
  };

  const onFilterChange = async (data: IFilter[], changedProperty?: string) => {
    if (changedProperty === "clear-filters") {
      setLoadedFilter(undefined);
      managementDashboardId.current = undefined;
    }
    if (changedProperty === "load-filters") {
      let details = JSON.parse(JSON.stringify(data));
      setLoadedFilter(details);
      await getTimesheetDetailsFilters(
        details.filters,
        firmsDic,
        personsDic,
        changedProperty,
        {
          professionalLevelsDic: { ...initialProfessionalLevels },
          categoriesCodeDic: { ...initialCategoryCodes },
          workTypesDic: { ...initialWorkTypes },
        },
        true
      );
    } else {
      let search = { ...state.search };
      search.from = 0;
      let statusArray =
        data.find((ele) => ele.name === "statusCounts")?.items || [];
      let professionalLevelArray =
        data.find((ele) => ele.name === "professionalCounts")?.items || [];
      let categoryCodeArray =
        data.find((ele) => ele.name === "categoryCodeCounts")?.items || [];
      let workTypesArray =
        data.find((ele) => ele.name === "workTypeCounts")?.items || [];
      let personArray =
        data.find((ele) => ele.name === "personCounts")?.items || [];
      let noteArray = data.find((ele) => ele.name === "noteId")?.items || [];
      let firmArray =
        data.find((ele) => ele.name === "firmCounts")?.items || [];
      let attentionArray =
        data.find((ele) => ele.name === "attentionCounts")?.items || [];
      let approvedByArray =
        data.find((ele) => ele.name === "approvedBy")?.items || [];
      let timeArray = data.find((ele) => ele.name === "time")?.items || [];
      let minMaxArray = data.find((ele) => ele.name === "min-max")?.items || [];
      search.min = minMaxArray.find((ele) => ele.name === "min")?.value || "";
      search.max = minMaxArray.find((ele) => ele.name === "max")?.value || "";
      let minMaxWordCountArray =
        data.find((ele) => ele.name === "word-counts")?.items || [];
      search.minWordCount =
        minMaxWordCountArray.find((ele) => ele.name === "minWordCount")
          ?.value || "";
      search.maxWordCount =
        minMaxWordCountArray.find((ele) => ele.name === "maxWordCount")
          ?.value || "";
      let descriptionArray =
        data.find((ele) => ele.name === "descriptionSearchFilters")?.items ||
        [];
      search.descriptionSearchFilters = descriptionArray
        // .filter((ele) => ele.value)
        .map((ele) => {
          let d = {
            search: ele.value || "",
            isExcluded: ele.isSelected,
          };
          return d;
        });
      let disapprovedStatus =
        data.find((ele) => ele.name === "showDisapproved")?.items || [];
      search.showDisapproved = disapprovedStatus.find(
        (ele) => ele.name === "showDisapproved"
      ).isSelected;
      let onlyDisapprovedStatus =
        data.find((ele) => ele.name === "showOnlyDisapproved")?.items || [];
      search.showOnlyDisapproved = onlyDisapprovedStatus.find(
        (ele) => ele.name === "showOnlyDisapproved"
      ).isSelected;
      if (search.min && search.max && search.min > search.max) {
        showToast("Max value cannot be less than min value", "error");
        return;
      }
      if (
        search.minWordCount &&
        search.maxWordCount &&
        search.minWordCount > search.maxWordCount
      ) {
        showToast(
          "Max word count value cannot be less than min word count value",
          "error"
        );
        return;
      }
      let extraFilters =
        data.find((ele) => ele.name === "extra-filters")?.items || [];
      search.startDate = timeArray[0].value[0].startDate || undefined;
      // timeArray.find((ele) => ele.name === "startDate")?.value || undefined;
      search.endDate = timeArray[0].value[0].endDate || undefined;
      // timeArray.find((ele) => ele.name === "endDate")?.value || undefined;
      search.statuses = statusArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      // if show 'only dis approved' is true we need to make status array empty and 'dis approved' false
      if (search.showOnlyDisapproved) {
        search.showDisapproved = false;
        search.statuses = [];
      }
      search.persons =
        personArray.length > 0
          ? personArray[0].selectedItems.length > 0
            ? personArray[0].selectedItems.map((ele) => ele.value)
            : []
          : [];
      search.firms = firmArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          if (e.name) {
            return e.name;
          }
        });
      search.attentionNames = attentionArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          if (e.name) {
            return e.name;
          }
        });
      search.professionalLevel = professionalLevelArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.categoryCode = categoryCodeArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.workType = workTypesArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      let keywordsArray =
        data.find((ele) => ele.name === "keyword")?.items || [];
      search.keyword = keywordsArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.approvedBy = approvedByArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.noteId =
        noteArray.length > 0
          ? noteArray[0].selectedItems.length > 0
            ? noteArray[0].selectedItems[0].value
            : ""
          : "";
      if (extraFilters?.length > 0) {
        search.showMultipleBillingRates = extraFilters[0].isSelected;
      }
      await getTimesheetDetailsFilters(
        search,
        firmsDic,
        personsDic,
        changedProperty,
        {
          professionalLevelsDic: { ...initialProfessionalLevels },
          categoriesCodeDic: { ...initialCategoryCodes },
          workTypesDic: { ...initialWorkTypes },
        },
        true
      );
    }
  };

  const setNextTimesheets = (data: any) => {
    let dataToSet = JSON.stringify(data);
    localStorage.setItem("nextTimesheets", dataToSet);
  };

  const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className="d-flex">
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <ChevronDoubleLeft />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <ChevronRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <ChevronDoubleRight />
        </IconButton>
      </div>
    );
  };

  const handleDownload = async (
    isZip?: boolean,
    includeChangeLog?: boolean
  ) => {
    let searchObj = { ...state.search };
    let obj = {
      searchQuery: {
        ...searchObj,
        min: searchObj?.min || undefined,
        max: searchObj?.max || undefined,
        from: 0,
        size: state.timesheetCount,
      },
      categoryList: searchObj.categoryCode.map(
        (ele) => initialCategoryCodes[ele].label
      ),
      workType: searchObj.workType,
      professionalLevelNames: searchObj.professionalLevel.map(
        (ele) => initialProfessionalLevels[ele].label
      ),
      firmList: searchObj.firms.map(
        (ele) => initialFirms[ele].lawFirmShortName
      ),
      isZip: isZip ? true : false,
      includeChangeLog: includeChangeLog ? true : false,
      localTime: moment().format("YYYYMMDDHHmmss"),
    };
    obj = removeNulls(obj);
    let result = await fetch(`${API_URL}timesheets/generate-pdf-details`, {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + (getToken() || ""),
        dbName: getSubDomain(),
      },
    });
    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], {
      type: pdf.type === "application/zip" ? "octet/stream" : "application/pdf",
    });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = pdfFileURL;
    a.download = await getFileName(
      pdf.type === "application/zip" ? "zip" : "pdf",
      "case"
    );
    // pdf.type === "application/zip"
    //   ? `Time Entry Summary.zip`
    //   : `Time Entry Summary.pdf`;
    a.click();
  };
  const handleDownloadAsCsv = async (
    isZip?: boolean,
    includeChangeLog?: boolean
  ) => {
    let searchObj = { ...state.search };
    let obj = {
      searchQuery: {
        ...searchObj,
        min: searchObj?.min || undefined,
        max: searchObj?.max || undefined,
        from: 0,
        size: state.timesheetCount,
      },
      categoryList: searchObj.categoryCode.map(
        (ele) => initialCategoryCodes[ele].label
      ),
      workType: searchObj.workType,
      professionalLevelNames: searchObj.professionalLevel.map(
        (ele) => initialProfessionalLevels[ele].label
      ),
      firmList: searchObj.firms.map(
        (ele) => initialFirms[ele].lawFirmShortName
      ),
      isZip: isZip ? true : false,
      includeChangeLog: includeChangeLog ? true : false,
      localTime: moment().format("YYYYMMDDHHmmss"),
    };
    obj = removeNulls(obj);
    let result = await fetch(`${API_URL}timesheets/generate-csv-details`, {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + (getToken() || ""),
        dbName: getSubDomain(),
      },
    });
    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], {
      type: pdf.type === "application/zip" ? "octet/stream" : "application/csv",
    });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = pdfFileURL;
    a.download = await getFileName(
      pdf.type === "application/zip" ? "zip" : "csv",
      "case"
    );
    // pdf.type === "application/zip"
    //   ? `Time Entry Summary.zip`
    //   : `Time Entry Summary.csv`;
    a.click();
  };
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenExportDialog = () => {
    setExportDialogOpen(true);
  };

  const handleCloseExportDialog = async (data?: {
    exportType: "csv" | "pdf";
    includeChangeLog: boolean;
    exportSeparately: boolean;
  }) => {
    if (data) {
      if (data.exportType === "csv") {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownloadAsCsv(
          data?.exportSeparately,
          data.includeChangeLog
        );
        setLoadingMessage("");
        setLoading(false);
      } else {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownload(data?.exportSeparately, data.includeChangeLog);
        setLoadingMessage("");
        setLoading(false);
      }
    }
    setExportDialogOpen(false);
  };

  const handleSelectChange = (index: number) => {
    let timesheetsList = state.timeEntries;
    let timesheet = timesheetsList[index];
    timesheet.isSelected = !timesheet.isSelected;
    timesheetsList[index] = timesheet;
    let selectedTimeEntries = [...state.selectedTimeEntries];
    let unselectedTimeEntries = [...state.unselectedTimeEntries];
    let isSelectedIndex = selectedTimeEntries.indexOf(
      timesheetsList[index].timeCommonBenefitId
    );
    if (timesheet.isSelected) {
      selectedTimeEntries.push(timesheetsList[index].timeCommonBenefitId);
      let unSelectedIndex = unselectedTimeEntries.indexOf(
        timesheetsList[index].timeCommonBenefitId
      );
      if (unSelectedIndex > -1) {
        unselectedTimeEntries.splice(unSelectedIndex, 1);
      }
    } else {
      if (isSelectedIndex > -1) {
        selectedTimeEntries.splice(isSelectedIndex, 1);
      }
      let unSelectedIndex = unselectedTimeEntries.indexOf(
        timesheetsList[index].timeCommonBenefitId
      );
      if (unSelectedIndex === -1 && state.hasAllSelectionChanged) {
        unselectedTimeEntries.push(timesheetsList[index].timeCommonBenefitId);
      }
    }

    let isAllSelected = state.isAllSelected;
    if (isAllSelected && unselectedTimeEntries.length) {
      isAllSelected = false;
    }
    if (state.hasAllSelectionChanged && unselectedTimeEntries.length===0) {
      isAllSelected = true;
    }

    if (selectedTimeEntries.length === state.timesheetCount) {
      isAllSelected = true;
    }

    setState({
      ...state,
      selectedTimeEntries: selectedTimeEntries.filter(onlyUnique),
      unselectedTimeEntries,
      timeEntries: timesheetsList,
      isAllSelected,
    });
    const ids = timesheetsList
      .filter((d) => d.isSelected)
      .map((d) => String(d.timeCommonBenefitId));
    console.log(ids);
    setManageNoteDialog({ ...manageNoteDialog, timeEntryIds: ids });
  };

  const handleOpenActionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionAnchorEl(event.currentTarget);
  };

  const handleCloseActionMenu = () => {
    setActionAnchorEl(null);
  };

  const handleSplitDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = state.timeEntries[editIndex];
    }
    setManageSplitDialog({
      isOpen: isOpen,
      data: data,
      index: editIndex,
    });
  };

  const handleSplitDialogClose = async (data: boolean) => {
    setLoading(true);
    if (data) {
      setManageSplitDialog({
        isOpen: false,
        data: undefined,
        index: undefined,
      });
      if (data) {
        setCountsLoaded(false);
        await getTimesheetDetailsFilters(
          undefined,
          firmsDic,
          personsDic,
          "clear-filters",
          {
            professionalLevelsDic: { ...initialProfessionalLevels },
            categoriesCodeDic: { ...initialCategoryCodes },
            workTypesDic: { ...initialWorkTypes },
          },
          false,
          true
        );
      } else {
        // timeEntriesList.unshift(data);
        showToast("Entry added successfully", "success");
      }
    }
    setManageSplitDialog({
      isOpen: false,
      data: undefined,
      index: undefined,
    });
    setLoading(false);
  };

  const reviewTimesheet = async () => {
    handleCloseActionMenu();
    let timeEntries = [...state.timeEntries];
    let tempSearch = { ...state.search };

    tempSearch = removeNulls(tempSearch);

    let selectedEntries = [...state.selectedTimeEntries];
    if (selectedEntries.length > 0) {
      let obj = {
        ...tempSearch,
        isSelectedAll: state.hasAllSelectionChanged,
        timesheetIds: state.hasAllSelectionChanged
          ? [...state.unselectedTimeEntries]
          : [...state.selectedTimeEntries],
      };
      setLoading(true);
      const result = await timesheetReviewed(obj);
      setLoading(false);
      if (result?.isSuccess) {
        if (result?.isReadOnly) {
          showToast(result.message, "error");
          updateStatus(result.isReadOnly);
        } else {
          showToast(`Timesheet reviewed Successfully`, "success");
          timeEntries.forEach((ele) => {
            if (ele.isSelected) {
              ele.status = "reviewed";
              ele.isSelected = false;
              ele.version = ele.version + 1;
            }
          });
          setState({ ...state, timeEntries: timeEntries });
          await getTimesheetDetailsFilters(
            undefined,
            firmsDic,
            personsDic,
            "clear-filters",
            {
              professionalLevelsDic: { ...initialProfessionalLevels },
              categoriesCodeDic: { ...initialCategoryCodes },
              workTypesDic: { ...initialWorkTypes },
            },
            true,
            true
          );
        }
      } else {
        showToast(
          result?.message || `Error occurred while reviewing timesheet`,
          "error"
        );
      }
    } else {
      showToast(`Please select a time entry to review`, "error");
    }
  };

  const handleAddDialogOpen = (
    isOpen: boolean,
    editIndex?: number,
    isBulkEdit = false
  ) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = state.timeEntries[editIndex];
    }
    handleCloseActionMenu();
    setState({
      ...state,
      manageTimeEntryDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
        isBulkEdit,
      },
    });
  };

  const disApproveEntries = async () => {
    handleCloseActionMenu();
    confirmDialog.show(
      "Are you sure",
      "Do you want to disapprove?",
      async () => {
        let tempSearch = { ...state.search };
        tempSearch = removeNulls(tempSearch);
        let requestObj = {
          ...tempSearch,
          // firmEmployeesEdits: [{
          //     lawFirmCode: state.timeEntries[0].firmCode,
          //     resourceName: state.timeEntries[0].personId,
          // }],
          firmEmployeesEdits: [],
          isSelectedAll: state.hasAllSelectionChanged,
          timesheetIds: state.hasAllSelectionChanged
            ? [...state.unselectedTimeEntries]
            : [...state.selectedTimeEntries],
          isDetailedPage: true,
        };
        setLoading(true);
        let result = await disApproveEntriesAPI(requestObj);
        if (result?.isSuccess) {
          if (result?.isReadOnly) {
            showToast(result.message, "error");
            updateStatus(result.isReadOnly);
          } else {
            showToast("Timesheet disapproved successfully", "success");
            setCountsLoaded(false);
            await getTimesheetDetailsFilters(
              undefined,
              firmsDic,
              personsDic,
              "clear-filters",
              {
                professionalLevelsDic: { ...initialProfessionalLevels },
                categoriesCodeDic: { ...initialCategoryCodes },
                workTypesDic: { ...initialWorkTypes },
              },
              true,
              true
            );
          }
        } else {
          showToast("Error while disapproving timesheet", "error");
        }
        setLoading(false);
      }
    );
  };
  const handleApproveTimesheet = async (index) => {
    handleCloseActionMenu();
    confirmDialog.show("Are you sure", "Do you want to approve?", async () => {
      let tempSearch = { ...state.search };
      tempSearch = removeNulls(tempSearch);
      let requestObj = {
        ...tempSearch,
        // firmEmployeesEdits: [{
        //     lawFirmCode: state.timeEntries[index].firmCode,
        //     resourceName: state.timeEntries[index].personName,
        // }],
        firmEmployeesEdits: [],
        isSelectedAll: false,
        timesheetIds: [state.timeEntries[index].timeCommonBenefitId],
        isDetailedPage: true,
      };
      setLoading(true);
      let result = await approveTimesheets(requestObj);
      if (result?.isSuccess) {
        if (result?.isReadOnly) {
          showToast(result.message, "error");
          updateStatus(result.isReadOnly);
        } else {
          showToast("Timesheet approved successfully", "success");
          setCountsLoaded(false);
          await getTimesheetDetailsFilters(
            undefined,
            firmsDic,
            personsDic,
            "clear-filters",
            {
              professionalLevelsDic: { ...initialProfessionalLevels },
              categoriesCodeDic: { ...initialCategoryCodes },
              workTypesDic: { ...initialWorkTypes },
            },
            true,
            true
          );
        }
      } else {
        showToast("Error while approving timesheet", "error");
      }
      setLoading(false);
    });
  };
  const handleDisApproveTimesheet = async (index) => {
    handleCloseActionMenu();
    confirmDialog.show(
      "Are you sure",
      "Do you want to disapprove?",
      async () => {
        let tempSearch = { ...state.search };
        tempSearch = removeNulls(tempSearch);
        let requestObj = {
          ...tempSearch,
          // firmEmployeesEdits: [{
          //     lawFirmCode: state.timeEntries[index].firmCode,
          //     resourceName: state.timeEntries[index].personName,
          // }],
          firmEmployeesEdits: [],
          isSelectedAll: false,
          timesheetIds: [state.timeEntries[index].timeCommonBenefitId],
          isDetailedPage: true,
        };
        setLoading(true);
        let result = await disApproveEntriesAPI(requestObj);
        if (result?.isSuccess) {
          if (result?.isReadOnly) {
            showToast(result.message, "error");
            updateStatus(result.isReadOnly);
          } else {
            showToast("Timesheet disapproved successfully", "success");
            setCountsLoaded(false);
            await getTimesheetDetailsFilters(
              undefined,
              firmsDic,
              personsDic,
              "clear-filters",
              {
                professionalLevelsDic: { ...initialProfessionalLevels },
                categoriesCodeDic: { ...initialCategoryCodes },
                workTypesDic: { ...initialWorkTypes },
              },
              true,
              true
            );
          }
        } else {
          showToast("Error while disapproving timesheet", "error");
        }
        setLoading(false);
      }
    );
  };

  const editTimeEntryApiCall = async (data) => {
    setLoading(true);
    let result = await editTimeSheet(data);
    if (result?.isSuccess) {
      if (result?.isReadOnly) {
        showToast(result.message, "error");
        updateStatus(result.isReadOnly);
      } else {
        // timeEntriesList[state.manageTimeEntryDialog.index] = result?.data;
        showToast("Entry updated successfully", "success");
        setCountsLoaded(false);
        await getTimesheetDetailsFilters(
          undefined,
          firmsDic,
          personsDic,
          "clear-filters",
          {
            professionalLevelsDic: { ...initialProfessionalLevels },
            categoriesCodeDic: { ...initialCategoryCodes },
            workTypesDic: { ...initialWorkTypes },
          },
          true,
          true
        );
      }
    } else {
      showToast("Entry not updated as no value changed", "warn");
    }
  };

  const handleAddDialogClose = async (data?: ITimeEntry) => {
    // let timeEntriesList = [...state.timeEntries];
    setLoading(true);
    if (data) {
      if (state.manageTimeEntryDialog.isBulkEdit) {
        let tempSearch = { ...state.search };
        tempSearch = removeNulls(tempSearch);
        data = removeNulls(data);
        let requestObj = {
          ...tempSearch,
          // firmEmployeesEdits: [{
          //     lawFirmCode: state.timeEntries[0].firmCode,
          //     resourceName: state.timeEntries[0].personId,
          // }],
          firmEmployeesEdits: [],
          timeEntryEditDetails: data,
          isSelectedAll: state.hasAllSelectionChanged,
          timesheetIds: state.hasAllSelectionChanged
            ? [...state.unselectedTimeEntries]
            : [...state.selectedTimeEntries],
          isDetailedPage: true,
        };
        let result = await bulkEditTimeEntries(requestObj);
        if (result?.isSuccess) {
          if (result?.isReadOnly) {
            showToast(result.message, "error");
            updateStatus(result.isReadOnly);
          } else {
            showToast("Updated successfully", "success");
            setCountsLoaded(false);
            await getTimesheetDetailsFilters(
              undefined,
              firmsDic,
              personsDic,
              "clear-filters",
              {
                professionalLevelsDic: { ...initialProfessionalLevels },
                categoriesCodeDic: { ...initialCategoryCodes },
                workTypesDic: { ...initialWorkTypes },
              },
              true,
              true
            );
          }
        } else {
          showToast("Error while bulk edit", "error");
        }
      } else {
        if (state.manageTimeEntryDialog.index !== undefined) {
          if (data.isEdit) {
            let obj = {
              status: "adjusted",
              timesheetsInfo: data,
            };
            // showing confirmation dialog based on status
            if (
              [
                "approved",
                "disapproved",
                "submitted",
                "granted",
                "not-granted",
              ].includes(data.status)
            ) {
              confirmDialog.show(
                "Do you want change entry status to adjusted?",
                "",
                async () => {
                  await editTimeEntryApiCall({ ...data, isStatusChange: true });
                },
                async () => {
                  await editTimeEntryApiCall({
                    ...data,
                    isStatusChange: false,
                  });
                },
                "Yes",
                "No"
              );
            } else {
              await editTimeEntryApiCall(data);
            }
            // let result = await editTimeSheet(data);
            // if (result?.isSuccess) {
            //     if (result?.isReadOnly) {
            //         showToast(result.message, "error");
            //         updateStatus(result.isReadOnly);
            //     } else {
            //         // timeEntriesList[state.manageTimeEntryDialog.index] = result?.data;
            //         showToast("Entry updated successfully", "success");
            //         setCountsLoaded(false);
            //         await getTimesheetDetailsFilters(undefined, firmsDic, personsDic, "clear-filters", {
            //             professionalLevelsDic: { ...initialProfessionalLevels },
            //             categoriesCodeDic: { ...initialCategoryCodes },
            //             workTypesDic: { ...initialWorkTypes }
            //         }, true, true);
            //     }
            // } else {
            //     showToast("Entry not updated as no value changed", "warn");
            // }
          }
        } else {
          // timeEntriesList.unshift(data);
          showToast("Entry added successfully", "success");
        }
      }
    } else {
      setState({
        ...state,
        manageTimeEntryDialog: {
          isOpen: false,
          data: undefined,
          index: undefined,
          isBulkEdit: false,
        },
      });
    }

    setLoading(false);
  };

  const handleViewLogsDialog = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (isOpen) {
      let timeEntry = { ...state.timeEntries[editIndex as number] };
      if (editIndex !== undefined) {
        // data = {
        //   StartDate: state.startDate,
        //   EndDate: state.endDate,
        //   FirmId: timeEntry.firmCode as number,
        //   PersonId: timeEntry.personId as number,
        //   Type: timeEntry.type as string,
        //   TimeCommonBenefitIds: [timeEntry.timeCommonBenefitId as number],
        // };
        data = timeEntry.timeCommonBenefitId;
      }
    }
    setState({
      ...state,
      viewLogsDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
      },
    });
  };
  const handleRuleFailedDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    let timeEntries = [...state.timeEntries];
    if (editIndex !== undefined) {
      data = timeEntries[editIndex];
    }
    setState({
      ...state,
      manageRuleFailedDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
      },
    });
  };

  const handleRuleFailedDialogClose = async (isDetailsUpdated: boolean) => {
    if (isDetailsUpdated) {
      await getTimesheetDetailsFilters(
        undefined,
        firmsDic,
        personsDic,
        "clear-filters",
        {
          professionalLevelsDic: { ...initialProfessionalLevels },
          categoriesCodeDic: { ...initialCategoryCodes },
          workTypesDic: { ...initialWorkTypes },
        },
        false,
        true
      );
    } else {
      setState({
        ...state,
        manageRuleFailedDialog: {
          isOpen: false,
          data: undefined,
          index: undefined,
        },
      });
    }
  };

  const handleEditTimesheet = async (status: string) => {
    handleCloseActionMenu();
    let timeEntries = [...state.timeEntries];
    let tempSearch = { ...state.search };
    tempSearch = removeNulls(tempSearch);

    if (state.selectedTimeEntries.length > 0) {
      let obj = {
        ...tempSearch,
        isSelectedAll: state.hasAllSelectionChanged,
        timesheetIds: state.hasAllSelectionChanged
          ? [...state.unselectedTimeEntries]
          : [...state.selectedTimeEntries],
      };
      setLoading(true);
      const result = await approveTimesheets(obj);
      setLoading(false);
      if (result?.isSuccess) {
        if (result?.isReadOnly) {
          showToast(result.message, "error");
          updateStatus(result.isReadOnly);
        } else {
          showToast(
            `Timesheet ${
              status === "approved" ? "approved" : "saved"
            } successfully`,
            "success"
          );
          timeEntries.map((ele) => {
            if (ele.isSelected) {
              ele.status = "approved";
              ele.isSelected = false;
              ele.version = ele.version + 1;
            }
          });
          setState({
            ...state,
            timeEntries,
            isAllSelected: false,
            selectedTimeEntries: [],
            unselectedTimeEntries: [],
          });
          await getTimesheetDetailsFilters(
            undefined,
            firmsDic,
            personsDic,
            "clear-filters",
            {
              professionalLevelsDic: { ...initialProfessionalLevels },
              categoriesCodeDic: { ...initialCategoryCodes },
              workTypesDic: { ...initialWorkTypes },
            },
            true,
            true
          );
        }
      } else {
        showToast(
          result?.message ||
            `Error while ${
              status === "approved" ? "approving" : "saving"
            } timesheet`,
          "error"
        );
      }
    } else {
      showToast(`Please select a timesheet to approve`, "error");
    }
  };

  const selectAll = () => {
    let timesheetsList = [...state.timeEntries];
    timesheetsList.map((ele) => {
      if (state.isAllSelected === false) {
        ele.isSelected = true;
      } else {
        ele.isSelected = false;
      }
      return ele;
    });
    let selectedTimeEntries = [];
    if (!state.isAllSelected) {
      selectedTimeEntries = state.timeEntries.map((x) => x.timeCommonBenefitId);
    }
    setState({
      ...state,
      hasAllSelectionChanged: !state.isAllSelected,
      selectedTimeEntries: selectedTimeEntries.filter(onlyUnique),
      unselectedTimeEntries: [],
      timeEntries: timesheetsList,
      isAllSelected: !state.isAllSelected,
    });
    const ids = timesheetsList
      .filter((d) => d.isSelected)
      .map((d) => String(d.timeCommonBenefitId));
    console.log(ids);
    setManageNoteDialog({ ...manageNoteDialog, timeEntryIds: ids });
  };

  const onSortChange = async (sortBy) => {
    let search = { ...state.search };
    if (sortBy === search.sortBy) {
      search.sortOrder = search.sortOrder === "asc" ? "desc" : "asc";
    } else {
      search.sortBy = sortBy;
      search.sortOrder = "asc";
    }
    search.from = 0;
    await getTimesheetsList(search);
  };

  const classes = styles();
  return (
    <React.Fragment>
      {isLoading && <Loading message={loadingMessage} />}
      <section className="p-16 pr-0 pb-0">
        <Typography variant="h5" align="center">
          Time Entry Details
        </Typography>
        <Grid className={`${classes.main}`}>
          <Grid
            className={`${
              isFilterOpen
                ? classes.filterOpenContainer
                : classes.filterCloseContainer
            }`}
          >
            <Hidden mdDown>
              <Grid container spacing={2}>
                <Grid item className={`non-dashboard-filters-height`}>
                  {filterData.length > 0 && (
                    <Filter
                      data={[...filterData]}
                      options={state.options || []}
                      onChange={(data: IFilter[], changedProperty?: string) => {
                        onFilterChange(data, changedProperty);
                      }}
                      isFromManagementDashboard={
                        fromManagementDashboard.current
                      }
                      managementDashboardId={
                        loadedFilter?.timeManagementDashboardId &&
                        managementDashboardId.current &&
                        loadedFilter.timeManagementDashboardId ===
                          managementDashboardId.current
                          ? managementDashboardId.current
                          : undefined
                      }
                      typeOfFilters="timesheets"
                      selectedFilters={state.search}
                      loadedFilter={loadedFilter}
                      dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
                    />
                  )}
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <ToggleFilterSection
            showFilterClass="toggle-filters-non-dashboard-container"
            classes={classes}
            toggleFilter={() => setFilterOpen(!isFilterOpen)}
            isFilterOpen={isFilterOpen}
          />
          <Grid
            className={`non-dashboard-main-container ${
              isFilterOpen
                ? classes.mainOpenContainer
                : classes.mainCloseContainer
            }`}
          >
            <br />
            <Grid container>
              <Grid item xs={12}>
                <section className="px-16">
                  <Grid container spacing={2}>
                    {/* {Object.keys(allEmployeeDetails).length > 0 && (
                                    <React.Fragment> */}
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="Total Fees"
                        value={`${currencyFormat(
                          convertToFixed(allEmployeeDetails.totalFees || 0)
                        )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="Avg Hourly Rate"
                        value={`${currencyFormat(
                          convertToFixed(allEmployeeDetails.avgHourlyRate || 0)
                        )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="High Hourly Rate"
                        value={`${currencyFormat(
                          convertToFixed(allEmployeeDetails.highHourlyRate || 0)
                        )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="Low Hourly Rate"
                        value={`${currencyFormat(
                          convertToFixed(allEmployeeDetails.lowHourlyRate || 0)
                        )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="Total Hours"
                        value={`${hoursFormat(
                          allEmployeeDetails.totalHours || 0
                        )}`}
                        // value={`${numberFormat(
                        //     convertToFixed(
                        //         allEmployeeDetails.totalHours || 0
                        //     )
                        // )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="Avg Hours"
                        value={`${hoursFormat(
                          allEmployeeDetails.avgHours || 0
                        )}`}
                        // value={`${numberFormat(
                        //     convertToFixed(
                        //         allEmployeeDetails.avgHours || 0
                        //     )
                        // )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="High Hours"
                        value={`${hoursFormat(
                          allEmployeeDetails.highHours || 0
                        )}`}
                        // value={`${numberFormat(
                        //     convertToFixed(
                        //         allEmployeeDetails.highHours || 0
                        //     )
                        // )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="Low Hours"
                        value={`${hoursFormat(
                          allEmployeeDetails.lowHours || 0
                        )}`}
                        // value={`${numberFormat(
                        //     convertToFixed(
                        //         allEmployeeDetails.lowHours || 0
                        //     )
                        // )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    <Grid item lg={2} md={3} sm={6} xs={12}>
                      <TimeSheetHeader
                        label="Time Entries"
                        value={`${numberFormat(
                          convertToFixed(allEmployeeDetails.timeEntries || 0)
                        )}`}
                      ></TimeSheetHeader>
                    </Grid>
                    {/* </React.Fragment>
                                )} */}
                  </Grid>
                </section>
              </Grid>
            </Grid>
            <br />
            <Grid>
              <Grid container spacing={2} justify="flex-end">
                <Hidden lgUp>
                  <Grid item>
                    {filterData.length > 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setFilterDialogOpen(true)}
                      >
                        Filters
                      </Button>
                    )}
                  </Grid>
                </Hidden>
                {state.timeEntries && state.timeEntries.length > 0 && (
                  <Grid item>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleOpenExportDialog();
                      }}
                    >
                      Export
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    variant="outlined"
                    color="primary"
                    onClick={handleOpenActionMenu}
                    disabled={state.selectedTimeEntries.length === 0}
                  >
                    Action <ChevronDown />
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={actionAnchorEl}
                    keepMounted
                    open={Boolean(actionAnchorEl)}
                    onClose={handleCloseActionMenu}
                  >
                    <MenuItem
                      disabled={isReadOnly}
                      onClick={() => {
                        setManageNoteDialog({
                          ...manageNoteDialog,
                          isOpen: true,
                          isAddToNote: false,
                        });
                        handleCloseActionMenu();
                      }}
                    >
                      Create Note
                    </MenuItem>
                    <MenuItem
                      disabled={isReadOnly}
                      onClick={() => {
                        setManageNoteDialog({
                          ...manageNoteDialog,
                          isOpen: true,
                          isAddToNote: true,
                        });
                        handleCloseActionMenu();
                      }}
                    >
                      Add to Note
                    </MenuItem>
                    <MenuItem
                      disabled={isReadOnly}
                      onClick={() => handleAddDialogOpen(true, undefined, true)}
                    >
                      Bulk Edit
                    </MenuItem>
                    <MenuItem
                      disabled={isReadOnly}
                      onClick={() => reviewTimesheet()}
                    >
                      Review
                    </MenuItem>
                    <MenuItem
                      disabled={isReadOnly}
                      onClick={() => handleEditTimesheet("approved")}
                    >
                      Approve
                    </MenuItem>
                    <MenuItem
                      disabled={isReadOnly}
                      onClick={() => disApproveEntries()}
                    >
                      Disapprove
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {/* <p>{state.hasAllSelectionChanged ? "true" : "false"} - {state.selectedTimeEntries.length} - {state.unselectedTimeEntries.length}</p> */}
                  <ViewTimesheetTable
                    from="timesheets-details"
                    timeEntries={state.timeEntries}
                    handleAddDialogOpen={handleAddDialogOpen}
                    handleRuleFailedDialogOpen={handleRuleFailedDialogOpen}
                    handleSelectChange={handleSelectChange}
                    handleViewLogsDialog={handleViewLogsDialog}
                    isAllSelected={state.isAllSelected}
                    selectedTimeEntries={state.selectedTimeEntries}
                    unselectedTimeEntries={state.unselectedTimeEntries}
                    selectAll={selectAll}
                    handleSplitDialogOpen={handleSplitDialogOpen}
                    canSortHeader={true}
                    sortBy={state.search.sortBy}
                    sortOrder={state.search.sortOrder}
                    onSort={(sortBy) => onSortChange(sortBy)}
                    handleApproveTimesheet={handleApproveTimesheet}
                    handleDisApproveTimesheet={handleDisApproveTimesheet}
                  />
                  <TablePagination
                    rowsPerPageOptions={state.rowsList}
                    component="div"
                    count={state.timesheetCount}
                    rowsPerPage={state.search.size}
                    page={
                      state.search.from === 0
                        ? 0
                        : state.search.from / state.search.size
                    }
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onPageChange={(event: any, newPage: number) => {
                      let search = { ...state.search };
                      search.from =
                        newPage === 0 ? 0 : Number(newPage) * state.search.size;
                      onSearch(search);
                    }}
                    onRowsPerPageChange={(event: any) => {
                      let value = event.target.value;
                      let search = { ...state.search };
                      search.size = value ? Number(value) : 10;
                      // set to a different state
                      setRowsPerPage(search.size);
                      search.from = 0;
                      onSearch(
                        search,
                        search.size ==
                          getCeilValueForTotalCount(state.timesheetCount)
                          ? true
                          : false
                      );
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      {isFilterDialogOpen && (
        <CustomDrawer
          title={"Filter"}
          onClose={() => setFilterDialogOpen(false)}
        >
          <section className="p-24">
            <Filter
              isPopup={true}
              data={[...filterData]}
              onChange={(data: IFilter[]) => {
                onFilterChange(data);
              }}
              typeOfFilters="timesheets"
              isFromManagementDashboard={fromManagementDashboard.current}
              managementDashboardId={
                loadedFilter?.timeManagementDashboardId &&
                managementDashboardId.current &&
                loadedFilter.timeManagementDashboardId ===
                  managementDashboardId.current
                  ? managementDashboardId.current
                  : undefined
              }
              selectedFilters={state.search}
              loadedFilter={loadedFilter}
              dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
            />
          </section>
        </CustomDrawer>
      )}
      {state.manageTimeEntryDialog.isOpen && (
        <ManageTimeEntryDialog
          isBulkEdit={state.manageTimeEntryDialog.isBulkEdit}
          data={state.manageTimeEntryDialog.data}
          handleDialogClose={(data?: ITimeEntry) => handleAddDialogClose(data)}
        />
      )}
      {state.manageRuleFailedDialog.isOpen && (
        <RuleFailedDialog
          timeEntryData={state.manageRuleFailedDialog.data}
          handleDialogClose={(isDetailsUpdated: boolean) =>
            handleRuleFailedDialogClose(isDetailsUpdated)
          }
          updateStatus={updateStatus}
        />
      )}
      {state.viewLogsDialog.isOpen && (
        <LogDialog
          timesheetId={state.viewLogsDialog.data}
          handleDialogClose={() => handleViewLogsDialog(false)}
        />
      )}
      {isExportDialogOpen && (
        <ExportDialog
          exportFor={"timesheetOrExpense"}
          handleDialogClose={(data?) => handleCloseExportDialog(data)}
        />
      )}
      {manageNoteDialog.isOpen && (
        <ManageNotesDialog
          type="timesheets"
          isAddToNote={manageNoteDialog.isAddToNote}
          filters={removeNulls({ ...state.search })}
          isSelectedAll={state.hasAllSelectionChanged}
          entries={
            state.hasAllSelectionChanged
              ? [...state.unselectedTimeEntries.map((x) => x.toString())]
              : [...state.selectedTimeEntries.map((x) => x.toString())]
          }
          handleDialogClose={(isAdded) => {
            setManageNoteDialog({
              ...manageNoteDialog,
              isOpen: false,
              isAddToNote: false,
            });
            if (isAdded) {
              setState({
                ...state,
                timeEntries: state.timeEntries.map((timeEntry) => {
                  timeEntry.isSelected = false;
                  return timeEntry;
                }),
                isAllSelected: false,
                hasAllSelectionChanged: false,
                selectedTimeEntries: [],
                unselectedTimeEntries: [],
              });
            }
          }}
          updateStatus={updateStatus}
        />
      )}
      {manageSplitDialog.isOpen && (
        <ManageSplitTimeEntryDialog
          data={manageSplitDialog.data}
          handleDialogClose={(data) => handleSplitDialogClose(data)}
          updateStatus={updateStatus}
        />
      )}
    </React.Fragment>
  );
};

export default withConfirmDialogContext(TimesheetsDetailsMain);
