import * as React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import CustomDrawer from "../common/CustomDrawer";
import { Formik, FormikErrors } from "formik";
import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { requestAccessAPI } from "../../services/RequestAccessService";
import { IRequestAccess } from "../../vm";

interface RequestAccessMainProps {
  handleDialogClose: Function;
}

const RequestAccessMain: React.FunctionComponent<RequestAccessMainProps> = ({
  handleDialogClose,
}) => {
  const history = useHistory();
  const [isLoading, setLoading] = React.useState(true);
  const { showToast } = React.useContext(ToastContext);
  const [requestAccessCred, setRequestAccessCred] = useState<IRequestAccess>({
    firstName: "",
    lastName: "",
    phoneNo: "",
    email: "",
    firmName: "",
    roleInFirm: "",
    caseName: "",
    caseNumber: "",
    roleOnCase: "",
  });
  const [firstPartCompleted, setFirstPartCompleted] = useState(false);
  const classes = useStyles();

  const closeAddDialog = (data?: any) => {
    handleDialogClose(data);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <CustomDrawer title={"Request Access"} onClose={() => closeAddDialog()}>
        <section className="p-16">
          <Formik
            enableReinitialize
            initialValues={requestAccessCred}
            validate={(values) => {
              let errors: FormikErrors<IRequestAccess> = {};
              if (!values.firstName) {
                errors.firstName = "Required";
              }
              if (!values.lastName) {
                errors.lastName = "Required";
              }
              if (!values.phoneNo) {
                errors.phoneNo = "Required";
              }
              if (!values.email) {
                errors.email = "Required";
              }
              if (
                values.email &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (!values.firmName) {
                errors.firmName = "Required";
              }
              if (!values.roleInFirm) {
                errors.roleInFirm = "Required";
              }
              if (firstPartCompleted) {
                if (!values.caseName) {
                  errors.caseName = "Required";
                }
                if (!values.caseNumber) {
                  errors.caseNumber = "Required";
                }
                if (!values.roleOnCase) {
                  errors.roleOnCase = "Required";
                }
              }
              return errors;
            }}
            onSubmit={async (values: IRequestAccess, { setSubmitting }) => {
              const obj = { ...values };
              console.log("Values", obj);
              if (!firstPartCompleted) {
                setFirstPartCompleted(true);
              } else {
                setLoading(true);
                let result = await requestAccessAPI(values);
                if (result && result.isSuccess) {
                  setLoading(false);
                  handleDialogClose(true);
                } else {
                  setLoading(false);
                  showToast(result?.message, "error");
                }
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Grid container spacing={1} alignItems="center">
                  {!firstPartCompleted ? (
                    <>
                      <Grid item xs={12} sm={5}>
                        <Typography>Your First Name: </Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <FormControl margin="normal" required fullWidth>
                          <TextField
                            size="small"
                            id="firstName"
                            name="firstName"
                            label=""
                            autoComplete="firstName"
                            onChange={handleChange}
                            error={
                              errors.firstName && touched.firstName
                                ? true
                                : false
                            }
                            helperText={
                              errors.firstName &&
                              touched.firstName &&
                              errors.firstName
                            }
                            onBlur={handleBlur}
                            value={values.firstName || ""}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <Typography>Your Last Name: </Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <FormControl margin="normal" required fullWidth>
                          <TextField
                            size="small"
                            id="lastName"
                            name="lastName"
                            label=""
                            autoComplete="lastName"
                            onChange={handleChange}
                            error={
                              errors.lastName && touched.lastName ? true : false
                            }
                            helperText={
                              errors.lastName &&
                              touched.lastName &&
                              errors.lastName
                            }
                            onBlur={handleBlur}
                            value={values.lastName || ""}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <Typography>Your Direct Phone No.: </Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <FormControl margin="normal" required fullWidth>
                          <TextField
                            size="small"
                            id="phoneNo"
                            name="phoneNo"
                            label=""
                            autoComplete="phoneNo"
                            onChange={handleChange}
                            error={errors.phoneNo && touched.phoneNo ? true : false}
                            helperText={
                              errors.phoneNo && touched.phoneNo && errors.phoneNo
                            }
                            onBlur={handleBlur}
                            value={values.phoneNo || ""}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <Typography>Your Business Email: </Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <FormControl margin="normal" required fullWidth>
                          <TextField
                            size="small"
                            id="email"
                            name="email"
                            label=""
                            autoComplete="email"
                            onChange={handleChange}
                            error={errors.email && touched.email ? true : false}
                            helperText={
                              errors.email && touched.email && errors.email
                            }
                            onBlur={handleBlur}
                            value={values.email || ""}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <Typography>Your Firm/Company Name: </Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <FormControl margin="normal" required fullWidth>
                          <TextField
                            size="small"
                            id="firmName"
                            name="firmName"
                            label=""
                            autoComplete="firmName"
                            onChange={handleChange}
                            error={
                              errors.firmName && touched.firmName ? true : false
                            }
                            helperText={
                              errors.firmName &&
                              touched.firmName &&
                              errors.firmName
                            }
                            onBlur={handleBlur}
                            value={values.firmName || ""}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <Typography>Your Role in Firm/Company: </Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <FormControl margin="normal" required fullWidth>
                          <TextField
                            size="small"
                            id="roleInFirm"
                            name="roleInFirm"
                            label=""
                            autoComplete="roleInFirm"
                            onChange={handleChange}
                            error={
                              errors.roleInFirm && touched.roleInFirm
                                ? true
                                : false
                            }
                            helperText={
                              errors.roleInFirm &&
                              touched.roleInFirm &&
                              errors.roleInFirm
                            }
                            onBlur={handleBlur}
                            value={values.roleInFirm || ""}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={5}>
                        <Typography>Case Name: </Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <FormControl margin="normal" required fullWidth>
                          <TextField
                            size="small"
                            id="caseName"
                            name="caseName"
                            label=""
                            autoComplete="caseName"
                            onChange={handleChange}
                            error={
                              errors.caseName && touched.caseName ? true : false
                            }
                            helperText={
                              errors.caseName &&
                              touched.caseName &&
                              errors.caseName
                            }
                            onBlur={handleBlur}
                            value={values.caseName || ""}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <Typography>Case Number: </Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <FormControl margin="normal" required fullWidth>
                          <TextField
                            size="small"
                            id="caseNumber"
                            name="caseNumber"
                            label=""
                            autoComplete="caseNumber"
                            onChange={handleChange}
                            error={
                              errors.caseNumber && touched.caseNumber
                                ? true
                                : false
                            }
                            helperText={
                              errors.caseNumber &&
                              touched.caseNumber &&
                              errors.caseNumber
                            }
                            onBlur={handleBlur}
                            value={values.caseNumber || ""}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <Typography>Role on Case: </Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <FormControl margin="normal" required fullWidth>
                          <TextField
                            size="small"
                            id="roleOnCase"
                            name="roleOnCase"
                            label=""
                            autoComplete="roleOnCase"
                            onChange={handleChange}
                            error={
                              errors.roleOnCase && touched.roleOnCase
                                ? true
                                : false
                            }
                            helperText={
                              errors.roleOnCase &&
                              touched.roleOnCase &&
                              errors.roleOnCase
                            }
                            onBlur={handleBlur}
                            value={values.roleOnCase || ""}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Grid container spacing={2} justify="flex-end">
                      {firstPartCompleted && (
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setFirstPartCompleted(false)}
                          >
                            Previous
                          </Button>
                        </Grid>
                      )}
                      <Grid item>
                        <Button variant="text" onClick={() => closeAddDialog()}>
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {firstPartCompleted ? "Submit" : "Next"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </section>
      </CustomDrawer>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  form: {
    width: "100%",
  },
});

export default RequestAccessMain;
