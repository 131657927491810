import {
  Avatar,
  Button,
  FormControl,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik, FormikErrors } from "formik";
import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { parseJwt, setToken } from "../../services/UtilService";
import { THEME } from "../../Styles";
import { ToastContext } from "../common/ToastProvider";
import { resetPassword } from "../../services/auth/AuthService";
import { Lock } from "mdi-material-ui";
import { IResetPassword } from "../../vm";

export interface ForgotPasswordMainProps {}

const ForgotPasswordMain: React.FC<ForgotPasswordMainProps> = () => {
  const history = useHistory();
  const routeMatch = useRouteMatch<{ token: string }>();
  const [isLoading, setLoading] = React.useState(true);
  const { showToast } = React.useContext(ToastContext);
  const classes = useStyles();
  let tokenParams = window.location.href.split("?")?.[1];
  let token = tokenParams.split("=");
  const [forgotPassword, setForgotPassword] = React.useState({
    token: token[1] || "",
    password: "",
    confirmPassword: "",
  });

  React.useEffect(() => {}, []);

  return (
    <React.Fragment>
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Lock />
          </Avatar>
          <br />
          <br />
          <Typography variant="h5">
            Recover Account - Time & Expense Application
          </Typography>
          <br />
          <Formik
            initialValues={forgotPassword}
            validate={(values: any) => {
              let errors: FormikErrors<any> = {};
              if (!values.password) {
                errors.password = "Required";
              } else if (values.password.length <= 4) {
                errors.password = "Needs to be more than 4 characters";
              }
              if (!values.confirmPassword) {
                errors.confirmPassword = "Required";
              } else if (values.confirmPassword !== values.password) {
                errors.confirmPassword = "Password does not match";
              }
              return errors;
            }}
            onSubmit={async (values: any, { setSubmitting }) => {
              let tokenData = parseJwt(values.token);
              let obj: IResetPassword = {
                userId: Number(tokenData.CustomerId),
                email: tokenData.Email,
                password: values.password,
              };
              let result = await resetPassword(obj);
              if (result && result.isSuccess) {
                showToast("Password updated successfully", "success");
                history.push("/dashboard");
              } else {
                showToast(result.message, "error");
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Grid container spacing={1} alignItems="center">
                  {/* <Grid item xs={12} sm={3}>
                    <Typography>Password</Typography>
                  </Grid> */}
                  <Grid item xs={12} sm={12}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        size="small"
                        name="password"
                        label="New Password"
                        type="password"
                        autoComplete="current-password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors.password && touched.password ? true : false
                        }
                        helperText={
                          errors.password && touched.password && errors.password
                        }
                        value={values.password}
                      />
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} sm={3}>
                    <Typography>Re-enter Password</Typography>
                  </Grid> */}
                  <Grid item xs={12} sm={12}>
                    <FormControl margin="normal" required fullWidth>
                      <TextField
                        size="small"
                        name="confirmPassword"
                        label="Re-enter Password"
                        type="password"
                        autoComplete="current-password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors.confirmPassword && touched.confirmPassword
                            ? true
                            : false
                        }
                        helperText={
                          errors.confirmPassword &&
                          touched.confirmPassword &&
                          errors.confirmPassword
                        }
                        value={values.confirmPassword}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting}
                >
                  Update Password
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  className="mt-8"
                  color="primary"
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  Home
                </Button>
              </form>
            )}
          </Formik>
        </Paper>
      </main>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    marginTop: THEME.spacing(8),
    maxWidth: "600px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${THEME.spacing(2)}px ${THEME.spacing(3)}px ${
      THEME.spacing() * 3
    }px`,
  },
  logo: {
    height: "86px",
  },
  avatar: {
    margin: THEME.spacing(),
    backgroundColor: THEME.palette.secondary.main,
  },
  form: {
    //   width: "100%",
    marginTop: THEME.spacing(),
  },
  submit: {
    marginTop: THEME.spacing(3),
    marginBottom: THEME.spacing(1),
  },
});

export default ForgotPasswordMain;
