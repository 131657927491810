import * as React from 'react';
import {
    Checkbox,
    Grid, IconButton, Menu, MenuItem, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { currencyFormat, hoursFormat, numberFormat, StyledTableCell, StyledTableRow } from "../../services/UtilService";
import moment from "moment";
import { ITimeEntry } from '../../vm';
import { TIMESHEET_ALL_STATUS } from '../../Constant';
import { DotsVertical, ExclamationThick, Pencil, TrashCanOutline } from 'mdi-material-ui';
import { IsReadOnlyContext } from '../common/IsReadOnlyProvider';
interface ViewTimesheetTableProps {
    timeEntries: ITimeEntry[];
    isAllSelected?: boolean;
    from: "timesheets" | "notes" | "timesheets-split" | "timesheets-split-header" | "timesheets-details" | "attention-timesheets-details";
    selectAll?: Function
    handleSelectChange?: Function;
    handleViewLogsDialog?: Function;
    handleRuleFailedDialogOpen?: Function;
    handleAddDialogOpen?: Function;
    deleteEntry?: Function;
    selectedTimeEntries?: number[];
    unselectedTimeEntries?: number[];
    handleSplitDialogOpen?: Function;
    selectedIndex?: number;
    canSortHeader?: boolean
    sortBy?: string;
    sortOrder?: "desc" | "asc";
    onSort?: Function;
    handleApproveTimesheet?: Function;
    handleDisApproveTimesheet?: Function;
}

const ViewTimesheetTable: React.FC<ViewTimesheetTableProps> = (props) => {
    const [state, setState] = React.useState({
        selectedItemIndex: -1,
        anchorElMenu: null,
    } as {
        selectedItemIndex: number;
        anchorElMenu: any;
    });
    const { isReadOnly } = React.useContext(IsReadOnlyContext);

    const handleClickMenu = (
        event: React.MouseEvent<HTMLButtonElement>,
        index: number
    ) => {
        setState({
            ...state,
            selectedItemIndex: index,
            anchorElMenu: event.currentTarget,
        });
    };

    const handleCloseMenu = () => {
        setState({ ...state, selectedItemIndex: -1, anchorElMenu: null });
    };

    const getTableHeader = (label, sortProp) => {
        return props.canSortHeader ? (<TableSortLabel
            active={props.sortBy === sortProp}
            direction={props.sortOrder}
            onClick={() => {
                props.onSort(sortProp);
            }}
        >
            {label}
        </TableSortLabel>) : label
    }



    return (
        <section>
            <TableContainer className={props.from === "timesheets" ? "detailed-timesheet-table moz-table-pb" : ["timesheets-details", "attention-timesheets-details"].includes(props.from) ? "timesheets-details-table-container moz-table-pb" : "moz-table-pb"}>
                <Table size="small" className="custom-table">
                    <TableHead>
                        <TableRow>
                            {["timesheets", "timesheets-details", "attention-timesheets-details"].includes(props.from) && (
                                <StyledTableCell>
                                    <Checkbox
                                        size="small"
                                        color='secondary'
                                        checked={props.isAllSelected}
                                        indeterminate={!props.isAllSelected && (props.selectedTimeEntries?.length || props.unselectedTimeEntries?.length) ? true : false}
                                        value={props.isAllSelected}
                                        onClick={() => {
                                            props.selectAll();
                                        }}
                                    />
                                </StyledTableCell>
                            )}
                            {props.from === "attention-timesheets-details" && (
                                <>
                                    <StyledTableCell>{getTableHeader('Attention Name', 'attentionName')}</StyledTableCell>
                                    <StyledTableCell>{getTableHeader('Attention Description', 'attentionDescription')}</StyledTableCell>
                                </>
                            )}
                            {["timesheets-details", "attention-timesheets-details"].includes(props.from) && (
                                <>
                                    <StyledTableCell>{getTableHeader('Firm', 'firmName')}</StyledTableCell>
                                    <StyledTableCell>{getTableHeader('Resource', 'personName')}</StyledTableCell>
                                </>
                            )}
                            <StyledTableCell>
                                {getTableHeader('Service Date', 'serviceDate')}
                            </StyledTableCell>
                            <StyledTableCell>{getTableHeader('Professional Level', 'professionalLevelName')}</StyledTableCell>
                            {/* <StyledTableCell>Category Code</StyledTableCell> */}
                            <StyledTableCell>{getTableHeader('Category Name', 'categoryName')}</StyledTableCell>
                            <StyledTableCell className="time-entry-description">
                                {getTableHeader('Detailed Description of Work performed', 'detailedDescription')}
                            </StyledTableCell>
                            {/* <StyledTableCell>{getTableHeader('Approved by', 'approvedBy')}</StyledTableCell> */}
                            <StyledTableCell align="center">{getTableHeader('Time', 'timeSpent')}</StyledTableCell>
                            <StyledTableCell align="center">
                                {getTableHeader('Billing rate', 'billingRate')}
                            </StyledTableCell>
                            <StyledTableCell align="center">{getTableHeader('Fees Total', 'feesTotal')}</StyledTableCell>
                            <StyledTableCell align="center">{getTableHeader('Class Work', 'classActionWorkIndicator')}</StyledTableCell>
                            {/* <StyledTableCell>Escape Attention Validation</StyledTableCell> */}
                            <StyledTableCell>{getTableHeader('Status', 'status')}</StyledTableCell>
                            <StyledTableCell align="center">{getTableHeader('Version', 'version')}</StyledTableCell>
                            {["timesheets", "timesheets-split", "timesheets-details", "attention-timesheets-details"].includes(props.from) && (
                                <StyledTableCell>Actions</StyledTableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.timeEntries.length > 0 ? (
                            props.timeEntries.map((ele, index) => (
                                <StyledTableRow key={index} className={`${props.selectedIndex === index ? 'selected-split-entry' : ''}`}>
                                    {["timesheets", "timesheets-details", "attention-timesheets-details"].includes(props.from) && (
                                        <StyledTableCell>
                                            <Checkbox
                                                size="small"
                                                checked={ele.isSelected ? true : false}
                                                // value={ele.isSelected}
                                                onClick={() => {
                                                    props.handleSelectChange(index);
                                                }}
                                            />
                                        </StyledTableCell>
                                    )}
                                    {props.from === "attention-timesheets-details" && (
                                        <>
                                            <StyledTableCell>{ele.attentionName}</StyledTableCell>
                                            <StyledTableCell>{ele.attentionDescription}</StyledTableCell>
                                        </>
                                    )}
                                    {["timesheets-details", "attention-timesheets-details"].includes(props.from) && (
                                        <>
                                            <StyledTableCell>{ele.firmName}</StyledTableCell>
                                            <StyledTableCell>{ele.personName}</StyledTableCell>
                                        </>
                                    )}
                                    <StyledTableCell>
                                        {ele.serviceDate ? moment(ele.serviceDate).format("MM/DD/YYYY") : ""}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {ele.professionalLevelName}
                                    </StyledTableCell>
                                    {/* <StyledTableCell>{ele.categoryCode}</StyledTableCell> */}
                                    <StyledTableCell>{ele.categoryName}</StyledTableCell>
                                    <StyledTableCell className="time-entry-description">
                                        {ele.detailedDescription}
                                    </StyledTableCell>
                                    {/* <StyledTableCell>{ele.approvedBy}</StyledTableCell> */}
                                    <StyledTableCell align="right">
                                        {hoursFormat(ele?.timeSpent || 0)}
                                        {/* {numberFormat(ele?.timeSpent || 0)} */}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {currencyFormat(ele?.billingRate || 0)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {currencyFormat(ele?.feesTotal || 0)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {ele.classActionWorkIndicator}
                                    </StyledTableCell>
                                    {/* <StyledTableCell>
                                    {ele.escapeAttentionValidation ? "Enabled" : "Not Enabled"}
                                </StyledTableCell> */}
                                    <StyledTableCell>
                                        {TIMESHEET_ALL_STATUS[ele.status || ""]}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {["timesheets", "timesheets-details", "attention-timesheets-details"].includes(props.from) ? (
                                            <React.Fragment>
                                                {ele.version > 1 ? (
                                                    <Typography
                                                        className="MuiLink-underlineHover pointer"
                                                        component="a"
                                                        align="center"
                                                        color="primary"
                                                        onClick={() => props.handleViewLogsDialog(true, index)}
                                                    >
                                                        {ele.version}
                                                    </Typography>
                                                ) : (
                                                    ele.version
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                {ele.version}
                                            </React.Fragment>
                                        )}
                                    </StyledTableCell>
                                    {["timesheets", "timesheets-details", "attention-timesheets-details"].includes(props.from) && (

                                        <StyledTableCell>
                                            <Grid
                                                container
                                                spacing={1}
                                                direction="row"
                                                wrap="nowrap"
                                                className="d-flex flex-row"
                                            >
                                                <Grid item>
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => handleClickMenu(e, index)}
                                                    >
                                                        <DotsVertical />
                                                    </IconButton>
                                                    {/* <Tooltip title="Edit Entry" placement="bottom">
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            props.handleAddDialogOpen(true, index)
                                                        }
                                                    >
                                                        <Pencil />
                                                    </IconButton>
                                                </Tooltip> */}
                                                </Grid>
                                                {ele.hasAttention && (
                                                    <Grid item>
                                                        <Tooltip
                                                            title="View Rules Failed"
                                                            placement="bottom"
                                                        >
                                                            <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                    props.handleRuleFailedDialogOpen(true, index)
                                                                }
                                                            >
                                                                <ExclamationThick color="error" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </StyledTableCell>
                                    )}
                                    {props.from === "timesheets-split" && (
                                        <StyledTableCell>
                                            <Tooltip title="Edit Entry" placement="bottom">
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        props.handleAddDialogOpen(index)
                                                    }
                                                >
                                                    <Pencil />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete" placement="bottom">
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        props.deleteEntry(index)
                                                    }
                                                >
                                                    <TrashCanOutline />
                                                </IconButton>
                                            </Tooltip>
                                        </StyledTableCell>
                                    )}
                                </StyledTableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell align="center" colSpan={11}>
                                    No Entries found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Menu
                id="simple-menu"
                anchorEl={state.anchorElMenu}
                keepMounted
                open={Boolean(state.anchorElMenu)}
                onClose={handleCloseMenu}
            >
                <MenuItem
                disabled={isReadOnly}
                    onClick={() => {
                        props.handleAddDialogOpen(true, state.selectedItemIndex);
                        handleCloseMenu();
                    }}
                >
                    Edit Entry
                </MenuItem>
                <MenuItem
                disabled={isReadOnly}
                    onClick={() => {
                        props.handleSplitDialogOpen(true, state.selectedItemIndex);
                        handleCloseMenu();
                    }}
                >
                    Split Entry
                </MenuItem>
                <MenuItem
                disabled={isReadOnly}
                    onClick={() => {
                        props.handleApproveTimesheet(state.selectedItemIndex);
                        handleCloseMenu();
                    }}
                >
                    Approve
                </MenuItem>
                <MenuItem
                disabled={isReadOnly}
                    onClick={() => {
                        props.handleDisApproveTimesheet(state.selectedItemIndex);
                        handleCloseMenu();
                    }}
                >
                    Disapprove
                </MenuItem>
                {/* {state.selectedItemIndex !== -1 && props.timeEntries[state.selectedItemIndex].hasAttention  && (
            <MenuItem
            onClick={() => {
                props.handleRuleFailedDialogOpen(true, state.selectedItemIndex);
                handleCloseMenu();
            }}
            >
              View Rules Failed
            </MenuItem>
            )} */}
            </Menu>
        </section>
    );
}

export default ViewTimesheetTable;