import { ILogin, IResetPassword, IStandardAPIResponse, IUser } from "../../vm";
import { httpClient } from "../UtilService";

export async function loginToApp(
  cred: ILogin
): Promise<IStandardAPIResponse<string>> {
  try {
    let res = await httpClient<string>(`auth/login`, "POST", cred);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function sendEmailResetPwd(
  email: string
): Promise<IStandardAPIResponse<string>> {
  try {
    let res = await httpClient<string>(
      `accounts/forgot-password?email=${email}`,
      "POST",
      {}
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function resetPassword(
  data: IResetPassword
): Promise<IStandardAPIResponse<string>> {
  try {
    let res = await httpClient<string>(`accounts/reset-password`, "POST", {
      password: data.password,
      customerId: data.userId,
      email: data.email,
    }, false, true);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function verifyUserAccount(
  token: string
): Promise<IStandardAPIResponse<string>> {
  try {
    let res = await httpClient<string>(`accounts/verify`, "POST", {
      token,
      isUpdateToken: true,
    });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function checkDbExistsAPI(): Promise<IStandardAPIResponse<boolean>> {
  try {
    let res = await httpClient<boolean>(`auth/check-db`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getUserIP(): Promise<IStandardAPIResponse<boolean>> {
  try {
    let res = await httpClient<boolean>(`https://api.ipify.org/?format=json`, "GET",undefined,undefined,undefined,true);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function loginToAppForNoCase(
  cred: ILogin
): Promise<IStandardAPIResponse<IUser>> {
  try {
    let res = await httpClient<IUser>(`login/login`, "POST", cred);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getTokenForNoCase(
  email: string,caseName:string
): Promise<IStandardAPIResponse<string>> {
  try {
    let res = await httpClient<string>(`auth/auth-token`, "POST", {email,caseName});
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function verifyOTP(
  code:string,email:string
): Promise<IStandardAPIResponse<string>> {
  try {
    let res = await httpClient<string>(`login/verify-code`, "POST", { code, email });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function resendOTP(
  email: string
): Promise<IStandardAPIResponse<string>> {
  try {
    let res = await httpClient<string>(`login/resend-verification-code`, "POST", { email });
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function fetchAppVersion(): Promise<IStandardAPIResponse<string>> {
  try {
    let res = await httpClient<string>(`accounts/version`, "GET");
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
