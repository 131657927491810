import * as React from "react";
import { useState, useEffect } from "react";
import { INotificationManagement } from "../../vm";
import { ToastContext } from "../common/ToastProvider";
import Loading from "../common/Loading";
import CustomDialogDrawer from "../common/CustomDialogDrawer";
import { Formik, FormikErrors } from "formik";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import ReactSelect from "react-select";
import { NOTIFICATION_FREQUENCY, NOTIFICATION_TYPES } from "../../Constant";

interface ManageNotificationManagementDialogProps {
  data: INotificationManagement;
  handleDialogClose: Function;
  index:number
}

const ManageNotificationManagementDialog: React.FunctionComponent<
  ManageNotificationManagementDialogProps
> = ({ data, handleDialogClose,index }) => {
  const [state, setState] = React.useState({
    notification: {
      type: "",
      sendEmailNotification: false,
      frequency: "daily",
      typeObj: undefined,
      ...data,
    },
  } as {
    notification: INotificationManagement;
  });
  const [notificationsList, setNotificationsList] = useState<
    {
      value: string;
      label: string;
    }[]
  >();

  const [isLoading, setLoading] = React.useState(false);
  const { showToast } = React.useContext(ToastContext);

  const closeAddDialog = (data?: INotificationManagement) => {
    handleDialogClose(data);
  };
  useEffect(() => {
    let list = Object.keys(NOTIFICATION_TYPES).map((e) => {
      return { value: e, label: NOTIFICATION_TYPES[e] };
    });
    setNotificationsList(list);
  }, []);

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <CustomDialogDrawer
        variant="small"
        title={
          state.notification.id||index>-1
            ? "Edit Notification Type"
            : "Create Notification Type"
        }
        onClose={() => closeAddDialog()}
        handleOpen={true}
      >
        <Grid
          container
          direction="column"
          spacing={2}
          className="firm-configure-dialog"
        >
          <Grid item lg={12} md={12} sm={12}>
            <Formik
              enableReinitialize
              initialValues={state.notification}
              validate={(values) => {
                let errors: FormikErrors<INotificationManagement> = {};
                if (!values.type) {
                  errors.type = "Required";
                }
                if (values.sendEmailNotification && !values.frequency) {
                  errors.frequency = "Required";
                }
                return errors;
              }}
              onSubmit={async (
                values: INotificationManagement,
                { setSubmitting }
              ) => {
                // setLoading(true);
                // let obj = { ...values };
                // let res;
                // if (obj.id) {
                //     res = await updateAProfessionalLevel(obj);
                // } else {
                //     res = await createAProfessionalLevel(obj);
                // }
                // setLoading(false);
                // if (res.isSuccess) {
                //   if (res?.isReadOnly) {
                //     showToast(res.message, "error");
                //     closeAddDialog(obj);
                //   } else {
                closeAddDialog(values);
                showToast(
                  `Notification Type ${
                    (values.id||index>-1) ? "updated" : "created"
                  } successfully`,
                  "success"
                );
                //   }
                // } else {
                //   showToast(
                //     res.message
                //       ? res.message
                //       : `Error while ${
                //           values.id ? "updating" : "creating"
                //         } notification type`,
                //     "error"
                //   );
                // }
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className="position-relative">
                      <InputLabel className="custom-react-select-label">
                        Type
                      </InputLabel>
                      <ReactSelect
                        className={`react-select ${
                          errors && errors.type && touched.type
                            ? "react-select-field-error"
                            : ""
                        }`}
                        isMulti={false}
                        placeholder="Type"
                        value={values?.typeObj}
                        onChange={(data: any) => {
                          setFieldValue("typeObj", data);
                          setFieldValue("type", data?.value || "");
                        }}
                        options={notificationsList}
                      />
                      {errors && errors.type && touched.type && (
                        <FormHelperText className="text-danger">
                          {errors.type}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={
                              values?.sendEmailNotification ? true : false
                            }
                            onChange={() => {
                              setFieldValue(
                                "sendEmailNotification",
                                !values?.sendEmailNotification
                              );
                            }}
                          />
                        }
                        label="Send Email Notification"
                      />
                    </Grid>
                    {values?.sendEmailNotification&&<Grid item xs={12}>
                      <FormControl>
                        <FormLabel>Email Frequency</FormLabel>
                        <RadioGroup
                          row
                          name="frequency"
                          value={values.frequency}
                          onChange={handleChange}
                        >
                          {Object.keys(NOTIFICATION_FREQUENCY).map(
                            (e, index) => (
                              <FormControlLabel
                                key={index}
                                value={e}
                                control={<Radio size="small"/>}
                                label={NOTIFICATION_FREQUENCY[e]}
                              />
                            )
                          )}
                        </RadioGroup>
                      </FormControl>
                    </Grid>}
                    <Grid item xs={12}>
                      <Grid container spacing={2} justify="flex-end">
                        <Grid item>
                          <Button
                            variant="text"
                            onClick={() => closeAddDialog()}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {(values.id||index>-1) ? "Update" : "Add"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </CustomDialogDrawer>
    </React.Fragment>
  );
};

export default ManageNotificationManagementDialog;
