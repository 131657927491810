import * as React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";

interface EndUserLicenseAgreementMainProps {}

const EndUserLicenseAgreementMain: React.FunctionComponent<
  EndUserLicenseAgreementMainProps
> = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <section className="p-16">
        <main className={classes.main}>
          <img src="tortera_logo.svg" alt="" className={classes.logo} />
          <Typography variant="h4" align="center">
            End-User License Agreement
          </Typography>
        </main>
        <br />
        <Typography align="center">TortEra</Typography>
        <br />
        <Grid container justifyContent="center">
          <Grid item xs={11} sm={10} md={10}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" className="mb-1">
                  This End-User License Agreement (hereinafter, "Agreement")
                  creates a legally binding Agreement between you, as an end
                  user of our services (hereinafter, "End User"), and the
                  following company: TortEra (hereinafter,
                  "Company"). You will be referred to through second-person
                  pronouns such as "your" and "yours." We, the Company, will be
                  referred to with pronouns such as "us," "our," and "ours."
                  Collectively, you and the Company may be referred to as the
                  "Parties" and individually as "Party."
                </Typography>
                <Typography variant="subtitle1">
                  This license governs the use of all of our software products
                  (hereinafter, "Software"), specifically:
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ marginLeft: "32px" }}
                  className="mb-1"
                >
                  A software solution that collects, stores and organizes
                  confidential information regarding a specific legal matter.
                  The software solution is known as LegalFees.
                </Typography>
                <Typography variant="subtitle1">
                  By accessing, downloading, installing, or otherwise using our
                  Software in any way, you agree to be bound by this Agreement
                  in its entirety. If you do not agree, you must cease use of
                  the Software immediately.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  className="mb-1"
                  component="div"
                >
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    SOFTWARE LICENSE:&nbsp;
                  </Typography>
                  When you lawfully access the Software, whether through
                  purchase or other lawful means, we grant you, subject to all
                  of the terms and conditions of this Agreement, a
                  non-exclusive, non-transferable, limited, revocable license to
                  use the Software ("License"). This License extends to the use
                  of documentation, data, or information developed by the
                  Company, and other materials which may assist in your use of
                  the Software. This License may not be transferred to any third
                  parties without express, lawful, written permission from the
                  Company and this License terminates upon your cessation of use
                  of the Software.
                </Typography>
                <Typography variant="subtitle1">
                  This License shall be applicable to all lawful End Users of
                  the Software, unless a separate written agreement has been
                  executed between you and the Company
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    LICENSE: &nbsp;
                  </Typography>
                  The License is provided at no additional cost in conjunction
                  with the free nature of the Software. No fees are collected
                  from End Users.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    ADDITIONAL TERMS: &nbsp;
                  </Typography>
                  Additional terms may be applicable to the Parties'
                  relationship with each other, such as the Company Terms &
                  Conditions or Terms of Use, the Company Privacy Policy, and
                  any other such written agreements governing your relationship
                  with us. Nothing contained herein is intended to restrict the
                  terms of any other written agreement. Instead, all relevant
                  documents shall be construed as broadly as possible.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    INTELLECTUAL PROPERTY: &nbsp;
                  </Typography>
                  You agree that the Software, Company website and all services
                  provided by the Company are the property of the Company,
                  including all copyrights, trademarks, trade secrets, patents,
                  and other intellectual property ("Company IP"). You agree that
                  the Company owns all right, title and interest in and to the
                  Company IP and that you will not use the Company IP for any
                  unlawful or infringing purpose. You agree not to reproduce or
                  distribute the Company IP in any way, including electronically
                  or via registration of any new trademarks, trade names,
                  service marks or Uniform Resource Locators (URLs), without
                  express written permission from the Company.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  component="div"
                  className="mb-1"
                >
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    ACCEPTABLE USE: &nbsp;
                  </Typography>
                  In addition to the terms of our Acceptable Use Policy (“AUP”)
                  You agree not to use the Software for any unlawful purpose or
                  any purpose prohibited under this clause. You agree not to use
                  the Software in any way that could damage the Company website,
                  services, or general business of the Company.
                </Typography>
                <Typography variant="subtitle1" className="mb-1">
                  You further agree not to use the Software:
                </Typography>
                <Box style={{ marginLeft: "32px" }}>
                  <Typography variant="subtitle1" className="mb-1">
                    I) To harass, abuse, or threaten others or otherwise violate
                    any person's legal rights;
                  </Typography>
                  <Typography variant="subtitle1" className="mb-1">
                    II) To violate any intellectual property rights of the
                    Company or any third party;
                  </Typography>
                  <Typography variant="subtitle1" className="mb-1">
                    III) To upload or otherwise disseminate any computer viruses
                    or other software that may damage the property of another;
                  </Typography>
                  <Typography variant="subtitle1" className="mb-1">
                    IV) To perpetrate any fraud;
                  </Typography>
                  <Typography variant="subtitle1" className="mb-1">
                    V) To engage in or create any unlawful gambling,
                    sweepstakes, or pyramid scheme;
                  </Typography>
                  <Typography variant="subtitle1" className="mb-1">
                    VI) To publish or distribute any obscene or defamatory
                    material;
                  </Typography>
                  <Typography variant="subtitle1" className="mb-1">
                    VII) To publish or distribute any material that incites
                    violence, hate, or discrimination towards any group;
                  </Typography>
                  <Typography variant="subtitle1" className="mb-1">
                    VIII) To unlawfully gather information about others.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  component="div"
                  className="mb-1"
                >
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    REVERSE ENGINEERING & SECURITY: &nbsp;
                  </Typography>
                  You agree not to undertake any of the following actions:
                </Typography>
                <Box style={{ marginLeft: "32px" }}>
                  <Typography variant="subtitle1" className="mb-1">
                    a) Reverse engineer, or attempt to reverse engineer or
                    disassemble the Software or any code within or related to
                    the Software or the Company website;
                  </Typography>
                  <Typography variant="subtitle1" className="mb-1">
                    b) Violate the security of the Software through any
                    unauthorized access, circumvention of encryption or other
                    security tools, data mining or interference to any host,
                    user or network;
                  </Typography>
                  <Typography variant="subtitle1" className="mb-1">
                    c) Copy or otherwise distribute copies of the Software
                    unlawfully, such as through any peer-to-peer network or
                    other intellectual property circumvention tool.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    MAINTENANCE: &nbsp;
                  </Typography>
                  This License may include Software maintenance and support.
                  Please contact the Company for assistance.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    INDEMNIFICATION: &nbsp;
                  </Typography>
                  You agree to defend and indemnify the Company and any of its
                  affiliates (if applicable) and hold us harmless against any
                  and all legal claims and demands, including reasonable
                  attorney's fees, which may arise from or relate to your use or
                  misuse of the Software, your breach of this Agreement, or your
                  conduct or actions. You agree that the Company shall be able
                  to select its own legal counsel and may participate in its own
                  defense, if the Company wishes.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    SPAM POLICY: &nbsp;
                  </Typography>
                  You are strictly prohibited from using the Software or any of
                  the Company's services for illegal spam activities, including
                  gathering email addresses and personal information from others
                  or sending any mass commercial emails.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    SERVICE INTERRUPTIONS: &nbsp;
                  </Typography>
                  The Company may need to interrupt access to the Software to
                  perform maintenance or emergency services on a scheduled or
                  unscheduled basis. You agree that your access to the Software
                  may be affected by unanticipated or unscheduled downtime, for
                  any reason, but that the Company shall have no liability for
                  any damage or loss caused as a result of such downtime.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    NO WARRANTIES: &nbsp;
                  </Typography>
                  You agree that your use of the Software is at your sole and
                  exclusive risk and that the Software is provided on "As Is"
                  basis. The Company hereby expressly disclaims any and all
                  express or implied warranties of any kind, including, but not
                  limited to the implied warranty of fitness for a particular
                  purpose and the implied warranty of merchantability. The
                  Company makes no warranties that the Software will meet your
                  needs or that access to the Software will be uninterrupted or
                  error-free. The Company also makes no warranties as to the
                  reliability or accuracy of any information contained within
                  the Software. You agree that any damage that may occur to you,
                  through your computer system, or as a result of loss of your
                  data from your use of the Software is your sole responsibility
                  and that the Company is not liable for any such damage or
                  loss.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    LIMITATION ON LIABILITY: &nbsp;
                  </Typography>
                  The Company is not liable for any damages that may occur to
                  you as a result of your use of the Software, to the fullest
                  extent permitted by law. This section applies to any and all
                  claims by you, including, but not limited to, lost profits or
                  revenues, consequential or punitive damages, negligence,
                  strict liability, fraud, or torts of any kind.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    DISPUTE RESOLUTION & GOVERNING LAW: &nbsp;
                  </Typography>
                  All disputes will be resolved as provided for in the Terms &
                  Conditions or Terms of Service. Should Company not have a live
                  version of any Terms document or any other provisions in any
                  user-facing document covering dispute resolution and governing
                  law, the laws of California shall govern any matter or dispute
                  relating to or arising from this EULA or the Company's
                  relationship with End User.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    ASSIGNMENT: &nbsp;
                  </Typography>
                  This Agreement, or the rights granted hereunder, may not be
                  assigned, sold, leased or otherwise transferred in whole or
                  part by you. Should this Agreement, or the rights granted
                  hereunder, by assigned, sold, leased or otherwise transferred
                  by the Company, the rights and liabilities of the Company will
                  bind and inure to any assignees, administrators, successors,
                  and executors.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    HEADINGS FOR CONVENIENCE ONLY: &nbsp;
                  </Typography>
                  Headings of parts and sub-parts under this Agreement are for
                  convenience and organization, only. Headings shall not affect
                  the meaning of any provisions of this Agreement.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    NO AGENCY, PARTNERSHIP OR JOINT VENTURE: &nbsp;
                  </Typography>
                  No agency, partnership, or joint venture has been created
                  between the Parties as a result of this Agreement. No Party
                  has any authority to bind the other to third parties.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    FORCE MAJEURE: &nbsp;
                  </Typography>
                  The Company is not liable for any failure to perform due to
                  causes beyond its reasonable control including, but not
                  limited to, acts of God, acts of civil authorities, acts of
                  military authorities, riots, embargoes, terrorism, cyber
                  attacks, acts of nature and natural disasters, and other acts
                  which may be due to unforeseen circumstances.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 700 }}
                    component="span"
                  >
                    ELECTRONIC COMMUNICATIONS PERMITTED: &nbsp;
                  </Typography>
                  Electronic communications are permitted to both Parties under
                  this Agreement. For any questions or concerns, please email us
                  at the following address: Support@TortEra.com.
                  circumstances.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  Last Updated: September 6, 2023
                </Typography>
                <br />
                <br />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 206,
    margin: "16px 0",
  },
});

export default EndUserLicenseAgreementMain;
