import * as React from "react";
import { useState, useEffect } from "react";
import { ToastContext } from "../common/ToastProvider";
import Loading from "../common/Loading";
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { IConfirmDialog, INotificationManagement } from "../../vm";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";
import { StyledTableCell, StyledTableRow } from "../../services/UtilService";
import { DotsVertical } from "mdi-material-ui";
import ManageNotificationManagementDialog from "./ManageNotificationManagementDialog";
import { NOTIFICATION_FREQUENCY, NOTIFICATION_TYPES } from "../../Constant";

interface NotificationsManagementProps extends IConfirmDialog {}

const NotificationsManagement: React.FunctionComponent<
  NotificationsManagementProps
> = ({ confirmDialog }) => {
  const [state, setState] = React.useState({
    notificationsList: [{}],
    selectedItemIndex: -1,
    anchorElMenu: null,
    manageNotificationManagementDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
    },
  } as {
    notificationsList: INotificationManagement[];
    selectedItemIndex: number;
    anchorElMenu: any;
    manageNotificationManagementDialog: {
      isOpen: boolean;
      index?: number;
      data?: INotificationManagement;
    };
  });

  const [isLoading, setLoading] = React.useState(false);
  const { showToast } = React.useContext(ToastContext);
  const { isReadOnly, updateStatus } = React.useContext(IsReadOnlyContext);

  React.useEffect(() => {
    const fetchDetails = async () => {
      await getAllNotifications();
    };
    fetchDetails();
  }, []);

  const getAllNotifications = async () => {
    setState({ ...state, notificationsList: [
        {type:"attention",sendEmailNotification:true,frequency:"weekly"},
        {type:"action requested",sendEmailNotification:true,frequency:"monthly"},
    ] });
  };

  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setState({
      ...state,
      selectedItemIndex: index,
      anchorElMenu: event.currentTarget,
    });
  };

  const handleCloseMenu = () => {
    setState({ ...state, selectedItemIndex: -1, anchorElMenu: null });
  };

  const handleAddDialogOpen = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (editIndex !== undefined) {
      data = state.notificationsList[editIndex];
    }
    setState({
      ...state,
      manageNotificationManagementDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
      },
      selectedItemIndex: -1,
      anchorElMenu: null,
    });
  };

  const handleAddDialogClose = (data?: INotificationManagement) => {
    if (data) {
      // fetchProfessionalLevels();
      let list = [...(state.notificationsList || [])];
      if (state.manageNotificationManagementDialog.index === -1) {
        list.push(data);
      } else {
        list[state.manageNotificationManagementDialog.index] = data;
      }
      setState({
        ...state,
        notificationsList: list,
        manageNotificationManagementDialog: {
          isOpen: false,
          data: undefined,
          index: undefined,
        },
      });
    } else {
      setState({
        ...state,
        manageNotificationManagementDialog: {
          isOpen: false,
          data: undefined,
          index: undefined,
        },
      });
    }
  };

  const handleDelete = (index: number) => {
    confirmDialog.show("Are you sure", "Do you want to delete?", async () => {
      let list = [...state.notificationsList];
      list.splice(index, 1);
      setState({
        ...state,
        notificationsList: list,
        selectedItemIndex: -1,
        anchorElMenu: null,
      });
      showToast("A notification type deleted successfully", "success");
    });
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <section className="p-16">
        <div className="text-center">
          <Typography variant="h5">Notifications Management</Typography>
        </div>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isReadOnly}
                  onClick={() => {
                    handleAddDialogOpen(true, -1);
                  }}
                >
                  Add new type
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} direction="row">
              <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TableContainer className="professional-level-table-container moz-table-pb">
                      <Table stickyHeader size="small" className="custom-table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Notification Type</StyledTableCell>
                            <StyledTableCell>
                              Send Email Notification
                            </StyledTableCell>
                            <StyledTableCell>Frequency</StyledTableCell>
                            <StyledTableCell>Actions</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {state.notificationsList.length > 0 ? (
                            state.notificationsList.map(
                              (ele, index: number) => (
                                <StyledTableRow key={index}>
                                  <StyledTableCell>
                                    {NOTIFICATION_TYPES[ele.type]}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {ele.sendEmailNotification ? "Yes" : "No"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {ele.sendEmailNotification && ele.frequency
                                      ? NOTIFICATION_FREQUENCY[ele.frequency]
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <IconButton
                                      size="small"
                                      onClick={(e) => handleClickMenu(e, index)}
                                    >
                                      <DotsVertical />
                                    </IconButton>
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            )
                          ) : (
                            <TableRow>
                              <TableCell align="center" colSpan={4}>
                                No notification types found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <Menu
        id="simple-menu"
        anchorEl={state.anchorElMenu}
        keepMounted
        open={Boolean(state.anchorElMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          disabled={isReadOnly}
          onClick={() => {
            handleAddDialogOpen(true, state.selectedItemIndex);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          disabled={isReadOnly}
          onClick={() => {
            handleDelete(state.selectedItemIndex);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      {state.manageNotificationManagementDialog.isOpen && (
        <ManageNotificationManagementDialog
          data={state.manageNotificationManagementDialog.data}
          handleDialogClose={(data?: INotificationManagement) =>
            handleAddDialogClose(data)
          }
        />
      )}
    </React.Fragment>
  );
};

export default withConfirmDialogContext(NotificationsManagement);
