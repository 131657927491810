import * as React from "react";
import { isTokenExpired } from "./services/UtilService";
import { ClassProps } from "./vm";
import {
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import { AuthenticatedRoute } from "./components/common/AuthenticatedRoute";
import LoginPage from "./pages/auth/LoginPage";
import { createStyles, Theme, withStyles } from "@material-ui/core";
import Page404 from "./pages/common/Page404";
import DashboardPage from "./pages/dashboard/DashboardPage";
import TimesheetsPage from "./pages/timesheets/TimesheetsPage";
import SubmitToCourtMain from "./components/court/SubmitToCourtMain";
import ExpensesPage from "./pages/expenses/ExpensesPage";
import ViewTimesheetMain from "./components/timesheets/ViewTimesheetMain";
import ViewExpenseMain from "./components/expenses/ViewExpenseMain";
import CourtSubmissionsPage from "./pages/court-submissions/CourtSubmissionsPage";
import BusinessRulesMain from "./components/business-rules/BusinessRulesMain";
import AccessManagementMain from "./components/access-management/AccessManagementMain";
import VerifyAccountMain from "./components/auth/VerifyAccountMain";
import ForgotPasswordMain from "./components/forgot-password/ForgotPasswordMain";
import ExpenseSubmitToCourtMain from "./components/expenses/ExpenseSubmitToCourtMain";
import TimeGrantedNotGranted from "./components/time-granted-not-granted/TimeGrantedNotGrantedMian";
import ExpenseGrantedNotGranted from "./components/expense-granted-not-granted/ExpenseGrantedNotGranted";
import ConfigureFirms from "./components/configure-firms/ConfigureFirms";
import ImportList from "./components/import/ImportList";
import NotAuthorizedPage from "./pages/common/NotAuthorizedPage";
import NotesMain from "./components/notes/NotesMain";
import ResourcesMain from "./components/resources/ResourcesMain";
import ProfessionalLevelManagement from "./components/professional-level-management/ProfessionalLevelManagement";
import TimeCategoryManagement from "./components/time-category-management/TimeCategoryManagement";
import TimeApprovedByManagement from "./components/time-approved-by-management/TimeApprovedByManagement";
import KeywordManagement from "./components/keywords-management/KeywordManagement";
import RulesMain from "./components/rules/RulesMain";
import TimesheetsDetailsPage from "./pages/timesheets/TimesheetsDetailsPage";
import AttentionTimesheetsDetailsPage from "./pages/timesheets/AttentionTimesheetsDetailsPage";
import CmoProtocolMain from "./components/cmo-protocols/CmoProtocolMain";
import DBNotExists from "./pages/common/DBNotExists";
import { URL_PATHS } from "./Constant";
import AboutMain from "./components/about/AboutMain";
import ReportDetailsView from "./components/reports/ReportDetailsView";
import LoginsHistory from "./components/logins-history/LoginsHistory";
import VerifyPage from "./pages/auth/VerifyPage";
import CommitteeReport from "./components/reports/CommitteeReport";
import AcceptableUsePolicyPage from "./pages/static/AcceptableUsePolicyPage";
import EndUserLicenseAgreementPage from "./pages/static/EndUserLicenseAgreementPage";
import NotificationsManagement from "./components/notifications/NotificationsManagement";

export interface RoutesMainProps extends RouteComponentProps, ClassProps { }

const RoutesMain: React.FC<RoutesMainProps> = ({ classes, location }) => {
  let hasTokenExpired = isTokenExpired();
  return (
    <main className={`${classes.content} ${(hasTokenExpired || location.pathname.indexOf(URL_PATHS.DB_DOES_NOT_EXISTS) > -1) ? "un-auth-pages" : "mt-50"}`}>
      <Switch>
        <Route path="/" exact component={LoginPage} />
        <Route path="/verify" exact component={VerifyPage} />
        <Route path="/verify/account" exact component={VerifyAccountMain} />
        <Route path="/forgot-password" exact component={ForgotPasswordMain} />
        <Route path="/acceptable-use-policy" exact component={AcceptableUsePolicyPage} />
        <Route path="/end-user-license-agreement" exact component={EndUserLicenseAgreementPage} />
        <AuthenticatedRoute path="/dashboard" exact component={DashboardPage} />
        <AuthenticatedRoute
          path="/timesheets-summary"
          exact
          component={TimesheetsPage}
        />
        <AuthenticatedRoute
          path="/timesheets-details"
          exact
          component={TimesheetsDetailsPage}
        />
        <AuthenticatedRoute
          path="/attention-timesheets-details"
          exact
          component={AttentionTimesheetsDetailsPage}
        />
        <AuthenticatedRoute
          path="/submit-time-to-court"
          exact
          component={SubmitToCourtMain}
        />
        {/* <AuthenticatedRoute
          path="/submit-expense-to-court"
          exact
          component={ExpenseSubmitToCourtMain}
        /> */}
        <AuthenticatedRoute
          path="/timesheets-summary/timesheet"
          exact
          component={ViewTimesheetMain}
        />
        {/* <AuthenticatedRoute path="/expenses" exact component={ExpensesPage} /> */}
        <AuthenticatedRoute
          path="/expenses/expense"
          exact
          component={ViewExpenseMain}
        />
        <AuthenticatedRoute
          path="/court-submissions"
          exact
          component={CourtSubmissionsPage}
        />
        <AuthenticatedRoute
          path="/business-rules"
          exact
          component={BusinessRulesMain}
        />
        {/* <AuthenticatedRoute
          path="/time-granted-notgranted"
          exact
          component={TimeGrantedNotGranted}
        /> */}
        {/* <AuthenticatedRoute
          path="/expense-granted-notgranted"
          exact
          component={ExpenseGrantedNotGranted}
        /> */}
        <AuthenticatedRoute
          path="/access-management"
          exact
          component={AccessManagementMain}
        />
        <AuthenticatedRoute
          path="/configure-firms"
          exact
          component={ConfigureFirms}
        />
        <AuthenticatedRoute
          path="/notes"
          exact
          component={NotesMain}
        />
        <AuthenticatedRoute
          path="/import"
          exact
          component={ImportList}
        />
        <AuthenticatedRoute
          path="/resource-management"
          exact
          component={ResourcesMain}
        />
        <AuthenticatedRoute
          path="/professional-level-management"
          exact
          component={ProfessionalLevelManagement}
        />
        <AuthenticatedRoute
          path="/time-category-management"
          exact
          component={TimeCategoryManagement}
        />
        {/* <AuthenticatedRoute
          path="/time-approved-by-management"
          exact
          component={TimeApprovedByManagement}
        /> */}
        <AuthenticatedRoute
          path="/keyword-management"
          exact
          component={KeywordManagement}
        />
        <AuthenticatedRoute
          path="/notifications-management"
          exact
          component={NotificationsManagement}
        />
        <AuthenticatedRoute
          path="/cmo-protocols-for-common-benefit-work"
          exact
          component={CmoProtocolMain}
        />

        <AuthenticatedRoute
          path="/rules"
          exact
          component={RulesMain}
        />
        {/* <AuthenticatedRoute
          path="/reports"
          exact
          component={ReportsMain}
        /> */}
        <AuthenticatedRoute
          path="/reports/:reportId/details"
          exact
          component={ReportDetailsView}
        // component={ReportDetails}
        />
        <AuthenticatedRoute
          path="/reports/committee"
          exact
          component={CommitteeReport}
        // component={ReportDetails}
        />
        <AuthenticatedRoute
          path="/about"
          exact
          component={AboutMain}
        />
        <AuthenticatedRoute
          path="/logins-history"
          exact
          component={LoginsHistory}
        />
        <Route path="/not-authorized" exact component={NotAuthorizedPage} />
        <Route path={`/${URL_PATHS.DB_DOES_NOT_EXISTS}`} exact component={DBNotExists} />
        <Route path="" exact component={Page404} />
      </Switch>
    </main>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    appBarSpacer: { height: 16 },
    content: {
      flexGrow: 1,
      height: "calc(100vh - 50px)",
      overflow: "auto",
      //   padding: 8,
      //   [theme.breakpoints.down("sm")]: {
      //     padding: 8,
      //   },
      backgroundColor: "#fbfbfb",
    },
  });

export default withStyles(styles)(withRouter(RoutesMain));
