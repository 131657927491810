import * as React from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableSortLabel,
    Grid,
    Button,
} from "@material-ui/core";
import {
    convertToFixed,
    currencyFormat,
    numberFormat,
    StyledTableCell,
} from "../../services/UtilService";
import { IFirmTotalFees } from "../../vm";
import "./dashboard.css";
import SecondaryLoading from "../common/SecondaryLoading";
export interface FirmServiceFeesProps {
    firmServiceFees: Array<IFirmTotalFees>;
    label?: string;
    onSort: Function;
    onExport: Function;
    sortBy?: any;
    sortOrder?: any;
    isLoading: boolean;
}

const FirmServiceFees: React.FC<FirmServiceFeesProps> = (
    props
) => {
    const onSort = (sortBy?: any) => {
        props.onSort(sortBy);
    };
    const onExport = () => {
        props.onExport();
    };
    return (
        <section className="position-relative">
            {/* {props.isLoading && <SecondaryLoading />} */}
            <Grid container spacing={2} justify="space-between" className="py-16">
                <Grid item>
                    <Typography variant="h6">
                        {props.label ? props.label : "Firm Service Fees"}
                    </Typography>
                </Grid>
                {props.firmServiceFees?.length > 0 && !props.isLoading &&
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                onExport();
                            }}
                        >
                            Export
                        </Button>
                    </Grid>
                }
            </Grid>
            {props.isLoading ? <SecondaryLoading /> :
                <TableContainer className="firm-service-fees-table-container moz-table-pb">
                    <Table size="small" className="custom-table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center" className="head-header fixed-header-column"></StyledTableCell>
                                <StyledTableCell colSpan={2} align="center" className="fixed-header-column">Total Fees</StyledTableCell>
                                <StyledTableCell colSpan={2} align="center" className="fixed-header-column" width={250}>New, Attention, Reviewed and Adjusted</StyledTableCell>
                                <StyledTableCell colSpan={2} align="center" className="fixed-header-column">Approved</StyledTableCell>
                                <StyledTableCell colSpan={2} align="center" className="fixed-header-column">Disapproved</StyledTableCell>
                                {/* <StyledTableCell colSpan={2} align="center" className="fixed-header-column">Submitted</StyledTableCell>
                                <StyledTableCell colSpan={2} align="center" className="fixed-header-column">Granted</StyledTableCell>
                                <StyledTableCell colSpan={2} align="center" className="fixed-header-column">Not Granted</StyledTableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell className="head-header firm-service-header-fixed-column-2">
                                    <TableSortLabel
                                        active={props.sortBy === "firmName"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("firmName");
                                        }}
                                    >
                                        Firm
                                    </TableSortLabel>

                                </StyledTableCell>
                                <StyledTableCell

                                    align="center" className="firm-service-header-fixed-column-2"
                                >
                                    <TableSortLabel
                                        active={props.sortBy === "totalFeesSum"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("totalFeesSum");
                                        }}
                                    >
                                        All
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="firm-service-header-fixed-column-2 firms-percentage-column">
                                    <TableSortLabel
                                        active={props.sortBy === "totalFeesPercentage"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("totalFeesPercentage");
                                        }}
                                    >
                                        % of all firms
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="firm-service-header-fixed-column-2">
                                    <TableSortLabel
                                        active={props.sortBy === "newAttentionSum"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("newAttentionSum");
                                        }}
                                    >
                                        All
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="firm-service-header-fixed-column-2 firms-percentage-column">
                                    <TableSortLabel
                                        active={props.sortBy === "newAttentionPercentage"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("newAttentionPercentage");
                                        }}
                                    >
                                        % of all firms
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="firm-service-header-fixed-column-2">
                                    <TableSortLabel
                                        active={props.sortBy === "approvedSum"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("approvedSum");
                                        }}
                                    >
                                        All
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="firm-service-header-fixed-column-2 firms-percentage-column">
                                    <TableSortLabel
                                        active={props.sortBy === "approvedPercentage"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("approvedPercentage");
                                        }}
                                    >
                                        % of all firms
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="firm-service-header-fixed-column-2">
                                    <TableSortLabel
                                        active={props.sortBy === "disapprovedSum"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("disapprovedSum");
                                        }}
                                    >
                                        All
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="firm-service-header-fixed-column-2 firms-percentage-column">
                                    <TableSortLabel
                                        active={props.sortBy === "disapprovedPercentage"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("disapprovedPercentage");
                                        }}
                                    >
                                        % of all firms
                                    </TableSortLabel>
                                </StyledTableCell>
                                {/* <StyledTableCell align="center" className="firm-service-header-fixed-column-2">
                                    <TableSortLabel
                                        active={props.sortBy === "submittedSum"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("submittedSum");
                                        }}
                                    >
                                        All
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="firm-service-header-fixed-column-2 firms-percentage-column">
                                    <TableSortLabel
                                        active={props.sortBy === "submittedPercentage"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("submittedPercentage");
                                        }}
                                    >
                                        % of all firms
                                    </TableSortLabel>
                                </StyledTableCell> */}
                                {/* <StyledTableCell align="center" className="firm-service-header-fixed-column-2">
                                    <TableSortLabel
                                        active={props.sortBy === "grantedSum"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("grantedSum");
                                        }}
                                    >
                                        All
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="firm-service-header-fixed-column-2 firms-percentage-column">
                                    <TableSortLabel
                                        active={props.sortBy === "grantedPercentage"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("grantedPercentage");
                                        }}
                                    >
                                        % of all firms
                                    </TableSortLabel>
                                </StyledTableCell> */}
                                {/* <StyledTableCell align="center" className="firm-service-header-fixed-column-2">
                                    <TableSortLabel
                                        active={props.sortBy === "notGrantedSum"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("notGrantedSum");
                                        }}
                                    >
                                        All
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="center" className="firm-service-header-fixed-column-2 firms-percentage-column">
                                    <TableSortLabel
                                        active={props.sortBy === "notGrantedPercentage"}
                                        direction={props.sortOrder}
                                        onClick={() => {
                                            onSort("notGrantedPercentage");
                                        }}
                                    >
                                        % of all firms
                                    </TableSortLabel>
                                </StyledTableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.firmServiceFees?.length > 0 ? (
                                <React.Fragment>
                                    {props.firmServiceFees.map((firmServiceFee, index) => (
                                        <TableRow key={`firmServiceFee_${index}`}>
                                            <TableCell className="head-column">
                                                {firmServiceFee.firmName}
                                            </TableCell>
                                            <TableCell align="right">
                                                {currencyFormat(
                                                    convertToFixed(firmServiceFee.totalFeesSum)
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {convertToFixed(firmServiceFee.totalFeesPercentage)}
                                            </TableCell>
                                            <TableCell align="right">
                                                {currencyFormat(
                                                    convertToFixed(firmServiceFee.newAttentionSum)
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {convertToFixed(firmServiceFee.newAttentionPercentage)}
                                            </TableCell>
                                            <TableCell align="right">
                                                {currencyFormat(
                                                    convertToFixed(firmServiceFee.approvedSum)
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {convertToFixed(firmServiceFee.approvedPercentage)}
                                            </TableCell>
                                            <TableCell align="right">
                                                {currencyFormat(
                                                    convertToFixed(firmServiceFee.disapprovedSum)
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {convertToFixed(firmServiceFee.disapprovedPercentage)}
                                            </TableCell>
                                            {/* <TableCell align="right">
                                                {currencyFormat(
                                                    convertToFixed(firmServiceFee.submittedSum)
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {convertToFixed(firmServiceFee.submittedPercentage)}
                                            </TableCell>
                                            <TableCell align="right">
                                                {currencyFormat(
                                                    convertToFixed(firmServiceFee.grantedSum)
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {convertToFixed(firmServiceFee.grantedPercentage)}
                                            </TableCell>
                                            <TableCell align="right">
                                                {currencyFormat(
                                                    convertToFixed(firmServiceFee.notGrantedSum)
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {convertToFixed(firmServiceFee.notGrantedPercentage)}
                                            </TableCell> */}
                                        </TableRow>
                                    ))}
                                </React.Fragment>
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        No Data found
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </section>
    );
};

export default FirmServiceFees;
