import * as React from "react";
import { Theme, withStyles, createStyles, Grid, Button, FormControl, TextField } from "@material-ui/core";
// import { Link } from "react-router-dom";
// import Home from "mdi-material-ui/Home";
import { ClassProps } from "../../vm";
import "./db-not-exists.css"
import { RouteComponentProps, withRouter } from "react-router-dom";
// const MyLink = (props: any) => <Link to="/" {...props} />;

export interface DBNotExistsProps extends RouteComponentProps, ClassProps { }

export interface DBNotExistsState {
    email: string;
}

class DBNotExists extends React.Component<
    DBNotExistsProps,
    DBNotExistsState
> {
    constructor(props: DBNotExistsProps) {
        super(props);
        this.state = {
            email: ""
        };
    }

    goToLoginPage=()=>{
        this.props.history.push("/");
    }
    
    render() {
        const { classes } = this.props;
        return (
            <section className="db-not--exists-main-section">
                <Grid className="contact-us-header" container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <img src="/new-logo.png" alt="leagalfees" style={{ height: 55 }} />
                        {/* <img src="/legalfee-logo.png" alt="leagalfees" /> */}
                    </Grid>
                    <Grid>
                        <Button variant="contained" color="primary" className={classes.login} onClick={()=>{ this.goToLoginPage() }}>User Login</Button>
                        <Button variant="text" className="contact-us-btn">Contact Us</Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="db-not--exists-main-container">
                    <Grid item lg={12}>
                        <img className="banner-img" src="/banner.png" alt="leagalfees" />
                    </Grid>
                    <Grid item lg={12} className="legal-fee-container">
                        <p className="legal-fee-content">
                            <strong>LegalFees</strong> is a stealth startup that leverages artificial intelligence to improve transparency and reduce costs in the world of mass tort fund fees and costs. Our team of experienced data scientists and legal professionals are hard at work building a platform that will provide plaintiffs and their attorneys with a more efficient and effective way to manage the fees associated with mass tort settlements.
                        </p>
                        <p className="legal-fee-content">
                            We understand that mass tort litigation can be complex and expensive, and that hidden fees can make it difficult for plaintiffs to get the compensation they deserve. That's why we're dedicated to providing an innovative and data-driven solution that will help plaintiffs and their attorneys make more informed decisions about their cases.
                        </p>
                        <p className="legal-fee-content">
                            We're not quite ready to share all the details yet, but we promise that our solution will be a game-changer in the world of mass tort litigation. Our platform will provide real-time insights into the costs and fees associated with mass tort settlements, and will enable plaintiffs and their attorneys to make more informed decisions about their cases.
                        </p>
                        <p className="legal-fee-content">
                            Stay tuned for more information about LegalFees. We'll be updating this page as we get closer to launch, and we'll also be sharing news and updates on our social media channels.
                        </p>
                        <p className="legal-fee-content">
                            In the meantime, if you're an attorney or plaintiff involved in a mass tort settlement, please reach out to us. We'll be happy to provide you with more information about our platform and how we can help you reduce your costs and improve your outcomes.
                        </p>
                    </Grid>
                    <Grid item lg={12} className="reach-out-container">
                        <Grid container spacing={2} direction="column">
                            <Grid item className="text-center reach-out-header">
                                Reach out to us
                            </Grid>
                            <Grid item >
                                <Grid container spacing={2} className="reach-out-email-container">
                                    <Grid item >
                                        <FormControl size="small" fullWidth>
                                            <TextField
                                                label="Your Email Address"
                                                value={this.state.email}
                                                autoFocus={false}
                                                fullWidth
                                                onChange={(event: any) => {
                                                    const value = event.target.value;
                                                    this.setState(state => ({
                                                        ...state,
                                                        email: value
                                                    }))
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item >
                                        <Button fullWidth
                                            variant="contained"
                                            color="primary"
                                            className="reach-out-btn"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={12} className="contact-us-footer">
                        <Grid container spacing={2}>
                            <Grid item lg={3} xs={12}>
                                <img src="/legalfee-footer-logo.png" alt="footer" />
                            </Grid>
                            <Grid item lg={9} xs={12} className="footer-content">
                                Thank you for your interest in LegalFees. We can't wait to show you what we've been working on and how we're using artificial intelligence to make a difference in the world of mass tort fund fees.
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <hr />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Grid container spacing={2} className="footer-copy-right-section">
                                    <Grid item lg={12} className="text-center">
                                        Copyright © {new Date().getFullYear()} - LegalFees
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </section>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            textAlign: "center",
            padding: theme.spacing(4),
            width: "400px",
            margin: "16px auto",
        },
        paper: {},
        button: {},
        login: {
            marginRight: theme.spacing(1),
        },
    });

export default withRouter(withStyles(styles)(DBNotExists));
