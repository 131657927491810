import {
  Button,
  Card,
  FormControlLabel,
  Grid,
  Hidden,
  Switch,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import {
  abbreviateNumber,
  convertToFixed,
  currencyFormat,
  numberFormat,
} from "../../services/UtilService";
import {
  getProfessionalLevelHourDetails,
  getCategoryHourStatsDetails,
  getFirmServiceFees,
  getFirmServiceHours,
  getTimesheetReport,
  getTimesheetHoursFeeReport,
} from "../../services/DashboardService";
import moment from "moment";
import { ToastContext } from "../common/ToastProvider";
import Loading from "../common/Loading";
import CustomDateDialog from "./CustomDateDialog";
import { FirmContext } from "../common/FirmProvider";
import Filter from "../common/Filter";
import {
  IFilter,
  IProfessionalHourStats,
  ICategoryHourStats,
  IKeyValue,
  IFirmDetails,
  IPerson,
  IDashboardTime,
  IFirmTotalFees,
  ITimeFilter,
  IConfirmDialog,
  IFirmTotalHours,
} from "../../vm";
import CustomDrawer from "../common/CustomDrawer";
import {
  getTimesheetPersons,
  getTimesheetFiltersCount,
  getAllProfessionalLevels,
  getAllCategories,
  getAllWorkTypes,
  getAllApprovedListForTimeEntry,
} from "../../services/TimesheetService";
import {
  DESCRIPTION_FILTER_ENTRY,
  styles,
  TIMESHEET_STATUS,
  TIME_ENTRY_KEY_WORDS,
  TREE_YEAR_DATE_FORMAT,
  YEAR_ARRAY,
} from "../../Constant";
import ToggleFilterSection from "./ToggleFilter";
import { getAllNotes } from "../../services/NoteService";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";
import BarChart from "../common/charts/BarChart";
import SecondaryLoading from "../common/SecondaryLoading";
import TreeMap from "../common/charts/TreeMap";
import TimeSheetHeader from "../timesheets/TimeSheetHeader";
import PieChart from "../common/charts/PieChart";

export interface DashboardTabMainProps extends IConfirmDialog { }

const DashboardTabMain: React.FC<DashboardTabMainProps> = (props) => {
  const [state, setState] = React.useState({
    searchObj: {
      from: 0,
      size: 10,
      statuses: [],
      firms: [],
      lawFirmCode: [],
      persons: [],
      // startDate: moment("2017-01-01", "YYYY-MM-DD")
      //   .subtract(1, "year")
      //   .toDate(),
      // endDate: moment().toDate(),
      startDate: undefined,
      endDate: undefined,
      sortBy: "",
      sortOrder: "asc",
      professionalLevelSortBy: "PROFESSIONAL_LEVEL_SORT",
      professionalLevelSortOrder: "asc",
      categoryHourSortBy: "CATEGORY_CODE",
      categoryHourSortOrder: "asc",
      firmServiceFeesSortBy: "firmName",
      firmServiceFeesSortOrder: "asc",
      firmServiceHoursSortBy: "firmName",
      firmServiceHoursSortOrder: "asc",
      firmTimesheetMetricsSortBy: "firmName",
      firmTimesheetMetricsSortOrder: "asc",
      keyword: [],
      min: "",
      max: "",
      categoryCode: [],
      workType: [],
      professionalLevel: [],
      minWordCount: "",
      maxWordCount: "",
      showDisapproved: false,
      descriptionSearchFilters: [],
      timePeriod: [],
      approvedBy: [],
      showOnlyDisapproved: false,
      noteId: "",
    },
    firmsList: [],
  } as {
    searchObj: IDashboardTime;
    firmsList: { value: any; label: any }[];
  });
  const [selectedProfessionalChart, setSelectedProfessionalChart] =
    React.useState("totalHours" as "totalHours" | "totalFees");
  const [selectedTimeCategoryChart, setSelectedTimeCategoryChart] =
    React.useState("totalHours" as "totalHours" | "totalFees");
  const [selectedFirmFeesChart, setSelectedFirmFeesChart] = React.useState(
    "totalHours" as "totalHours" | "totalFees"
  );
  const [isFirmFeesChartPercentage, setIsFirmFeesChartPercentage] =
    React.useState(false);
  const [isFirmHoursChartPercentage, setIsFirmHoursChartPercentage] =
    React.useState(false);

  const [
    isProfessionalLevelFeePercentage,
    setIsProfessionalLevelFeePercentage,
  ] = React.useState(false);
  const [
    isProfessionalLevelHoursPercentage,
    setIsProfessionalLevelHoursPercentage,
  ] = React.useState(false);
  const [
    isProfessionalLevelResourcesPercentage,
    setIsProfessionalLevelResourcesPercentage,
  ] = React.useState(false);

  const [isTimeCategoryFeePercentage, setIsTimeCategoryFeePercentage] =
    React.useState(false);
  const [isTimeCategoryHoursPercentage, setIsTimeCategoryHoursPercentage] =
    React.useState(false);
  const [
    isTimeCategoryResourcesPercentage,
    setIsTimeCategoryResourcesPercentage,
  ] = React.useState(false);

  const [firmServiceHourLoading, setFirmServiceHourLoading] =
    React.useState(true);
  const [isFilterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [firmsDic, setFirmsDic] = React.useState({} as IKeyValue<IFirmDetails>);
  const [professionalLevelDic, setProfessionalLevelDic] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [categoryCodeDic, setCategoryCodeDic] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialWorkTypes, setInitialWorkTypes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialApprovedBy, setInitialApprovedBy] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [noteList, setNoteList] = React.useState(
    [] as Array<{ label: string; value: string }>
  );
  const [personsDic, setPersonsDic] = React.useState({} as IKeyValue<IPerson>);
  const { showToast } = React.useContext(ToastContext);
  const firmProvider = React.useContext(FirmContext);
  const [filterData, setFilterData] = React.useState([] as IFilter[]);
  const [initialFirms, setInitialFirms] = React.useState(
    {} as IKeyValue<IFirmDetails>
  );
  const [initialProfessionalLevels, setInitialProfessionalLevels] =
    React.useState({} as IKeyValue<{ label: string; value: string }>);
  const [initialCategoryCodes, setInitialCategoryCodes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [, setProfessionalLevels] = React.useState([]);
  const [, setCategories] = React.useState([]);
  const [keyWordsList] = React.useState(TIME_ENTRY_KEY_WORDS);

  const [isCustomDateOpen, setCustomDialog] = React.useState(false);

  const [professionalHoursStatsData, setProfessionalHoursStatsData] =
    React.useState([] as Array<IProfessionalHourStats>);

  const [categoryHoursStatsData, setCategoryHoursStatsData] = React.useState(
    [] as Array<ICategoryHourStats>
  );

  const [hoursAndFeeCount, setHoursAndFeeCount] = React.useState({
    hours: 0,
    fee: 0,
  });
  const [timesheetCountDic, setTimesheetCountDic] = React.useState(
    {} as IKeyValue<number>
  );

  const [firmServiceFees, setFirmServiceFees] = React.useState(
    [] as Array<IFirmTotalFees>
  );

  const [firmServiceHours, setFirmServiceHours] = React.useState(
    [] as Array<IFirmTotalHours>
  );

  const [isCountsLoaded, setCountsLoaded] = React.useState(false);
  const [filterCountsDic, setFilterCountsDic] = React.useState({});
  const [isFilterOpen, setFilterOpen] = React.useState(false);
  const [professionalLevelLoading, setProfessionalLevelLoading] =
    React.useState(true);
  const [timeCategoryLoading, setTimeCategoryLoading] = React.useState(true);
  const [loadedFilter, setLoadedFilter] = React.useState(
    undefined as ITimeFilter
  );
  const [dateRangePickerMinMaxObj, setDateRangePickerMinMaxObj] =
    React.useState({
      minDate: undefined,
      maxDate: undefined,
    } as {
      minDate?: Date;
      maxDate?: Date;
    });
  const [colorCode, setColorCode] = React.useState<Array<string>>([
    "#98AFC7",
    "#728FCE",
    "#00BFFF",
    "#B0E0E6",
    "#E3E4FA",
    "#AAF0D1",
    "#50C878",
    "#CCFB5D",
    "#C3FDB8",
    "#DBF9DB",
    "#F0FFF0",
    "#F3E5AB",
    "#ECC5C0",
    "#FDD7E4",
    "#FFC0CB",
    "#CCCCFF",
    "#E0B0FF",
    "#DFD3E3",
    "#E9E4D4",
    "#F5F5DC",
    "#FFFDD0",
    "#CCFF99",
    "#CCFFCC",
    "#CCFFFF",
    "#FF99CC",
    "#FFCCCC",
    "#FFFFCC",
    "#F5F5F5",
    "#FFF5EE",
    "#FAAFBE",
    "#F98B88",
    "#FF8C00",
    "#827839",
    "#D4A017",
    "#FFF380",
    "#045D5D",
  ]);

  React.useEffect(() => {
    const fetchTimeSheetReport = async () => {
      setLoading(true);
      let professionalLevelsDic = await getAllProfessionalLevelsList();
      let categoriesCodeDic = await getAllCategoriesList();
      let workTypesDic = await getAllWorkTypesList();
      let approvedByDic = await getAllApprovedByList();
      let noteList = await getAllNoteList();
      await getUserFirms({
        professionalLevelsDic,
        categoriesCodeDic,
        workTypesDic,
        approvedByDic,
        noteList,
      });
      setLoading(false);
    };
    fetchTimeSheetReport();
  }, []);

  const getAllProfessionalLevelsList = async () => {
    const result = await getAllProfessionalLevels();
    let professionalLevelDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return {
            label: x.professionalLevelName,
            value: x.professionalLevelCode,
          };
        });
        professionalLevelDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setProfessionalLevelDic(professionalLevelDic);
        setProfessionalLevels(data || []);
      }
    } else {
      showToast(
        result.message || "Error while fetching professional levels",
        "error"
      );
    }
    return professionalLevelDic;
  };
  const getAllCategoriesList = async () => {
    const result = await getAllCategories();
    let categoryCodeDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.timeCategoryName, value: x.timeCategoryCode };
        });
        categoryCodeDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setCategoryCodeDic(categoryCodeDic);
        setCategories(data || []);
      }
    } else {
      showToast(result.message || "Error while fetching categories", "error");
    }
    return categoryCodeDic;
  };

  const getAllWorkTypesList = async () => {
    const result = await getAllWorkTypes();
    let workTypesDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.workTypeLongDescription, value: x.workTypeCode };
        });
        workTypesDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialWorkTypes(workTypesDic);
      }
    } else {
      showToast(result.message || "Error while fetching work types", "error");
    }
    return workTypesDic;
  };

  const getAllApprovedByList = async () => {
    const result = await getAllApprovedListForTimeEntry();
    let approvedByDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.timeApprovedByName, value: x.timeApprovedByCode };
        });
        approvedByDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialApprovedBy(approvedByDic);
      }
    } else {
      showToast(result.message || "Error while fetching approved by", "error");
    }
    return approvedByDic;
  };

  const getAllNoteList = async () => {
    const result = await getAllNotes({});
    let noteList = [];
    if (result?.isSuccess) {
      if (result?.data) {
        noteList = result.data.results.map((x) => {
          return { label: x.description, value: x.id };
        });
        setNoteList(noteList);
      }
    } else {
      showToast(result.message || "Error while fetching notes", "error");
    }
    return noteList;
  };

  const getUserFirms = async (professionCategoriesData) => {
    let userFirms = firmProvider.userFirms;
    if (userFirms.length === 0) {
      userFirms = await firmProvider.getFirmsList();
    }
    let firmsDic = userFirms.reduce((acc: any, ele) => {
      acc[ele.lawFirmCode] = ele;
      return acc;
    }, {});
    setFirmsDic(firmsDic);
    setInitialFirms(firmsDic);
    await getPersonsList(firmsDic, professionCategoriesData);
  };

  const getPersonsList = async (firmsDic, professionCategoriesData) => {
    let result = await getTimesheetPersons();
    if (result?.isSuccess) {
      if (result.data.length > 0) {
        let personsDic = result.data.reduce((acc: any, ele: any) => {
          acc[ele.personId] = {
            personId: ele.personId,
            name:
              (ele.personFirstName || "") + " " + (ele.personLastName || ""),
          };
          return acc;
        }, {});
        setPersonsDic(personsDic);
        await getTimesheetFilters(
          undefined,
          firmsDic,
          personsDic,
          undefined,
          professionCategoriesData
        );
      }
    }
  };

  const getTimesheetFilters = async (
    search?: any,
    firmsDic?: any,
    personsDic?: any,
    changedProperty?: any,
    professionCategoriesData?: any
  ) => {
    let tempSearch = { ...state.searchObj };
    if (search) {
      tempSearch = { ...state.searchObj, ...search };
    }
    if (!tempSearch.endDate) {
      delete tempSearch.endDate;
    }
    if (!tempSearch.startDate) {
      delete tempSearch.startDate;
    }
    let searchForFilter: any = { ...tempSearch };
    // if(searchForFilter.endDate){
    //   searchForFilter.endDate=moment(searchForFilter.endDate).format("YYYY-MM-DD")
    // }
    // if(searchForFilter.startDate){
    //   searchForFilter.startDate=moment(searchForFilter.startDate).format("YYYY-MM-DD")
    // }
    let keyWords = [...keyWordsList];
    let initialWorkTypesTemp = { ...initialWorkTypes };
    if (professionCategoriesData?.workTypesDic) {
      initialWorkTypesTemp = {
        ...initialWorkTypes,
        ...professionCategoriesData.workTypesDic,
      };
    }
    let initialApprovedByTemp = { ...initialApprovedBy };
    if (professionCategoriesData?.approvedByDic) {
      initialApprovedByTemp = {
        ...initialApprovedBy,
        ...professionCategoriesData.approvedByDic,
      };
    }
    let initialNoteListTemp = [...noteList];
    if (professionCategoriesData?.noteList) {
      initialNoteListTemp = [
        ...initialNoteListTemp,
        ...professionCategoriesData.noteList,
      ];
    }
    // if (changedProperty) {
    //   switch (changedProperty) {
    //     case "firmCounts":
    //       searchForFilter.statuses = [];
    //       break;
    //     case "statusCounts":
    //       searchForFilter.firms = [];
    //       break;
    //     default:
    //       break;
    //   }
    // }
    delete tempSearch.size;
    delete tempSearch.from;
    let result = {
      isSuccess: true,
      success: true,
      message: "",
      data: {} as any,
    };
    if (isCountsLoaded === false || changedProperty == "clear-filters") {
      result = await getTimesheetFiltersCount(searchForFilter);
    }
    if (result?.isSuccess) {
      console.log("result", result.data);
      let data = { ...result.data };
      let filterCountDic = filterCountsDic;
      if (isCountsLoaded === false || changedProperty == "clear-filters") {
        let keyParams = {
          categoryCodeCounts: "categoryCode",
          workTypeCounts: "workType",
          firmCounts: "firmCode",
          keywordCounts: "keyword",
          statusCounts: "status",
          professionalCounts: "professionalLevel",
          approvedByCounts: "timeApprovedByCode",
        };
        filterCountDic = Object.keys(result.data).reduce(
          (acc, key) => {
            if (acc[key]) {
              result.data[key].forEach((data) => {
                acc[key][data[keyParams[key]]] = data;
              });
            }
            return acc;
          },
          {
            categoryCodeCounts: {},
            workTypeCounts: {},
            firmCounts: {},
            keywordCounts: {},
            statusCounts: {},
            professionalCounts: {},
            approvedByCounts: {},
          }
        );
        setFilterCountsDic(filterCountDic);
      }
      // updating state as it will use as min, max for date range picker
      setDateRangePickerMinMaxObj({
        minDate: data.startDate || dateRangePickerMinMaxObj.minDate,
        maxDate: data.endDate || dateRangePickerMinMaxObj.maxDate,
      });

      let filter: IFilter[] = [];
      let initialFirmsTemp = { ...initialFirms };
      if (firmsDic) {
        initialFirmsTemp = {
          ...initialFirms,
          ...firmsDic,
        };
      }
      let initialProfessionalLevelTemp = { ...initialProfessionalLevels };
      let initialCategoryCodeTemp = { ...initialCategoryCodes };
      if (Object.keys(initialFirmsTemp).length === 0) {
        let firmCounts = data["firmCounts"] || [];
        initialFirmsTemp = firmCounts.reduce((acc: any, ele) => {
          acc[ele.firmCode] = firmsDic[ele.firmCode] || {};
          return acc;
        }, {});
      }
      if (Object.keys(initialProfessionalLevelTemp).length === 0) {
        let professionalCounts = data["professionalCounts"] || [];
        initialProfessionalLevelTemp = professionalCounts.reduce(
          (acc: any, ele) => {
            acc[ele.professionalLevel] =
              professionCategoriesData.professionalLevelsDic[
              ele.professionalLevel
              ] || {};
            return acc;
          },
          {}
        );
      }
      if (Object.keys(initialCategoryCodeTemp).length === 0) {
        let categoryCodeCounts = data["categoryCodeCounts"] || [];
        initialCategoryCodeTemp = categoryCodeCounts.reduce((acc: any, ele) => {
          acc[ele.categoryCode] =
            professionCategoriesData.categoriesCodeDic[ele.categoryCode] || {};
          return acc;
        }, {});
      }

      let filterOrder = [
        "descriptionSearchFilters",
        "workTypeCounts",
        "time",
        // "tree",
        "statusCounts",
        "showDisapproved",
        "showOnlyDisapproved",
        "firmCounts",
        // "personCounts",
        "professionalCounts",
        "categoryCodeCounts",
        "approvedBy",
        "noteId",
        "keyword",
        "min-max",
        "word-counts",
      ];
      let customFilters = [
        "time",
        // "tree",
        "keyword",
        "min-max",
        "descriptionSearchFilters",
        "word-counts",
        "showDisapproved",
        "showOnlyDisapproved",
      ];
      delete data["personCounts"];
      if (!tempSearch.max && data?.maxTimeSpentHours) {
        tempSearch.max = data?.maxTimeSpentHours;
      }
      if (!tempSearch.startDate) {
        tempSearch.startDate = data?.startDate || tempSearch.startDate;
      }
      if (!tempSearch.endDate) {
        tempSearch.endDate = data?.endDate || tempSearch.endDate;
      }
      filterOrder.forEach((filterType) => {
        if (customFilters.indexOf(filterType) > -1) {
          switch (filterType) {
            case "time":
              filter.push({
                header: "Time Period",
                name: "time",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "time").isHidden) ||
                  false,
                items: [
                  {
                    type: "date-range",
                    value: [
                      {
                        startDate: tempSearch.startDate,
                        endDate: tempSearch.endDate,
                        key: "selection",
                      },
                    ],
                    label: "",
                  },
                ],
                // items: [
                //   {
                //     value: tempSearch.startDate
                //       ? tempSearch.startDate
                //       : data?.startDate,
                //     type: "date",
                //     label: "Start Date",
                //     name: "startDate",
                //   },
                //   {
                //     value: tempSearch.endDate
                //       ? tempSearch.endDate
                //       : data?.endDate,
                //     type: "date",
                //     label: "End Date",
                //     name: "endDate",
                //   },
                // ],
              });
              break;
            case "tree":
              const options = YEAR_ARRAY.map((d) => ({
                label: d,
                value: moment(d).format(TREE_YEAR_DATE_FORMAT),
              }));
              filter.push({
                header: "Time Period",
                name: "tree",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "tree").isHidden) ||
                  false,
                items: [
                  {
                    type: "tree",
                    label: "",
                    options,
                    name: "startDate",
                    // value: tempSearch.startDate
                    selectedItems: tempSearch.timePeriod.map((d) => ({
                      label: d,
                      value: d,
                    })),
                  },
                ],
              });
              break;
            case "keyword":
              filter.push({
                header: "Keywords",
                name: "keyword",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "keyword").isHidden) ||
                  false,
                items: keyWords.map((ele) => {
                  return {
                    isSelected: tempSearch.keyword
                      ? tempSearch.keyword.includes(ele.value)
                      : false,
                    label: ele.label,
                    type: "checkbox",
                    name: ele.value,
                    // count:
                    //   changedProperty !== undefined &&
                    //   changedProperty === ele.label &&
                    //   tempSearch.statuses.length > 0
                    //     ? filterData
                    //         .find((ed) => ed.name === 'professionalLevel')
                    //         ?.items.find((ed) => ed.name === ele.label)?.count || 0
                    //     : statusCounts.find((e) => e.status === eds)?.count || 0,
                  };
                }),
              });
              break;
            case "min-max":
              filter.push({
                header: "Total Time Spent for Day",
                name: "min-max",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "min-max").isHidden) ||
                  false,
                items: [
                  {
                    label: "Min",
                    type: "number",
                    name: "min",
                    value: tempSearch.min,
                  },
                  {
                    label: "Max",
                    type: "number",
                    name: "max",
                    value: tempSearch.max,
                  },
                ],
              });
              break;
            case "descriptionSearchFilters":
              filter.push({
                header: "Description Keyword Search",
                name: "descriptionSearchFilters",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find(
                      (e) => e.name === "descriptionSearchFilters"
                    ).isHidden) ||
                  false,
                items:
                  tempSearch.descriptionSearchFilters.length > 0
                    ? tempSearch.descriptionSearchFilters.map((d) => {
                      let itemObj = {
                        type: "text-checkbox",
                        value: d.search,
                        isSelected: d.isExcluded,
                        name: "description",
                        label: "",
                      };
                      return itemObj;
                    })
                    : [{ ...DESCRIPTION_FILTER_ENTRY }],
              });
              break;
            case "word-counts":
              filter.push({
                header: "Word Count",
                name: "word-counts",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "word-counts")
                      ?.isHidden) ||
                  false,
                items: [
                  {
                    label: "Min Words",
                    type: "number",
                    name: "minWordCount",
                    value: tempSearch.minWordCount,
                  },
                  {
                    label: "Max Words",
                    type: "number",
                    name: "maxWordCount",
                    value: tempSearch.maxWordCount,
                  },
                ],
              });
              break;
            case "showDisapproved":
              filter.push({
                className: "disapproved-section",
                header: "",
                name: "showDisapproved",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "showDisapproved")
                      ?.isHidden) ||
                  false,
                items: [
                  {
                    isSelected: tempSearch.showDisapproved,
                    label: "Show Disapproved",
                    type: "switch",
                    name: "showDisapproved",
                    isDisabled: tempSearch.showOnlyDisapproved,
                  },
                ],
              });
              break;
            case "showOnlyDisapproved":
              filter.push({
                className: "only-disapproved-section",
                header: "",
                name: "showOnlyDisapproved",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "showOnlyDisapproved")
                      ?.isHidden) ||
                  false,
                items: [
                  {
                    isSelected: tempSearch.showOnlyDisapproved,
                    label: "Show Only Disapproved",
                    type: "switch",
                    name: "showOnlyDisapproved",
                  },
                ],
              });
              break;
            default:
              break;
          }
        } else {
          let ele = filterType;
          let a: IFilter = { header: "", items: [], name: ele };
          switch (ele) {
            case "firmCounts":
              a.header = "Firm";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let firmCounts = data["firmCounts"] || [];
              Object.keys(initialFirmsTemp).map((element) => {
                a.items.push({
                  label: firmsDic[element]
                    ? firmsDic[element].lawFirmShortName
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.firms.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : firmCounts.find((e) => e.firmCode === element)
                          ?.count || 0
                      : filterCountDic?.["firmCounts"]?.[element]?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.firms
                    ? tempSearch.firms.includes(element)
                    : false,
                });
              });
              // sorting based on label i.e. firm short name
              a.items.sort((a, b) => (a.label > b.label ? 1 : -1));
              break;
            case "professionalCounts":
              a.header = "Professional Level";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let professionalCounts = data["professionalCounts"] || [];
              Object.keys(initialProfessionalLevelTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.professionalLevelsDic[element]
                    ? professionCategoriesData.professionalLevelsDic[element]
                      .label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.professionalLevel.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === element)?.count ||
                        0
                        : professionalCounts.find(
                          (e) => e.professionalLevel === element
                        )?.count || 0
                      : filterCountDic?.["professionalCounts"]?.[element]
                        ?.count || 0,
                  name: element,
                  isSelected: tempSearch.professionalLevel
                    ? tempSearch.professionalLevel.includes(element)
                    : false,
                });
              });
              break;
            case "workTypeCounts":
              a.header = "Work Types";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let workTypeCounts = data["workTypeCounts"] || [];
              Object.keys(initialWorkTypesTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.workTypesDic[element]
                    ? professionCategoriesData.workTypesDic[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.workType.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : workTypeCounts.find((e) => e.workType === element)
                          ?.count || 0
                      : filterCountDic?.["workTypeCounts"]?.[element]?.count ||
                      0,
                  name: String(element),
                  isSelected: tempSearch.workType
                    ? tempSearch.workType.includes(element)
                    : false,
                });
              });
              break;
            case "categoryCodeCounts":
              a.header = "Time Category";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let categoryCodeCounts = data["categoryCodeCounts"] || [];
              Object.keys(initialCategoryCodeTemp)
                .sort((a, b) =>
                  initialCategoryCodeTemp[a].label >
                    initialCategoryCodeTemp[b].label
                    ? 1
                    : -1
                )
                .map((element) => {
                  a.items.push({
                    label: professionCategoriesData.categoriesCodeDic[element]
                      ? professionCategoriesData.categoriesCodeDic[element]
                        .label
                      : "",
                    type: "checkbox",
                    count:
                      isCountsLoaded === false
                        ? changedProperty !== undefined &&
                          changedProperty === ele &&
                          tempSearch.categoryCode.length > 0
                          ? filterData
                            .find((ed) => ed.name === ele)
                            ?.items.find((ed) => ed.name === String(element))
                            ?.count || 0
                          : categoryCodeCounts.find(
                            (e) => e.categoryCode === element
                          )?.count || 0
                        : filterCountDic?.["categoryCodeCounts"]?.[element]
                          ?.count || 0,
                    name: String(element),
                    isSelected: tempSearch.categoryCode
                      ? tempSearch.categoryCode.includes(element)
                      : false,
                  });
                });
              break;
            case "statusCounts":
              a.header = "Status";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let statusCounts = data["statusCounts"] || [];
              Object.keys(TIMESHEET_STATUS).map((eds) => {
                a.items.push({
                  isSelected: tempSearch.statuses
                    ? tempSearch.statuses.includes(eds)
                    : false,
                  label: TIMESHEET_STATUS[eds],
                  type: "checkbox",
                  name: eds,
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.statuses.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === eds)?.count || 0
                        : statusCounts.find((e) => e.status === eds)?.count || 0
                      : filterCountDic?.["statusCounts"]?.[eds]?.count || 0,
                  isDisabled: tempSearch.showOnlyDisapproved,
                });
              });
              break;
            case "personCounts":
              a.header = "";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let personsCount = data["personCounts"] || [];
              let options = [];
              let selectedItems = [];
              personsCount.map((ele) => {
                if (
                  tempSearch.persons.length > 0 &&
                  tempSearch.persons.includes(ele.personId)
                ) {
                  selectedItems.push({
                    value: ele.personId,
                    label: personsDic[ele.personId].name,
                  });
                }
                options.push({
                  value: ele.personId,
                  label: personsDic[ele.personId].name,
                });
              });
              a.items = [
                {
                  label: "Resources",
                  type: "dropdown",
                  name: ele,
                  selectedItems: selectedItems || [],
                  options:
                    changedProperty !== undefined &&
                      changedProperty === ele &&
                      tempSearch.persons.length > 0
                      ? filterData.find((ed) => ed.name === ele)?.items[0]
                        .options
                      : options,
                },
              ];
              break;
            case "approvedBy":
              a.header = "Approved By";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let approvedByCounts = data["approvedByCounts"] || [];
              Object.keys(initialApprovedByTemp).map((element) => {
                a.items.push({
                  label: initialApprovedByTemp[element]
                    ? initialApprovedByTemp[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.approvedBy.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : approvedByCounts.find((e) => e.approvedBy === element)
                          ?.count || 0
                      : filterCountDic?.["approvedByCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.approvedBy.includes(element),
                });
              });
              break;
            case "noteId":
              a.header = "";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let noteOptions = [];
              let selectedNoteItems = [];
              initialNoteListTemp.map((ele) => {
                if (tempSearch.noteId === ele.value) {
                  selectedNoteItems.push(ele);
                }
                noteOptions.push(ele);
              });
              a.items = [
                {
                  label: "Notes",
                  type: "dropdown",
                  name: ele,
                  selectedItems: selectedNoteItems || [],
                  options:
                    changedProperty !== undefined &&
                      changedProperty === ele &&
                      tempSearch.noteId
                      ? filterData.find((ed) => ed.name === ele)?.items[0]
                        .options
                      : noteOptions,
                  isMulti: false,
                },
              ];
              break;
            default:
              a = undefined;
              break;
          }
          if (a) {
            filter.push(a);
          }
        }
      });
      setCountsLoaded(true);
      setFilterData(filter);
      setInitialFirms(initialFirmsTemp);
      setInitialProfessionalLevels(initialProfessionalLevelTemp);
      setInitialCategoryCodes(initialCategoryCodeTemp);
      if (changedProperty !== "add-descriptions") {
        tempSearch.from = 0;
        tempSearch.size = 10;
        setLoading(false);
        // setLoading(true);

        //change loading state for below calls
        setFirmServiceHourLoading(true);
        setProfessionalLevelLoading(true);
        setTimeCategoryLoading(true);

        // if (props.isFirm !== true) {
        // }
        await getDashTimesheetReport(
          tempSearch,
          undefined,
          true,
          changedProperty,
          filter
        );
        await getFirmServiceFeeDetails(tempSearch, true);
        await getFirmServiceHourDetails(tempSearch, true);
        await getProfessionalLevelDetails(tempSearch, undefined, true);
        await getCategoryHourDetails(tempSearch, undefined, true);
        // setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      showToast(
        result.message || "Error while fetching timesheets counts",
        "error"
      );
    }
  };

  const getProfessionalLevelDetails = async (
    searchObj?: any,
    firmsList?: any,
    isDefaultCall = false
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    if (!isDefaultCall) {
      // setLoading(true);
      setProfessionalLevelLoading(true);
    }
    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      tempSearch.lawFirmCode = [];
    }
    tempSearch.sortBy = tempSearch.professionalLevelSortBy;
    tempSearch.sortOrder = tempSearch.professionalLevelSortOrder;
    if (
      tempSearch.descriptionSearchFilters &&
      tempSearch.descriptionSearchFilters.length
    ) {
      tempSearch.descriptionSearchFilters =
        tempSearch.descriptionSearchFilters.filter((x) => x.search);
    }
    const result = await getProfessionalLevelHourDetails(tempSearch);
    if (result?.isSuccess) {
      result.data = result.data.map((x) => {
        x.employeesPercentage = (x.employees * 100) / result.data[0].employees;
        x.totalFeesPercentage = (x.totalFees * 100) / result.data[0].totalFees;
        return x;
      });
      setProfessionalHoursStatsData(result.data);
      // setState({
      //     ...state,
      //     searchObj: tempSearch,
      // });
    } else {
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
      });
      showToast(
        result?.message
          ? result.message
          : "Error while fetching professional level hour stats",
        "error"
      );
    }
    // if (!isDefaultCall) {
    //   setLoading(false);
    // }
    setProfessionalLevelLoading(false);
  };

  const getDashTimesheetReport = async (
    searchObj?: any,
    firmsList?: any,
    isDefaultCall = false,
    changedProperty?: string,
    filter?: IFilter[]
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }

    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      delete tempSearch.lawFirmCode;
    }
    // if(tempSearch.endDate){
    //   tempSearch.endDate=moment(tempSearch.endDate).format("YYYY-MM-DD") as any
    // }
    // if(tempSearch.startDate){
    //   tempSearch.startDate=moment(tempSearch.startDate).format("YYYY-MM-DD") as any
    // }
    if (
      tempSearch.descriptionSearchFilters &&
      tempSearch.descriptionSearchFilters.length
    ) {
      tempSearch.descriptionSearchFilters =
        tempSearch.descriptionSearchFilters.filter((x) => x.search);
    }
    const result = await getTimesheetReport(tempSearch);
    if (result?.isSuccess) {
      let timesheetDic = result.data.reduce(
        (acc, obj) => {
          let key = obj["status"];
          acc[key] = obj.count;
          return acc;
        },
        {
          total: result.data.reduce((acc, val) => acc + val.count, 0),
        }
      );
      setTimesheetCountDic(timesheetDic);
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
        searchObj: tempSearch,
      });
    } else {
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
      });
      showToast(
        result?.message
          ? result.message
          : "Error while fetching time entries count",
        "error"
      );
    }
    if (
      tempSearch.descriptionSearchFilters &&
      tempSearch.descriptionSearchFilters.length
    ) {
      tempSearch.descriptionSearchFilters =
        tempSearch.descriptionSearchFilters.filter((x) => x.search);
    }
    const result2 = await getTimesheetHoursFeeReport(tempSearch);
    if (result2?.isSuccess) {
      let horusFeeCount = { ...hoursAndFeeCount };
      horusFeeCount.hours = result2.data?.totalTimeSpent || 0;
      horusFeeCount.fee = result2.data?.totalFees || 0;
      if (
        changedProperty === "noteId" &&
        result2.data.statuses &&
        tempSearch.noteId
      ) {
        tempSearch.statuses = result2.data.statuses;
        let statusIndexInFilter = filter.findIndex(
          (x) => x.name === "statusCounts"
        );
        if (statusIndexInFilter > -1) {
          filter[statusIndexInFilter].items.forEach((item) => {
            item.isSelected = tempSearch.statuses
              ? tempSearch.statuses.includes(item.name)
              : false;
          });
        }
        if (
          result2.data.statuses.includes("disapproved") &&
          !tempSearch.showDisapproved
        ) {
          let showDisapprovedIndexInFilter = filter.findIndex(
            (x) => x.name === "showDisapproved"
          );
          if (showDisapprovedIndexInFilter > -1) {
            filter[showDisapprovedIndexInFilter].items[0].isSelected = result2
              .data.statuses
              ? result2.data.statuses.includes("disapproved")
              : false;
          }
          tempSearch.showDisapproved = true;
          props.confirmDialog.show(
            "Disapproved",
            "Show Disapproved enabled",
            undefined,
            undefined,
            undefined,
            undefined,
            true
          );
          setState({
            ...state,
            searchObj: tempSearch,
          });
        }
        setFilterData(filter);
      }
      setHoursAndFeeCount({ ...horusFeeCount });
    } else {
      showToast(
        result2?.message
          ? result2.message
          : "Error while fetching hours and fees count",
        "error"
      );
    }
    // if (!isDefaultCall) {
    //   setLoading(false);
    // }
  };

  const getFirmServiceFeeDetails = async (
    searchObj?: any,
    isDefaultCall = false
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      tempSearch.lawFirmCode = [];
    }
    tempSearch.sortBy = tempSearch.firmServiceFeesSortBy;
    tempSearch.sortOrder = tempSearch.firmServiceFeesSortOrder;
    if (
      tempSearch.descriptionSearchFilters &&
      tempSearch.descriptionSearchFilters.length
    ) {
      tempSearch.descriptionSearchFilters =
        tempSearch.descriptionSearchFilters.filter((x) => x.search);
    }
    const result = await getFirmServiceFees(tempSearch);
    if (result?.isSuccess) {
      setFirmServiceFees(result.data);
      // setState({
      //     ...state,
      //     searchObj: tempSearch,
      // });
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching firm service fees",
        "error"
      );
    }
  };

  const getFirmServiceHourDetails = async (
    searchObj?: any,
    isDefaultCall = false
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    setFirmServiceHourLoading(true);

    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      tempSearch.lawFirmCode = [];
    }
    tempSearch.sortBy = tempSearch.firmServiceHoursSortBy;
    tempSearch.sortOrder = tempSearch.firmServiceHoursSortOrder;
    if (
      tempSearch.descriptionSearchFilters &&
      tempSearch.descriptionSearchFilters.length
    ) {
      tempSearch.descriptionSearchFilters =
        tempSearch.descriptionSearchFilters.filter((x) => x.search);
    }
    const result = await getFirmServiceHours(tempSearch);
    if (result?.isSuccess) {
      setFirmServiceHours(result.data);
      // setState({
      //     ...state,
      //     searchObj: tempSearch,
      // });
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching firm service hours",
        "error"
      );
    }
    setFirmServiceHourLoading(false);
  };

  const getCategoryHourDetails = async (
    searchObj?: any,
    firmsList?: any,
    isDefaultCall = false
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    if (!isDefaultCall) {
      //   setLoading(true);
      setTimeCategoryLoading(true);
    }
    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      tempSearch.lawFirmCode = [];
    }
    tempSearch.sortBy = tempSearch.categoryHourSortBy;
    tempSearch.sortOrder = tempSearch.categoryHourSortOrder;
    if (
      tempSearch.descriptionSearchFilters &&
      tempSearch.descriptionSearchFilters.length
    ) {
      tempSearch.descriptionSearchFilters =
        tempSearch.descriptionSearchFilters.filter((x) => x.search);
    }
    const result = await getCategoryHourStatsDetails(tempSearch);
    if (result?.isSuccess) {
      result.data = result.data.map((x) => {
        x.employeesPercentage = (x.employees * 100) / result.data[0].employees;
        x.totalFeesPercentage = (x.totalFees * 100) / result.data[0].totalFees;
        return x;
      });
      setCategoryHoursStatsData(result.data);
      // setState({
      //     ...state,
      //     searchObj: tempSearch,
      // });
    } else {
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
      });
      showToast(
        result?.message
          ? result.message
          : "Error while fetching category level hour stats",
        "error"
      );
    }
    // if (!isDefaultCall) {
    //   setLoading(false);
    // }
    setTimeCategoryLoading(false);
  };

  const handleCloseCustomDate = async (data: any) => {
    let searchObj = { ...state.searchObj };
    searchObj.startDate = data.startDate;
    searchObj.endDate = data.endDate;
    setCustomDialog(false);
    setState({ ...state, searchObj: { ...searchObj } });
    // if (props.isFirm !== true) {
    // }
    await getDashTimesheetReport(searchObj);
    await getFirmServiceFeeDetails(searchObj);
    await getFirmServiceHourDetails(searchObj);
    await getProfessionalLevelDetails(searchObj);
    await getCategoryHourDetails(searchObj);
  };

  const onFilterChange = async (data: IFilter[], changedProperty?: string) => {
    setLoading(true);
    if (changedProperty === "clear-filters") {
      setLoadedFilter(undefined);
    }
    if (changedProperty === "load-filters") {
      let details = JSON.parse(JSON.stringify(data));
      setLoadedFilter(details);
      await getTimesheetFilters(
        details.filters,
        firmsDic,
        personsDic,
        changedProperty,
        {
          professionalLevelsDic: professionalLevelDic,
          categoriesCodeDic: categoryCodeDic,
          workTypesDic: { ...initialWorkTypes },
        }
      );
    } else {
      let search = { ...state.searchObj };
      search.from = 0;
      let statusArray =
        data.find((ele) => ele.name === "statusCounts")?.items || [];
      let professionalLevelArray =
        data.find((ele) => ele.name === "professionalCounts")?.items || [];
      let categoryCodeArray =
        data.find((ele) => ele.name === "categoryCodeCounts")?.items || [];
      let workTypesArray =
        data.find((ele) => ele.name === "workTypeCounts")?.items || [];
      let personArray =
        data.find((ele) => ele.name === "personCounts")?.items || [];
      let noteArray = data.find((ele) => ele.name === "noteId")?.items || [];
      let firmArray =
        data.find((ele) => ele.name === "firmCounts")?.items || [];
      let approvedByArray =
        data.find((ele) => ele.name === "approvedBy")?.items || [];
      let timeArray = data.find((ele) => ele.name === "time")?.items || [];
      let treeArray = data.find((ele) => ele.name === "tree")?.items || [];
      let minMaxArray = data.find((ele) => ele.name === "min-max")?.items || [];
      search.min = minMaxArray.find((ele) => ele.name === "min")?.value || "";
      search.max = minMaxArray.find((ele) => ele.name === "max")?.value || "";
      let minMaxWordCountArray =
        data.find((ele) => ele.name === "word-counts")?.items || [];
      search.minWordCount =
        minMaxWordCountArray.find((ele) => ele.name === "minWordCount")
          ?.value || "";
      search.maxWordCount =
        minMaxWordCountArray.find((ele) => ele.name === "maxWordCount")
          ?.value || "";
      let descriptionArray =
        data.find((ele) => ele.name === "descriptionSearchFilters")?.items ||
        [];
      search.descriptionSearchFilters = descriptionArray
        // .filter((ele) => ele.value)
        .map((ele) => {
          let d = {
            search: ele.value || "",
            isExcluded: ele.isSelected,
          };
          return d;
        });
      let disapprovedStatus =
        data.find((ele) => ele.name === "showDisapproved")?.items || [];
      search.showDisapproved = disapprovedStatus.find(
        (ele) => ele.name === "showDisapproved"
      ).isSelected;
      let onlyDisapprovedStatus =
        data.find((ele) => ele.name === "showOnlyDisapproved")?.items || [];
      search.showOnlyDisapproved = onlyDisapprovedStatus.find(
        (ele) => ele.name === "showOnlyDisapproved"
      ).isSelected;
      if (search.min && search.max && search.min > search.max) {
        showToast("Max value cannot be less than min value", "error");
        return;
      }
      if (
        search.minWordCount &&
        search.maxWordCount &&
        search.minWordCount > search.maxWordCount
      ) {
        showToast(
          "Max word count value cannot be less than min word count value",
          "error"
        );
        return;
      }
      search.startDate = timeArray[0].value[0].startDate || undefined;
      // timeArray.find((ele) => ele.name === "startDate")?.value || undefined;
      search.endDate = timeArray[0].value[0].endDate || undefined;
      // timeArray.find((ele) => ele.name === "endDate")?.value || undefined;

      if (treeArray.length > 0) {
        search.timePeriod = treeArray[0].selectedItems
          .map((d) => d.value)
          .sort((a, b) => (a > b ? 1 : -1));
      }
      if (search.timePeriod.length > 0) {
        search.startDate = search.timePeriod[0] as any;
        search.endDate = moment(search.timePeriod[search.timePeriod.length - 1])
          .endOf("month")
          .format(TREE_YEAR_DATE_FORMAT) as any;
      } else if (timeArray.length === 0) {
        search.startDate = undefined;
        search.endDate = undefined;
      }
      // search.startDate =
      //   timeArray.find((ele) => ele.name === "startDate")?.value || undefined;
      // if (search.startDate) {
      //   if (changedProperty === 'time-period-year') {
      //     search.endDate = moment(search.startDate).endOf("year").format(TREE_YEAR_DATE_FORMAT) as any;
      //   }
      //   if (changedProperty === "time-period-month") {
      //     search.endDate = moment(search.startDate).endOf("month").format(TREE_YEAR_DATE_FORMAT) as any;
      //   }
      // } else {
      //   search.endDate = undefined;
      // }
      search.statuses = statusArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      // if show 'only dis approved' is true we need to make status array empty and 'dis approved' false
      if (search.showOnlyDisapproved) {
        search.showDisapproved = false;
        search.statuses = [];
      }
      search.persons =
        personArray.length > 0
          ? personArray[0].selectedItems.length > 0
            ? personArray[0].selectedItems.map((ele) => ele.value)
            : []
          : [];
      search.firms = firmArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          if (e.name) {
            return e.name;
          }
        });
      search.professionalLevel = professionalLevelArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.categoryCode = categoryCodeArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.workType = workTypesArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      let keywordsArray =
        data.find((ele) => ele.name === "keyword")?.items || [];
      search.keyword = keywordsArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.approvedBy = approvedByArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.noteId =
        noteArray.length > 0
          ? noteArray[0].selectedItems.length > 0
            ? noteArray[0].selectedItems[0].value
            : ""
          : "";
      if (changedProperty !== "add-descriptions") {
        await getTimesheetFilters(
          search,
          firmsDic,
          personsDic,
          changedProperty,
          {
            professionalLevelsDic: professionalLevelDic,
            categoriesCodeDic: categoryCodeDic,
            workTypesDic: { ...initialWorkTypes },
          }
        );
      } else {
        setLoading(false);
        setState({
          ...state,
          searchObj: {
            ...state.searchObj,
            ...search,
          },
        });
      }
    }
  };

  const classes = styles();

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <section className="p-16 pr-0 pb-0">
        <br />
        <Grid className={`${classes.main}`}>
          <Grid
            className={`${isFilterOpen
              ? classes.filterOpenContainer
              : classes.filterCloseContainer
              }`}
          >
            <Hidden mdDown>
              <Grid container spacing={2}>
                <Grid item xs={12} className="dashboard-filters-height">
                  {filterData.length > 0 && (
                    <Filter
                      data={[...filterData]}
                      onChange={(data: IFilter[], changedProperty?: string) => {
                        onFilterChange(data, changedProperty);
                      }}
                      handleFilterHide={() => setFilterOpen(false)}
                      typeOfFilters="timesheets"
                      selectedFilters={state.searchObj}
                      loadedFilter={loadedFilter}
                      dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
                    />
                  )}
                </Grid>
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid container justify="flex-end">
                <Grid item>
                  {filterData.length > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setFilterDialogOpen(true)}
                    >
                      Filters
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <ToggleFilterSection
            showFilterClass="mt--8"
            classes={classes}
            toggleFilter={() => setFilterOpen(!isFilterOpen)}
            isFilterOpen={isFilterOpen}
          />
          <Grid
            className={`dashboard-timesheet-main-container ${isFilterOpen
              ? `${classes.mainOpenContainer}`
              : classes.mainCloseContainer
              }`}
          >
            <Grid container spacing={2} className="position-relative">
              {isLoading && <SecondaryLoading />}
              <Grid item xs={12} sm={12} md={6} lg={3} className="dashed-border">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <Card className="p-16 count-card">
                      <Typography variant="h4" className="fw-600 fs-50">
                        ${abbreviateNumber(hoursAndFeeCount.fee)}
                      </Typography>
                      <Typography variant="h6">Fees</Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <Card className="p-16 count-card">
                      <Typography variant="h4" className="fw-600 fs-50">
                        {abbreviateNumber(hoursAndFeeCount.hours)}
                      </Typography>
                      <Typography variant="h6">Hours</Typography>
                    </Card>
                  </Grid>

                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={9} className="dashed-border">
                <Typography variant="h6">Time Entries Count</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} lg={3}>
                    <Card className="p-16 count-card">
                      <Typography variant="h4" className="fw-600">
                        {abbreviateNumber(timesheetCountDic?.total || 0)}
                      </Typography>
                      <Typography variant="h6">Total</Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={8} lg={9}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Card
                          className="p-16 count-card"
                        >
                          <Typography
                            variant="h4"
                            className="fw-600"
                            color="secondary"
                          >
                            {abbreviateNumber(timesheetCountDic?.attention || 0)}
                          </Typography>
                          <Typography variant="h6">Attention</Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Card
                          className="p-16 count-card"
                        >
                          <Typography variant="h4" className="fw-600">
                            {abbreviateNumber(timesheetCountDic?.new || 0)}
                          </Typography>
                          <Typography variant="h6">New</Typography>
                        </Card>
                      </Grid>

                      <Grid item xs={6} sm={6} lg={6}>
                        <Card
                          className="p-16 count-card"
                        >
                          <Typography variant="h4" className="fw-600">
                            {abbreviateNumber(timesheetCountDic?.approved || 0)}
                          </Typography>
                          <Typography variant="h6">Approved</Typography>
                        </Card>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={6}>
                        <Card
                          className={`p-16 count-card`}
                        >
                          {(state.searchObj.showDisapproved || state.searchObj.showOnlyDisapproved) ? (
                            <Typography variant="h4" className="fw-600">
                              {abbreviateNumber(timesheetCountDic["disapproved"] || 0)}
                            </Typography>
                          ) : (
                            <Typography variant="subtitle1" className="fw-600">
                              Not shown
                            </Typography>
                          )}
                          <Typography variant="h6">Disapproved</Typography>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>

            <br />

            <Grid container spacing={2}>
              <Grid item lg={12}>
                <Grid container spacing={1}>
                  <Grid item lg={6} md={12} className={`position-relative`}>
                    <Card className="p-16">
                      <Typography variant="h6">Firms Fees</Typography>
                      {firmServiceHourLoading ? (
                        <SecondaryLoading />
                      ) : (
                        <React.Fragment>
                          {firmServiceFees.length === 0 &&
                            firmServiceHours.length === 0 ? (
                            <div>
                              <Typography variant="h6" align="center">
                                No Data found
                              </Typography>
                            </div>
                          ) : (
                            <React.Fragment>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={isFirmFeesChartPercentage}
                                        onChange={(e, checked) => {
                                          setIsFirmFeesChartPercentage(checked);
                                        }}
                                        name="percentage"
                                      />
                                    }
                                    label="Percentage"
                                  />
                                </Grid>
                              </Grid>
                              <div className="dashboard-professional-level-chart-container">
                                <BarChart
                                  data={[
                                    ...firmServiceFees
                                      .sort((a, b) =>
                                        a.totalFeesSum > b.totalFeesSum ? -1 : 1
                                      )
                                      .map((x, index) => {
                                        let y: any = { ...x };
                                        let value = isFirmFeesChartPercentage
                                          ? convertToFixed(
                                            x.totalFeesPercentage
                                          )
                                          : convertToFixed(x.totalFeesSum);
                                        y.formattedValue =
                                          isFirmFeesChartPercentage
                                            ? `${numberFormat(value)}%`
                                            : currencyFormat(value);
                                        y.colorCode = colorCode[index];
                                        return y;
                                      }),
                                  ]}
                                  keys={[
                                    isFirmFeesChartPercentage
                                      ? "totalFeesPercentage"
                                      : "totalFeesSum",
                                  ]}
                                  indexBy={"firmName"}
                                  getYAxisValueFormatter={(data: number) => {
                                    return !isFirmFeesChartPercentage
                                      ? currencyFormat(
                                        convertToFixed(data, true)
                                      )
                                      : `${numberFormat(
                                        convertToFixed(data, true)
                                      )}%`;
                                  }}
                                  axisBottomTickRotation={-45}
                                  marginLeft={
                                    isFirmFeesChartPercentage ? 50 : 100
                                  }
                                  marginBottom={70}
                                  ranges={
                                    isFirmFeesChartPercentage
                                      ? [...[1, 10, 30, 50, 80, 100]]
                                      : [
                                        ...[
                                          1, 20, 400, 8000, 160000, 3200000,
                                          64000000,
                                        ],
                                      ]
                                  }
                                  isLogScale={true}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </Card>
                  </Grid>

                  <Grid item lg={6} md={12} className={`position-relative`}>
                    <Card className="p-16">
                      <Typography variant="h6">Firms Hours</Typography>
                      {firmServiceHourLoading ? (
                        <SecondaryLoading />
                      ) : (
                        <React.Fragment>
                          {firmServiceFees.length === 0 &&
                            firmServiceHours.length === 0 ? (
                            <Typography variant="h6" align="center">
                              No Data found
                            </Typography>
                          ) : (
                            <React.Fragment>
                              <Grid
                                container
                                spacing={10}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={isFirmHoursChartPercentage}
                                        onChange={(e, checked) => {
                                          setIsFirmHoursChartPercentage(
                                            checked
                                          );
                                        }}
                                        name="percentage"
                                      />
                                    }
                                    label="Percentage"
                                  />
                                </Grid>
                              </Grid>
                              <div className="dashboard-professional-level-chart-container">
                                <BarChart
                                  data={[
                                    ...firmServiceHours
                                      .sort((a, b) =>
                                        a.totalHoursSum > b.totalHoursSum
                                          ? -1
                                          : 1
                                      )
                                      .map((x, index) => {
                                        let y: any = { ...x };
                                        let value = isFirmHoursChartPercentage
                                          ? `${convertToFixed(
                                            x.totalHoursPercentage
                                          )}%`
                                          : convertToFixed(x.totalHoursSum);
                                        y.formattedValue = numberFormat(value);
                                        y.colorCode = colorCode[index];
                                        return y;
                                      }),
                                  ]}
                                  keys={[
                                    isFirmHoursChartPercentage
                                      ? "totalHoursPercentage"
                                      : "totalHoursSum",
                                  ]}
                                  indexBy={"firmName"}
                                  getYAxisValueFormatter={(data: number) => {
                                    return isFirmHoursChartPercentage
                                      ? `${numberFormat(
                                        convertToFixed(data, true)
                                      )}%`
                                      : numberFormat(
                                        convertToFixed(data, true)
                                      );
                                  }}
                                  axisBottomTickRotation={-45}
                                  marginLeft={
                                    isFirmHoursChartPercentage ? 50 : 100
                                  }
                                  marginBottom={70}
                                  ranges={
                                    isFirmHoursChartPercentage
                                      ? [...[1, 10, 30, 50, 80, 100]]
                                      : [
                                        ...[
                                          1, 20, 400, 8000, 160000, 3200000,
                                          64000000,
                                        ],
                                      ]
                                  }
                                  isLogScale={true}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </Card>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={12}>
                <Card className="p-16">
                  <Typography variant="h6">Professional Level</Typography>
                  <Grid container spacing={1}>
                    <Grid item lg={4} md={12} className={`position-relative`}>
                      <Typography variant="h6" align="center">
                        Fees
                      </Typography>
                      {professionalLevelLoading ? (
                        <SecondaryLoading />
                      ) : (
                        <React.Fragment>
                          {professionalHoursStatsData.length === 0 ? (
                            <Typography variant="h6" align="center">
                              No Data found
                            </Typography>
                          ) : (
                            <React.Fragment>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          isProfessionalLevelFeePercentage
                                        }
                                        onChange={(e, checked) => {
                                          setIsProfessionalLevelFeePercentage(
                                            checked
                                          );
                                        }}
                                        name="percentage"
                                      />
                                    }
                                    label="Percentage"
                                  />
                                </Grid>
                              </Grid>
                              <div style={{ height: 400 }}>
                                <PieChart
                                  data={professionalHoursStatsData
                                    .filter(
                                      (x) => x.professionalLevelName !== "Total"
                                    )
                                    .map((x, index) => {
                                      return {
                                        id: x.professionalLevelName,
                                        label: x.professionalLevelName,
                                        professionalLevelName:
                                          x.professionalLevelName,
                                        value: isProfessionalLevelFeePercentage
                                          ? x.totalFeesPercentage
                                          : x.totalFees,
                                        color: colorCode[index],
                                      };
                                    })}
                                  valueFormat={(data: number) => {
                                    return isProfessionalLevelFeePercentage
                                      ? `${numberFormat(convertToFixed(data))}%`
                                      : currencyFormat(convertToFixed(data));
                                  }}
                                  isDonutChart={false}
                                  showLegends={false}
                                  enableArcLabels={true}
                                  enableArcLinkLabels={false}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </Grid>
                    <Grid item lg={4} md={12} className={`position-relative`}>
                      <Typography variant="h6" align="center">
                        Hours
                      </Typography>
                      {professionalLevelLoading ? (
                        <SecondaryLoading />
                      ) : (
                        <React.Fragment>
                          {professionalHoursStatsData.length === 0 ? (
                            <Typography variant="h6" align="center">
                              No Data found
                            </Typography>
                          ) : (
                            <React.Fragment>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          isProfessionalLevelHoursPercentage
                                        }
                                        onChange={(e, checked) => {
                                          setIsProfessionalLevelHoursPercentage(
                                            checked
                                          );
                                        }}
                                        name="percentage"
                                      />
                                    }
                                    label="Percentage"
                                  />
                                </Grid>
                              </Grid>
                              <div style={{ height: 400 }}>
                                <PieChart
                                  data={professionalHoursStatsData
                                    .filter(
                                      (x) => x.professionalLevelName !== "Total"
                                    )
                                    .map((x, index) => {
                                      return {
                                        id: x.professionalLevelName,
                                        label: x.professionalLevelName,
                                        professionalLevelName:
                                          x.professionalLevelName,
                                        value:
                                          isProfessionalLevelHoursPercentage
                                            ? x.perTotalTime
                                            : x.totalHours,
                                        color: colorCode[index],
                                      };
                                    })}
                                  valueFormat={(data: number) => {
                                    return isProfessionalLevelHoursPercentage
                                      ? `${numberFormat(convertToFixed(data))}%`
                                      : numberFormat(convertToFixed(data));
                                  }}
                                  isDonutChart={false}
                                  showLegends={false}
                                  enableArcLabels={true}
                                  enableArcLinkLabels={false}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </Grid>
                    <Grid item lg={4} md={12} className={`position-relative`}>
                      <Typography variant="h6" align="center">
                        Resources
                      </Typography>
                      {professionalLevelLoading ? (
                        <SecondaryLoading />
                      ) : (
                        <React.Fragment>
                          {professionalHoursStatsData.length === 0 ? (
                            <Typography variant="h6" align="center">
                              No Data found
                            </Typography>
                          ) : (
                            <React.Fragment>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          isProfessionalLevelResourcesPercentage
                                        }
                                        onChange={(e, checked) => {
                                          setIsProfessionalLevelResourcesPercentage(
                                            checked
                                          );
                                        }}
                                        name="percentage"
                                      />
                                    }
                                    label="Percentage"
                                  />
                                </Grid>
                              </Grid>
                              <div style={{ height: 400 }}>
                                <PieChart
                                  data={professionalHoursStatsData
                                    .filter(
                                      (x) => x.professionalLevelName !== "Total"
                                    )
                                    .map((x, index) => {
                                      return {
                                        id: x.professionalLevelName,
                                        label: x.professionalLevelName,
                                        professionalLevelName:
                                          x.professionalLevelName,
                                        value:
                                          isProfessionalLevelResourcesPercentage
                                            ? x.employeesPercentage
                                            : x.employees,
                                        color: colorCode[index],
                                      };
                                    })}
                                  valueFormat={(data: number) => {
                                    return isProfessionalLevelResourcesPercentage
                                      ? `${numberFormat(convertToFixed(data))}%`
                                      : numberFormat(convertToFixed(data));
                                  }}
                                  isDonutChart={false}
                                  showLegends={false}
                                  enableArcLabels={true}
                                  enableArcLinkLabels={false}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </Grid>

                    {professionalHoursStatsData.length > 0 && (
                      <Grid item lg={12}>
                        <Grid container spacing={2} justifyContent="center">
                          {professionalHoursStatsData
                            .filter((x) => x.professionalLevelName !== "Total")
                            .map((x, index) => (
                              <Grid item>
                                <div className="d-flex">
                                  <span
                                    className="pie-chart-label"
                                    style={{
                                      backgroundColor: colorCode[index],
                                    }}
                                  ></span>
                                  <span>{x.professionalLevelName}</span>
                                </div>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Card>
              </Grid>

              <Grid item lg={12}>
                <Card className="p-16">
                  <Typography variant="h6">Time Category</Typography>
                  <Grid container spacing={1}>
                    <Grid item lg={4} md={12} className={`position-relative`}>
                      <Typography variant="h6" align="center">
                        Fees
                      </Typography>
                      {timeCategoryLoading ? (
                        <SecondaryLoading />
                      ) : (
                        <React.Fragment>
                          {categoryHoursStatsData.length === 0 ? (
                            <Typography variant="h6" align="center">
                              No Data found
                            </Typography>
                          ) : (
                            <React.Fragment>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={isTimeCategoryFeePercentage}
                                        onChange={(e, checked) => {
                                          setIsTimeCategoryFeePercentage(
                                            checked
                                          );
                                        }}
                                        name="percentage"
                                      />
                                    }
                                    label="Percentage"
                                  />
                                </Grid>
                              </Grid>
                              <div className="dashboard-time-category-chart-container">
                                <TreeMap
                                  convertedData={{
                                    name: "Total",
                                    color: "hsl(334, 70%, 50%)",
                                    children: categoryHoursStatsData
                                      .filter((x) => x.categoryName !== "Total")
                                      .map((x, index) => {
                                        return {
                                          categoryName: x.categoryName,
                                          totalHours: x.totalHours,
                                          totalFees: x.totalFees,
                                          totalFeesPercentage:
                                            x.totalFeesPercentage,
                                          color: colorCode[index],
                                        };
                                      }),
                                  }}
                                  // tooltipFormatter={({ node }) => {
                                  //   console.log(node);
                                  //   return (
                                  //     <strong
                                  //       style={{
                                  //         color: node.labelTextColor,
                                  //       }}
                                  //     >
                                  //       {node.pathComponents
                                  //         .join(" / ")
                                  //         .replace("/", "")}
                                  //       :{" "}
                                  //       {isTimeCategoryFeePercentage
                                  //         ? `${numberFormat(
                                  //             convertToFixed(
                                  //               node.data["totalFeesPercentage"]
                                  //             )
                                  //           )}%`
                                  //         : currencyFormat(
                                  //             convertToFixed(
                                  //               node.data["totalFees"]
                                  //             )
                                  //           )}
                                  //     </strong>
                                  //   );
                                  // }}
                                  // labelFormatter={(node) =>
                                  //   `${node.id} - ${
                                  //     isTimeCategoryFeePercentage
                                  //       ? `${numberFormat(
                                  //           convertToFixed(
                                  //             node.data["totalFeesPercentage"]
                                  //           )
                                  //         )}%`
                                  //       : currencyFormat(
                                  //           convertToFixed(
                                  //             node.data["totalFees"]
                                  //           )
                                  //         )
                                  //   }`
                                  // }
                                  identityName="categoryName"
                                  valueProperty={
                                    isTimeCategoryFeePercentage
                                      ? "totalFeesPercentage"
                                      : "totalFees"
                                  }
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </Grid>
                    <Grid item lg={4} md={12} className={`position-relative`}>
                      <Typography variant="h6" align="center">
                        Hours
                      </Typography>
                      {timeCategoryLoading ? (
                        <SecondaryLoading />
                      ) : (
                        <React.Fragment>
                          {categoryHoursStatsData.length === 0 ? (
                            <Typography variant="h6" align="center">
                              No Data found
                            </Typography>
                          ) : (
                            <React.Fragment>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={isTimeCategoryHoursPercentage}
                                        onChange={(e, checked) => {
                                          setIsTimeCategoryHoursPercentage(
                                            checked
                                          );
                                        }}
                                        name="percentage"
                                      />
                                    }
                                    label="Percentage"
                                  />
                                </Grid>
                              </Grid>
                              <div className="dashboard-time-category-chart-container">
                                <TreeMap
                                  convertedData={{
                                    name: "Total",
                                    color: "hsl(334, 70%, 50%)",
                                    children: categoryHoursStatsData
                                      .filter((x) => x.categoryName !== "Total")
                                      .map((x, index) => {
                                        return {
                                          categoryName: x.categoryName,
                                          totalHours: x.totalHours,
                                          totalFees: x.totalFees,
                                          perTotalTime: x.perTotalTime,
                                          color: colorCode[index],
                                        };
                                      }),
                                  }}
                                  // tooltipFormatter={({ node }) => {
                                  //   return (
                                  //     <strong
                                  //       style={{
                                  //         color: node.labelTextColor,
                                  //       }}
                                  //     >
                                  //       {node.pathComponents
                                  //         .join(" / ")
                                  //         .replace("/", "")}
                                  //       :{" "}
                                  //       {isTimeCategoryHoursPercentage
                                  //         ? `${numberFormat(
                                  //             convertToFixed(
                                  //               node.data["perTotalTime"]
                                  //             )
                                  //           )}%`
                                  //         : numberFormat(
                                  //             convertToFixed(
                                  //               node.data["totalHours"]
                                  //             )
                                  //           )}
                                  //     </strong>
                                  //   );
                                  // }}
                                  // labelFormatter={(node) =>
                                  //   `${node.id} - ${
                                  //     isTimeCategoryHoursPercentage
                                  //       ? `${numberFormat(
                                  //           convertToFixed(
                                  //             node.data["perTotalTime"]
                                  //           )
                                  //         )}%`
                                  //       : numberFormat(
                                  //           convertToFixed(
                                  //             node.data["totalHours"]
                                  //           )
                                  //         )
                                  //   }`
                                  // }
                                  identityName="categoryName"
                                  valueProperty={
                                    isTimeCategoryHoursPercentage
                                      ? "perTotalTime"
                                      : "totalHours"
                                  }
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </Grid>
                    <Grid item lg={4} md={12} className={`position-relative`}>
                      <Typography variant="h6" align="center">
                        Resources
                      </Typography>
                      {timeCategoryLoading ? (
                        <SecondaryLoading />
                      ) : (
                        <React.Fragment>
                          {categoryHoursStatsData.length === 0 ? (
                            <Typography variant="h6" align="center">
                              No Data found
                            </Typography>
                          ) : (
                            <React.Fragment>
                              <Grid
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Grid item>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          isTimeCategoryResourcesPercentage
                                        }
                                        onChange={(e, checked) => {
                                          setIsTimeCategoryResourcesPercentage(
                                            checked
                                          );
                                        }}
                                        name="percentage"
                                      />
                                    }
                                    label="Percentage"
                                  />
                                </Grid>
                              </Grid>
                              <div className="dashboard-time-category-chart-container">
                                <TreeMap
                                  convertedData={{
                                    name: "Total",
                                    color: "hsl(334, 70%, 50%)",
                                    children: categoryHoursStatsData
                                      .filter((x) => x.categoryName !== "Total")
                                      .map((x, index) => {
                                        return {
                                          categoryName: x.categoryName,
                                          totalHours: x.totalHours,
                                          totalFees: x.totalFees,
                                          employees: x.employees,
                                          employeesPercentage:
                                            x.employeesPercentage,
                                          color: colorCode[index],
                                        };
                                      }),
                                  }}
                                  // tooltipFormatter={({ node }) => {
                                  //   console.log(node);
                                  //   return (
                                  //     <strong
                                  //       style={{
                                  //         color: node.labelTextColor,
                                  //       }}
                                  //     >
                                  //       {node.pathComponents
                                  //         .join(" / ")
                                  //         .replace("/", "")}
                                  //       :{" "}
                                  //       {isTimeCategoryResourcesPercentage
                                  //         ? `${numberFormat(
                                  //             convertToFixed(
                                  //               node.data["employeesPercentage"]
                                  //             )
                                  //           )}%`
                                  //         : numberFormat(
                                  //             node.data["employees"]
                                  //           )}
                                  //     </strong>
                                  //   );
                                  // }}
                                  // labelFormatter={(node) =>
                                  //   `${node.id} - ${
                                  //     isTimeCategoryResourcesPercentage
                                  //       ? `${numberFormat(
                                  //           convertToFixed(
                                  //             node.data["employeesPercentage"]
                                  //           )
                                  //         )}%`
                                  //       : numberFormat(node.data["employees"])
                                  //   }`
                                  // }
                                  identityName="categoryName"
                                  valueProperty={
                                    isTimeCategoryResourcesPercentage
                                      ? "employeesPercentage"
                                      : "employees"
                                  }
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
      {isCustomDateOpen && (
        <CustomDateDialog
          startDate={state.searchObj.startDate}
          endDate={state.searchObj.endDate}
          handleDialogClose={(data?: any) => handleCloseCustomDate(data)}
        />
      )}
      {isFilterDialogOpen && (
        <CustomDrawer
          title={"Filter"}
          onClose={() => setFilterDialogOpen(false)}
        >
          <section className="p-24">
            <Filter
              isPopup={true}
              data={[...filterData]}
              onChange={(data: IFilter[]) => {
                onFilterChange(data);
              }}
              typeOfFilters="timesheets"
              selectedFilters={state.searchObj}
              loadedFilter={loadedFilter}
              dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
            />
          </section>
        </CustomDrawer>
      )}
    </React.Fragment>
  );
};

export default withConfirmDialogContext(DashboardTabMain);
