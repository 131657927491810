import { createStyles, makeStyles, Theme } from "@material-ui/core";

//local
// export const API_URL = 'http://localhost:5000/';

//dev
// export const API_URL =
//   "http://bouchertem-env.eba-y5urwrw5.ap-south-1.elasticbeanstalk.com/";
// export const APP_VERSION = "1.19";

//boucher
// export const API_URL = 'http://158.85.204.6:5000/';
// export const API_URL = 'https://api.legalfees.app/'; // http://169.44.180.251:5000/
// export const APP_VERSION = "1.22";
// export const WEB_URL_PATH = 'legalfees.app/';
// export const MAIN_APP_DOMAINS = [""];

//locus test
// export const API_URL = 'http://169.44.180.251:8502/'; 
// export const APP_VERSION = "1.22";
// export const WEB_URL_PATH = 'legalfees.app/';
// export const MAIN_APP_DOMAINS = [""];

//locus dev
// export const API_URL = 'https://devapi.legalfees.app/'; //'http://169.44.180.251:6502/';
// export const APP_VERSION = "1.23";
// export const WEB_URL_PATH = 'contenterra.in/';
// export const MAIN_APP_DOMAINS = ["","dev"]

//locus demo
export const API_URL = 'https://api.legalfees.tortera.com/'; //'http://169.44.180.251:5502/';
export const APP_VERSION = "1.26";
export const WEB_URL_PATH = 'legalfees.tortera.com/';
export const MAIN_APP_DOMAINS = ["","dev","legalfees"]

//locus demo2
// export const API_URL = 'http://169.44.180.251:5508/';
// export const APP_VERSION = "1.25";
// export const WEB_URL_PATH = 'contenterra.in/';
// export const MAIN_APP_DOMAINS = ["","dev"]


export const MAIN_CASE_NAME = "JCCP4861"
export const USE_POLICY_URL = "https://boucher-docs.s3.ap-south-1.amazonaws.com/LegalFees.app+Acceptable+Use+Policy.pdf";
export const LICENSE_AGREEMENT_URL = "https://boucher-docs.s3.ap-south-1.amazonaws.com/LegalFees.app+End-User+License+Agreement.pdf";

export const USER_ROLES: any = {
  0: "Application Administrator",
  1: "Case Administrator",
  2: "Firm Administrator",
};

export const ALLOWED_USER_ROLES = [0, 2];

export const TIMESHEET_STATUS: any = {
  new: "New",
  attention: "Attention",
  reviewed: "Reviewed",
  adjusted: "Adjusted",
  approved: "Approved",
  // submitted: "Submitted",
  // granted: "Granted",
  // "not-granted": "Not Granted"
};
export const TIMESHEET_ALL_STATUS: any = {
  ...TIMESHEET_STATUS,
  disapproved: "Disapproved",
}
export const GRANTED_NOT_GRANTED_STATUS: any = {
  submitted: "Submitted",
  granted: "Granted",
  "not-granted": "Not Granted"
};

export const TIME_ENTRY_KEY_WORDS = [{ label: "Conf Call", value: "Conf Call" },
{ label: "Conference Call", value: "Conference Call" },
{ label: "Deposition", value: "Deposition" },
{ label: "Discovery", value: "Discovery" },
{ label: "Email", value: "Email" },
{ label: "Hearing", value: "Hearing" },
{ label: "Meeting", value: "Meeting" },
{ label: "Status Conference", value: "Status Conference" }];

export const RECEIPT_TYPES: any = {
  yes: "Yes",
  no: "No",
  unknown: "Unknown",
};

export const URL_PATHS = {
  DB_DOES_NOT_EXISTS: "contact-us"
}

export const NON_AUTHENTICATED_PAGES = ["/", "/verify/account", "/forgot-password", "/not-authorized", `/${URL_PATHS.DB_DOES_NOT_EXISTS}`, '/acceptable-use-policy', '/end-user-license-agreement'];


export const CONTACT_EMAIL = "TEM-Support@LocusSolution.com"

export const FIRM_CONFIGURE_FILE_FORMAT = {
  "excel": "Excel",
  "csv": "CSV"
};
export const FIRM_CONFIGURE_TYPES = {
  "timesheet": "Timesheet",
  // "expense": "Expense"
};
export const FIRM_CONFIGURE__REPORT_TYPES = [
  "Time Report for Common Benefit",
  "Time Report for Class Work",
  // "Expense Report for Common Benef",
  // "Expense Report for Class Work"
];

export const FIRM_IMPORT_STATUS = {
  start: "Start",
  error: "Error",
  ready: "Ready",
  processed: "Processed",
  processing: "Processing",
}

export const NOTE_TYPES = {
  "all": "All",
  "timesheets": "Timesheet",
  "expenses": "Expense"
};

export const DESCRIPTION_FILTER_ENTRY: any = {
  type: "text-checkbox",
  value: "",
  isSelected: false,
  name: "description",
  label: ""
}

export const RESOURCE_STATE = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "Virgin Islands"
]


export const RESOURCE_APPLICABLE_STATUS = [
  { label: "All Entries", value: "12345" },
  { label: "New/Reviewed/Attention/Adjusted", value: "1" },
  { label: "Disapproved", value: "2" },
  { label: "Approved", value: "3" },
  { label: "Submitted", value: "4" },
  { label: "Granted/Not Granted", value: "5" }
]

export const RESOURCE_MANAGEMENT_STATUS = {
  new: "New",
  approved: "Approved",
  "not-approved": "Not Approved"
}

const FILTER_OPEN_WIDTH = 310;
const FILTER_CLOSE_WIDTH = 0;
export const styles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      [theme.breakpoints.between("xs", "md")]: {
        flexWrap: "wrap"
      },
      display: "flex",
      gap: 16,
      flexWrap: "nowrap"
    },
    main8: {
      [theme.breakpoints.between("xs", "md")]: {
        flexWrap: "wrap"
      },
      display: "flex",
      gap: 8,
      flexWrap: "nowrap"
    },
    filterOpenContainer: {
      [theme.breakpoints.between("xs", "md")]: {
        width: `100%`
      },
      [theme.breakpoints.up("lg")]: {
        width: `${FILTER_OPEN_WIDTH}px`,
        minWidth: `${FILTER_OPEN_WIDTH}px`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    filterCloseContainer: {
      [theme.breakpoints.between("xs", "md")]: {
        width: `100%`
      },
      [theme.breakpoints.up("lg")]: {
        // width: `${FILTER_CLOSE_WIDTH}px`,
        display: "none",
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    },
    mainOpenContainer: {
      [theme.breakpoints.between("xs", "md")]: {
        width: `100%`
      },
      [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${FILTER_OPEN_WIDTH + 58}px)`,
        flex: 1,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    mainCloseContainer: {
      // [theme.breakpoints.between("xs", "md")]: {
      width: `100%`,
      // },
      [theme.breakpoints.up("lg")]: {
        width: `calc(100% - 50px)`,
        // width: `calc(100% - 60px)`,
      },
      // [theme.breakpoints.up("lg")]: {
      //   width: `calc(100% - ${FILTER_CLOSE_WIDTH}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      // },
    },
    toggleFilterSection: {
      maxWidth: 42,
      minWidth: 42,
      opacity: 0.4,
      background: "#ddd",
      cursor: "pointer",
      position: "relative",
      maxHeight: "calc(100vh - 130px)",
      height: "calc(100vh - 130px)",
      "&:hover, &:focus": {
        opacity: 1,
      }
    },
    toggleContent: {
      transform: "translate(-4px) rotate(90deg)",
      position: "absolute",
      top: "50%",
      left: -48,
      width: 150,
      display: "flex",
      fontSize: 20,
      alignItems: "end",
    }
  })
);

export const YEAR_ARRAY = ["2023-01-01", "2022-01-01", "2021-01-01", "2020-01-01", "2019-01-01"];
export const TREE_YEAR_DATE_FORMAT = "YYYY-MM-DD";
export const TREE_MONTH_DATE_FORMAT = "YYYY/MM/DD";

export const CMO_DIC = {
  "time-keeping-protocol": "Time Keeping Protocol",
  "client-specific-work": "Client-Specific Work",
  "deposition": "Depositions",
  "periodic-committee-conference-calls": "Periodic Committee Conference Calls and Liaison Counsel Meetings",
  "periodic-status-conferences": "Periodic Status Conferences",
  "identification-and-work-up-of-experts": "Identification and Work-Up of Experts",
  "attendance-at-seminars": "Attendance at Seminars",
  "discovery-and-document-review-or-production": "Discovery and Document Review or Production",
  "review-of-filing-and-orders": "Review of Filings and Orders",
  "emails-and-correspondences": "Emails and Correspondence",
  "travel-time": "Travel Time",
}

export const REPORTS_DICT = {
  1: "Time Detail Report",
  2: "Time Summary Report",
  3: "Imported Time Entries Report",
  // 3: "Time Calendar Summary",
  // 4: "Time Calendar Detail",
  5: "Time Summary Crosstab Report",
  6: "Summary Visualization",
  7: "Changed Time Entries Report"
};

export const REPORT_METADATA_DICT = {
  Firms: "Firm", // same as BURST_REPORT_DICT
  'Firm Long Name': 'Firm Long Name', // not there in BURST_REPORT_DICT
  Resource: "Resource", // same as BURST_REPORT_DICT
  'Time Category': 'Time Category', // same as BURST_REPORT_DICT
  'Professional Level': 'Professional Level', // same as BURST_REPORT_DICT
};
export const SUMMARY_VISUALIZATION_COLUMN_METADATA_DICT = {
  Firms: "Firm", // same as BURST_REPORT_DICT
  'Firm Long Name': 'Firm Long Name', // not there in BURST_REPORT_DICT
  // Resource: "Resource", // same as BURST_REPORT_DICT
  'Time Category': 'Time Category', // same as BURST_REPORT_DICT
  'Professional Level': 'Professional Level', // same as BURST_REPORT_DICT
};

export const CHANGED_TIME_ENTRIES_COLUMN_METADATA_DICT = {
  "Firms": "Firm",
  "Firm Long Name": "Firm Long Name",
  "Import Source": "Import Source",
  "Imported Service Date": "Imported Service Date",
  "Imported Resource": "Imported Resource",
  "Imported Professional Level": "Imported Professional Level",
  "Imported Time Category": "Imported Time Category",
  // "Imported Approved By": "Imported Approved By",
  "Imported Class Work": "Imported Class Work",
  "Current Service Date": "Current Service Date",
  "Current Resource": "Current Resource",
  "Current Professional Level": "Current Professional Level",
  "Current Time Category": "Current Time Category",
  "Current Approved By": "Current Approved By",
  "Current Class Work": "Current Class Work"
};

export const SPLIT_ENTRIES_COLUMN_METADATA_DICT = {
  "Firms": "Firm",
  "Firm Long Name": "Firm Long Name",
  "Original Entry Service Date": "Original Entry Service Date",
  "Original Entry Resource": "Original Entry Resource",
  "Original Entry Professional Level": "Original Entry Professional Level",
  "Original Entry Time Category": "Original Entry Time Category",
  "Original Entry Approved By": "Original Entry Approved By",
  "Original Entry Class Work": "Original Entry Class Work",
  // "Current Status of Original Entry": "Current Status of Original Entry",
  "Split Entry Service Date": "Split Entry Service Date",
  "Split Entry Resource": "Split Entry Resource",
  "Split Entry Professional Level": "Split Entry Professional Level",
  "Split Entry Time Category": "Split Entry Time Category",
  "Split Entry Approved By": "Split Entry Approved By",
  "Split Entry Class Work": "Split Entry Class Work",
  // "Split Entry Reason": "Split Entry Reason",
  // "Split Entry User": "Split Entry User",
  // "Current Status of Split Entry": "Current Status of Split Entry",
};


export const TIME_DETAIL_REPORT_METADATA_DICT = {
  ...REPORT_METADATA_DICT,
  'Service Date': 'Service Date'
};

export const IMPORTED_TIME_ENTRIES_REPORT_METADATA_DICT = {
  ...TIME_DETAIL_REPORT_METADATA_DICT,
  'Import Source': 'Import Source',
  // 'detailedDescription': 'Description',
  // 'Imported Approved By': 'Imported Approved By',
  'Imported Class Work': 'Imported Class Work',
}

export const METRICS_DICT = {
  all: "All",
  // ...REPORT_METADATA_DICT,
  resources: "Resources",
  resourcesPercent: "Resources (%)",
  hours: "Hours",
  hoursPercent: "Hours (%)",
  fees: "Fees",
  feesPercent: "Fees (%)",
  entries: "Entries",
  entriesPercent: "Entries (%)",
  months: "Months",
  monthsPercent: "Months (%)",
  'max-hourly-rates': "High Hourly Rates",
  'avg-hourly-rates': "Avg Hourly Rates",
  'min-hourly-rates': "Low Hourly Rates",
};
export const SUMMARY_VISUALIZATION_METADATA_DICT = {
  // all: "All",
  // ...REPORT_METADATA_DICT,
  // resources: "Resources",
  // resourcesPercent: "Resources (%)",
  hours: "Hours",
  // hoursPercent: "Hours (%)",
  fees: "Fees",
  // feesPercent: "Fees (%)",
  // entries: "Entries",
  // entriesPercent: "Entries (%)",
  // months: "Months",
  // monthsPercent: "Months (%)",
  // 'max-hourly-rates': "High Hourly Rates",
  // 'avg-hourly-rates': "Avg Hourly Rates",
  // 'min-hourly-rates': "Low Hourly Rates",
};

export const CHANGED_TIME_ENTRIES_METADATA_DICT = {
  all: "All",
  "importedHours": "Imported Hours",
  "importedBillingRate": "Imported Rate",
  "importedFeesTotal": "Imported Amount",
  "importedDescription": "Imported Description",
  "currentHours": "Current Hours",
  "currentBillingRate": "Current Rate",
  "currentFeesTotal": "Current Amount",
  "hoursDifference": "Hours Difference",
  "billingRateDifference": "Rate Difference",
  "feesTotalDifference": "Amount Difference",
  "currentDescription": "Current Description",
};

export const CHANGED_TIME_ENTRY_COLUMN_MAP_DIC = {
  "firmName": "Firms",
  "firmLongName": "Firm Long Name",
  "importSource": "Import Source",
  "importedServiceDate": "Imported Service Date",
  "importedResource": "Imported Resource",
  "importedProfessionalLevel": "Imported Professional Level",
  "Imported Time Category": "Imported Time Category",
  "importedDescription": "Imported Description",
  // "importedApprovedBy": "Imported Approved By",
  "importedClassWork": "Imported Class Work",
  "currentServiceDate": "Current Service Date",
  "currentResource": "Current Resource",
  "currentProfessionalLevel": "Current Professional Level",
  "currentTimeCategory": "Current Time Category",
  "currentDescription": "Current Description",
  "currentApprovedBy": "Current Approved By",
  "currentClassWork": "Current Class Work",
  "importedHours": "Imported Hours",
  "importedBillingRate": "Imported Rate",
  "importedFeesTotal": "Imported Amount",
  "currentHours": "Current Hours",
  "currentBillingRate": "Current Rate",
  "currentFeesTotal": "Current Amount",
  "hoursDifference": "Hours Difference",
  "billingRateDifference": "Rate Difference",
  "feesTotalDifference": "Amount Difference"
};

export const SPLIT_ENTRIES_METADATA_DICT = {
  all: "All",
  "originalEntryHours": "Original Entry Hours",
  "originalEntryRate": "Original Entry Rate",
  "originalEntryAmount": "Original Entry Amount",
  "originalEntryDescription": "Original Entry Description",
  "splitEntryHours": "Split Entry Hours",
  "splitEntryRate": "Split Entry Rate",
  "splitEntryAmount": "Split Entry Amount",
  "splitEntryDescription": "Split Entry Description",
  "originalSplitHoursDifference": "Original Vs All Split Hours Difference",
  "originalSplitAmountDifference": "Original Vs All Split Amount Difference",
  "originalEntryCurrentStatus": "Current Status of Original Entry",
  "splitEntryReason": "Split Entry Reason",
  "splitEntryUser": "Split Entry User",
  "splitEntryCurrentStatus": "Current Status of Split Entry",
};

export const SPLIT_ENTRIES_COLUMN_MAP_DICT = {
  "FirmName": "Firm",
  "firmLongName": "Firm Long Name",
  "originalEntryServiceDate": "Original Entry Service Date",
  "originalEntryResource": "Original Entry Resource",
  "originalEntryProfessionalLevel": "Original Entry Professional Level",
  "originalEntryTimeCategory": "Original Entry Time Category",
  "originalEntryApprovedBy": "Original Entry Approved By",
  "originalEntryClassWork": "Original Entry Class Work",
  "currentStatusOfOriginalEntry": "Current Status of Original Entry",
  "splitEntryServiceDate": "Split Entry Service Date",
  "splitEntryResource": "Split Entry Resource",
  "splitEntryProfessionalLevel": "Split Entry Professional Level",
  "splitEntryTimeCategory": "Split Entry Time Category",
  "splitEntryApprovedBy": "Split Entry Approved By",
  "splitEntryClassWork": "Split Entry Class Work",
  "splitEntryReason": "Split Entry Reason",
  "splitEntryUser": "Split Entry User",
  "currentStatusOfSplitEntry": "Current Status of Split Entry",
  ...SPLIT_ENTRIES_METADATA_DICT
}

export const TIME_DETAIL_REPORT_METRICS_DICT = {
  all: "All",
  // ...REPORT_METADATA_DICT,
  // firmName: "Firm",
  // professionalLevelName: "Professional Level",
  // resourceName: "Resource",
  // timeCategoryName: "Category",
  time: "Time",
  rate: "Rate",
  amount: "Amount",
  description: "Description",
};

export const IMPORTED_TIME_ENTRIES_REPORT_METRICS_DICT = {
  all: "All",
  hours: "Hours",
  rate: "Rate",
  amount: "Amount",
  description: "Description",
};

export const TIME_DETAILED_DICT = {
  firmName: "Firms",
  professionalLevelName: "Professional Level",
  resourceName: "Resource",
  timeCategoryName: "Time Category",
  serviceDate: 'Service Date'
}

export const TIME_SUMMARY_CROSS_TABLE_REPORT_DICT = {
  "Firms": "Firms",
  "Professional Level": "Professional Level",
  "Resource Name": "Resource",
  "Time Category": "Time Category",
  "Status": "Status"
}

export const TIME_SUMMARY_CROSS_TABLE_REPORT_METRICS_DICT = {
  // all: "All",
  // "Hours" : "Hours",
  // "Fees" : "Fees",
  // "Months" : "Months",
  // "Entries" : "Entries",
  // "Resources" : "Resources",
  all: "All",
  // ...REPORT_METADATA_DICT,
  "Resources": "Resources",
  "Resources (%)": "Resources (%)",
  "Hours": "Hours",
  "Hours (%)": "Hours (%)",
  "Fees": "Fees",
  "Fees (%)": "Fees (%)",
  "Entries": "Entries",
  "Entries (%)": "Entries (%)",
  "Months": "Months",
  "Months (%)": "Months (%)",
  "High Hourly Rates": "High Hourly Rates",
  "Avg Hourly Rates": "Avg Hourly Rates",
  "Low Hourly Rates": "Low Hourly Rates",
}

export const BURST_REPORT_DICT = {
  'Do Not Burst': 'Do Not Burst',
  // ...REPORT_METADATA_DICT,
  Firms: "Firm", // same as REPORT_METADATA_DICT
  Resource: "Resource", // same as REPORT_METADATA_DICT
  'Time Category': 'Time Category', // same as REPORT_METADATA_DICT
  'Professional Level': 'Professional Level', // same as REPORT_METADATA_DICT
};

export const TIME_DETAIL_BURST_REPORT_DICT = {
  'Do Not Burst': 'Do Not Burst',
  Firms: "Firm",
  "Firm Long Name": "Firm Long Name",
  Resource: "Resource",
  'Time Category': 'Time Category',
  'Professional Level': 'Professional Level',
};

export const CHANGED_TIME_ENTRIES_BURST_REPORT_DICT = {
  'Do Not Burst': 'Do Not Burst',
  "Firms": "Firm",
  "Firm Long Name": "Firm Long Name",
  "Imported Resource": "Imported Resource",
  "Imported Time Category": "Imported Time Category",
  "Imported Professional Level": "Imported Professional Level",
  "Import Source": "Import Source",
  "Current Resource": "Current Resource",
  "Current Time Category": "Current Time Category",
  "Current Professional Level": "Current Professional Level"
};

export const SPLIT_ENTRIES_BURST_REPORT_DICT = {
  'Do Not Burst': 'Do Not Burst',
  Firms: "Firm",
  "Firm Long Name": "Firm Long Name",
  "Split Entry Resource": "Split Entry Resource",
  'Split Entry Time Category': 'Split Entry Time Category',
  'Split Entry Professional Level': 'Split Entry Professional Level',
};

export const CHANGED_TIME_ENTRIES_SHOW_ENTRIES_DICT = {
  'Any Change': 'Any Change',
  "Service Date Changed": "Service Date Changed",
  "Hours Changed": "Hours Changed",
  "Rate Changed": "Rate Changed",
  "Amount Changed": "Amount Changed",
  "Resource Changed": "Resource Changed",
  "Professional Level Changed": "Professional Level Changed",
  "Time Category Changed": "Time Category Changed",
  "Description Changed": "Description Changed",
  "Approved-by Changed": "Approved-by Changed",
  "Class Work Changed": "Class Work Changed"

};

export const SPLIT_ENTRIES_SHOW_ENTRIES_DICT = {
  'All Split Entries': 'All Split Entries',
  'User Split Entries': 'User Split Entries',
  'System Split Entries': 'System Split Entries',
  'Hours Changed': 'Hours Changed',
  'Amount Changed': 'Amount Changed',
};

export const CHANGED_TIME_ENTRIES_SHOW_ENTRIES_MAP_WITH_SHOW_METRICS = {
  'Any Change': [],
  "Service Date Changed": [],
  "Hours Changed": ["importedHours", "currentHours", "hoursDifference"],
  "Rate Changed": ["importedBillingRate", "currentBillingRate", "billingRateDifference"],
  "Amount Changed": ["importedFeesTotal", "currentFeesTotal", "feesTotalDifference"],
  "Resource Changed": [],
  "Professional Level Changed": [],
  "Time Category Changed": [],
  "Description Changed": ["importedDescription", "currentDescription"],
  "Approved-by Changed": [],
  "Class Work Changed": []
};

export const SPLIT_TIME_ENTRIES_SHOW_ENTRIES_MAP_WITH_SHOW_METRICS = {
  'All Split Entries': [],
  'User Split Entries': [],
  'System Split Entries': [],
  'Hours Changed': [],
  // 'Hours Changed': ["originalEntryHours", "splitEntryHours", "originalSplitHoursDifference"],
  'Amount Changed': [],
  // 'Amount Changed': ["originalEntryAmount", "splitEntryAmount", "originalSplitAmountDifference"],
};

export const IMPORTED_TIME_ENTRIES_BURST_REPORT_DICT = {
  'Do Not Burst': 'Do Not Burst',
  Firms: "Firm",
  'Firm Long Name': 'Firm Long Name',
  Resource: "Resource",
  'Time Category': 'Time Category',
  'Professional Level': 'Professional Level',
  'Import Source': 'Import Source'
}

// export const BURST_REPORT_TO_METADATA_DICT = {
//   Firms: "firmName",
//   Resource: "resourceName",
//   'Time Category': 'timeCategoryName',
//   'Professional Level': 'professionalLevelName',
// };

// export const METADATA_TO_BURST_REPORT_DICT = {
//   "firmName": "Firms",
//   "resourceName": "Resource",
//   'timeCategoryName': 'Time Category',
//   'professionalLevelName': 'Professional Level',
// };

export const SORT_ORDER_DICT = {
  asc: "ASC",
  desc: "DESC",
}

export const REPORT_OUTPUT_DICT = {
  // csv: "CSV (list only)",
  csv: "CSV",
  pdf: "PDF",
  web: "Web",
  // excel: "MS Excel"
}

export const REPORT_GROUP_BY_NAME = {
  professionalLevelName: "Professional Level",
  timeCategoryName: "Time Category",
  resourceName: "Resource",
  firmName: "Firm",
  serviceDate: "Service Date"
}

export const SUMMARY_REPORT_GROUP_BY_NAME = {
  professionalLevelName: "Professional Level",
  timeCategoryName: "Time Category",
  resourceName: "Resource",
  firmName: "Firm"
}

export const TIME_SUMMARY_METRICS_MAP_WITH_VALUE = {
  resources: "resourceCount",
  resourcesPercent: "resourcePercent",
  hours: "totalHoursCount",
  hoursPercent: "totalHoursPercent",
  fees: "feesTotalCount",
  feesPercent: "feesTotalPercent",
  entries: "timeEntriesCount",
  entriesPercent: "timeEntriesPercent",
  months: "monthCount",
  monthsPercent: "monthPercent",
  'max-hourly-rates': "maxHourlyRate",
  'avg-hourly-rates': "avgHourlyRate",
  'min-hourly-rates': "minHourlyRate",
  // resources: {
  //   count: "resourceCount",
  //   percentage: "resourcePercent"
  // },
  // hours: {
  //   count: "totalHoursCount",
  //   percentage: "totalHoursPercent"
  // },
  // fees: {
  //   count: "feesTotalCount",
  //   percentage: "feesTotalPercent"
  // },
  // entries: {
  //   count: "timeEntriesCount",
  //   percentage: "timeEntriesPercent"
  // },
  // months: {
  //   count: "monthCount",
  //   percentage: "monthPercent"
  // },
  // 'hourly-rates': {
  //   high: "maxHourlyRate",
  //   avg: "avgHourlyRate",
  //   low: "minHourlyRate",
  // },
}

export const TIME_DETAIL_METRICS_MAP_WITH_VALUE = {
  time: "totalHours",
  rate: "billingRate",
  amount: "feesTotal",
  description: "detailedDescription",
}

export const IMPORTED_TIME_ENTRIES_METRICS_MAP_WITH_VALUE = {
  hours: "totalHours",
  rate: "billingRate",
  amount: "feesTotal",
  description: "detailedDescription",
}

export const BURST_REPORTS_MAP_WITH_VALUE = {
  Firms: "firm",
  Resource: "resource",
  'Time Category': 'timeCategory',
  'Professional Level': 'professionalLevelName',
};

export const BURST_REPORTS_MAP_WITH_VALUE_FOR_TIME_DETAILED_REPORT = {
  Firms: "firm",
  Resource: "resource",
  'Time Category': 'timeCategory',
  'Professional Level': 'professionalLevelName',
  'Service Date': 'serviceDate',
  'Firm Long Name': 'firmLongName',
  'Import Source': 'importSource',
}

export const BURST_REPORTS_MAP_WITH_VALUE_FOR_IMPORTED_TIME_ENTRIES_REPORT = {
  ...BURST_REPORTS_MAP_WITH_VALUE_FOR_TIME_DETAILED_REPORT,
  'Firm Long Name': 'firm',
}

export const BUST_REPORT_MAP_FOR_CHANGED_TIME_ENTRIES = {
  Firm: 'firm',
  Firms: 'firm',
  'Firm Long Name': "firmLongName",
  "Imported Resource": "importedResource",
  "Imported Time Category": "importedTimeCategory",
  "Imported Professional Level": "importedProfessionalLevel",
  "Import Source": "importSource",
  "Current Resource": "currentResource",
  "Current Time Category": "currentTimeCategory",
  "Current Professional Level": "currentProfessionalLevel",

}

export const BUST_REPORT_MAP_FOR_SPLIT_ENTRIES = {
  Firm: 'firm',
  Firms: 'firm',
  'Firm Long Name': "firmLongName",
  "Split Entry Resource": "splitEntryResource",
  "Split Entry Time Category": "splitEntryTimeCategory",
  "Split Entry Professional Level": "splitEntryProfessionalLevel",
}

export const SESSION_TIMEOUT_TIME_IN_SEC = 900;

export const IS_READ_ONLY_ALERT_TEXT = "App is in Read Only mode!";
export const IS_READ_ONLY_TOAST_MESSAGE = "App is in Read Only mode, You can't perform this operation!";

export const MAX_PAGINATION_OPTIONS = 300;

export const LOGIN_HISTORY = {
  type: {
    "login": "Login",
    "logout": "Logout"
  },
  logoutType: {
    "auto": "Automatic",
    "manual": "Manual"
  }
}

export const DATE_COLUMNS = ["serviceDate", "importedServiceDate", "currentServiceDate", "originalEntryServiceDate", "splitEntryServiceDate"]

export const NOTIFICATION_TYPES = {
  'attention':"Attention",
  'new entries added':"New Entries Added",
  'action requested':"Action Requested",
  'firm added':"Firm Added",
  'user added':"User Added",
}

export const NOTIFICATION_FREQUENCY = {
  daily:"Daily",
  weekly:"Weekly",
  monthly:"Monthly",
}