import React from "react";
import { Collapse, List, Tooltip } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { isUserAllowed, parseJwt, removeToken } from "../../services/UtilService";
import {
  CashMultiple,
  GoogleSpreadsheet,
  Home,
  Logout,
  Gavel,
  ViewDashboard,
  AccountCog,
  CogOutline,
  CloudUpload,
  FileCheck,
  Note,
  AccountBox,
  ChevronUp,
  ChevronDown,
  AccountLock,
  WrenchOutline,
  AccountTie,
  ClockCheck,
  BookLock,
  ChartPie,
  FormatAlignJustify,
  CurrencyUsd,
  AccountCheck,
  InformationOutline,
  ClockOutline,
  ChartTree,
  TableArrowDown,
  TablePlus,
  ClockCheckOutline,
} from "mdi-material-ui";
import { withIsReadOnlyContext } from "./IsReadOnlyProvider";
import { setLoginHistory } from "../../services/UserService";
import { withToastContext } from "./ToastProvider";
// import { IsReadOnlyContext } from "./IsReadOnlyProvider";
export interface SideMenuProps extends RouteComponentProps {
  isReadOnly: boolean;
  userIP: string;
}

export interface SideMenuState {
  userData: any;
  selectedIndex: number;
  isTimeMenuOpen: boolean;
  isCourtMenuOpen: boolean;
  isImportMenuOpen: boolean;
  isConfigurationMenuOpen: boolean;
  isAdministrationMenuOpen: boolean;
  isReportMenuOpen: boolean;
}

class SideMenu extends React.Component<SideMenuProps, SideMenuState> {

  // static contextType = IsReadOnlyContext;

  constructor(props: SideMenuProps) {
    super(props);
    this.state = {
      userData: parseJwt(),
      selectedIndex: 0,
      isTimeMenuOpen: false,
      isCourtMenuOpen: false,
      isImportMenuOpen: false,
      isConfigurationMenuOpen: false,
      isAdministrationMenuOpen: false,
      isReportMenuOpen: false
    };
  }
  componentDidMount = () => {
    this.updateSelectedMenu();
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.updateSelectedMenu();
    }
    if (this.props.isReadOnly !== prevProps.isReadOnly && this.props.isReadOnly === true) {
      this.setState({
        isCourtMenuOpen: false,
        isImportMenuOpen: false,
        // isAdministrationMenuOpen: false
      })
    }
  }

  updateSelectedMenu = () => {
    let selectedIndex = 0;
    let pathName = this.props.location.pathname;
    let isAdministrationMenuOpen = this.state.isAdministrationMenuOpen;
    let isTimeMenuOpen = this.state.isTimeMenuOpen;
    let isCourtMenuOpen = this.state.isCourtMenuOpen;
    let isImportMenuOpen = this.state.isImportMenuOpen;
    let isConfigurationMenuOpen = this.state.isConfigurationMenuOpen;
    let isReportMenuOpen = this.state.isReportMenuOpen;

    // if (pathName.includes('reports') && pathName.includes('details')) pathName = '/reports';

    switch (pathName) {
      case "/dashboard":
        selectedIndex = 0;
        break;
      case "/timesheets-summary":
        isTimeMenuOpen = true;
        selectedIndex = 1;
        break;
      case "/timesheets-summary/timesheet":
        isTimeMenuOpen = true;
        selectedIndex = 1;
        break;
      case "/expenses":
        selectedIndex = 2;
        break;
      case "/submit-time-to-court":
        isReportMenuOpen = true
        selectedIndex = 3;
        break;
      case "/submit-expense-to-court":
        isCourtMenuOpen = true
        selectedIndex = 4;
        break;
      case "/time-granted-notgranted":
        isCourtMenuOpen = true
        selectedIndex = 5;
        break;
      case "/expense-granted-notgranted":
        isCourtMenuOpen = true
        selectedIndex = 6;
        break;
      // case "/business-rules":
      //   selectedIndex = 4;
      //   break;
      case "/access-management":
        selectedIndex = 7;
        isAdministrationMenuOpen = true;
        break;
      case "/configure-firms":
        isImportMenuOpen = true;
        // isConfigurationMenuOpen = true;
        selectedIndex = 8;
        break;
      case "/import":
        isImportMenuOpen = true;
        selectedIndex = 9;
        break;
      case "/notes":
        isTimeMenuOpen = true
        selectedIndex = 10;
        break;
      case "/resource-management":
        selectedIndex = 11;
        isConfigurationMenuOpen = true;
        break;
      case "/professional-level-management":
        selectedIndex = 12;
        isConfigurationMenuOpen = true;
        break;
      case "/time-category-management":
        selectedIndex = 13;
        isConfigurationMenuOpen = true;
        break;
      case "/time-approved-by-management":
        selectedIndex = 14;
        isConfigurationMenuOpen = true;
        break;
      case "/keyword-management":
        selectedIndex = 15;
        isConfigurationMenuOpen = true;
        break;
      case "/notifications-management":
        selectedIndex = 31;
        isConfigurationMenuOpen = true;
        break;
      case "/cmo-protocols-for-common-benefit-work":
        // case "/management-dashboard":
        selectedIndex = 16;
        break;
      case "/rules":
        selectedIndex = 17;
        isAdministrationMenuOpen = true;
        break;
      // case "/reports":
      //   selectedIndex = 18;
      //   break;
      case "/timesheets-details":
        isTimeMenuOpen = true;
        selectedIndex = 19;
        break;
      case "/attention-timesheets-details":
        isTimeMenuOpen = true;
        selectedIndex = 20;
        break;
      case "/about":
        isAdministrationMenuOpen = true;
        selectedIndex = 21;
        break;
      case "/reports/1/details":
        selectedIndex = 22;
        isReportMenuOpen = true;
        break;
      case "/reports/2/details":
        selectedIndex = 23;
        isReportMenuOpen = true;
        break;
      case "/reports/5/details":
        selectedIndex = 24;
        isReportMenuOpen = true;
        break;
      case "/reports/6/details":
        selectedIndex = 25;
        isReportMenuOpen = true;
        break;
      case "/reports/3/details":
        selectedIndex = 26;
        isReportMenuOpen = true;
        break;
      case "/reports/7/details":
        selectedIndex = 27;
        isReportMenuOpen = true;
        break;
      case "/reports/8/details":
        selectedIndex = 28;
        isReportMenuOpen = true;
        break;
      case "/reports/committee":
        selectedIndex = 30;
        isReportMenuOpen = true;
        break;
      case "/logins-history":
        selectedIndex = 29;
        isAdministrationMenuOpen = true;
        break;
      default:
        break;
    }
    this.setState({
      selectedIndex,
      isAdministrationMenuOpen,
      isTimeMenuOpen,
      isCourtMenuOpen,
      isImportMenuOpen,
      isConfigurationMenuOpen,
      isReportMenuOpen
    });
  }

  handleListItemClick = (index: number) => {
    let isAdministrationMenuOpen = this.state.isAdministrationMenuOpen;
    // if (![7, 11, 12, 13, 14, 15, 17].includes(index)) {
    //   isAdministrationMenuOpen = false;
    // }
    this.setState({ selectedIndex: index, isAdministrationMenuOpen });
  };

  render() {
    const { selectedIndex } = this.state;
    // const { this.props.isReadOnly } = this.context;
    const { isReadOnly } = this.props;
    return (
      <List className="side-menu-list custom-side-menu">
        <ListItem
          button
          component={Link}
          to="/dashboard"
          selected={selectedIndex === 0}
          onClick={() => this.handleListItemClick(0)}
        >
          <Tooltip title="Home" placement="right">
            <ListItemIcon>
              <Home />
            </ListItemIcon>
          </Tooltip>
          <ListItemText className="">Home</ListItemText>
        </ListItem>
        <ListItem
          button
          onClick={() => this.setState({ isTimeMenuOpen: !this.state.isTimeMenuOpen })}
        >
          <Tooltip title="Time" placement="right">
            <ListItemIcon>
              <ClockOutline />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Time</ListItemText>
          {this.state.isTimeMenuOpen ? <ChevronUp /> : <ChevronDown />}
        </ListItem>
        <Collapse in={this.state.isTimeMenuOpen} timeout="auto" unmountOnExit>
          <List disablePadding className="nested-custom-side-menu" dense={true}>
            <ListItem
              button
              component={Link}
              to="/timesheets-summary"
              selected={selectedIndex === 1}
              onClick={() => this.handleListItemClick(1)}
            >
              <Tooltip title="Summary" placement="right">
                <ListItemIcon>
                  <GoogleSpreadsheet />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Summary</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/timesheets-details"
              selected={selectedIndex === 19}
              onClick={() => this.handleListItemClick(19)}
            >
              <Tooltip title="Details" placement="right">
                <ListItemIcon>
                  <FormatAlignJustify />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Details</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/attention-timesheets-details"
              selected={selectedIndex === 20}
              disabled={isReadOnly}
              onClick={() => this.handleListItemClick(20)}
            >
              <Tooltip title="Attention" placement="right">
                <ListItemIcon>
                  <GoogleSpreadsheet />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Attention</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/notes"
              selected={selectedIndex === 10}
              onClick={() => this.handleListItemClick(10)}
            >
              <Tooltip title="Notes" placement="right">
                <ListItemIcon>
                  <Note />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Notes</ListItemText>
            </ListItem>
          </List>
        </Collapse>
        {/* {isUserAllowed() && (
          <ListItem
            button
            component={Link}
            to="/expenses"
            disabled={isReadOnly}
            selected={selectedIndex === 2}
            onClick={() => this.handleListItemClick(2)}
          >
            <Tooltip title="Expenses" placement="right">
              <ListItemIcon>
                <CurrencyUsd />
              </ListItemIcon>
            </Tooltip>
            <ListItemText>Expenses</ListItemText>
          </ListItem>
        )} */}
        <ListItem
          button
          component={Link}
          to="/cmo-protocols-for-common-benefit-work"
          selected={selectedIndex === 16}
          onClick={() => this.handleListItemClick(16)}
        >
          <Tooltip title="CMO Compliance" placement="right">
            <ListItemIcon>
              <ViewDashboard />
            </ListItemIcon>
          </Tooltip>
          <ListItemText className="">CMO Compliance</ListItemText>
        </ListItem>

        {/* <ListItem
          button
          disabled={isReadOnly}
          onClick={() => this.setState({ isCourtMenuOpen: !this.state.isCourtMenuOpen })}
        >
          <Tooltip title="Court Reporting" placement="right">
            <ListItemIcon>
              <Gavel />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Court Reporting</ListItemText>
          {this.state.isCourtMenuOpen ? <ChevronUp /> : <ChevronDown />}
        </ListItem>
        <Collapse in={this.state.isCourtMenuOpen} timeout="auto" unmountOnExit>
          <List disablePadding className="nested-custom-side-menu" dense={true}>
            <ListItem
              button
              component={Link}
              to="/submit-time-to-court"
              selected={selectedIndex === 3}
              onClick={() => this.handleListItemClick(3)}
            >
              <Tooltip title="Submit Time" placement="right">
                <ListItemIcon>
                  <Gavel />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Submit Time</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/time-granted-notgranted"
              selected={selectedIndex === 5}
              onClick={() => this.handleListItemClick(5)}
            >
              <Tooltip title="Granted/Not Granted Time" placement="right">
                <ListItemIcon>
                  <Gavel />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Granted/Not Granted Time</ListItemText>
            </ListItem>

            {isUserAllowed() && (
              <React.Fragment>
                <ListItem
                  button
                  component={Link}
                  to="/submit-expense-to-court"
                  selected={selectedIndex === 4}
                  onClick={() => this.handleListItemClick(4)}
                >
                  <Tooltip title="Submit Expenses" placement="right">
                    <ListItemIcon>
                      <Gavel />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText>Submit Expenses</ListItemText>
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/expense-granted-notgranted"
                  selected={selectedIndex === 6}
                  onClick={() => this.handleListItemClick(6)}
                >
                  <Tooltip title="Granted/Not Granted Expense" placement="right">
                    <ListItemIcon>
                      <Gavel />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText>Granted/Not Granted Expense</ListItemText>
                </ListItem>
              </React.Fragment>
            )}
          </List>
        </Collapse> */}
        <ListItem
          button
          disabled={isReadOnly}
          onClick={() => this.setState({ isImportMenuOpen: !this.state.isImportMenuOpen })}
        >
          <Tooltip title="Import" placement="right">
            <ListItemIcon>
              <CloudUpload />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Import</ListItemText>
          {this.state.isImportMenuOpen ? <ChevronUp /> : <ChevronDown />}
        </ListItem>
        <Collapse in={this.state.isImportMenuOpen} timeout="auto" unmountOnExit>
          <List disablePadding className="nested-custom-side-menu" dense={true}>
            <ListItem
              button
              component={Link}
              to="/configure-firms"
              selected={selectedIndex === 8}
              onClick={() => this.handleListItemClick(8)}
            >
              <Tooltip title="Configure Firms" placement="right">
                <ListItemIcon>
                  <CogOutline />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Configure Firms</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/import"
              selected={selectedIndex === 9}
              onClick={() => this.handleListItemClick(9)}
            >
              <Tooltip title="Import" placement="right">
                <ListItemIcon>
                  <FileCheck />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Import</ListItemText>
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          button
          // component={Link}
          // to="/reports"
          // selected={selectedIndex === 18}
          // onClick={() => this.handleListItemClick(18)}
          onClick={() => this.setState({ isReportMenuOpen: !this.state.isReportMenuOpen })}
        >
          <Tooltip title="Reports" placement="right">
            <ListItemIcon>
              <ChartPie />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Reports</ListItemText>
          {this.state.isReportMenuOpen ? <ChevronUp /> : <ChevronDown />}
        </ListItem>
        <Collapse in={this.state.isReportMenuOpen} timeout="auto" unmountOnExit>
          <List disablePadding className="nested-custom-side-menu" dense={true}>
            <ListItem
              button
              component={Link}
              to="/reports/1/details"
              selected={selectedIndex === 22}
              onClick={() => this.handleListItemClick(22)}
            >
              <Tooltip title="Time Detail" placement="right">
                <ListItemIcon>
                  <FormatAlignJustify />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Time Detail</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/reports/2/details"
              selected={selectedIndex === 23}
              onClick={() => this.handleListItemClick(23)}
            >
              <Tooltip title="Time Summary" placement="right">
                <ListItemIcon>
                  <GoogleSpreadsheet />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Time Summary</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/reports/5/details"
              selected={selectedIndex === 24}
              onClick={() => this.handleListItemClick(24)}
            >
              <Tooltip title="Time Summary Crosstab" placement="right">
                <ListItemIcon>
                  <ChartTree />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Time Summary Crosstab</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/reports/6/details"
              selected={selectedIndex === 25}
              onClick={() => this.handleListItemClick(25)}
            >
              <Tooltip title="Summary Visualization" placement="right">
                <ListItemIcon>
                  <GoogleSpreadsheet />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Summary Visualization</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/reports/3/details"
              selected={selectedIndex === 26}
              onClick={() => this.handleListItemClick(26)}
            >
              <Tooltip title="Imported Time Entries" placement="right">
                <ListItemIcon>
                  <TableArrowDown />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Imported Time Entries</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/reports/7/details"
              selected={selectedIndex === 27}
              onClick={() => this.handleListItemClick(27)}
            >
              <Tooltip title="Changed Time Entries" placement="right">
                <ListItemIcon>
                  <TableArrowDown />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Changed Time Entries</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/reports/8/details"
              selected={selectedIndex === 28}
              onClick={() => this.handleListItemClick(28)}
            >
              <Tooltip title="Split Entries" placement="right">
                <ListItemIcon>
                  <TablePlus />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Split Entries</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/reports/committee"
              selected={selectedIndex === 30}
              onClick={() => this.handleListItemClick(30)}
            >
              <Tooltip title="Committee's Report" placement="right">
                <ListItemIcon>
                  <TablePlus />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Committee's Report</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/submit-time-to-court"
              selected={selectedIndex === 3}
              onClick={() => this.handleListItemClick(3)}
            >
              <Tooltip title="Submit Time" placement="right">
                <ListItemIcon>
                  <Gavel />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Court Submit Time</ListItemText>
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          button
          onClick={() => this.setState({ isConfigurationMenuOpen: !this.state.isConfigurationMenuOpen })}
        >
          <Tooltip title="Configuration" placement="right">
            <ListItemIcon>
              <CogOutline />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Configuration</ListItemText>
          {this.state.isConfigurationMenuOpen ? <ChevronUp /> : <ChevronDown />}
        </ListItem>
        <Collapse in={this.state.isConfigurationMenuOpen} timeout="auto" unmountOnExit>
          <List disablePadding className="nested-custom-side-menu" dense={true}>
            <ListItem
              button
              component={Link}
              to="/resource-management"
              selected={selectedIndex === 11}
              onClick={() => this.handleListItemClick(11)}
            >
              <Tooltip title="Resources" placement="right">
                <ListItemIcon>
                  <AccountBox />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Resources</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/professional-level-management"
              selected={selectedIndex === 12}
              onClick={() => this.handleListItemClick(12)}
            >
              <Tooltip title="Professional Levels and Rates" placement="right">
                <ListItemIcon>
                  <AccountTie />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Professional Levels and Rates</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/keyword-management"
              selected={selectedIndex === 15}
              onClick={() => this.handleListItemClick(15)}
            >
              <Tooltip title="Keywords and Attentions" placement="right">
                <ListItemIcon>
                  <BookLock />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Keywords and Attentions</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/time-category-management"
              selected={selectedIndex === 13}
              onClick={() => this.handleListItemClick(13)}
            >
              <Tooltip title="Time Categories" placement="right">
                <ListItemIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#757575" height="24" viewBox="0 96 960 960" width="24"><path d="M598.898 953.711V830.378H435.927v-420.61h-74.159v126.667H54.753V197.912h307.015v123.334h237.13V197.912h306.726v338.523H598.898V409.768h-74.825v332.464h74.825V615.565h306.726v338.146H598.898Zm-456-667.276v161.478-161.478Zm544.523 417.276v161.854-161.854Zm0-417.276v161.478-161.478Zm0 161.478h129.681V286.435H687.421v161.478Zm0 417.652h129.681V703.711H687.421v161.854ZM142.898 447.913h130.725V286.435H142.898v161.478Z" /></svg>
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Time Categories</ListItemText>
            </ListItem>
            {/* <ListItem
              button
              component={Link}
              to="/time-approved-by-management"
              selected={selectedIndex === 14}
              onClick={() => this.handleListItemClick(14)}
            >
              <Tooltip title="Approvers" placement="right">
                <ListItemIcon>
                  <AccountCheck />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Approvers</ListItemText>
            </ListItem> */}
            <ListItem
              button
              component={Link}
              to="/notifications-management"
              selected={selectedIndex === 31}
              onClick={() => this.handleListItemClick(31)}
            >
              <Tooltip title="Notifications" placement="right">
              <ListItemIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#757575" height="24" viewBox="0 0 24 24"><title>bell-cog</title><path d="M19 12C15.13 12 12 15.13 12 19C12 19.34 12.03 19.67 12.08 20H3V19L5 17V11C5 7.9 7.03 5.17 10 4.29V4C10 2.9 10.9 2 12 2S14 2.9 14 4V4.29C16.97 5.17 19 7.9 19 11V12M10 21C10 22.11 10.9 23 12 23C12.39 23 12.75 22.88 13.06 22.69C12.74 22.17 12.5 21.6 12.3 21H10M23.77 20.32C23.87 20.39 23.89 20.53 23.83 20.64L22.83 22.37C22.77 22.5 22.64 22.5 22.53 22.5L21.28 21.97C21 22.17 20.75 22.34 20.44 22.47L20.25 23.79C20.23 23.91 20.13 24 20 24H18C17.88 24 17.77 23.91 17.75 23.79L17.57 22.47C17.25 22.34 17 22.17 16.72 21.97L15.5 22.5C15.37 22.5 15.23 22.5 15.17 22.37L14.17 20.64C14.11 20.53 14.14 20.39 14.23 20.32L15.29 19.5C15.27 19.33 15.25 19.17 15.25 19S15.27 18.67 15.29 18.5L14.23 17.68C14.14 17.61 14.11 17.5 14.17 17.36L15.17 15.64C15.23 15.53 15.37 15.5 15.5 15.53L16.72 16C17 15.83 17.25 15.66 17.57 15.54L17.75 14.21C17.77 14.09 17.88 14 18 14H20C20.13 14 20.23 14.09 20.25 14.21L20.44 15.54C20.75 15.66 21 15.83 21.28 16L22.53 15.53C22.64 15.5 22.77 15.53 22.83 15.64L23.83 17.36C23.89 17.5 23.87 17.61 23.77 17.68L22.72 18.5C22.74 18.67 22.75 18.84 22.75 19S22.74 19.33 22.72 19.5L23.77 20.32M20.75 19C20.75 18.03 19.97 17.25 19 17.25S17.25 18.03 17.25 19 18.04 20.75 19 20.75 20.75 19.97 20.75 19Z" /></svg>
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Notifications</ListItemText>
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          button
          disabled={isReadOnly && this.state.userData.Role != 0}
          onClick={() => this.setState({ isAdministrationMenuOpen: !this.state.isAdministrationMenuOpen })}
        >
          <Tooltip title="Administration" placement="right">
            <ListItemIcon>
              <WrenchOutline />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Administration</ListItemText>
          {this.state.isAdministrationMenuOpen ? <ChevronUp /> : <ChevronDown />}
        </ListItem>
        <Collapse in={this.state.isAdministrationMenuOpen} timeout="auto" unmountOnExit>
          <List disablePadding className="nested-custom-side-menu" dense={true}>
            <ListItem
              button
              component={Link}
              to="/access-management"
              selected={selectedIndex === 7}
              onClick={() => this.handleListItemClick(7)}
            >
              <Tooltip title="Access Management" placement="right">
                <ListItemIcon>
                  <AccountCog />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Access Management</ListItemText>
            </ListItem>

            {/* <ListItem
              button
              component={Link}
              to="/rules"
              selected={selectedIndex === 17}
              onClick={() => this.handleListItemClick(17)}
            >
              <Tooltip title="Rules" placement="right">
                <ListItemIcon>
                  <BookLock />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Rules</ListItemText>
            </ListItem> */}
            <ListItem
              button
              component={Link}
              to="/about"
              selected={selectedIndex === 21}
              onClick={() => this.handleListItemClick(21)}
            >
              <Tooltip title="About" placement="right">
                <ListItemIcon>
                  <InformationOutline />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>About</ListItemText>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/logins-history"
              selected={selectedIndex === 29}
              onClick={() => this.handleListItemClick(29)}
            >
              <Tooltip title="Login/Logout Activity" placement="right">
                <ListItemIcon>
                  <ClockCheckOutline />
                </ListItemIcon>
              </Tooltip>
              <ListItemText>Login/Logout Activity</ListItemText>
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={async () => {
          await setLoginHistory({
            hostAddress: this.props.userIP,
            type: "logout",
            logoutType: "manual"
          })
          removeToken();
          this.props.history.push("/");
        }}>
          <Tooltip title="Logout" placement="right">
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Logout</ListItemText>
        </ListItem>




        {/* <ListItem
          button
          component={Link}
          to="/management-dashboard"
          selected={selectedIndex === 16}
          onClick={() => this.handleListItemClick(16)}
        >
          <Tooltip title="Management Dashboard" placement="right">
            <ListItemIcon>
              <ViewDashboard />
            </ListItemIcon>
          </Tooltip>
          <ListItemText className="">Management Dashboard</ListItemText>
        </ListItem> */}





        {/* <ListItem
          button
          component={Link}
          to="/court-submissions"
          selected={selectedIndex === 3}
          onClick={() => this.handleListItemClick(3)}
        >
          <Tooltip title="Court Submissions" placement="right">
            <ListItemIcon>
              <Gavel />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Court Submissions</ListItemText>
        </ListItem> */}
        {/* <ListItem
          button
          component={Link}
          to="/business-rules"
          selected={selectedIndex === 4}
          onClick={() => this.handleListItemClick(4)}
        >
          <Tooltip title="Business Rules" placement="right">
            <ListItemIcon>
              <Handshake />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Business Rules</ListItemText>
        </ListItem> */}
        {/* <ListItem
          button
          component={Link}
          to="/access-management"
          selected={selectedIndex === 7}
          onClick={() => this.handleListItemClick(7)}
        >
          <Tooltip title="Access Management" placement="right">
            <ListItemIcon>
              <AccountCog />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Access Management</ListItemText>
        </ListItem> */}



        {/* <ListItem
          button
          component={Link}
          to="/resource-management"
          selected={selectedIndex === 11}
          onClick={() => this.handleListItemClick(11)}
        >
          <Tooltip title="Resource Management" placement="right">
            <ListItemIcon>
              <AccountBox />
            </ListItemIcon>
          </Tooltip>
          <ListItemText>Resource Management</ListItemText>
        </ListItem> */}


      </List>
    );
  }
}

export default withRouter(withToastContext(withIsReadOnlyContext(SideMenu)));
