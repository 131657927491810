import {
  Button,
  Grid,
  Hidden,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Checkbox,
  Tooltip,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import {
  IFilter,
  IFirmDetails,
  ITimesheets,
  IKeyValue,
  IPerson,
  ISubmitCourt,
  ICustomDialogData,
  ITimeFilter,
  IConfirmDialog,
} from "../../vm";
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronLeft,
  ChevronRight,
  OpenInNew,
  Pencil,
} from "mdi-material-ui";
import { useHistory, useLocation } from "react-router-dom";
import Filter from "../common/Filter";
import { ToastContext } from "../common/ToastProvider";
import {
  getTimesheetPersons,
  getTimeSheetToSubmitCourt,
  getTimesheetFiltersCount,
  getAllProfessionalLevels,
  getAllCategories,
  submitTimeSheetEntriesCourt,
  generateExportForSubmitForCourt,
  getAllWorkTypes,
  getAllApprovedListForTimeEntry
} from "../../services/TimesheetService";
import Loading from "../common/Loading";
import { FirmContext } from "../common/FirmProvider";
import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import {
  getToken,
  StyledTableCell,
  StyledTableRow,
  numberFormat,
  currencyFormat,
  getFileName,
  getSubDomain,
  hoursFormat,
  getCeilValueForTotalCount,
  removeNulls,
} from "../../services/UtilService";
import {
  API_URL,
  DESCRIPTION_FILTER_ENTRY,
  GRANTED_NOT_GRANTED_STATUS,
  styles,
  TIMESHEET_ALL_STATUS,
  TIMESHEET_STATUS,
  TIME_ENTRY_KEY_WORDS,
  MAX_PAGINATION_OPTIONS,
} from "../../Constant";
import CustomDrawer from "../common/CustomDrawer";
import moment from "moment";
import LogDialog from "./../timesheets/LogDialog";
import ExportDialog from "./../common/ExportDialog";
import ToggleFilterSection from "../dashboard/ToggleFilter";
import { getAllNotes } from "../../services/NoteService";
import { IsReadOnlyContext } from "../common/IsReadOnlyProvider";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";

export interface SubmitToCourtMainProps extends IConfirmDialog { }

const SubmitToCourtMain: React.FC<SubmitToCourtMainProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  let searchObj: any = {};
  if (location.state) {
    let searchState = { ...(location.state as any) };
    if (searchState.status) {
      searchObj.statuses = [searchState.status];
    }
    // if (searchState.professionalLevel) {
    //   searchObj.professionalLevel = [searchState.professionalLevel];
    // }
    // if (searchState.categoryCode) {
    //   searchObj.categoryCode = [searchState.categoryCode];
    // }
    if (searchState.startDate) {
      searchObj.startDate = searchState.startDate;
    }
    if (searchState.endDate) {
      searchObj.endDate = searchState.endDate;
    }
    if (searchState.selectedFirm?.length > 0) {
      searchObj.firms = searchState.selectedFirm;
    }
    if (searchState.professionalLevel?.length > 0) {
      searchObj.professionalLevel = searchState.professionalLevel;
    }
    if (searchState.categoryCode?.length > 0) {
      searchObj.categoryCode = searchState.categoryCode;
    }
    if (searchState.workType?.length > 0) {
      searchObj.workType = searchState.workType;
    }
    if (searchState.keyword?.length > 0) {
      searchObj.keyword = searchState.keyword;
    }
    if (searchState.min) {
      searchObj.min = searchState.min;
    }
    if (searchState.max) {
      searchObj.max = searchState.max;
    }
    if (searchState.minWordCount) {
      searchObj.minWordCount = searchState.minWordCount;
    }
    if (searchState.maxWordCount) {
      searchObj.maxWordCount = searchState.maxWordCount;
    }
    if (
      searchState.descriptionSearchFilters &&
      searchState.descriptionSearchFilters.length > 0
    ) {
      searchObj.descriptionSearchFilters = searchState.descriptionSearchFilters;
    }
    if (searchState.showDisapproved) {
      searchObj.showDisapproved = searchState.showDisapproved;
    }
    if (searchState.persons && searchState.persons.length > 0) {
      searchObj.persons = searchState.persons;
    }
    if (searchState.approvedBy && searchState.approvedBy.length > 0) {
      searchObj.approvedBy = searchState.approvedBy;
    }
    if (searchState.showOnlyDisapproved) {
      searchObj.showOnlyDisapproved = searchState.showOnlyDisapproved;
    }
    if (searchState.noteId) {
      searchObj.noteId = searchState.noteId;
    }
  }
  const [loadedFilter, setLoadedFilter] = React.useState(
    undefined as ITimeFilter
  );
  const [state, setState] = React.useState({
    timesheetsList: [],
    selectedTimeSheetsList: [],
    unselectedTimeSheetsList: [],
    nonSubmittedOrGrantedCounts: 0,
    search: {
      from: 0,
      size: 10,
      statuses: searchObj?.statuses ? searchObj.statuses : [],
      firms: searchObj.firms ? searchObj.firms : [],
      persons: [],
      // startDate: searchObj.startDate
      //   ? searchObj.startDate
      //   : moment("2017-01-01", "YYYY-MM-DD").subtract(1, "year").toDate(),
      // endDate: searchObj.endDate ? searchObj.endDate : moment().toDate(),
      startDate: searchObj.startDate ? searchObj.startDate : undefined,
      endDate: searchObj.endDate ? searchObj.endDate : undefined,
      sortBy: "",
      sortOrder: "asc",
      keyword: searchObj?.keyword || [],
      min: searchObj.min || "",
      max: searchObj.max || "",
      minWordCount: searchObj.minWordCount || "",
      maxWordCount: searchObj.maxWordCount || "",
      descriptionSearchFilters: searchObj.descriptionSearchFilters || [],
      showDisapproved: searchObj.showDisapproved || false,
      categoryCode: searchObj?.categoryCode ? searchObj.categoryCode : [],
      workType: searchObj?.workType ? searchObj.workType : [],
      professionalLevel: searchObj?.professionalLevel
        ? searchObj.professionalLevel
        : [],
      createSeparateCSVForFirm: true,
      includeChangeLog: false,
      showMultipleBillingRates: false,
      approvedBy: searchObj?.approvedBy ? searchObj.approvedBy : [],
      showOnlyDisapproved: searchObj.showOnlyDisapproved || false,
      noteId: searchObj.noteId || "",
    },
    viewLogsDialog: {
      isOpen: false,
      index: undefined,
      data: undefined,
    },
    hasAllSelectionChanged: false,
    isAllSelected: false,
    timesheetCount: 0,
    filterData: [],
    rowsList: [10],
  } as {
    search: {
      from: number;
      size: number;
      firms: string[];
      persons: string[];
      statuses: string[];
      startDate?: Date;
      endDate?: Date;
      sortBy?:
        | ""
        | "firm"
        | "person"
        | "new"
        | "reviewed"
        | "adjusted"
        | "attention"
        | "approved"
        | "submitted";
      sortOrder?: "asc" | "desc";
      keyword: string[];
      categoryCode: any;
      workType: any;
      professionalLevel: any;
      min?: any;
      max?: any;
      minWordCount?: any;
      maxWordCount?: any;
      showDisapproved?: boolean;
      descriptionSearchFilters: Array<{ search: string; isExcluded: boolean }>;
      createSeparateCSVForFirm?: boolean;
      includeChangeLog?: boolean;
      showMultipleBillingRates: boolean;
      approvedBy: string[];
      showOnlyDisapproved?: boolean;
      noteId: any; // can be number or string
    };
    timesheetsList: ISubmitCourt[];
    selectedTimeSheetsList: number[];
    unselectedTimeSheetsList: number[];
    nonSubmittedOrGrantedCounts: number;
    timesheetCount: number;
    isAllSelected: boolean;
    hasAllSelectionChanged: boolean;
    filterData: IFilter[];
    rowsList: number[];
    options: { value: string; label: string }[];
    viewLogsDialog: ICustomDialogData;
  });

  const [isExportDialogOpen, setExportDialogOpen] = React.useState(false);
  const [isExportDialogOpenMain, setExportDialogOpenMain] =
    React.useState(false);
  const [isFilterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("");
  const [firmsDic, setFirmsDic] = React.useState({} as IKeyValue<IFirmDetails>);

  const [personsDic, setPersonsDic] = React.useState({} as IKeyValue<IPerson>);
  const { showToast } = React.useContext(ToastContext);
  const firmProvider = React.useContext(FirmContext);
  const [filterData, setFilterData] = React.useState([] as IFilter[]);
  const [initialFirms, setInitialFirms] = React.useState(
    {} as IKeyValue<IFirmDetails>
  );
  const [initialProfessionalLevels, setInitialProfessionalLevels] =
    React.useState({} as IKeyValue<{ label: string; value: string }>);
  const [initialCategoryCodes, setInitialCategoryCodes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialWorkTypes, setInitialWorkTypes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialApprovedBy, setInitialApprovedBy] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [noteList, setNoteList] = React.useState(
    [] as Array<{ label: string; value: string }>
  );
  const [, setProfessionalLevels] = React.useState([]);
  const [, setCategories] = React.useState([]);
  const [keyWordsList] = React.useState(TIME_ENTRY_KEY_WORDS);
  const [isCountsLoaded, setCountsLoaded] = React.useState(false);
  const [filterCountsDic, setFilterCountsDic] = React.useState({});
  const [isFilterOpen, setFilterOpen] = React.useState(true);
  const [lastPageSize, setLastPageSize] = React.useState(
    state.search.size || 10
  );
  const [dateRangePickerMinMaxObj, setDateRangePickerMinMaxObj] =
    React.useState({
      minDate: undefined,
      maxDate: undefined,
    } as {
      minDate?: Date;
      maxDate?: Date;
    });

  const { isReadOnly, updateStatus } = React.useContext(IsReadOnlyContext);

  React.useEffect(() => {
    getAllRecordsFromStarting();
  }, []);

  const getAllRecordsFromStarting = async (search?) => {
    setLoading(true);
    let professionalLevelsDic = await getAllProfessionalLevelsList();
    let categoriesCodeDic = await getAllCategoriesList();
    let workTypesDic = await getAllWorkTypesList();
    let approvedByDic = await getAllApprovedByList();
    let noteList = await getAllNoteList();
    await getUserFirms({
      professionalLevelsDic,
      categoriesCodeDic,
      search,
      workTypesDic,
      approvedByDic,
      noteList,
    });
    setLoading(false);
  };

  const getAllProfessionalLevelsList = async () => {
    const result = await getAllProfessionalLevels();
    let professionalLevelDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return {
            label: x.professionalLevelName,
            value: x.professionalLevelCode,
          };
        });
        professionalLevelDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialProfessionalLevels(professionalLevelDic);
        setProfessionalLevels(data || []);
      }
    } else {
      showToast(
        result.message || "Error while fetching professional levels",
        "error"
      );
    }
    return professionalLevelDic;
  };
  const getAllCategoriesList = async () => {
    const result = await getAllCategories();
    let categoryCodeDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.timeCategoryName, value: x.timeCategoryCode };
        });
        categoryCodeDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialCategoryCodes(categoryCodeDic);
        setCategories(data || []);
      }
    } else {
      showToast(result.message || "Error while fetching categories", "error");
    }
    return categoryCodeDic;
  };

  const getAllWorkTypesList = async () => {
    const result = await getAllWorkTypes();
    let workTypesDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.workTypeLongDescription, value: x.workTypeCode };
        });
        workTypesDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialWorkTypes(workTypesDic);
      }
    } else {
      showToast(result.message || "Error while fetching work types", "error");
    }
    return workTypesDic;
  };

  const getAllApprovedByList = async () => {
    const result = await getAllApprovedListForTimeEntry();
    let approvedByDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.timeApprovedByName, value: x.timeApprovedByCode };
        });
        approvedByDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialApprovedBy(approvedByDic);
      }
    } else {
      showToast(result.message || "Error while fetching approved by", "error");
    }
    return approvedByDic;
  };

  const getAllNoteList = async () => {
    const result = await getAllNotes({});
    let noteList = [];
    if (result?.isSuccess) {
      if (result?.data) {
        noteList = result.data.results.map((x) => {
          return { label: x.description, value: x.id };
        });
        setNoteList(noteList);
      }
    } else {
      showToast(result.message || "Error while fetching notes", "error");
    }
    return noteList;
  };

  const getTimesheetFilters = async (
    search?: any,
    firmsDic?: any,
    personsDic?: any,
    changedProperty?: any,
    professionCategoriesData?: any,
    resetSelectAll?: boolean
  ) => {
    let tempSearch = { ...state.search };
    if (search) {
      tempSearch = { ...state.search, ...search };
      if (changedProperty === "load-filters") {
        tempSearch = { ...search, from: 0, size: tempSearch.size };
      }
    }
    if (!tempSearch.endDate) {
      delete tempSearch.endDate;
    }
    if (!tempSearch.startDate) {
      delete tempSearch.startDate;
    }
    let initialProfessionalLevelTemp = { ...initialProfessionalLevels };
    if (professionCategoriesData?.professionalLevelsDic) {
      initialProfessionalLevelTemp = {
        ...initialProfessionalLevels,
        ...professionCategoriesData.professionalLevelsDic,
      };
    }
    let initialCategoryCodeTemp = { ...initialCategoryCodes };
    if (professionCategoriesData?.categoriesCodeDic) {
      initialCategoryCodeTemp = {
        ...initialCategoryCodes,
        ...professionCategoriesData.categoriesCodeDic,
      };
    }
    let initialWorkTypesTemp = { ...initialWorkTypes };
    if (professionCategoriesData?.workTypesDic) {
      initialWorkTypesTemp = {
        ...initialWorkTypes,
        ...professionCategoriesData.workTypesDic,
      };
    }
    let initialApprovedByTemp = { ...initialApprovedBy };
    if (professionCategoriesData?.approvedByDic) {
      initialApprovedByTemp = {
        ...initialApprovedBy,
        ...professionCategoriesData.approvedByDic,
      };
    }
    let initialNoteListTemp = [...noteList];
    if (professionCategoriesData?.noteList) {
      initialNoteListTemp = [
        ...initialNoteListTemp,
        ...professionCategoriesData.noteList,
      ];
    }
    let searchForFilter = { ...tempSearch };
    let keyWords = [...keyWordsList];
    // if (changedProperty) {
    //   switch (changedProperty) {
    //     case "firmCounts":
    //       searchForFilter.statuses = [];
    //       break;
    //     case "statusCounts":
    //       searchForFilter.firms = [];
    //       break;
    //     default:
    //       break;
    //   }
    // }
    delete tempSearch.size;
    delete tempSearch.from;
    setLoading(true);
    let result = {
      isSuccess: true,
      success: true,
      message: "",
      data: {
        firmCounts: {},
        statusCounts: {},
        professionalCounts: {},
        categoryCodeCounts: {},
        workTypeCounts: {},
        keywordCounts: {},
        personCounts: {},
        approvedByCounts: {},
      } as any,
    };
    if (isCountsLoaded === false || changedProperty == "clear-filters") {
      result = await getTimesheetFiltersCount(searchForFilter);
    }
    setLoading(false);
    if (result?.isSuccess) {
      console.log("result", result.data);
      let data = { ...result.data };
      let filterCountDic = filterCountsDic;
      if (isCountsLoaded === false || changedProperty == "clear-filters") {
        let keyParams = {
          categoryCodeCounts: "categoryCode",
          workTypeCounts: "workType",
          firmCounts: "firmCode",
          keywordCounts: "keyword",
          statusCounts: "status",
          professionalCounts: "professionalLevel",
          personCounts: "personId",
          approvedByCounts: "timeApprovedByCode",
        };
        filterCountDic = Object.keys(result.data).reduce(
          (acc, key) => {
            if (acc[key]) {
              result.data[key].forEach((data) => {
                acc[key][data[keyParams[key]]] = data;
              });
            }
            return acc;
          },
          {
            categoryCodeCounts: {},
            workTypeCounts: {},
            firmCounts: {},
            keywordCounts: {},
            statusCounts: {},
            professionalCounts: {},
            personCounts: {},
            approvedByCounts: {},
          }
        );
        if (result.data?.startDate) {
          tempSearch.startDate = result.data.startDate;
        }
        if (result.data?.endDate) {
          tempSearch.endDate = result.data.endDate;
        }
        setFilterCountsDic(filterCountDic);
      }
      // updating state as it will use as min, max for date range picker
      setDateRangePickerMinMaxObj({
        minDate: data.startDate || dateRangePickerMinMaxObj.minDate,
        maxDate: data.endDate || dateRangePickerMinMaxObj.maxDate,
      });

      if (!tempSearch.startDate) {
        tempSearch.startDate = data?.startDate || tempSearch.startDate;
      }
      if (!tempSearch.endDate) {
        tempSearch.endDate = data?.endDate || tempSearch.endDate;
      }
      if (!tempSearch.max) {
        tempSearch.max = data?.maxTimeSpentHours;
      }
      let filter: IFilter[] = [];
      let initialFirmsTemp = { ...initialFirms };
      if (firmsDic) {
        initialFirmsTemp = {
          ...initialFirmsTemp,
          ...firmsDic,
        };
      }
      // let initialProfessionalLevelTemp = { ...initialProfessionalLevels };
      // let initialCategoryCodeTemp = { ...initialCategoryCodes };
      if (
        Object.keys(initialFirmsTemp).length === 0 ||
        professionCategoriesData?.search
      ) {
        let firmCounts = data["firmCounts"] || [];
        initialFirmsTemp = firmCounts.reduce((acc: any, ele) => {
          acc[ele.firmCode] = firmsDic[ele.firmCode] || {};
          return acc;
        }, {});
      }

      let filterOrder = [
        "descriptionSearchFilters",
        "workTypeCounts",
        "time",
        "statusCounts",
        "showDisapproved",
        "showOnlyDisapproved",
        "firmCounts",
        "personCounts",
        "professionalCounts",
        "categoryCodeCounts",
        "approvedBy",
        "noteId",
        "keyword",
        "min-max",
        "word-counts",
      ];
      let customFilters = [
        "time",
        "keyword",
        "min-max",
        "descriptionSearchFilters",
        "word-counts",
        "showDisapproved",
        "showOnlyDisapproved",
      ];
      filterOrder.forEach((filterType) => {
        if (customFilters.indexOf(filterType) > -1) {
          switch (filterType) {
            case "time":
              filter.push({
                header: "Time Period",
                name: "time",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "time")?.isHidden) ||
                  false,
                items: [
                  {
                    type: "date-range",
                    value: [
                      {
                        startDate: tempSearch.startDate,
                        endDate: tempSearch.endDate,
                        key: "selection",
                      },
                    ],
                    label: "",
                  },
                ],
                // items: [
                //   {
                //     value: tempSearch.startDate,
                //     type: "date",
                //     label: "Start Date",
                //     name: "startDate",
                //   },
                //   {
                //     value: tempSearch.endDate,
                //     type: "date",
                //     label: "End Date",
                //     name: "endDate",
                //   },
                // ],
              });
              break;
            case "keyword":
              filter.push({
                header: "Keywords",
                name: "keyword",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "keyword")?.isHidden) ||
                  false,
                items: keyWords.map((ele) => {
                  return {
                    isSelected: tempSearch.keyword
                      ? tempSearch.keyword.includes(ele.value)
                      : false,
                    label: ele.label,
                    type: "checkbox",
                    name: ele.value,
                    // count:
                    //   changedProperty !== undefined &&
                    //   changedProperty === ele.label &&
                    //   tempSearch.statuses.length > 0
                    //     ? filterData
                    //         .find((ed) => ed.name === 'professionalLevel')
                    //         ?.items.find((ed) => ed.name === ele.label)?.count || 0
                    //     : statusCounts.find((e) => e.status === eds)?.count || 0,
                  };
                }),
              });
              break;
            case "min-max":
              filter.push({
                header: "Total Time Spent for Day",
                name: "min-max",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "min-max")?.isHidden) ||
                  false,
                items: [
                  {
                    label: "Min",
                    type: "number",
                    name: "min",
                    value: tempSearch.min,
                  },
                  {
                    label: "Max",
                    type: "number",
                    name: "max",
                    value: tempSearch.max,
                  },
                ],
              });
              break;
            case "descriptionSearchFilters":
              filter.push({
                header: "Description Keyword Search",
                name: "descriptionSearchFilters",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find(
                      (e) => e.name === "descriptionSearchFilters"
                    ).isHidden) ||
                  false,
                items:
                  tempSearch.descriptionSearchFilters.length > 0
                    ? tempSearch.descriptionSearchFilters.map((d) => {
                        let itemObj = {
                          type: "text-checkbox",
                          value: d.search,
                          isSelected: d.isExcluded,
                          name: "description",
                          label: "",
                        };
                        return itemObj;
                      })
                    : [{ ...DESCRIPTION_FILTER_ENTRY }],
              });
              break;
            case "word-counts":
              filter.push({
                header: "Word Count",
                name: "word-counts",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "word-counts")
                      ?.isHidden) ||
                  false,
                items: [
                  {
                    label: "Min Words",
                    type: "number",
                    name: "minWordCount",
                    value: tempSearch.minWordCount,
                  },
                  {
                    label: "Max Words",
                    type: "number",
                    name: "maxWordCount",
                    value: tempSearch.maxWordCount,
                  },
                ],
              });
              break;
            case "showDisapproved":
              filter.push({
                className: "disapproved-section",
                header: "",
                name: "showDisapproved",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "showDisapproved")
                      ?.isHidden) ||
                  false,
                items: [
                  {
                    isSelected: tempSearch.showDisapproved,
                    label: "Show Disapproved",
                    type: "switch",
                    name: "showDisapproved",
                    isDisabled: tempSearch.showOnlyDisapproved,
                  },
                ],
              });
              break;
            case "showOnlyDisapproved":
              filter.push({
                className: "only-disapproved-section",
                header: "",
                name: "showOnlyDisapproved",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "showOnlyDisapproved")
                      ?.isHidden) ||
                  false,
                items: [
                  {
                    isSelected: tempSearch.showOnlyDisapproved,
                    label: "Show Only Disapproved",
                    type: "switch",
                    name: "showOnlyDisapproved",
                  },
                ],
              });
              break;
            default:
              break;
          }
        } else {
          let ele = filterType;
          let a: IFilter = { header: "", items: [], name: ele };
          switch (ele) {
            case "statusCounts":
              a.header = "Status";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let statusCounts = data["statusCounts"] || [];
              Object.keys(TIMESHEET_STATUS).forEach((eds) => {
                a.items.push({
                  isSelected: tempSearch.statuses
                    ? tempSearch.statuses.includes(eds)
                    : false,
                  label: TIMESHEET_STATUS[eds],
                  type: "checkbox",
                  name: eds,
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.statuses.length > 0
                        ? filterData
                            .find((ed) => ed.name === ele)
                            ?.items.find((ed) => ed.name === eds)?.count || 0
                        : statusCounts.find((e) => e.status === eds)?.count || 0
                      : filterCountDic?.["statusCounts"]?.[eds]?.count || 0,
                  isDisabled: tempSearch.showOnlyDisapproved,
                });
              });
              break;
            case "firmCounts":
              a.header = "Firm";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let firmCounts = data["firmCounts"] || [];
              Object.keys(initialFirmsTemp).forEach((element: any) => {
                a.items.push({
                  label: firmsDic[element]
                    ? firmsDic[element].lawFirmShortName
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.firms.length > 0
                        ? filterData
                            .find((ed) => ed.name === ele)
                            ?.items.find((ed) => ed.name === String(element))
                            ?.count || 0
                        : firmCounts.find((e) => e.firmCode === element)
                            ?.count || 0
                      : filterCountDic?.["firmCounts"]?.[element]?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.firms
                    ? tempSearch.firms.includes(element)
                    : false,
                });
              });
              // sorting based on label i.e. firm short name
              a.items.sort((a, b) => (a.label > b.label ? 1 : -1));
              break;
            case "personCounts":
              a.header = "";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let personsCount: Array<any> = filterCountDic?.["personCounts"]
                ? Object.values(filterCountDic?.["personCounts"])
                : [];
              let options = [];
              let selectedItems = [];
              personsCount.forEach((ele) => {
                if (
                  tempSearch.persons.length > 0 &&
                  tempSearch.persons.includes(ele.personId)
                ) {
                  selectedItems.push({
                    value: ele.personId,
                    label: personsDic[ele.personId]?.name,
                  });
                }
                options.push({
                  value: ele.personId,
                  label: personsDic[ele.personId]?.name,
                });
              });
              a.items = [
                {
                  label: "Resources",
                  type: "dropdown",
                  name: ele,
                  selectedItems: selectedItems || [],
                  options:
                    changedProperty !== undefined &&
                    changedProperty === ele &&
                    tempSearch.persons.length > 0
                      ? filterData.find((ed) => ed.name === ele)?.items[0]
                          .options
                      : options,
                },
              ];
              break;
            case "professionalCounts":
              a.header = "Professional Level";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let professionalCounts = data["professionalCounts"] || [];
              Object.keys(initialProfessionalLevelTemp).forEach((element) => {
                a.items.push({
                  label: professionCategoriesData.professionalLevelsDic[element]
                    ? professionCategoriesData.professionalLevelsDic[element]
                        .label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.professionalLevel.length > 0
                        ? filterData
                            .find((ed) => ed.name === ele)
                            ?.items.find((ed) => ed.name === element)?.count ||
                          0
                        : professionalCounts.find(
                            (e) => e.professionalLevel === element
                          )?.count || 0
                      : filterCountDic?.["professionalCounts"]?.[element]
                          ?.count || 0,
                  name: element,
                  isSelected: tempSearch.professionalLevel
                    ? tempSearch.professionalLevel.includes(element)
                    : false,
                });
              });
              break;
            case "workTypeCounts":
              a.header = "Work Types";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let workTypeCounts = data["workTypeCounts"] || [];
              Object.keys(initialWorkTypesTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.workTypesDic[element]
                    ? professionCategoriesData.workTypesDic[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.workType.length > 0
                        ? filterData
                            .find((ed) => ed.name === ele)
                            ?.items.find((ed) => ed.name === String(element))
                            ?.count || 0
                        : workTypeCounts.find((e) => e.workType === element)
                            ?.count || 0
                      : filterCountDic?.["workTypeCounts"]?.[element]?.count ||
                        0,
                  name: String(element),
                  isSelected: tempSearch.workType
                    ? tempSearch.workType.includes(element)
                    : false,
                });
              });
              break;
            case "categoryCodeCounts":
              a.header = "Time Category";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let categoryCodeCounts = data["categoryCodeCounts"] || [];
              Object.keys(initialCategoryCodeTemp)
                .sort((a, b) =>
                  initialCategoryCodeTemp[a].label >
                  initialCategoryCodeTemp[b].label
                    ? 1
                    : -1
                )
                .forEach((element) => {
                  a.items.push({
                    label: professionCategoriesData.categoriesCodeDic[element]
                      ? professionCategoriesData.categoriesCodeDic[element]
                          .label
                      : "",
                    type: "checkbox",
                    count:
                      isCountsLoaded === false
                        ? changedProperty !== undefined &&
                          changedProperty === ele &&
                          tempSearch.categoryCode.length > 0
                          ? filterData
                              .find((ed) => ed.name === ele)
                              ?.items.find((ed) => ed.name === String(element))
                              ?.count || 0
                          : categoryCodeCounts.find(
                              (e) => e.categoryCode === element
                            )?.count || 0
                        : filterCountDic?.["categoryCodeCounts"]?.[element]
                            ?.count || 0,
                    name: String(element),
                    isSelected: tempSearch.categoryCode
                      ? tempSearch.categoryCode.includes(element)
                      : false,
                  });
                });
              break;
            case "approvedBy":
              a.header = "Approved By";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let approvedByCounts = data["approvedByCounts"] || [];
              Object.keys(initialApprovedByTemp).map((element) => {
                a.items.push({
                  label: initialApprovedByTemp[element]
                    ? initialApprovedByTemp[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.approvedBy.length > 0
                        ? filterData
                            .find((ed) => ed.name === ele)
                            ?.items.find((ed) => ed.name === String(element))
                            ?.count || 0
                        : approvedByCounts.find((e) => e.approvedBy === element)
                            ?.count || 0
                      : filterCountDic?.["approvedByCounts"]?.[element]
                          ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.approvedBy
                    ? tempSearch.approvedBy.includes(element)
                    : false,
                });
              });
              break;
            case "noteId":
              a.header = "";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let noteOptions = [];
              let selectedNoteItems = [];
              initialNoteListTemp.map((ele) => {
                if (tempSearch.noteId === ele.value) {
                  selectedNoteItems.push(ele);
                }
                noteOptions.push(ele);
              });
              a.items = [
                {
                  label: "Notes",
                  type: "dropdown",
                  name: ele,
                  selectedItems: selectedNoteItems || [],
                  options:
                    changedProperty !== undefined &&
                    changedProperty === ele &&
                    tempSearch.noteId
                      ? filterData.find((ed) => ed.name === ele)?.items[0]
                          .options
                      : noteOptions,
                  isMulti: false,
                },
              ];
              break;
            default:
              a = undefined;
              break;
          }
          if (a) {
            filter.push(a);
          }
        }
      });

      // filter.push({
      //   header: "",
      //   name: "multiple-billings",
      //   isHidden: false,
      //   items: [
      //     {
      //       isSelected: tempSearch.showMultipleBillingRates,
      //       label: "Show Multiple Bill Rates",
      //       type: "checkbox",
      //       name: "showMultipleBillingRates",
      //     },
      //   ],
      // });

      // filter.push({
      //   header: "",
      //   name: "extra-filters",
      //   isHidden: false,
      //   items: [
      //     {
      //       isSelected: tempSearch.createSeparateCSVForFirm,
      //       label: "Also create separate CSVs for each firm",
      //       type: "checkbox",
      //       name: "createSeparateCSVForFirm",
      //     },
      //     {
      //       isSelected: tempSearch.includeChangeLog,
      //       label: "Include Change Log",
      //       type: "checkbox",
      //       name: "includeChangeLog",
      //     },
      //   ],
      // });
      setCountsLoaded(true);
      setFilterData(filter);
      setInitialFirms(initialFirmsTemp);
      // setInitialProfessionalLevels(initialProfessionalLevelTemp);
      // setInitialCategoryCodes(initialCategoryCodeTemp);
      if (changedProperty !== "add-descriptions") {
        // tempSearch.from = 0;
        // tempSearch.size = 10;
        await getTimeSheetToSubmitCourtList(
          tempSearch,
          changedProperty,
          filter,
          undefined,
          resetSelectAll
        );
      }
    } else {
      showToast(
        result.message || "Error while fetching timesheets counts",
        "error"
      );
    }
  };

  const getUserFirms = async (professionCategoriesData) => {
    let userFirms = firmProvider.userFirms;
    if (userFirms.length === 0) {
      userFirms = await firmProvider.getFirmsList();
    }
    let firmsDic = userFirms.reduce((acc: any, ele) => {
      acc[ele.lawFirmCode] = ele;
      return acc;
    }, {});
    setFirmsDic(firmsDic);
    await getPersonsList(firmsDic, professionCategoriesData);
  };

  const getPersonsList = async (firmsDic, professionCategoriesData) => {
    let result = await getTimesheetPersons();
    if (result?.isSuccess) {
      if (result.data.length > 0) {
        let personsDic = result.data.reduce((acc: any, ele: any) => {
          acc[ele.resourceName] = {
            personId: ele.resourceName,
            name: (ele.firstName || "") + " " + (ele.lastName || ""),
          };
          return acc;
        }, {});
        setPersonsDic(personsDic);
        await getTimesheetFilters(
          professionCategoriesData?.search || undefined,
          firmsDic,
          personsDic,
          undefined,
          professionCategoriesData
        );
      }
    }
  };

  const getTimeSheetToSubmitCourtList = async (
    search?: any,
    changedProperty?: string,
    filter?: IFilter[],
    isAllSelectedFromRowsPerPage?: boolean,
    resetSelectAll?: boolean
  ) => {
    let tempSearch = { ...state.search };
    if (search) {
      tempSearch = { ...state.search, ...search };
    }
    if (!tempSearch.endDate) {
      delete tempSearch.endDate;
    }
    if (!tempSearch.startDate) {
      delete tempSearch.startDate;
    }
    let searchObj = { ...tempSearch };
    setLoading(true);
    if (
      searchObj.descriptionSearchFilters &&
      searchObj.descriptionSearchFilters.length
    ) {
      searchObj.descriptionSearchFilters =
        searchObj.descriptionSearchFilters.filter((x) => x.search);
    }
    // if size and total data count is same then changing the size to nearest value multiply by 10
    // if (!isAllSelectedFromRowsPerPage && searchObj.size === state.timesheetCount) {
    if (
      !isAllSelectedFromRowsPerPage &&
      searchObj.size === getCeilValueForTotalCount(state.timesheetCount)
    ) {
      // let updatedSize = Math.round(searchObj.size / 10) * 10;
      // if (updatedSize === 0) updatedSize = 10;
      // searchObj.size = updatedSize;
      // tempSearch.size = updatedSize;
      // last saved size from another variable
      searchObj.size = lastPageSize;
      tempSearch.size = lastPageSize;
    }
    // if (isAllSelectedFromRowsPerPage) {
    //   setLastPageSize(state.search.size);
    // }
    const result = await getTimeSheetToSubmitCourt(searchObj);
    if (result?.isSuccess) {
      let menuItems = [];
      for (let index = 10; index <= result.data.count; index = index + 10) {
        menuItems.push(index);
      }
      if (menuItems[menuItems.length - 1] < result.data.count) {
        menuItems.push(getCeilValueForTotalCount(result.data.count));
        // menuItems.push(result.data.count);
      }
      if (menuItems.length > MAX_PAGINATION_OPTIONS) {
        menuItems = menuItems.slice(0, MAX_PAGINATION_OPTIONS);
      }
      // else {
      //   let totalCount = menuItems.pop();
      //   if (totalCount) {
      //     menuItems.unshift({ label: "All", value: totalCount });
      //   }
      // }
      // checking is the size is more than the data count
      if (tempSearch.size > result.data.count) {
        setLastPageSize(tempSearch.size);
        tempSearch.size = getCeilValueForTotalCount(result.data.count) || 10;
        // tempSearch.size = result.data.count || 10;
      }
      if (
        changedProperty === "noteId" &&
        result.data.statuses &&
        tempSearch.noteId
      ) {
        tempSearch.statuses = result.data.statuses.filter(
          (x) => x !== "disapproved"
        );
        let statusIndexInFilter = filter.findIndex(
          (x) => x.name === "statusCounts"
        );
        if (statusIndexInFilter > -1) {
          filter[statusIndexInFilter].items.forEach((item) => {
            item.isSelected = tempSearch.statuses
              ? tempSearch.statuses.includes(item.name)
              : false;
          });
        }
        if (
          result.data.statuses?.includes("disapproved") &&
          !tempSearch.showDisapproved
        ) {
          let showDisapprovedIndexInFilter = filter.findIndex(
            (x) => x.name === "showDisapproved"
          );
          if (showDisapprovedIndexInFilter > -1) {
            filter[showDisapprovedIndexInFilter].items[0].isSelected = result
              .data.statuses
              ? result.data.statuses.includes("disapproved")
              : false;
          }
          tempSearch.showDisapproved = true;
          props.confirmDialog.show(
            "Disapproved",
            "Show Disapproved enabled",
            undefined,
            undefined,
            undefined,
            undefined,
            true
          );
        }
        setFilterData(filter);
      }
      let isAllSelected = state.isAllSelected;
      let selectedTimeSheetsList = [...state.selectedTimeSheetsList];
      let unselectedTimeSheetsList = [...state.unselectedTimeSheetsList];
      let hasAllSelectionChanged = state.hasAllSelectionChanged;
      if (hasAllSelectionChanged) {
        selectedTimeSheetsList = [
          ...selectedTimeSheetsList,
          ...result.data.timesheetViews
            .filter(
              (x) =>
                state.unselectedTimeSheetsList.indexOf(
                  x.timeCommonBenefitId
                ) === -1
            )
            .map((x) => x.timeCommonBenefitId),
        ];
      }
      if (resetSelectAll) {
        isAllSelected = false;
        hasAllSelectionChanged = false;
        selectedTimeSheetsList = [];
        unselectedTimeSheetsList = [];
      }
      setState({
        ...state,
        timesheetCount: result.data.count,
        nonSubmittedOrGrantedCounts: result.data.nonSubmittedOrGrantedCounts,
        timesheetsList: result.data.timesheetViews.map((ele) => {
          ele.isEdit = false;
          if (
            Object.keys(GRANTED_NOT_GRANTED_STATUS).indexOf(ele.status) === -1
          ) {
            if (
              hasAllSelectionChanged &&
              unselectedTimeSheetsList.indexOf(ele.timeCommonBenefitId) === -1
            ) {
              ele.isSelected = true;
            }
            if (selectedTimeSheetsList.indexOf(ele.timeCommonBenefitId) > -1) {
              ele.isSelected = true;
            }
          }
          return ele;
        }),
        rowsList: [...menuItems],
        search: { ...state.search, ...tempSearch },
        isAllSelected,
        hasAllSelectionChanged,
        selectedTimeSheetsList,
        unselectedTimeSheetsList,
      });
    } else {
      showToast(
        result?.message ? result.message : "Error while fetching timesheets",
        "error"
      );
    }
    setLoading(false);
  };

  const onSearch = async (
    searchObj?: any,
    isAllSelectedFromRowsPerPage?: boolean
  ) => {
    let tempSearch = { ...state.search };
    if (searchObj) {
      tempSearch = { ...state.search, ...searchObj };
    }
    await getTimeSheetToSubmitCourtList(
      tempSearch,
      undefined,
      undefined,
      isAllSelectedFromRowsPerPage
    );
  };

  const isTimesheetSelected = () => {
    if (state.isAllSelected && state.nonSubmittedOrGrantedCounts > 0) {
      return true;
    } else {
      let selected = false;
      state.timesheetsList.forEach((ele) => {
        if (ele.isSelected === true) {
          selected = true;
        }
      });
      return selected;
    }
  };

  const onFilterChange = async (data: IFilter[], changedProperty?: string) => {
    if (changedProperty === "clear-filters") {
      setLoadedFilter(undefined);
    }
    if (changedProperty === "load-filters") {
      let details = JSON.parse(JSON.stringify(data));
      setLoadedFilter(details);
      await getTimesheetFilters(
        details.filters,
        firmsDic,
        personsDic,
        changedProperty,
        {
          professionalLevelsDic: { ...initialProfessionalLevels },
          categoriesCodeDic: { ...initialCategoryCodes },
          workTypesDic: { ...initialWorkTypes },
        },
        true
      );
    } else {
      let search = { ...state.search };
      search.from = 0;
      let statusArray =
        data.find((ele) => ele.name === "statusCounts")?.items || [];
      let professionalLevelArray =
        data.find((ele) => ele.name === "professionalCounts")?.items || [];
      let categoryCodeArray =
        data.find((ele) => ele.name === "categoryCodeCounts")?.items || [];
      let workTypesArray =
        data.find((ele) => ele.name === "workTypeCounts")?.items || [];
      let personArray =
        data.find((ele) => ele.name === "personCounts")?.items || [];
      let noteArray = data.find((ele) => ele.name === "noteId")?.items || [];
      let firmArray =
        data.find((ele) => ele.name === "firmCounts")?.items || [];
      let approvedByArray =
        data.find((ele) => ele.name === "approvedBy")?.items || [];
      let timeArray = data.find((ele) => ele.name === "time")?.items || [];
      let minMaxArray = data.find((ele) => ele.name === "min-max")?.items || [];
      let extraFilters =
        data.find((ele) => ele.name === "extra-filters")?.items || [];
      let multipleBillingFilters =
        data.find((ele) => ele.name === "multiple-billings")?.items || [];
      search.min = minMaxArray.find((ele) => ele.name === "min")?.value || "";
      search.max = minMaxArray.find((ele) => ele.name === "max")?.value || "";
      let minMaxWordCountArray =
        data.find((ele) => ele.name === "word-counts")?.items || [];
      search.minWordCount =
        minMaxWordCountArray.find((ele) => ele.name === "minWordCount")
          ?.value || "";
      search.maxWordCount =
        minMaxWordCountArray.find((ele) => ele.name === "maxWordCount")
          ?.value || "";
      let descriptionArray =
        data.find((ele) => ele.name === "descriptionSearchFilters")?.items ||
        [];
      search.descriptionSearchFilters = descriptionArray
        // .filter((ele) => ele.value)
        .map((ele) => {
          let d = {
            search: ele.value || "",
            isExcluded: ele.isSelected,
          };
          return d;
        });
      let disapprovedStatus =
        data.find((ele) => ele.name === "showDisapproved")?.items || [];
      search.showDisapproved = disapprovedStatus.find(
        (ele) => ele.name === "showDisapproved"
      ).isSelected;
      let onlyDisapprovedStatus =
        data.find((ele) => ele.name === "showOnlyDisapproved")?.items || [];
      search.showOnlyDisapproved = onlyDisapprovedStatus.find(
        (ele) => ele.name === "showOnlyDisapproved"
      ).isSelected;
      if (search.min && search.max && search.min > search.max) {
        showToast("Max value cannot be less than min value", "error");
        return;
      }
      if (
        search.minWordCount &&
        search.maxWordCount &&
        search.minWordCount > search.maxWordCount
      ) {
        showToast(
          "Max word count value cannot be less than min word count value",
          "error"
        );
        return;
      }
      search.startDate = timeArray[0].value[0].startDate || undefined;
      // timeArray.find((ele) => ele.name === "startDate")?.value || undefined;
      search.endDate = timeArray[0].value[0].endDate || undefined;
      // timeArray.find((ele) => ele.name === "endDate")?.value || undefined;
      search.statuses = statusArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      // if show 'only dis approved' is true we need to make status array empty and 'dis approved' false
      if (search.showOnlyDisapproved) {
        search.showDisapproved = false;
        search.statuses = [];
      }
      search.persons =
        personArray.length > 0
          ? personArray[0].selectedItems.length > 0
            ? personArray[0].selectedItems.map((ele) => ele.value)
            : []
          : [];
      search.firms = firmArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          if (e.name) {
            return e.name;
          }
          return undefined;
        })
        .filter((x) => x !== undefined);
      search.professionalLevel = professionalLevelArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.categoryCode = categoryCodeArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.workType = workTypesArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      let keywordsArray =
        data.find((ele) => ele.name === "keyword")?.items || [];
      search.keyword = keywordsArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.approvedBy = approvedByArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.noteId =
        noteArray.length > 0
          ? noteArray[0].selectedItems.length > 0
            ? noteArray[0].selectedItems[0].value
            : ""
          : "";
      if (extraFilters.length > 0) {
        search.createSeparateCSVForFirm = extraFilters[0].isSelected;
        search.includeChangeLog = extraFilters[1].isSelected;
      }
      if (multipleBillingFilters?.length > 0) {
        search.showMultipleBillingRates = multipleBillingFilters[0].isSelected;
      }
      if (changedProperty !== "extra-filters") {
        await getTimesheetFilters(
          search,
          firmsDic,
          personsDic,
          changedProperty,
          {
            professionalLevelsDic: { ...initialProfessionalLevels },
            categoriesCodeDic: { ...initialCategoryCodes },
            workTypesDic: { ...initialWorkTypes },
          },
          true
        );
      } else {
        let extraFiltersIndex = filterData.findIndex(
          (x) => x.name === "extra-filters"
        );
        if (extraFiltersIndex >= -1) {
          filterData[extraFiltersIndex].items[0].isSelected =
            search.createSeparateCSVForFirm;
          filterData[extraFiltersIndex].items[1].isSelected =
            search.includeChangeLog;
          setFilterData(filterData);
          setState({
            ...state,
            search: { ...state.search, ...search },
          });
        }
      }
    }
  };

  const setNextTimesheets = (data: any) => {
    let dataToSet = JSON.stringify(data);
    localStorage.setItem("nextTimesheets", dataToSet);
  };

  const viewTimesheet = async (timesheet: ITimesheets, status?: string) => {
    let data = {
      startDate: state.search.startDate,
      endDate: state.search.endDate,
      firmId: timesheet.firmId,
      personId: timesheet.personId,
      status: status ? [status] : state.search.statuses,
      professionalLevel: state.search.professionalLevel,
      categoryCode: state.search.categoryCode,
      keyword: state.search.keyword,
      min: state.search.min,
      max: state.search.max,
      // type: timesheet.timeReport,
    };
    await setNextTimesheets([data]);
    history.push(`/timesheets-summary/timesheet`);
  };

  const handleOpenExportDialog = (type?: string) => {
    if (!state.hasAllSelectionChanged) {
      let selectedEntries: any = [...state.timesheetsList].filter(
        (x) => x.isSelected
      );
      let isEntriesCannotBeReviewedSelected = selectedEntries.find(
        (x) => ["granted", "not-granted", "submitted"].indexOf(x.status) > -1
      );
      if (isEntriesCannotBeReviewedSelected) {
        showToast(
          `These entries cannot be submitted. Only entries with status other than  ${TIMESHEET_STATUS.submitted}, ${TIMESHEET_STATUS.granted} and ${TIMESHEET_STATUS["not-granted"]} can be submitted`,
          "error"
        );
        return;
      }
      let sameWorkTypeIncators = selectedEntries.filter(
        (x) => x.classActionWorkIndicator == "Y"
      );
      if (
        !(
          sameWorkTypeIncators.length == 0 ||
          sameWorkTypeIncators.length == selectedEntries.length
        )
      ) {
        showToast(
          `Class and Common Benefit Time must be submitted to court separately.`,
          "error"
        );
        return;
      }
    }
    setExportDialogOpen(true);
  };

  const handleCloseExportDialog = async (data?: {
    includeChangeLog: boolean;
    exportSeparately: boolean;
    useExcelTemplate: boolean;
  }) => {
    if (data) {
      submitToCourt(
        data?.includeChangeLog,
        data?.exportSeparately,
        data?.useExcelTemplate
      );
    }
    setExportDialogOpen(false);
  };

  const submitToCourt = async (
    includeChangeLog: boolean,
    createSeparateCSVForFirm: boolean,
    useExcelTemplate: boolean
  ) => {
    let selectedTimesheetIds = [...state.timesheetsList].reduce((acc, ele) => {
      if (ele.isSelected) {
        acc.push(ele.timeCommonBenefitId);
      }
      return acc;
    }, []);
    if (selectedTimesheetIds.length === 0) {
      showToast("Please select entries to submit", "error");
      return;
    }
    let selectedEntries: any = [...state.timesheetsList].filter(
      (x) => x.isSelected
    );
    let isEntriesCannotBeReviewedSelected = selectedEntries.find(
      (x) => ["granted", "not-granted", "submitted"].indexOf(x.status) > -1
    );
    if (isEntriesCannotBeReviewedSelected) {
      showToast(
        `These entries cannot be submitted. Only entries with status other than  ${TIMESHEET_STATUS.submitted}, ${TIMESHEET_STATUS.granted} and ${TIMESHEET_STATUS["not-granted"]} can be submitted`,
        "error"
      );
      return;
    }
    setLoading(true);
    let tempSearch = { ...state.search };
    tempSearch = removeNulls(tempSearch);
    let result = await submitTimeSheetEntriesCourt({
      // timesheetIds: selectedTimesheetIds,
      ...tempSearch,
      includeChangeLog: includeChangeLog,
      includeCSVExport: createSeparateCSVForFirm,
      isSelectedAll: state.hasAllSelectionChanged,
      timesheetIds: state.hasAllSelectionChanged
        ? [...state.unselectedTimeSheetsList]
        : [...state.selectedTimeSheetsList],
    });
    if (result.isSuccess) {
      if (result?.isReadOnly) {
        showToast(result.message, "error");
        updateStatus(result.isReadOnly);
      } else {
        showToast("Entries submitted to court successfully", "success");
        if(result?.data){
        let newtimesheetIds = result.data;
        await generateExportForSubmitForCourt(
          state.search,
          // state.hasAllSelectionChanged
          //   ? [...state.unselectedTimeSheetsList]
          //   : [...state.selectedTimeSheetsList],
          [],
          includeChangeLog,
          createSeparateCSVForFirm,
          newtimesheetIds
        );
        // await getTimesheetFilters(state.search, firmsDic, personsDic, undefined, {
        await getTimesheetFilters(
          tempSearch,
          firmsDic,
          personsDic,
          "clear-filters",
          {
            professionalLevelsDic: { ...initialProfessionalLevels },
            categoriesCodeDic: { ...initialCategoryCodes },
            workTypesDic: { ...initialWorkTypes },
          },
          true
        );
        if (useExcelTemplate) {
          let result = await fetch(
            `${API_URL}timesheets/generate-excel/time-submit`,
            {
              method: "POST",
              body: JSON.stringify({
                isSaveOnServer: true,
                // timesheetIds: selectedTimesheetIds,
                // isSelectedAll: state.hasAllSelectionChanged,
                newtimesheetIds:newtimesheetIds,
                timesheetIds:[],
                // timesheetIds: state.hasAllSelectionChanged
                //   ? [...state.unselectedTimeSheetsList]
                //   : [...state.selectedTimeSheetsList],
                includeChangeLog,
                includeCSVExport: createSeparateCSVForFirm,
                timesheetFilters: tempSearch,
                isZip: createSeparateCSVForFirm,
                type: "submitted",
                localTime: moment().format("YYYYMMDDHHmmss"),
              }),
              headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: "Bearer " + (getToken() || ""),
                dbName: getSubDomain(),
              },
            }
          );
          // let pdf = await result.blob();
          // const pdfFile = await new Blob([pdf], { type: "application/pdf" });
          // const pdfFileURL = await URL.createObjectURL(pdfFile);
          // var a: any = document.createElement("a");
          // document.body.appendChild(a);
          // a.style = "display:none";
          // a.href = pdfFileURL;
          // a.download = await getFileName(
          //   "zip",
          //   "submit"
          // );
          // a.click();
        }
      }
      setLoading(false);
    }
    } else {
      setLoading(false);
      showToast(result.message || "Error while submitting to court", "error");
    }
  };

  const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className="d-flex">
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <ChevronDoubleLeft />
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <ChevronRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <ChevronDoubleRight />
        </IconButton>
      </div>
    );
  };

  const onSort = async (sortBy) => {
    let search = { ...state.search };
    if (sortBy === search.sortBy) {
      search.sortOrder = search.sortOrder === "asc" ? "desc" : "asc";
    } else {
      search.sortBy = sortBy;
      search.sortOrder = "asc";
    }
    search.from = 0;
    await getTimeSheetToSubmitCourtList(search);
  };

  const selectAll = () => {
    let timesheetsList = [...state.timesheetsList];
    timesheetsList.map((ele) => {
      if (Object.keys(GRANTED_NOT_GRANTED_STATUS).indexOf(ele.status) === -1) {
        if (state.isAllSelected === false) {
          ele.isSelected = true;
        } else {
          ele.isSelected = false;
        }
      }
      return ele;
    });
    let selectedTimeSheetsList = [];
    if (!state.isAllSelected) {
      selectedTimeSheetsList = state.timesheetsList
        .filter(
          (e) =>
            Object.keys(GRANTED_NOT_GRANTED_STATUS).indexOf(e.status) === -1
        )
        .map((x) => x.timeCommonBenefitId);
    }
    setState({
      ...state,
      timesheetsList: timesheetsList,
      isAllSelected: !state.isAllSelected,
      hasAllSelectionChanged: !state.isAllSelected,
      selectedTimeSheetsList: selectedTimeSheetsList,
      unselectedTimeSheetsList: [],
    });
  };

  const handleSelectChange = (index: number) => {
    let timesheetsList = state.timesheetsList;
    let timesheet = timesheetsList[index];
    timesheet.isSelected = !timesheet.isSelected;
    timesheetsList[index] = timesheet;
    let selectedTimeSheetsList = [...state.selectedTimeSheetsList];
    let unselectedTimeSheetsList = [...state.unselectedTimeSheetsList];
    let isSelectedIndex = selectedTimeSheetsList.indexOf(
      timesheetsList[index].timeCommonBenefitId
    );
    if (timesheet.isSelected) {
      selectedTimeSheetsList.push(timesheetsList[index].timeCommonBenefitId);
      let unSelectedIndex = unselectedTimeSheetsList.indexOf(
        timesheetsList[index].timeCommonBenefitId
      );
      if (unSelectedIndex > -1) {
        unselectedTimeSheetsList.splice(unSelectedIndex, 1);
      }
    } else {
      if (isSelectedIndex > -1) {
        selectedTimeSheetsList.splice(isSelectedIndex, 1);
      }
      let unSelectedIndex = unselectedTimeSheetsList.indexOf(
        timesheetsList[index].timeCommonBenefitId
      );
      if (unSelectedIndex === -1 && state.hasAllSelectionChanged) {
        unselectedTimeSheetsList.push(
          timesheetsList[index].timeCommonBenefitId
        );
      }
    }
    let isAllSelected = state.isAllSelected;
    if (isAllSelected && unselectedTimeSheetsList.length) {
      isAllSelected = false;
    }
    if (state.hasAllSelectionChanged && unselectedTimeSheetsList.length === 0) {
      isAllSelected = true;
    }
    if (selectedTimeSheetsList.length === state.nonSubmittedOrGrantedCounts) {
      isAllSelected = true;
    }
    // if (timesheet.isSelected === false) {
    //   isAllSelected = false;
    // } else {
    //   timesheetsList.forEach((ele) => {
    //     if (
    //       Object.keys(GRANTED_NOT_GRANTED_STATUS).indexOf(ele.status) === -1
    //     ) {
    //       if (!ele.isSelected) {
    //         isAllSelected = false;
    //       }
    //     }
    //   });
    // }
    setState({
      ...state,
      timesheetsList: timesheetsList,
      isAllSelected,
      selectedTimeSheetsList: selectedTimeSheetsList,
      unselectedTimeSheetsList: unselectedTimeSheetsList,
    });
  };

  const handleViewLogsDialog = (isOpen: boolean, editIndex?: number) => {
    let data: any = undefined;
    if (isOpen) {
      let timeEntry = { ...state.timesheetsList[editIndex as number] };
      if (editIndex !== undefined) {
        data = timeEntry.timeCommonBenefitId;
      }
    }
    setState({
      ...state,
      viewLogsDialog: {
        isOpen: isOpen,
        data: data,
        index: editIndex,
      },
    });
  };
  const handleOpenExportDialogMain = () => {
    setExportDialogOpenMain(true);
  };
  const handleCloseExportDialogMain = async (data?: {
    exportType: "csv" | "pdf";
    includeChangeLog: boolean;
    exportSeparately: boolean;
  }) => {
    if (data) {
      if (data.exportType === "csv") {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownloadAsCsv(data?.includeChangeLog);
        setLoadingMessage("");
        setLoading(false);
      } else {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownload(data?.includeChangeLog);
        setLoadingMessage("");
        setLoading(false);
      }
    }
    setExportDialogOpenMain(false);
  };
  const handleDownload = async (includeChangeLog?: boolean) => {
    let searchObj = { ...state.search };
    let selectedTimesheetIds = [...state.timesheetsList].reduce((acc, ele) => {
      if (ele.isSelected) {
        acc.push(ele.timeCommonBenefitId);
      }
      return acc;
    }, []);
    let obj = {
      timesheetFilters: {
        ...searchObj,
        firms: searchObj.firms,
        persons: searchObj.persons,
        statuses: searchObj.statuses,
        startDate: searchObj.startDate,
        endDate: searchObj.endDate,
        from: 0,
        size: state.timesheetCount,
        sortBy: searchObj.sortBy,
        sortOrder: searchObj.sortOrder,
        keyword: searchObj.keyword,
        categoryCode: searchObj.categoryCode,
        workType: searchObj.workType,
        professionalLevel: searchObj.professionalLevel,
        min: searchObj?.min || undefined,
        max: searchObj?.max || undefined,
        showMultipleBillingRates: true,
        isShowAll: true,
      },
      isSelectedAll: state.hasAllSelectionChanged,
      timesheetIds: state.hasAllSelectionChanged
        ? [...state.unselectedTimeSheetsList]
        : [...state.selectedTimeSheetsList],
      workType: searchObj.workType,
      // timesheetIds: [...selectedTimesheetIds],
      includeChangeLog: includeChangeLog ? true : false,
      includeCSVExport: false,
      isSaveOnServer: false,
      firms: searchObj.firms.map((ele) => initialFirms[ele].lawFirmShortName),
      localTime: moment().format("YYYYMMDDHHmmss"),
    };
    let result = await fetch(`${API_URL}timesheets/generate-pdf-submit`, {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + (getToken() || ""),
      },
    });
    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], { type: "application/pdf" });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = pdfFileURL;
    a.download = await getFileName(
      pdf.type === "application/zip" ? "zip" : "pdf",
      "submit"
    );
    a.click();
  };
  const handleDownloadAsCsv = async (includeChangeLog?: boolean) => {
    let searchObj = { ...state.search };
    let selectedTimesheetIds = [...state.timesheetsList].reduce((acc, ele) => {
      if (ele.isSelected) {
        acc.push(ele.timeCommonBenefitId);
      }
      return acc;
    }, []);
    let obj = {
      timesheetFilters: {
        ...searchObj,
        firms: searchObj.firms,
        persons: searchObj.persons,
        statuses: searchObj.statuses,
        startDate: searchObj.startDate,
        endDate: searchObj.endDate,
        from: 0,
        size: state.timesheetCount,
        sortBy: searchObj.sortBy,
        sortOrder: searchObj.sortOrder,
        keyword: searchObj.keyword,
        categoryCode: searchObj.categoryCode,
        workType: searchObj.workType,
        professionalLevel: searchObj.professionalLevel,
        min: searchObj?.min || undefined,
        max: searchObj?.max || undefined,
        showMultipleBillingRates: true,
        isShowAll: true,
      },
      isSelectedAll: state.hasAllSelectionChanged,
      timesheetIds: state.hasAllSelectionChanged
        ? [...state.unselectedTimeSheetsList]
        : [...state.selectedTimeSheetsList],
      workType: searchObj.workType,
      // timesheetIds: [...selectedTimesheetIds],
      includeChangeLog: includeChangeLog ? true : false,
      includeCSVExport: false,
      isSaveOnServer: false,
      firms: searchObj.firms.map((ele) => initialFirms[ele].lawFirmShortName),
      localTime: moment().format("YYYYMMDDHHmmss"),
    };
    let result = await fetch(`${API_URL}timesheets/generate-csv-submit`, {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + (getToken() || ""),
      },
    });
    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], {
      type: pdf.type === "application/zip" ? "octet/stream" : "application/csv",
    });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = pdfFileURL;
    a.download = await getFileName(
      pdf.type === "application/zip" ? "zip" : "csv",
      "submit"
    );
    a.click();
  };

  const sendEmail = () => {
    let emailTo = "paeruva@contenterra.com";
    let emailCC = "paeruva@contenterra.com";
    let emailSub = "Email subject";
    let emailBody = "Email body goes here";
    window.open(
      "mailto:" +
        emailTo +
        "?cc=" +
        emailCC +
        "&subject=" +
        emailSub +
        "&body=" +
        emailBody
    );
  };

  const classes = styles();

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <section className="p-16">
        <div className="text-center">
          <Typography variant="h5">Submit Time to Court</Typography>
        </div>
        <br />
        <Grid className={`${classes.main8}`}>
          <Hidden mdDown>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={3}
              className={`timesheet-filters-height  ${
                isFilterOpen
                  ? classes.filterOpenContainer
                  : classes.filterCloseContainer
              }`}
            >
              {filterData.length > 0 && (
                <Filter
                  data={[...filterData]}
                  options={state.options || []}
                  onChange={(data: IFilter[], changedProperty?: string) => {
                    onFilterChange(data, changedProperty);
                  }}
                  typeOfFilters="timesheets"
                  selectedFilters={state.search}
                  loadedFilter={loadedFilter}
                  dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
                />
              )}
            </Grid>
          </Hidden>
          <ToggleFilterSection
            showFilterClass="toggle-filters-timesheet-container"
            classes={classes}
            toggleFilter={() => setFilterOpen(!isFilterOpen)}
            isFilterOpen={isFilterOpen}
          />
          <Grid
            className={`${
              isFilterOpen
                ? classes.mainOpenContainer
                : classes.mainCloseContainer
            }`}
          >
            <Grid container spacing={2} justify="flex-end">
              <Hidden lgUp>
                <Grid item>
                  {filterData.length > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setFilterDialogOpen(true)}
                    >
                      Filters
                    </Button>
                  )}
                </Grid>
              </Hidden>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <React.Fragment>
                  <Grid container spacing={2} justify="flex-end">
                    {/* {state.timesheetsList && state.timesheetsList.length > 0 &&<Grid item>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleOpenExportDialogMain();
                        }}
                      >
                        Export
                      </Button>
                    </Grid>} */}
                    {/* <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => sendEmail()}
                      >
                        Email
                      </Button>
                    </Grid> */}
                    {isTimesheetSelected() && (
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => handleOpenExportDialog()}
                          disabled={isReadOnly}
                        >
                          Submit To Court
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <br />
                </React.Fragment>
                <TableContainer className="timesheets-table-container moz-table-pb">
                  <Table size="small" className="custom-table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>
                          {state.nonSubmittedOrGrantedCounts !== 0 && (
                            <Checkbox
                              size="small"
                              checked={state.isAllSelected}
                              indeterminate={
                                !state.isAllSelected &&
                                (state.selectedTimeSheetsList?.length ||
                                  state.unselectedTimeSheetsList?.length)
                                  ? true
                                  : false
                              }
                              value={state.isAllSelected}
                              onClick={() => {
                                selectAll();
                              }}
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell sortDirection="desc">
                          <TableSortLabel
                            active={state.search.sortBy === "firm"}
                            direction={state.search.sortOrder}
                            onClick={() => {
                              onSort("firm");
                            }}
                          >
                            Firm
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell>
                          <TableSortLabel
                            active={state.search.sortBy === "person"}
                            direction={state.search.sortOrder}
                            onClick={() => {
                              onSort("person");
                            }}
                          >
                            Resource
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell>Service Date</StyledTableCell>
                        <StyledTableCell>Professional Level</StyledTableCell>
                        {/* <StyledTableCell>Category Code</StyledTableCell> */}
                        <StyledTableCell>Category Name</StyledTableCell>
                        <StyledTableCell style={{ minWidth: 200 }}>
                          Detailed Description of Work performed
                        </StyledTableCell>
                        {/* <StyledTableCell>Approved by</StyledTableCell> */}
                        <StyledTableCell align="center">Time</StyledTableCell>
                        <StyledTableCell align="center">
                          Billing rate
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Fees Total
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Class Work
                        </StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell align="center">
                          Version
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.timesheetsList.length > 0 ? (
                        state.timesheetsList.map((ele, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell>
                              {Object.keys(GRANTED_NOT_GRANTED_STATUS).indexOf(
                                ele.status
                              ) === -1 && (
                                <Checkbox
                                  size="small"
                                  checked={ele.isSelected ? true : false}
                                  // value={ele.isSelected}
                                  onClick={() => {
                                    handleSelectChange(index);
                                  }}
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell>{ele.firmName}</StyledTableCell>
                            <StyledTableCell>
                              {ele.firstName + " " + ele.lastName}{" "}
                              {ele.nameSuffix}
                            </StyledTableCell>
                            <StyledTableCell>
                              {moment(ele.serviceDate).format("MM/DD/YYYY")}
                            </StyledTableCell>
                            <StyledTableCell>
                              {ele.professionalLevel}
                            </StyledTableCell>
                            {/* <StyledTableCell>{ele.categoryCode}</StyledTableCell> */}
                            <StyledTableCell>
                              {ele.categoryName}
                            </StyledTableCell>
                            <StyledTableCell>
                              {ele.detailedDescription}
                            </StyledTableCell>
                            {/* <StyledTableCell>{ele.approvedBy}</StyledTableCell> */}
                            <StyledTableCell align="right">
                              {hoursFormat(ele?.timeSpent || 0)}
                              {/* {numberFormat(ele?.timeSpent || 0)} */}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {currencyFormat(ele?.billingRate || 0)}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {currencyFormat(ele?.feesTotal || 0)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele.classActionWorkIndicator}
                            </StyledTableCell>
                            <StyledTableCell>
                              {TIMESHEET_ALL_STATUS[ele.status || ""]}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele.version > 1 ? (
                                <Typography
                                  className="MuiLink-underlineHover pointer"
                                  component="a"
                                  align="center"
                                  color="primary"
                                  onClick={() =>
                                    handleViewLogsDialog(true, index)
                                  }
                                >
                                  {/* {ele.version}&nbsp;<History /> */}
                                  {ele.version}
                                </Typography>
                              ) : (
                                ele.version
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={12}>
                            No Entries found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={state.rowsList}
                  component="div"
                  count={state.timesheetCount}
                  rowsPerPage={state.search.size}
                  page={
                    state.search.from === 0
                      ? 0
                      : state.search.from / state.search.size
                  }
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={(event: any, newPage: number) => {
                    console.log("event", event);
                    console.log("newPage", newPage);
                    let search = { ...state.search };
                    search.from =
                      newPage === 0 ? 0 : Number(newPage) * state.search.size;
                    onSearch(search);
                  }}
                  onRowsPerPageChange={(event: any) => {
                    let value = event.target.value;
                    let search = { ...state.search };
                    search.size = value ? Number(value) : 10;
                    search.from = 0;
                    onSearch(
                      search,
                      search.size ==
                        getCeilValueForTotalCount(state.timesheetCount)
                        ? true
                        : false
                    );
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </Grid>
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    container
                    spacing={2}
                    justify="space-between"
                    alignItems="center"
                    className="padding-16"
                  >
                    <Grid item>
                      <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        disabled={state.search.from <= 0 ? true : false}
                        onClick={() =>
                          getTimesheetsFrom(state.search.from - state.search.size)
                        }
                      >
                        <ChevronLeft /> Previous
                      </Button>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">
                        Rows {state.search.from + 1} -{" "}
                        {state.search.from +
                          (state.timesheetsList.length === state.search.size
                            ? state.search.size
                            : state.timesheetsList.length)}{" "}
                        of {state.timesheetCount}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        disabled={
                          state.timesheetsList.length !== state.search.size
                            ? true
                            : false
                        }
                        onClick={() =>
                          getTimesheetsFrom(state.search.from + state.search.size)
                        }
                      >
                        Next <ChevronRight />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
               */}
            </Grid>
          </Grid>
        </Grid>
      </section>
      {state.viewLogsDialog.isOpen && (
        <LogDialog
          timesheetId={state.viewLogsDialog.data}
          handleDialogClose={() => handleViewLogsDialog(false)}
        />
      )}

      {isFilterDialogOpen && (
        <CustomDrawer
          title={"Filter"}
          onClose={() => setFilterDialogOpen(false)}
        >
          <section className="p-24">
            <Filter
              isPopup={true}
              data={[...filterData]}
              onChange={(data: IFilter[]) => {
                onFilterChange(data);
              }}
              typeOfFilters="timesheets"
              selectedFilters={state.search}
              loadedFilter={loadedFilter}
              dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
            />
          </section>
        </CustomDrawer>
      )}
      {isExportDialogOpen && (
        <ExportDialog
          exportFor={"submitToCourt"}
          handleDialogClose={(data?) => handleCloseExportDialog(data)}
        />
      )}
      {isExportDialogOpenMain && (
        <ExportDialog
          exportFor={"detailedTimesheetOrExpense"}
          handleDialogClose={(data?) => handleCloseExportDialogMain(data)}
        />
      )}
    </React.Fragment>
  );
};

export default withConfirmDialogContext(SubmitToCourtMain);
