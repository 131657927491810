import * as React from 'react';
import { ResponsiveTreeMapHtml } from '@nivo/treemap'
interface TreeMapProps {
    convertedData: any;
    identityName: string;
    valueProperty: string;
    tooltipFormatter?: any;
    labelFormatter?: any;
}

const TreeMap: React.FC<TreeMapProps> = ({ convertedData, identityName, valueProperty, tooltipFormatter,labelFormatter }) => {
    return (
        <div style={{ height: "500px" }}>
            <ResponsiveTreeMapHtml
                tooltip={tooltipFormatter?tooltipFormatter:({
                    node
                }) => {
                    // console.log(node);
                    return <strong style={{
                        color: node.color
                    }}>
                        {node.pathComponents.join(' / ').replace("/", "")}: {node.formattedValue}
                    </strong>
                }}
                label={labelFormatter?labelFormatter:node => `${node.id} - ${node.formattedValue}`}
                // label={node=>`${node.id} - ${node.formattedValue}`}
                data={convertedData}
                identity={identityName}
                value={valueProperty}
                valueFormat=".02s"
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                labelSkipSize={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}
                parentLabelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            3
                        ]
                    ]
                }}
                colors={{ scheme: 'yellow_orange_red' }}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.1
                        ]
                    ]
                }}

            />
        </div>
    );
}

export default TreeMap;