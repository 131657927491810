import { Card, Grid, Typography } from "@material-ui/core";
import * as React from "react";
import { abbreviateNumber } from "../../services/UtilService";
import { ICmoProtocols } from "../../vm";
import "./cmo-details.css"
export interface CMOComplianceHeaderProps {
  cmoDetails?: ICmoProtocols;
  onTableValueClick?: Function;
  showDisapproved: boolean;
  showOnlyDisapproved: boolean;
}

const CMOComplianceHeader: React.FC<CMOComplianceHeaderProps> = ({
  cmoDetails,
  onTableValueClick,
  showDisapproved,
  showOnlyDisapproved
}) => {
  const timeEntriesStatus = {
    New: "new",
    Attention: "attention",
    Reviewed: "reviewed",
    Adjusted: "adjusted",
    Approved: "approved",
    Disapproved: "disapproved",
    Submitted: "submitted",
    Granted: "granted",
    "Not Granted": "not-granted"
  }
  const getClickableTableValue = (value: any, type?: string, showOnlyDisapproved?: boolean) => {
    return (
      value ? (
        <React.Fragment>
          {value && (isNaN(value) || value > 0) ? (
            <Typography
              className="MuiLink-underlineHover cmo-count pointer"
              align="center"
              component="a"
              color="primary"
              onClick={() => {
                onTableValueClick(timeEntriesStatus[type], showOnlyDisapproved)
              }}
            >
              {value}
            </Typography>
          ) : value}
        </React.Fragment>
      ) : (
        value
      )
    );
  };

  const getCMOCard = (all, percent, type) => {
    return <Card className="p-16 count-card cmo-count-card">
      <Grid container spacing={2} justifyContent="space-around">
        {(type !== "Disapproved" || showDisapproved || showOnlyDisapproved) ? (
          <React.Fragment>
            <Grid item>
              <Typography variant="subtitle1" color="textSecondary">
                Count
              </Typography>
              <Typography variant="subtitle1" className="fw-600">
                {getClickableTableValue(abbreviateNumber(all || 0), type, showOnlyDisapproved)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" color="textSecondary">
                %
              </Typography>
              <Typography variant="subtitle1" className="fw-600">
                {getClickableTableValue(abbreviateNumber(percent || 0, 2, true), type, showOnlyDisapproved)}
              </Typography>
            </Grid>
          </React.Fragment>
        ) : (
          <Grid item>
            <Typography variant="subtitle1" className="fw-600">
              Not shown
            </Typography>
          </Grid>
        )}
      </Grid>
      <Typography variant="subtitle1">{type}</Typography>
    </Card>
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} className="position-relative">
        <Grid item xs={12} sm={12} md={12} lg={9} className="dashed-border">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} lg={3}>
              {getCMOCard(cmoDetails?.timeEntries, cmoDetails?.timeEntryPercent, "Entries")}
            </Grid>
            <Grid item xs={12} sm={8} lg={9}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} lg={4}>
                  {getCMOCard(cmoDetails?.newStatusCount, cmoDetails?.newStatusPercent, "New")}
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  {getCMOCard(cmoDetails?.attentionStatusCount, cmoDetails?.attentionStatusPercent, "Attention")}
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  {getCMOCard(cmoDetails?.reviewedStatusCount, cmoDetails?.reviewedStatusPercent, "Reviewed")}
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  {getCMOCard(cmoDetails?.adjustedStatusCount, cmoDetails?.adjustedStatusPercent, "Adjusted")}
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  {getCMOCard(cmoDetails?.approvedStatusCount, cmoDetails?.approvedStatusPercent, "Approved")}
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  {getCMOCard(cmoDetails?.disApprovedStatusCount, cmoDetails?.disApprovedStatusPercent, "Disapproved")}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  {getCMOCard(cmoDetails?.lawFirmCount, cmoDetails?.lawFirmsPercent, "Firms")}
                </Grid>
                <Grid item>
                  {getCMOCard(cmoDetails?.resourceCount, cmoDetails?.resourcePercent, "Resources")}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8} lg={9}>
              <Grid container spacing={2}>
                {/* <Grid item xs={6} sm={6} lg={4}>
                  {getCMOCard(cmoDetails?.submittedStatusCount, cmoDetails?.submittedStatusPercent, "Submitted")}
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  {getCMOCard(cmoDetails?.grantedStatusCount, cmoDetails?.grantedStatusPercent, "Granted")}
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  {getCMOCard(cmoDetails?.notGrantedStatusCount, cmoDetails?.notGrantedStatusPercent, "Not Granted")}
                </Grid> */}
                <Grid item xs={6} sm={6} lg={4}>
                  {getCMOCard(cmoDetails?.professionalLevelCount, cmoDetails?.professionalPercent, "Professional Levels")}
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  {getCMOCard(cmoDetails?.categoryCount, cmoDetails?.categoryPercent, "Time Categories")}
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                  {getCMOCard(cmoDetails?.daysEntriesCount, cmoDetails?.daysEntriesPercent, "Days with Entries")}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} className="dashed-border">
          <Grid container spacing={2} className="mt-8 h-100">
            <Grid item xs={12} sm={12} lg={12}>
              <Card className="p-16 count-card">
                <Grid container spacing={2} justifyContent="space-around">
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      Count
                    </Typography>
                    <Typography variant="subtitle1" className="fw-600">
                      {getClickableTableValue(abbreviateNumber(cmoDetails?.hours || 0))}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      %
                    </Typography>
                    <Typography variant="subtitle1" className="fw-600">
                      {getClickableTableValue(abbreviateNumber(cmoDetails?.hoursPercent || 0, 2, true))}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="space-around">
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      Min
                    </Typography>
                    <Typography variant="subtitle1" className="fw-600">
                      {getClickableTableValue(abbreviateNumber(cmoDetails?.minHours || 0, 2, true))}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      Avg
                    </Typography>
                    <Typography variant="subtitle1" className="fw-600">
                      {getClickableTableValue(abbreviateNumber(cmoDetails?.avgHours || 0, 2, true))}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      Max
                    </Typography>
                    <Typography variant="subtitle1" className="fw-600">
                      {getClickableTableValue(abbreviateNumber(cmoDetails?.maxHours || 0, 2, true))}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="subtitle1">Hours</Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Card className="p-16 count-card">
                <Grid container spacing={2} justifyContent="space-around">
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      Amount
                    </Typography>
                    <Typography variant="subtitle1" className="fw-600">
                      ${getClickableTableValue(abbreviateNumber(cmoDetails?.feesSum || 0, 2, true))}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      %
                    </Typography>
                    <Typography variant="subtitle1" className="fw-600">
                      {getClickableTableValue(abbreviateNumber(cmoDetails?.feesPercent || 0, 2, true))}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="space-around">
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      Min
                    </Typography>
                    <Typography variant="subtitle1" className="fw-600">
                      ${getClickableTableValue(abbreviateNumber((cmoDetails?.minRates || 0), 2, true))}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      Avg
                    </Typography>
                    <Typography variant="subtitle1" className="fw-600">
                      ${getClickableTableValue(abbreviateNumber(cmoDetails?.avgRates || 0, 2, true))}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      Max
                    </Typography>
                    <Typography variant="subtitle1" className="fw-600">
                      ${getClickableTableValue(abbreviateNumber(cmoDetails?.maxRates || 0, 2, true))}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="subtitle1">Fees</Typography>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CMOComplianceHeader;