import { Button, Grid, Hidden } from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router";
import { API_URL, DESCRIPTION_FILTER_ENTRY, styles, TIMESHEET_STATUS, TIME_ENTRY_KEY_WORDS } from "../../Constant";
import {
  getCategoryHourStatsDetails,
  getProfessionalLevelHourDetails,
  getTimesheetHoursFeeReport,
  getTimesheetReport,
} from "../../services/DashboardService";
import {
  getExpenseCategoryAmountStatsDetails,
  getExpenseStatusCountReport,
  getExpenseTopFirmsCategory,
  getExpenseTotalAmountFeeReport,
} from "../../services/ExpenseDashboardService";
import {
  getAllCategoriesForExpenses,
  getExpensesFiltersCount,
} from "../../services/ExpenseService";
import {
  getAllCategories,
  getAllProfessionalLevels,
  getTimesheetFiltersCount,
  getTimesheetPersons,
  getAllWorkTypes,
  getAllApprovedListForTimeEntry
} from "../../services/TimesheetService";
import { getToken, getFileName, removeNulls, getSubDomain } from "../../services/UtilService";
import {
  ICategoryAmountStats,
  ICategoryHourStats,
  IConfirmDialog,
  IDashboardFirm,
  IFilter,
  IFirmDetails,
  IKeyValue,
  IPerson,
  IProfessionalHourStats,
  ITimeFilter,
  ITopExpenseCategory,
  ITopExpenseFirm,
} from "../../vm";
import ExportDialog from "../common/ExportDialog";
import Filter from "../common/Filter";
import { FirmContext } from "../common/FirmProvider";
import Loading from "../common/Loading";
import { ToastContext } from "../common/ToastProvider";
import CategoryAmountStatsForFirms from "../expense-dashboard/CategoryAmountStatsForFirms";
import ExpenseStatsForAllFirms from "../expense-dashboard/ExpenseStatsForAllFirms";
import TopFirmsAndCategoryForExpense from "../expense-dashboard/TopFirmsAndCategoryForExpense";
import CategoryHourStatsForFirms from "./CategoryHourStatsForFirms";
import ProfessionalLevelHourStatsForFirms from "./ProfessionalLevelHourStatsForFirms";
import TimeEntriesStatsForAllFirms from "./TimeEntriesStatsForAllFirms";
import TimesheetDashboardMain from "./TimesheetDashboardMain";
import moment from "moment";
import ToggleFilterSection from "./ToggleFilter";
import CustomDrawer from "../common/CustomDrawer";
import { getAllNotes } from "../../services/NoteService";
import { withConfirmDialogContext } from "../common/ConfirmDialogProvider";

export interface FirmsDashboardMainProps extends IConfirmDialog { }

const FirmsDashboardMain: React.FC<FirmsDashboardMainProps> = (props) => {
  const history = useHistory();
  const [state, setState] = React.useState({
    searchObj: {
      from: 0,
      size: 10,
      statuses: [],
      firms: [],
      lawFirmCode: [],
      persons: [],
      startDate: undefined,
      endDate: undefined,
      sortBy: "",
      sortOrder: "asc",
      professionalLevelSortBy: "PROFESSIONAL_LEVEL_SORT",
      professionalLevelSortOrder: "asc",
      categoryHourSortBy: "CATEGORY_CODE",
      categoryHourSortOrder: "asc",
      categoryAmountSortBy: "EXPENSE_CATEGORY_CODE",
      categoryAmountSortOrder: "asc",
      keyword: [],
      min: "",
      max: "",
      amount: "",
      professionalLevel: [],
      categoryCodeForTime: [],
      categoryCodeForExpense: [],
      workType: [],
      minWordCount: "",
      maxWordCount: "",
      showDisapproved: false,
      descriptionSearchFilters: [],
      approvedBy: [],
      showOnlyDisapproved: false,
      noteId: "",
    },
    firmsList: [],
  } as {
    searchObj: IDashboardFirm;
    firmsList: { value: any; label: any }[];
  });
  const [isExportDialogOpen, setExportDialogOpen] = React.useState(false);
  const [exportFor, setExportFor] = React.useState("");
  const [isFilterDialogOpen, setFilterDialogOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("");
  const [firmsDic, setFirmsDic] = React.useState({} as IKeyValue<IFirmDetails>);

  const [professionalLevelDic, setProfessionalLevelDic] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [categoryCodeDic, setCategoryCodeDic] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [
    categoryCodeDicForExpense,
    setCategoryCodeDicForExpense,
  ] = React.useState({} as IKeyValue<{ label: string; value: string }>);
  const [personsDic, setPersonsDic] = React.useState({} as IKeyValue<IPerson>);

  const { showToast } = React.useContext(ToastContext);
  const firmProvider = React.useContext(FirmContext);
  const [filterData, setFilterData] = React.useState([] as IFilter[]);
  const [initialFirms, setInitialFirms] = React.useState(
    {} as IKeyValue<IFirmDetails>
  );
  const [
    initialProfessionalLevels,
    setInitialProfessionalLevels,
  ] = React.useState({} as IKeyValue<{ label: string; value: string }>);

  const [initialCategoryCodes, setInitialCategoryCodes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialWorkTypes, setInitialWorkTypes] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [initialApprovedBy, setInitialApprovedBy] = React.useState(
    {} as IKeyValue<{ label: string; value: string }>
  );
  const [noteList, setNoteList] = React.useState(
    [] as Array<{ label: string; value: string }>
  );
  const [
    initialCategoryCodesForExpense,
    setInitialCategoryCodesForExpense,
  ] = React.useState({} as IKeyValue<{ label: string; value: string }>);
  const [keyWordsList] = React.useState(TIME_ENTRY_KEY_WORDS);

  const [timesheetCountDic, setTimesheetCountDic] = React.useState(
    {} as IKeyValue<number>
  );
  const [
    allFirmsTimesheetCountDic,
    setAllFirmsTimesheetCountDic,
  ] = React.useState({} as IKeyValue<number>);

  const [expenseCountDic, setExpenseCountDic] = React.useState(
    {} as IKeyValue<number>
  );
  const [allFirmsExpenseCountDic, setAllFirmsExpenseCountDic] = React.useState(
    {} as IKeyValue<number>
  );

  const [hoursAndFeeCount, setHoursAndFeeCount] = React.useState({
    hours: 0,
    fee: 0,
  });
  const [
    allFirmsHoursAndFeeCount,
    setAllFirmsHoursAndFeeCount,
  ] = React.useState({
    hours: 0,
    fee: 0,
  });

  const [amountCount, setAmountCount] = React.useState({
    totalAmount: 0,
  });
  const [allFirmsAmountCount, setAllFirmsAmountCount] = React.useState({
    totalAmount: 0,
  });

  const [
    professionalHoursStatsData,
    setProfessionalHoursStatsData,
  ] = React.useState([] as Array<IProfessionalHourStats>);
  const [
    allFirmsProfessionalHoursStatsData,
    setAllFirmsProfessionalHoursStatsData,
  ] = React.useState([] as Array<IProfessionalHourStats>);

  const [categoryHoursStatsData, setCategoryHoursStatsData] = React.useState(
    [] as Array<ICategoryHourStats>
  );
  const [
    allFirmsCategoryHoursStatsData,
    setAllFirmsCategoryHoursStatsData,
  ] = React.useState([] as Array<ICategoryHourStats>);

  const [categoryAmountStatsData, setCategoryAmountStatsData] = React.useState(
    [] as Array<ICategoryAmountStats>
  );
  const [
    allFirmsCategoryAmountStatsData,
    setAllFirmsCategoryAmountStatsData,
  ] = React.useState([] as Array<ICategoryAmountStats>);
  const [isCountsLoaded, setCountsLoaded] = React.useState(false);
  const [filterCountsDic, setFilterCountsDic] = React.useState({});
  const [isAllDetailsLoaded, setAllDetailsLoaded] = React.useState(false);

  const [topFirmsData, setTopFirmsData] = React.useState(
    [] as Array<ITopExpenseFirm>
  );
  const [topCategoriesData, setTopCategoriesData] = React.useState(
    [] as Array<ITopExpenseCategory>
  );
  const [isFilterOpen, setFilterOpen] = React.useState(true);
  const [timeEntriesCountLoading, setTimeEntriesCountLoading] = React.useState(false);
  const [expenseCountLoading, setExpenseCountLoading] = React.useState(false);
  const [professionalLevelLoading, setProfessionalLevelLoading] = React.useState(false);
  const [timeCategoryLoading, setTimeCategoryLoading] = React.useState(false);
  const [expenseCategoryLoading, setExpenseCategoryLoading] = React.useState(false);
  const [loadedFilter, setLoadedFilter] = React.useState(undefined as ITimeFilter);
  const [dateRangePickerMinMaxObj, setDateRangePickerMinMaxObj] = React.useState({
    minDate: undefined,
    maxDate: undefined
  } as {
    minDate?: Date;
    maxDate?: Date
  })

  React.useEffect(() => {
    const fetchTimeSheetReport = async () => {
      setLoading(true);
      let professionalLevelsDic = await getAllProfessionalLevelsList();
      let categoriesCodeDic = await getAllCategoriesList();
      let categoriesCodeDicForExpense = await getAllCategoriesListForExpense();
      let workTypesDic = await getAllWorkTypesList();
      let approvedByDic = await getAllApprovedByList();
      let noteList = await getAllNoteList();
      await getUserFirms({
        professionalLevelsDic,
        categoriesCodeDic,
        categoriesCodeDicForExpense,
        workTypesDic,
        approvedByDic,
        noteList
      });
      setLoading(false);
    };
    fetchTimeSheetReport();
  }, []);
  const getAllProfessionalLevelsList = async () => {
    const result = await getAllProfessionalLevels();
    let professionalLevelDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return {
            label: x.professionalLevelName,
            value: x.professionalLevelCode,
          };
        });
        professionalLevelDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setProfessionalLevelDic(professionalLevelDic);
      }
    } else {
      showToast(
        result.message || "Error while fetching professional levels",
        "error"
      );
    }
    return professionalLevelDic;
  };
  const getAllCategoriesList = async () => {
    const result = await getAllCategories();
    let categoryCodeDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.timeCategoryName, value: x.timeCategoryCode };
        });
        categoryCodeDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setCategoryCodeDic(categoryCodeDic);
      }
    } else {
      showToast(result.message || "Error while fetching categories", "error");
    }
    return categoryCodeDic;
  };

  const getAllCategoriesListForExpense = async () => {
    const result = await getAllCategoriesForExpenses();
    let categoryCodeDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.expenseCategoryName, value: x.expenseCategoryCode };
        });
        categoryCodeDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setCategoryCodeDicForExpense(categoryCodeDic);
      }
    } else {
      showToast(result.message || "Error while fetching categories", "error");
    }
    return categoryCodeDic;
  };

  const getAllWorkTypesList = async () => {
    const result = await getAllWorkTypes();
    let workTypesDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.workTypeLongDescription, value: x.workTypeCode };
        });
        workTypesDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialWorkTypes(workTypesDic);
      }
    } else {
      showToast(result.message || "Error while fetching work types", "error");
    }
    return workTypesDic;
  };

  const getAllApprovedByList = async () => {
    const result = await getAllApprovedListForTimeEntry();
    let approvedByDic = {};
    if (result?.isSuccess) {
      if (result?.data) {
        let data = result.data.map((x) => {
          return { label: x.timeApprovedByName, value: x.timeApprovedByCode };
        });
        approvedByDic = data.reduce((acc: any, ele) => {
          acc[ele.value] = ele;
          return acc;
        }, {});
        setInitialApprovedBy(approvedByDic);
      }
    } else {
      showToast(result.message || "Error while fetching approved by", "error");
    }
    return approvedByDic;
  };

  const getAllNoteList = async () => {
    const result = await getAllNotes({});
    let noteList = [];
    if (result?.isSuccess) {
      if (result?.data) {
        noteList = result.data.results.map((x) => {
          return { label: x.description, value: x.id };
        });
        setNoteList(noteList);
      }
    } else {
      showToast(result.message || "Error while fetching notes", "error");
    }
    return noteList;
  };

  const getUserFirms = async (professionCategoriesData) => {
    let userFirms = firmProvider.userFirms;
    if (userFirms.length === 0) {
      userFirms = await firmProvider.getFirmsList();
    }
    let firmsDic = userFirms.reduce((acc: any, ele) => {
      acc[ele.lawFirmCode] = ele;
      return acc;
    }, {});
    setFirmsDic(firmsDic);
    setInitialFirms(firmsDic);
    await getPersonsList(firmsDic, professionCategoriesData);
  };

  const getPersonsList = async (firmsDic, professionCategoriesData) => {
    let result = await getTimesheetPersons();
    if (result?.isSuccess) {
      if (result.data.length > 0) {
        let personsDic = result.data.reduce((acc: any, ele: any) => {
          acc[ele.personId] = {
            personId: ele.personId,
            name:
              (ele.personFirstName || "") + " " + (ele.personLastName || ""),
          };
          return acc;
        }, {});
        setPersonsDic(personsDic);
        await getTimesheetFilters(
          undefined,
          firmsDic,
          personsDic,
          undefined,
          professionCategoriesData
        );
      }
    }
  };

  const getTimesheetFilters = async (
    search?: any,
    firmsDic?: any,
    personsDic?: any,
    changedProperty?: any,
    professionCategoriesData?: any
  ) => {
    let tempSearch = { ...state.searchObj };
    if (search) {
      tempSearch = { ...state.searchObj, ...search };
    }
    if (!tempSearch.endDate) {
      delete tempSearch.endDate;
    }
    if (!tempSearch.startDate) {
      delete tempSearch.startDate;
    }
    let searchForFilter: any = { ...tempSearch };

    let keyWords = [...keyWordsList];

    let initialWorkTypesTemp = { ...initialWorkTypes };
    if (professionCategoriesData?.workTypesDic) {
      initialWorkTypesTemp = {
        ...initialWorkTypes,
        ...professionCategoriesData.workTypesDic,
      };
    }
    let initialApprovedByTemp = { ...initialApprovedBy };
    if (professionCategoriesData?.approvedByDic) {
      initialApprovedByTemp = {
        ...initialApprovedBy,
        ...professionCategoriesData.approvedByDic,
      };
    }
    let initialNoteListTemp = [...noteList];
    if (professionCategoriesData?.noteList) {
      initialNoteListTemp = [
        ...initialNoteListTemp,
        ...professionCategoriesData.noteList,
      ];
    }
    delete tempSearch.size;
    delete tempSearch.from;
    let result = {
      isSuccess: true,
      success: true,
      message: "",
      data: {} as any,
    };
    let expenseResult = {
      isSuccess: true,
      success: true,
      message: "",
      data: {} as any,
    };
    let shouldCheckTimeCount = false;
    let shouldCheckExpenseCount = false;
    if (isCountsLoaded === false || changedProperty == "clear-filters") {
      if (changedProperty !== "categoryCodeForExpenseCounts") {
        let searchForTime = { ...searchForFilter };
        searchForTime.categoryCode = searchForTime.categoryCodeForTime;
        if (searchForTime.descriptionSearchFilters && searchForTime.descriptionSearchFilters.length > 0) {
          searchForTime.descriptionSearchFilters = searchForTime.descriptionSearchFilters.filter(x => x.search);
        }
        searchForTime = removeNulls(searchForTime);
        result = await getTimesheetFiltersCount(searchForTime);
      }
      if (
        changedProperty !== "categoryCodeForTimeCounts" &&
        changedProperty !== "professionalCounts"
      ) {
        let searchForExpense = { ...searchForFilter };
        searchForExpense.categoryCode = searchForExpense.categoryCodeForExpense;
        searchForExpense.min = searchForExpense.amount || undefined;
        if (searchForExpense.descriptionSearchFilters && searchForExpense.descriptionSearchFilters.length > 0) {
          searchForExpense.descriptionSearchFilters = searchForExpense.descriptionSearchFilters.filter(x => x.search);
        }
        searchForExpense = removeNulls(searchForExpense);
        expenseResult = await getExpensesFiltersCount(searchForExpense);
      }
    }
    if (result?.isSuccess && expenseResult?.isSuccess) {
      console.log("result", result.data);
      let data: any = { ...result?.data };
      let expenseData: any = { ...expenseResult?.data };
      let filterCountDic: any = filterCountsDic;
      if (isCountsLoaded === false || changedProperty == "clear-filters") {
        let keyParams = {
          categoryCodeCounts: "categoryCode",
          workTypeCounts: "workType",
          categoryCodeForTimeCounts: "categoryCode",
          categoryCodeForExpenseCounts: "categoryCode",
          firmCounts: "firmCode",
          keywordCounts: "keyword",
          statusCounts: "status",
          professionalCounts: "professionalLevel",
          approvedByCounts: "timeApprovedByCode"
        };
        filterCountDic = Object.keys(result.data).reduce(
          (acc, key) => {
            if (acc[key]) {
              result.data[key].forEach((data) => {
                acc[key][data[keyParams[key]]] = data;
              });
            }
            return acc;
          },
          {
            workTypeCounts: {},
            categoryCodeCounts: {},
            categoryCodeForTimeCounts: {},
            categoryCodeForExpenseCounts: {},
            firmCounts: {},
            keywordCounts: {},
            statusCounts: {},
            professionalCounts: {},
            approvedByCounts: {}
          }
        );
        if (filterCountDic.categoryCodeCounts) {
          filterCountDic.categoryCodeForTimeCounts = {
            ...filterCountDic.categoryCodeCounts,
          };
          delete filterCountDic.categoryCodeCounts;
        }
        filterCountDic.categoryCodeForExpenseCounts =
          expenseResult?.data?.categoryCodeCounts || [];
        setFilterCountsDic(filterCountDic);
      }
      // updating state as it will use as min, max for date range picker
      setDateRangePickerMinMaxObj({
        minDate: data.startDate || dateRangePickerMinMaxObj.minDate,
        maxDate: data.endDate || dateRangePickerMinMaxObj.maxDate,
      });

      let filter: IFilter[] = [];
      let initialFirmsTemp = { ...initialFirms };
      if (firmsDic) {
        initialFirmsTemp = {
          ...initialFirms,
          ...firmsDic,
        };
      }
      let initialProfessionalLevelTemp = { ...initialProfessionalLevels };
      let initialCategoryCodeTemp = { ...initialCategoryCodes };
      let initialCategoryCodeForExpenseTemp = {
        ...initialCategoryCodesForExpense,
      };
      if (Object.keys(initialFirmsTemp).length === 0) {
        let firmCounts = data["firmCounts"] || [];
        initialFirmsTemp = firmCounts.reduce((acc: any, ele) => {
          acc[ele.firmCode] = firmsDic[ele.firmCode] || {};
          return acc;
        }, {});
      }
      if (Object.keys(initialProfessionalLevelTemp).length === 0) {
        let professionalCounts = data["professionalCounts"] || [];
        initialProfessionalLevelTemp = professionalCounts.reduce(
          (acc: any, ele) => {
            acc[ele.professionalLevel] =
              professionCategoriesData.professionalLevelsDic[
              ele.professionalLevel
              ] || {};
            return acc;
          },
          {}
        );
      }
      if (Object.keys(initialCategoryCodeTemp).length === 0) {
        let categoryCodeForTimeCounts = data["categoryCodeCounts"] || [];
        initialCategoryCodeTemp = categoryCodeForTimeCounts.reduce(
          (acc: any, ele) => {
            acc[ele.categoryCode] =
              professionCategoriesData.categoriesCodeDic[ele.categoryCode] ||
              {};
            return acc;
          },
          {}
        );
      }
      if (Object.keys(initialCategoryCodeForExpenseTemp).length === 0) {
        let categoryCodeForExpenseCounts =
          expenseData["categoryCodeCounts"] || [];
        initialCategoryCodeForExpenseTemp = categoryCodeForExpenseCounts.reduce(
          (acc: any, ele) => {
            acc[ele.categoryCode] =
              professionCategoriesData.categoriesCodeDicForExpense[
              ele.categoryCode
              ] || {};
            return acc;
          },
          {}
        );
      }
      let filterOrder = [
        "descriptionSearchFilters",
        "workTypeCounts",
        "time",
        "statusCounts",
        "showDisapproved",
        "showOnlyDisapproved",
        "firmCounts",
        // "personCounts",
        "professionalCounts",
        "categoryCodeForTimeCounts",
        "approvedBy",
        "categoryCodeForExpenseCounts",
        "noteId",
        // "keyword",
        "min-max",
        "amount",
        "word-counts",
      ];
      let customFilters = ["time", "min-max", "amount", "descriptionSearchFilters", "word-counts", "showDisapproved", "showOnlyDisapproved"];
      delete data["personCounts"];
      if (!tempSearch.max && data?.maxTimeSpentHours) {
        tempSearch.max = data?.maxTimeSpentHours;
      }
      if (!tempSearch.startDate) {
        tempSearch.startDate = data?.startDate || tempSearch.startDate;
      }
      if (!tempSearch.endDate) {
        tempSearch.endDate = data?.endDate || tempSearch.endDate;
      }
      filterOrder.forEach((filterType) => {
        if (customFilters.indexOf(filterType) > -1) {
          switch (filterType) {
            case "time":
              filter.push({
                header: "Time Period",
                name: "time",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "time").isHidden) ||
                  false,
                items: [
                  {
                    type: 'date-range',
                    value: [
                      {
                        startDate: tempSearch.startDate,
                        endDate: tempSearch.endDate,
                        key: 'selection'
                      }
                    ],
                    label: ""
                  }
                ]
                // items: [
                //   {
                //     value: tempSearch.startDate
                //       ? tempSearch.startDate
                //       : data?.startDate,
                //     type: "date",
                //     label: "Start Date",
                //     name: "startDate",
                //   },
                //   {
                //     value: tempSearch.endDate
                //       ? tempSearch.endDate
                //       : data?.endDate,
                //     type: "date",
                //     label: "End Date",
                //     name: "endDate",
                //   },
                // ],
              });
              break;
            case "keyword":
              filter.push({
                header: "Keywords",
                name: "keyword",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "keyword").isHidden) ||
                  false,
                items: keyWords.map((ele) => {
                  return {
                    isSelected: tempSearch.keyword?tempSearch.keyword.includes(ele.value):false,
                    label: ele.label,
                    type: "checkbox",
                    name: ele.value,
                    // count:
                    //   changedProperty !== undefined &&
                    //   changedProperty === ele.label &&
                    //   tempSearch.statuses.length > 0
                    //     ? filterData
                    //         .find((ed) => ed.name === 'professionalLevel')
                    //         ?.items.find((ed) => ed.name === ele.label)?.count || 0
                    //     : statusCounts.find((e) => e.status === eds)?.count || 0,
                  };
                }),
              });
              break;
            case "min-max":
              filter.push({
                header: "Total Time Spent for Day",
                name: "min-max",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "min-max").isHidden) ||
                  false,
                items: [
                  {
                    label: "Min",
                    type: "number",
                    name: "min",
                    value: tempSearch.min,
                  },
                  {
                    label: "Max",
                    type: "number",
                    name: "max",
                    value: tempSearch.max,
                  },
                ],
              });
              break;
            case "amount":
              filter.push({
                header: "Expense Amount Greater Than",
                name: "amount",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "amount").isHidden) ||
                  false,
                items: [
                  {
                    label: "Amount",
                    type: "number",
                    name: "amount",
                    value: tempSearch.amount,
                  }
                ],
              });
              break;
            case "descriptionSearchFilters":
              filter.push({
                header: "Description Keyword Search",
                name: "descriptionSearchFilters",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "descriptionSearchFilters").isHidden) ||
                  false,
                items: tempSearch.descriptionSearchFilters.length > 0 ? tempSearch.descriptionSearchFilters.map((d) => {
                  let itemObj = {
                    type: "text-checkbox",
                    value: d.search,
                    isSelected: d.isExcluded,
                    name: "description",
                    label: ""
                  };
                  return itemObj;
                }) : [{ ...DESCRIPTION_FILTER_ENTRY }],
              });
              break;
            case "word-counts":
              filter.push({
                header: "Word Count",
                name: "word-counts",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "word-counts")?.isHidden) ||
                  false,
                items: [
                  {
                    label: "Min Words",
                    type: "number",
                    name: "minWordCount",
                    value: tempSearch.minWordCount,
                  },
                  {
                    label: "Max Words",
                    type: "number",
                    name: "maxWordCount",
                    value: tempSearch.maxWordCount,
                  },
                ],
              });
              break;
            case "showDisapproved":
              filter.push({
                className: "disapproved-section",
                header: "",
                name: "showDisapproved",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "showDisapproved")?.isHidden) ||
                  false,
                items: [{
                  isSelected: tempSearch.showDisapproved,
                  label: "Show Disapproved",
                  type: "switch",
                  name: "showDisapproved",
                  isDisabled: tempSearch.showOnlyDisapproved
                }],
              });
              break;
            case "showOnlyDisapproved":
              filter.push({
                className: "only-disapproved-section",
                header: "",
                name: "showOnlyDisapproved",
                isHidden:
                  (filterData.length > 0 &&
                    filterData.find((e) => e.name === "showOnlyDisapproved")?.isHidden) ||
                  false,
                items: [{
                  isSelected: tempSearch.showOnlyDisapproved,
                  label: "Show Only Disapproved",
                  type: "switch",
                  name: "showOnlyDisapproved",
                }],
              });
              break;
            default:
              break;
          }
        } else {
          let ele = filterType;
          let a: IFilter = { header: "", items: [], name: ele };
          switch (ele) {
            case "firmCounts":
              a.header = "Firm";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let firmCounts = data["firmCounts"] || [];

              Object.keys(initialFirmsTemp).map((element) => {
                a.items.push({
                  label: firmsDic[element]
                    ? firmsDic[element].lawFirmShortName
                    : "",
                  type: "checkbox",
                  // count:
                  //   isCountsLoaded === false
                  //     ? changedProperty !== undefined &&
                  //       changedProperty === ele &&
                  //       tempSearch.firms.length > 0
                  //       ? filterData
                  //           .find((ed) => ed.name === ele)
                  //           ?.items.find((ed) => ed.name === String(element))
                  //           ?.count || 0
                  //       : firmCounts.find((e) => e.firmCode === element)
                  //           ?.count || 0
                  //     : filterCountDic?.["firmCounts"]?.[element]?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.firms?tempSearch.firms.includes(element):false,
                });
              });
              // sorting based on label i.e. firm short name
              a.items.sort((a, b) => a.label > b.label ? 1 : -1);
              break;
            case "professionalCounts":
              a.header = "Professional Level";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let professionalCounts = data["professionalCounts"] || [];
              Object.keys(initialProfessionalLevelTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.professionalLevelsDic[element]
                    ? professionCategoriesData.professionalLevelsDic[element]
                      .label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.professionalLevel.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === element)?.count ||
                        0
                        : professionalCounts.find(
                          (e) => e.professionalLevel === element
                        )?.count || 0
                      : filterCountDic?.["professionalCounts"]?.[element]
                        ?.count || 0,
                  name: element,
                  isSelected: tempSearch.professionalLevel?tempSearch.professionalLevel.includes(element):false,
                });
              });
              break;
            case "workTypeCounts":
              a.header = "Work Types";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let workTypeCounts = data["workTypeCounts"] || [];
              Object.keys(initialWorkTypesTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.workTypesDic[element]
                    ? professionCategoriesData.workTypesDic[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.workType.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : workTypeCounts.find(
                          (e) => e.workType === element
                        )?.count || 0
                      : filterCountDic?.["workTypeCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.workType?tempSearch.workType.includes(element):false,
                });
              });
              break;
            case "categoryCodeForTimeCounts":
              a.header = "Time Category";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let categoryCodeForTimeCounts = data["categoryCodeCounts"] || [];
              Object.keys(initialCategoryCodeTemp).sort((a, b) => initialCategoryCodeTemp[a].label > initialCategoryCodeTemp[b].label ? 1 : -1).map((element) => {
                a.items.push({
                  label: professionCategoriesData.categoriesCodeDic[element]
                    ? professionCategoriesData.categoriesCodeDic[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.categoryCodeForTime.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : categoryCodeForTimeCounts.find(
                          (e) => e.categoryCode === element
                        )?.count || 0
                      : filterCountDic?.["categoryCodeForTimeCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.categoryCodeForTime?tempSearch.categoryCodeForTime.includes(element):false,
                });
              });
              break;
            case "categoryCodeForExpenseCounts":
              a.header = "Expense Category";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let categoryCodeForExpenseCounts =
                expenseData["categoryCodeCounts"] || [];
              Object.keys(initialCategoryCodeTemp).map((element) => {
                a.items.push({
                  label: professionCategoriesData.categoriesCodeDicForExpense[
                    element
                  ]
                    ? professionCategoriesData.categoriesCodeDicForExpense[
                      element
                    ].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.categoryCodeForExpense.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : categoryCodeForExpenseCounts.find(
                          (e) => e.categoryCode === element
                        )?.count || 0
                      : filterCountDic?.["categoryCodeForExpenseCounts"]?.[
                        element
                      ]?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.categoryCodeForExpense?tempSearch.categoryCodeForExpense.includes(
                    element
                  ):false,
                });
              });
              break;
            case "statusCounts":
              a.header = "Status";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let statusCounts = data["statusCounts"] || [];
              Object.keys(TIMESHEET_STATUS).map((eds) => {
                a.items.push({
                  isSelected: tempSearch.statuses?tempSearch.statuses.includes(eds):false,
                  label: TIMESHEET_STATUS[eds],
                  type: "checkbox",
                  name: eds,
                  // count:
                  //   isCountsLoaded === false
                  //     ? changedProperty !== undefined &&
                  //       changedProperty === ele &&
                  //       tempSearch.statuses.length > 0
                  //       ? filterData
                  //           .find((ed) => ed.name === ele)
                  //           ?.items.find((ed) => ed.name === eds)?.count || 0
                  //       : statusCounts.find((e) => e.status === eds)?.count || 0
                  //     : filterCountDic?.["statusCounts"]?.[eds]?.count || 0,
                  isDisabled: tempSearch.showOnlyDisapproved
                });
              });
              break;
            case "personCounts":
              a.header = "";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele).isHidden) ||
                false;
              let personsCount = data["personCounts"] || [];
              let options = [];
              let selectedItems = [];
              personsCount.map((ele) => {
                if (
                  tempSearch.persons.length > 0 &&
                  tempSearch.persons.includes(ele.personId)
                ) {
                  selectedItems.push({
                    value: ele.personId,
                    label: personsDic[ele.personId].name,
                  });
                }
                options.push({
                  value: ele.personId,
                  label: personsDic[ele.personId].name,
                });
              });
              a.items = [
                {
                  label: "Resources",
                  type: "dropdown",
                  name: ele,
                  selectedItems: selectedItems || [],
                  options:
                    changedProperty !== undefined &&
                      changedProperty === ele &&
                      tempSearch.persons.length > 0
                      ? filterData.find((ed) => ed.name === ele)?.items[0]
                        .options
                      : options,
                },
              ];
              break;
            case "approvedBy":
              a.header = "Approved By";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let approvedByCounts = data["approvedByCounts"] || [];
              Object.keys(initialApprovedByTemp).map((element) => {
                a.items.push({
                  label: initialApprovedByTemp[element]
                    ? initialApprovedByTemp[element].label
                    : "",
                  type: "checkbox",
                  count:
                    isCountsLoaded === false
                      ? changedProperty !== undefined &&
                        changedProperty === ele &&
                        tempSearch.approvedBy.length > 0
                        ? filterData
                          .find((ed) => ed.name === ele)
                          ?.items.find((ed) => ed.name === String(element))
                          ?.count || 0
                        : approvedByCounts.find(
                          (e) => e.approvedBy === element
                        )?.count || 0
                      : filterCountDic?.["approvedByCounts"]?.[element]
                        ?.count || 0,
                  name: String(element),
                  isSelected: tempSearch.approvedBy?tempSearch.approvedBy.includes(element):false,
                });
              });
              break;
            case "noteId":
              a.header = "";
              a.isHidden =
                (filterData.length > 0 &&
                  filterData.find((e) => e.name === ele)?.isHidden) ||
                false;
              let noteOptions = [];
              let selectedNoteItems = [];
              initialNoteListTemp.map((ele) => {
                if (tempSearch.noteId === ele.value) {
                  selectedNoteItems.push(ele);
                }
                noteOptions.push(ele);
              });
              a.items = [
                {
                  label: "Notes",
                  type: "dropdown",
                  name: ele,
                  selectedItems: selectedNoteItems || [],
                  options:
                    changedProperty !== undefined &&
                      changedProperty === ele &&
                      tempSearch.noteId
                      ? filterData.find((ed) => ed.name === ele)?.items[0].options
                      : noteOptions,
                  isMulti: false
                },
              ];
              break;
            default:
              a = undefined;
              break;
          }
          if (a) {
            filter.push(a);
          }
        }
      });
      setCountsLoaded(true);
      setFilterData(filter);
      setInitialFirms(initialFirmsTemp);
      setInitialProfessionalLevels(initialProfessionalLevelTemp);
      setInitialCategoryCodes(initialCategoryCodeTemp);
      setInitialCategoryCodesForExpense(initialCategoryCodeForExpenseTemp);
      if (changedProperty !== "add-descriptions") {
        if (isAllDetailsLoaded === false) {
          await getAllFirmsDashTimesheetReport(tempSearch);
          await getAllFirmsDashExpenseReport(tempSearch);
          await getAllFirmsProfessionalLevelDetails(tempSearch);
          await getAllFirmsCategoryHourDetails(tempSearch);
          await getAllFirmsCategoryAmountDetails(tempSearch);
          // await getTopFirmsAndCategories(
          //   tempSearch,
          //   initialFirmsTemp,
          //   initialCategoryCodeForExpenseTemp
          // );
          setAllDetailsLoaded(true);
        }
        setLoading(false);
        tempSearch.from = 0;
        tempSearch.size = 10;

        if (changedProperty !== "categoryCodeForExpenseCounts") {
          // change loading state to true for below method
          setTimeEntriesCountLoading(true);
          setProfessionalLevelLoading(true);
          setTimeCategoryLoading(true);
        }
        if (
          changedProperty !== "categoryCodeForTimeCounts" &&
          changedProperty !== "professionalCounts"
        ) {
          // change loading state to true for below method
          setExpenseCountLoading(true);
          setExpenseCategoryLoading(true);
        }
        if (changedProperty !== "categoryCodeForExpenseCounts") {
          await getDashTimesheetReport(tempSearch, undefined, changedProperty, filter);
          await getProfessionalLevelDetails(tempSearch);
          await getCategoryHourDetails(tempSearch);
        }
        if (
          changedProperty !== "categoryCodeForTimeCounts" &&
          changedProperty !== "professionalCounts"
        ) {
          await getDashExpenseReport(tempSearch);
          await getCategoryAmountDetails(tempSearch);
        }
      } else {
        setLoading(false);
      }
    } else {
      showToast(result.message || "Error while fetching counts", "error");
    }
  };

  const getDashTimesheetReport = async (searchObj?: any, firmsList?: any, changedProperty?: string, filter?: IFilter[]) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    // setLoading(true);
    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      delete tempSearch.lawFirmCode;
    }
    let searchForTime: any = { ...tempSearch };
    searchForTime.categoryCode = searchForTime?.categoryCodeForTime || [];
    if (searchForTime.descriptionSearchFilters && searchForTime.descriptionSearchFilters.length > 0) {
      searchForTime.descriptionSearchFilters = searchForTime.descriptionSearchFilters.filter(x => x.search);
    }
    searchForTime = removeNulls(searchForTime);
    const result = await getTimesheetReport(searchForTime);
    if (result?.isSuccess) {
      let timesheetDic = result.data.reduce(
        (acc, obj) => {
          let key = obj["status"];
          acc[key] = obj.count;
          return acc;
        },
        {
          total: result.data.reduce((acc, val) => acc + val.count, 0),
        }
      );
      setTimesheetCountDic(timesheetDic);
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
        searchObj: tempSearch,
      });
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching time entries count",
        "error"
      );
    }
    const result2 = await getTimesheetHoursFeeReport(searchForTime);
    if (result2?.isSuccess) {
      let horusFeeCount = { ...hoursAndFeeCount };
      horusFeeCount.hours = result2.data?.totalTimeSpent || 0;
      horusFeeCount.fee = result2.data?.totalFees || 0;
      setHoursAndFeeCount({ ...horusFeeCount });
      if ((changedProperty === "noteId") && result2.data.statuses && tempSearch.noteId) {
        tempSearch.statuses = result2.data.statuses;
        let statusIndexInFilter = filter.findIndex(x => x.name === "statusCounts");
        if (statusIndexInFilter > -1) {
          filter[statusIndexInFilter].items.forEach(item => {
            item.isSelected = tempSearch.statuses ? tempSearch.statuses.includes(item.name) : false
          })
        }
        if (result2.data.statuses.includes("disapproved") && !tempSearch.showDisapproved) {
          let showDisapprovedIndexInFilter = filter.findIndex(x => x.name === "showDisapproved");
          if (showDisapprovedIndexInFilter > -1) {
            filter[showDisapprovedIndexInFilter].items[0].isSelected = result2.data.statuses ? result2.data.statuses.includes("disapproved") : false
          }
          tempSearch.showDisapproved = true;
          props.confirmDialog.show("Disapproved", "Show Disapproved enabled", undefined, undefined, undefined, undefined, true);
        }
        setFilterData(filter);
      }
    } else {
      showToast(
        result2?.message
          ? result2.message
          : "Error while fetching hours and fees count",
        "error"
      );
    }
    // setLoading(false);
    setTimeEntriesCountLoading(false);
  };
  const getAllFirmsDashTimesheetReport = async (
    searchObj?: any,
    firmsList?: any
  ) => {
    let tempSearch: any = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    // setLoading(true);
    tempSearch.lawFirmCode = [];
    tempSearch.categoryCode = tempSearch?.categoryCodeForTime || [];
    if (tempSearch.descriptionSearchFilters && tempSearch.descriptionSearchFilters.length) {
      tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
    }
    tempSearch = removeNulls(tempSearch);
    const result = await getTimesheetReport(tempSearch);
    if (result?.isSuccess) {
      let timesheetDic = result.data.reduce(
        (acc, obj) => {
          let key = obj["status"];
          acc[key] = obj.count;
          return acc;
        },
        {
          total: result.data.reduce((acc, val) => acc + val.count, 0),
        }
      );
      setAllFirmsTimesheetCountDic(timesheetDic);
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching time entries count",
        "error"
      );
    }
    const result2 = await getTimesheetHoursFeeReport(tempSearch);
    if (result2?.isSuccess) {
      let horusFeeCount = { ...hoursAndFeeCount };
      horusFeeCount.hours = result2.data?.totalTimeSpent || 0;
      horusFeeCount.fee = result2.data?.totalFees || 0;
      setAllFirmsHoursAndFeeCount({ ...horusFeeCount });
    } else {
      showToast(
        result2?.message
          ? result2.message
          : "Error while fetching hours and fees count",
        "error"
      );
    }
    // setLoading(false);
  };

  const getDashExpenseReport = async (searchObj?: any, firmsList?: any) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    // setLoading(true);
    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      delete tempSearch.lawFirmCode;
    }
    let searchForExpense: any = { ...tempSearch };
    searchForExpense.categoryCode = searchForExpense?.categoryCodeForExpense || [];
    searchForExpense.min = searchForExpense.amount || undefined;
    delete searchForExpense.professionalLevel;
    if (searchForExpense.descriptionSearchFilters && searchForExpense.descriptionSearchFilters.length > 0) {
      searchForExpense.descriptionSearchFilters = searchForExpense.descriptionSearchFilters.filter(x => x.search);
    }
    searchForExpense = removeNulls(searchForExpense);
    const result = await getExpenseStatusCountReport(searchForExpense);
    if (result?.isSuccess) {
      let timesheetDic = result.data.reduce(
        (acc, obj) => {
          let key = obj["status"];
          acc[key] = obj.count;
          return acc;
        },
        {
          total: result.data.reduce((acc, val) => acc + val.count, 0),
        }
      );
      setExpenseCountDic(timesheetDic);
      setState({
        ...state,
        firmsList: firmsList ? firmsList : state.firmsList,
        searchObj: tempSearch,
      });
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching time entries count",
        "error"
      );
    }
    const result2 = await getExpenseTotalAmountFeeReport(searchForExpense);
    if (result2?.isSuccess) {
      let totalAmountCount = { ...amountCount };
      totalAmountCount.totalAmount = result2.data || 0;
      setAmountCount({ ...totalAmountCount });
    } else {
      showToast(
        result2?.message
          ? result2.message
          : "Error while fetching hours and fees count",
        "error"
      );
    }
    // setLoading(false);
    setExpenseCountLoading(false);
  };
  const getAllFirmsDashExpenseReport = async (searchObj?: any) => {
    let tempSearch: any = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    // setLoading(true);
    tempSearch.firms = [];
    tempSearch.categoryCode = tempSearch.categoryCodeForExpense;
    tempSearch.min = tempSearch.amount || undefined;
    if (tempSearch.descriptionSearchFilters && tempSearch.descriptionSearchFilters.length) {
      tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
    }
    tempSearch = removeNulls(tempSearch);
    const result = await getExpenseStatusCountReport(tempSearch);
    if (result?.isSuccess) {
      let timesheetDic = result.data.reduce(
        (acc, obj) => {
          let key = obj["status"];
          acc[key] = obj.count;
          return acc;
        },
        {
          total: result.data.reduce((acc, val) => acc + val.count, 0),
        }
      );
      setAllFirmsExpenseCountDic(timesheetDic);
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching time entries count",
        "error"
      );
    }
    const result2 = await getExpenseTotalAmountFeeReport(tempSearch);
    if (result2?.isSuccess) {
      let totalAmountCount = { ...amountCount };
      totalAmountCount.totalAmount = result2.data || 0;
      setAllFirmsAmountCount({ ...totalAmountCount });
    } else {
      showToast(
        result2?.message
          ? result2.message
          : "Error while fetching hours and fees count",
        "error"
      );
    }
    // setLoading(false);
  };

  const getProfessionalLevelDetails = async (
    searchObj?: any,
    firmsList?: any
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    // setLoading(true);
    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      tempSearch.lawFirmCode = [];
    }
    tempSearch.sortBy = tempSearch.professionalLevelSortBy;
    tempSearch.sortOrder = tempSearch.professionalLevelSortOrder;
    let searchForTime: any = { ...tempSearch };
    searchForTime.categoryCode = searchForTime?.categoryCodeForTime || [];
    if (searchForTime.descriptionSearchFilters && searchForTime.descriptionSearchFilters.length > 0) {
      searchForTime.descriptionSearchFilters = searchForTime.descriptionSearchFilters.filter(x => x.search);
    }
    searchForTime = removeNulls(searchForTime);
    const result = await getProfessionalLevelHourDetails(searchForTime);
    if (result?.isSuccess) {
      setProfessionalHoursStatsData(result.data);
      setState({
        ...state,
        searchObj: tempSearch,
      });
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching professional level hour stats",
        "error"
      );
    }
    // setLoading(false);
    setProfessionalLevelLoading(false);
  };
  const getAllFirmsProfessionalLevelDetails = async (
    searchObj?: any,
    firmsList?: any
  ) => {
    let tempSearch: any = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }

    // setLoading(true);
    tempSearch.lawFirmCode = [];
    tempSearch.sortBy = tempSearch.professionalLevelSortBy;
    tempSearch.sortOrder = tempSearch.professionalLevelSortOrder;
    tempSearch.categoryCode = tempSearch.categoryCodeForTime;
    if (tempSearch.descriptionSearchFilters && tempSearch.descriptionSearchFilters.length) {
      tempSearch.descriptionSearchFilters = tempSearch.descriptionSearchFilters.filter(x => x.search);
    }
    tempSearch = removeNulls(tempSearch);
    const result = await getProfessionalLevelHourDetails(tempSearch);
    if (result?.isSuccess) {
      setAllFirmsProfessionalHoursStatsData(result.data);
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching professional level hour stats",
        "error"
      );
    }
    // setLoading(false);
  };

  const getCategoryHourDetails = async (searchObj?: any, firmsList?: any) => {
    let tempSearch: any = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    // setLoading(true);
    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      delete tempSearch.lawFirmCode;
    }
    tempSearch.sortBy = tempSearch.categoryHourSortBy;
    tempSearch.sortOrder = tempSearch.categoryHourSortOrder;
    let searchForExpense: any = { ...tempSearch };
    searchForExpense.categoryCode = searchForExpense?.categoryCodeForTime || [];
    searchForExpense.min = searchForExpense?.amount || undefined;
    if (searchForExpense.descriptionSearchFilters && searchForExpense.descriptionSearchFilters.length > 0) {
      searchForExpense.descriptionSearchFilters = searchForExpense.descriptionSearchFilters.filter(x => x.search);
    }
    searchForExpense = removeNulls(searchForExpense);
    const result = await getCategoryHourStatsDetails(searchForExpense);
    if (result?.isSuccess) {
      setCategoryHoursStatsData(result.data);
      setState({
        ...state,
        searchObj: tempSearch,
      });
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching category level hour stats",
        "error"
      );
    }
    // setLoading(false);
    setTimeCategoryLoading(false);
  };
  const getAllFirmsCategoryHourDetails = async (
    searchObj?: any,
    firmsList?: any
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    // setLoading(true);
    tempSearch.lawFirmCode = [];
    tempSearch.sortBy = tempSearch.categoryHourSortBy;
    tempSearch.sortOrder = tempSearch.categoryHourSortOrder;
    let searchForTime: any = { ...tempSearch };
    searchForTime.categoryCode = searchForTime?.categoryCodeForTime || [];
    searchForTime.min = searchForTime?.amount || undefined;
    if (searchForTime.descriptionSearchFilters && searchForTime.descriptionSearchFilters.length) {
      searchForTime.descriptionSearchFilters = searchForTime.descriptionSearchFilters.filter(x => x.search);
    }
    searchForTime = removeNulls(searchForTime);
    const result = await getCategoryHourStatsDetails(searchForTime);
    if (result?.isSuccess) {
      setAllFirmsCategoryHoursStatsData(result.data);
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching category level hour stats",
        "error"
      );
    }
    // setLoading(false);
  };

  const getCategoryAmountDetails = async (searchObj?: any, firmsList?: any) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    // setLoading(true);
    if (tempSearch.firms) {
      tempSearch.lawFirmCode = tempSearch.firms;
    }
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      delete tempSearch.lawFirmCode;
    }
    tempSearch.sortBy = tempSearch.categoryAmountSortBy;
    tempSearch.sortOrder = tempSearch.categoryAmountSortOrder;
    let searchForExpense: any = { ...tempSearch };
    searchForExpense.categoryCode = searchForExpense.categoryCodeForExpense;
    searchForExpense.min = searchForExpense?.amount || undefined;
    if (searchForExpense.descriptionSearchFilters && searchForExpense.descriptionSearchFilters.length > 0) {
      searchForExpense.descriptionSearchFilters = searchForExpense.descriptionSearchFilters.filter(x => x.search);
    }
    searchForExpense = removeNulls(searchForExpense);
    const result = await getExpenseCategoryAmountStatsDetails(searchForExpense);
    if (result?.isSuccess) {
      setCategoryAmountStatsData(result.data);
      setState({
        ...state,
        searchObj: tempSearch,
      });
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching category level amount stats",
        "error"
      );
    }
    // setLoading(false);
    setExpenseCategoryLoading(false);
  };
  const getAllFirmsCategoryAmountDetails = async (
    searchObj?: any,
    firmsList?: any
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    // setLoading(true);
    tempSearch.lawFirmCode = [];
    tempSearch.sortBy = tempSearch.categoryAmountSortBy;
    tempSearch.sortOrder = tempSearch.categoryAmountSortOrder;
    let searchForExpense: any = { ...tempSearch };
    searchForExpense.categoryCode = searchForExpense.categoryCodeForExpense;
    searchForExpense.min = searchForExpense?.amount || undefined;
    if (searchForExpense.descriptionSearchFilters && searchForExpense.descriptionSearchFilters.length) {
      searchForExpense.descriptionSearchFilters = searchForExpense.descriptionSearchFilters.filter(x => x.search);
    }
    searchForExpense = removeNulls(searchForExpense);
    const result = await getExpenseCategoryAmountStatsDetails(searchForExpense);
    if (result?.isSuccess) {
      setAllFirmsCategoryAmountStatsData(result.data);
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching category level amount stats",
        "error"
      );
    }
    // setLoading(false);
  };

  const getTopFirmsAndCategories = async (
    searchObj?: any,
    firmsDicTemp?: any,
    categoriesDicTemp?: any
  ) => {
    let tempSearch = { ...state.searchObj };
    if (searchObj) {
      tempSearch = { ...tempSearch, ...searchObj };
    }
    setLoading(true);
    if (!tempSearch.lawFirmCode || tempSearch.lawFirmCode.length === 0) {
      tempSearch.lawFirmCode = [];
    }
    tempSearch.sortBy = tempSearch.categoryAmountSortBy;
    tempSearch.sortOrder = tempSearch.categoryAmountSortOrder;
    const result = await getExpenseTopFirmsCategory(tempSearch);
    if (result?.isSuccess) {
      let topFirms: ITopExpenseFirm[] = [...result?.data?.topFirms];
      let topCategories: ITopExpenseCategory[] = [
        ...result?.data?.topCategories,
      ];
      topFirms.map((ele) => {
        ele.lawFirmName = firmsDicTemp[ele.lawFirmCode]?.lawFirmShortName;
      });
      topCategories.map((ele) => {
        ele.expenseCategoryName =
          categoriesDicTemp[ele.expenseCategoryCode]?.label;
      });
      setTopFirmsData(topFirms);
      setTopCategoriesData(topCategories);
    } else {
      showToast(
        result?.message
          ? result.message
          : "Error while fetching top firms and category for expense",
        "error"
      );
    }
    setLoading(false);
  };

  const onFilterChange = async (data: IFilter[], changedProperty?: string) => {
    setLoading(true);
    if (changedProperty === "clear-filters") {
      setLoadedFilter(undefined);
    }
    if (changedProperty === "load-filters") {
      let details = JSON.parse(JSON.stringify(data));
      setLoadedFilter(details);
      await getTimesheetFilters(details.filters, firmsDic, personsDic, changedProperty, {
        professionalLevelsDic: professionalLevelDic,
        categoriesCodeDic: categoryCodeDic,
        categoriesCodeDicForExpense: categoryCodeDicForExpense,
        workTypesDic: { ...initialWorkTypes }
      });
    } else {
      let search = { ...state.searchObj };
      search.from = 0;
      let statusArray =
        data.find((ele) => ele.name === "statusCounts")?.items || [];
      let professionalLevelArray =
        data.find((ele) => ele.name === "professionalCounts")?.items || [];
      let workTypesArray =
        data.find((ele) => ele.name === "workTypeCounts")?.items || [];
      let categoryCodeForTimeArray =
        data.find((ele) => ele.name === "categoryCodeForTimeCounts")?.items || [];
      let categoryCodeForExpenseArray =
        data.find((ele) => ele.name === "categoryCodeForExpenseCounts")?.items ||
        [];
      let personArray =
        data.find((ele) => ele.name === "personCounts")?.items || [];
      let noteArray =
        data.find((ele) => ele.name === "noteId")?.items || [];
      let firmArray = data.find((ele) => ele.name === "firmCounts")?.items || [];
      let approvedByArray = data.find((ele) => ele.name === "approvedBy")?.items || [];
      let timeArray = data.find((ele) => ele.name === "time")?.items || [];
      let minMaxArray = data.find((ele) => ele.name === "min-max")?.items || [];
      let amountArray = data.find((ele) => ele.name === "amount")?.items || [];
      search.amount =
        amountArray.find((ele) => ele.name === "amount")?.value || "";
      search.min = minMaxArray.find((ele) => ele.name === "min")?.value || "";
      search.max = minMaxArray.find((ele) => ele.name === "max")?.value || "";
      let minMaxWordCountArray = data.find((ele) => ele.name === "word-counts")?.items || [];
      search.minWordCount = minMaxWordCountArray.find((ele) => ele.name === "minWordCount")?.value || "";
      search.maxWordCount = minMaxWordCountArray.find((ele) => ele.name === "maxWordCount")?.value || "";
      let descriptionArray = data.find((ele) => ele.name === "descriptionSearchFilters")?.items || [];
      search.descriptionSearchFilters = descriptionArray
        // .filter((ele) => ele.value)
        .map((ele) => {
          let d = {
            search: ele.value || "",
            isExcluded: ele.isSelected
          };
          return d;
        });
      let disapprovedStatus = data.find((ele) => ele.name === "showDisapproved")?.items || [];
      search.showDisapproved = disapprovedStatus.find(ele => ele.name === "showDisapproved").isSelected;
      let onlyDisapprovedStatus = data.find((ele) => ele.name === "showOnlyDisapproved")?.items || [];
      search.showOnlyDisapproved = onlyDisapprovedStatus.find(ele => ele.name === "showOnlyDisapproved").isSelected;
      if (search.min && search.max && search.min > search.max) {
        showToast("Max value cannot be less than min value", "error");
        return;
      }
      if (search.minWordCount && search.maxWordCount && search.minWordCount > search.maxWordCount) {
        showToast("Max word count value cannot be less than min word count value", "error");
        return;
      }

      search.startDate =
        timeArray[0].value[0].startDate || undefined;
      // timeArray.find((ele) => ele.name === "startDate")?.value || undefined;
      search.endDate =
        timeArray[0].value[0].endDate || undefined;
      // timeArray.find((ele) => ele.name === "endDate")?.value || undefined;
      search.statuses = statusArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      // if show 'only dis approved' is true we need to make status array empty and 'dis approved' false
      if (search.showOnlyDisapproved) {
        search.showDisapproved = false;
        search.statuses = [];
      }
      search.persons =
        personArray.length > 0
          ? personArray[0].selectedItems.length > 0
            ? personArray[0].selectedItems.map((ele) => ele.value)
            : []
          : [];
      search.firms = firmArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          if (e.name) {
            return e.name;
          }
        });
      search.professionalLevel = professionalLevelArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.categoryCodeForTime = categoryCodeForTimeArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.categoryCodeForExpense = categoryCodeForExpenseArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.workType = workTypesArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      let keywordsArray = data.find((ele) => ele.name === "keyword")?.items || [];
      search.keyword = keywordsArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.approvedBy = approvedByArray
        .filter((ele) => ele.isSelected === true)
        .map((e) => {
          return e.name;
        });
      search.noteId =
        noteArray.length > 0
          ? noteArray[0].selectedItems.length > 0
            ? noteArray[0].selectedItems[0].value
            : ""
          : "";
      if (changedProperty !== "add-descriptions") {
        await getTimesheetFilters(search, firmsDic, personsDic, changedProperty, {
          professionalLevelsDic: professionalLevelDic,
          categoriesCodeDic: categoryCodeDic,
          categoriesCodeDicForExpense: categoryCodeDicForExpense,
          workTypesDic: { ...initialWorkTypes }
        });
      }
      else {
        setLoading(false);
        setState({
          ...state,
          searchObj: {
            ...state.searchObj,
            ...search
          }
        })
      }
    }
  };
  const navigateToTimeSheets = (status, isDisapproved?: boolean, isShowOnlyDisapproved?: boolean) => {
    history.push({
      pathname: `/timesheets-summary`,
      state: {
        status: (!isDisapproved && !isShowOnlyDisapproved) ? status : "",
        startDate: state.searchObj.startDate,
        endDate: state.searchObj.endDate,
        selectedFirm: state.searchObj.firms,
        professionalLevel: state.searchObj.professionalLevel,
        categoryCode: state.searchObj.categoryCodeForTime,
        keyword: state.searchObj.keyword,
        min: state.searchObj.min,
        max: state.searchObj.max,
        minWordCount: state.searchObj.minWordCount,
        maxWordCount: state.searchObj.maxWordCount,
        descriptionSearchFilters: state.searchObj.descriptionSearchFilters,
        showDisapproved: isDisapproved ? true : state.searchObj.showDisapproved,
        showOnlyDisapproved: isShowOnlyDisapproved ? true : state.searchObj.showOnlyDisapproved,
        approvedBy: state.searchObj.approvedBy,
        noteId: state.searchObj.noteId
      },
    });
  };
  const navigateToExpenses = (status) => {
    history.push({
      pathname: `/expenses`,
      state: {
        status: status,
        startDate: state.searchObj.startDate,
        endDate: state.searchObj.endDate,
        selectedFirm: state.searchObj.firms,
        professionalLevel: state.searchObj.professionalLevel,
        categoryCode: state.searchObj.categoryCodeForExpense,
        keyword: state.searchObj.keyword,
        min: state.searchObj.amount,
      },
    });
  };

  const onSortProfessionalLevel = async (sortBy) => {
    let search = { ...state.searchObj };
    if (sortBy === search.professionalLevelSortBy) {
      search.professionalLevelSortOrder =
        search.professionalLevelSortOrder === "asc" ? "desc" : "asc";
    } else {
      search.professionalLevelSortBy = sortBy;
      search.professionalLevelSortOrder = "asc";
    }
    search.from = 0;
    setProfessionalLevelLoading(true);
    await getProfessionalLevelDetails(search);
  };

  const onSortCategoryHour = async (sortBy) => {
    let search = { ...state.searchObj };
    if (sortBy === search.categoryHourSortBy) {
      search.categoryHourSortOrder =
        search.categoryHourSortOrder === "asc" ? "desc" : "asc";
    } else {
      search.categoryHourSortBy = sortBy;
      search.categoryHourSortOrder = "asc";
    }
    search.from = 0;
    setTimeCategoryLoading(true);
    await getCategoryHourDetails(search);
  };

  const onSortCategoryAmount = async (sortBy) => {
    let search = { ...state.searchObj };
    if (sortBy === search.categoryAmountSortBy) {
      search.categoryAmountSortOrder =
        search.categoryAmountSortOrder === "asc" ? "desc" : "asc";
    } else {
      search.categoryAmountSortBy = sortBy;
      search.categoryAmountSortOrder = "asc";
    }
    search.from = 0;
    setExpenseCategoryLoading(true);
    await getCategoryAmountDetails(search);
  };
  const onExportDialogOpen = async (type: string) => {
    setExportFor(type);
    setExportDialogOpen(true);
  };
  const handleCloseExportDialog = async (data?: {
    exportType: "csv" | "pdf";
    includeChangeLog: boolean;
    exportSeparately: boolean;
  }) => {
    if (data) {
      if (data.exportType === "csv") {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownloadAsCsv(data?.exportSeparately);
        setLoadingMessage("");
        setLoading(false);
      } else {
        setLoading(true);
        setLoadingMessage("Exporting data. Please stand by..");
        await handleDownload(data?.exportSeparately);
        setLoadingMessage("");
        setLoading(false);
      }
    }
    setExportDialogOpen(false);
  };
  const handleDownloadAsCsv = async (isZip?: boolean) => {
    let searchObj = { ...state.searchObj };
    let obj: any = {}
    if (exportFor === 'expense-category') {
      obj = {
        searchQuery: {
          ...searchObj,
          startDate: searchObj.startDate,
          endDate: searchObj.endDate,
          firms: searchObj.firms,
          statuses: searchObj.statuses,
          categoryCode: searchObj.categoryCodeForExpense,
          workType: searchObj.workType,
          keyword: searchObj.keyword,
          min: searchObj?.min || undefined,
          sortBy: searchObj.categoryAmountSortBy,
          sortOrder: searchObj.categoryAmountSortOrder,
          showMultipleBillingRates: false,
          isShowAll: true,
          minWordCount: searchObj.minWordCount,
          maxWordCount: searchObj.maxWordCount,
          descriptionSearchFilters: searchObj.descriptionSearchFilters,
          showDisapproved: searchObj.showDisapproved,
          approvedBy: searchObj.approvedBy,
          noteId: searchObj.noteId
        },
        categoryList: searchObj.categoryCodeForExpense.map((ele) => initialCategoryCodesForExpense[ele].label),
        firmList: searchObj.firms.map((ele) => initialFirms[ele].lawFirmShortName),
        workType: searchObj.workType,
        isZip: isZip ? true : false,
        localTime: moment().format("YYYYMMDDHHmmss")
      };
      if (obj.searchQuery.descriptionSearchFilters && obj.searchQuery.descriptionSearchFilters.length > 0) {
        obj.searchQuery.descriptionSearchFilters = obj.searchQuery.descriptionSearchFilters.filter(x => x.search);
      }
    } else {
      obj = {
        dashboardHoursStatsVM: {
          startDate: searchObj.startDate,
          endDate: searchObj.endDate,
          lawFirmCode: searchObj.firms,
          keyword: searchObj.keyword,
          categoryCode: searchObj.categoryCodeForTime,
          workType: searchObj.workType,
          professionalLevel: searchObj.professionalLevel,
          min: searchObj?.min || undefined,
          max: searchObj?.max || undefined,
          sortBy:
            exportFor === "time-category"
              ? searchObj.categoryHourSortBy
              : searchObj.professionalLevelSortBy,
          sortOrder:
            exportFor === "time-category"
              ? searchObj.categoryHourSortOrder
              : searchObj.professionalLevelSortOrder,
          minWordCount: searchObj.minWordCount,
          maxWordCount: searchObj.maxWordCount,
          descriptionSearchFilters: searchObj.descriptionSearchFilters,
          showDisapproved: searchObj.showDisapproved,
          approvedBy: searchObj.approvedBy,
          noteId: searchObj.noteId
        },
        statusList: searchObj.statuses.map((ele) => TIMESHEET_STATUS[ele]),
        workType: searchObj.workType,
        isZip: isZip ? true : false,
        localTime: moment().format("YYYYMMDDHHmmss")
      };
      if (obj.dashboardHoursStatsVM.descriptionSearchFilters && obj.dashboardHoursStatsVM.descriptionSearchFilters.length > 0) {
        obj.dashboardHoursStatsVM.descriptionSearchFilters = obj.dashboardHoursStatsVM.descriptionSearchFilters.filter(x => x.search);
      }
    }

    let result: any;
    obj = removeNulls(obj);
    if (exportFor === 'expense-category') {
      result = await fetch(
        `${API_URL}timesheets/generate-csv/dashboard-firm-expense-category`,
        {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + (getToken() || ""),
            dbName: getSubDomain(),
          },
        }
      );
    }
    if (exportFor === 'time-category') {
      result = await fetch(
        `${API_URL}timesheets/generate-csv/dashboard-firm-time-category`,
        {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + (getToken() || ""),
            dbName: getSubDomain(),
          },
        }
      );
    }
    if (exportFor === 'professional-level') {
      result = await fetch(
        `${API_URL}timesheets/generate-csv/dashboard-firm-professional`,
        {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + (getToken() || ""),
            dbName: getSubDomain(),
          },
        }
      );
    }

    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], {
      type: pdf.type === "application/zip" ? "octet/stream" : "application/csv",
    });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = pdfFileURL;
    a.download = await getFileName(pdf.type === "application/zip" ? 'zip' : "csv", "case");
    a.click();
  };
  const handleDownload = async (isZip?: boolean) => {
    let searchObj = { ...state.searchObj };
    let obj: any = {}
    if (exportFor === 'expense-category') {
      obj = {
        searchQuery: {
          ...searchObj,
          startDate: searchObj.startDate,
          endDate: searchObj.endDate,
          firms: searchObj.firms,
          statuses: searchObj.statuses,
          categoryCode: searchObj.categoryCodeForExpense,
          workType: searchObj.workType,
          keyword: searchObj.keyword,
          min: searchObj?.min || undefined,
          sortBy: searchObj.categoryAmountSortBy,
          sortOrder: searchObj.categoryAmountSortOrder,
          showMultipleBillingRates: false,
          isShowAll: true,
          minWordCount: searchObj.minWordCount,
          maxWordCount: searchObj.maxWordCount,
          descriptionSearchFilters: searchObj.descriptionSearchFilters,
          showDisapproved: searchObj.showDisapproved,
          approvedBy: searchObj.approvedBy,
          noteId: searchObj.noteId
        },
        categoryList: searchObj.categoryCodeForExpense.map((ele) => initialCategoryCodesForExpense[ele].label),
        workType: searchObj.workType,
        firmList: searchObj.firms.map((ele) => initialFirms[ele].lawFirmShortName),
        isZip: isZip ? true : false,
        localTime: moment().format("YYYYMMDDHHmmss"),
      };
      if (obj.searchQuery.descriptionSearchFilters && obj.searchQuery.descriptionSearchFilters.length > 0) {
        obj.searchQuery.descriptionSearchFilters = obj.searchQuery.descriptionSearchFilters.filter(x => x.search);
      }
    } else {
      obj = {
        dashboardHoursStatsVM: {
          startDate: searchObj.startDate,
          endDate: searchObj.endDate,
          lawFirmCode: searchObj.firms,
          keyword: searchObj.keyword,
          categoryCode: searchObj.categoryCodeForTime,
          workType: searchObj.workType,
          professionalLevel: searchObj.professionalLevel,
          min: searchObj?.min || undefined,
          max: searchObj?.max || undefined,
          sortBy:
            exportFor === "time-category"
              ? searchObj.categoryHourSortBy
              : searchObj.professionalLevelSortBy,
          sortOrder:
            exportFor === "time-category"
              ? searchObj.categoryHourSortOrder
              : searchObj.professionalLevelSortOrder,
          minWordCount: searchObj.minWordCount,
          maxWordCount: searchObj.maxWordCount,
          descriptionSearchFilters: searchObj.descriptionSearchFilters,
          showDisapproved: searchObj.showDisapproved,
          approvedBy: searchObj.approvedBy,
          noteId: searchObj.noteId
        },
        statusList: searchObj.statuses.map((ele) => TIMESHEET_STATUS[ele]),
        workType: searchObj.workType,
        isZip: isZip ? true : false,
        localTime: moment().format("YYYYMMDDHHmmss")
      };
      if (obj.dashboardHoursStatsVM.descriptionSearchFilters && obj.dashboardHoursStatsVM.descriptionSearchFilters.length > 0) {
        obj.dashboardHoursStatsVM.descriptionSearchFilters = obj.dashboardHoursStatsVM.descriptionSearchFilters.filter(x => x.search);
      }
    }
    obj = removeNulls(obj);
    let result: any;
    if (exportFor === 'expense-category') {
      result = await fetch(
        `${API_URL}timesheets/generate-pdf/dashboard-firm-expense-category`,
        {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + (getToken() || ""),
            dbName: getSubDomain(),
          },
        }
      );
    }
    if (exportFor === 'time-category') {
      result = await fetch(
        `${API_URL}timesheets/generate-pdf/dashboard-firm-time-category`,
        {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + (getToken() || ""),
            dbName: getSubDomain(),
          },
        }
      );
    }
    if (exportFor === 'professional-level') {
      result = await fetch(
        `${API_URL}timesheets/generate-pdf/dashboard-firm-professional`,
        {
          method: "POST",
          body: JSON.stringify(obj),
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: "Bearer " + (getToken() || ""),
            dbName: getSubDomain(),
          },
        }
      );
    }
    let pdf = await result.blob();
    const pdfFile = await new Blob([pdf], {
      type: pdf.type === "application/zip" ? "octet/stream" : "application/pdf",
    });
    const pdfFileURL = await URL.createObjectURL(pdfFile);
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display:none";
    a.href = pdfFileURL;
    a.download = await getFileName(pdf.type === "application/zip" ? 'zip' : "pdf", "case");
    a.click();
  };

  const classes = styles();

  return (
    <React.Fragment>
      {isLoading && <Loading message={loadingMessage} />}
      <section className="p-16 pr-0 pb-0">
        <br />
        <Grid className={`${classes.main}`}>
          <Grid className={`${isFilterOpen ? classes.filterOpenContainer : classes.filterCloseContainer}`}>
            <Hidden mdDown>
              <Grid container spacing={2}>
                <Grid item xs={12} className="dashboard-filters-height">
                  {filterData.length > 0 && (
                    <Filter
                      data={[...filterData]}
                      onChange={(data: IFilter[], changedProperty?: string) => {
                        onFilterChange(data, changedProperty);
                      }}
                      handleFilterHide={() => setFilterOpen(false)}
                      typeOfFilters="timesheets"
                      selectedFilters={state.searchObj}
                      loadedFilter={loadedFilter}
                      dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
                    />
                  )}
                </Grid>
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid container justify="flex-end">
                <Grid item>
                  {filterData.length > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setFilterDialogOpen(true)}
                    >
                      Filters
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <ToggleFilterSection showFilterClass="mt--8" classes={classes} toggleFilter={() => setFilterOpen(!isFilterOpen)} isFilterOpen={isFilterOpen} />
          <Grid className={`dashboard-timesheet-main-container ${isFilterOpen ? `${classes.mainOpenContainer}` : classes.mainCloseContainer}`}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TimeEntriesStatsForAllFirms
                  search={state.searchObj}
                  allFirmsHoursAndFeeCount={allFirmsHoursAndFeeCount}
                  allFirmsTimesheetCountDic={allFirmsTimesheetCountDic}
                  hoursAndFeeCount={hoursAndFeeCount}
                  timesheetCountDic={timesheetCountDic}
                  navigateToTimeSheets={(status: any, isDisapproved, isShowOnlyDisapproved) =>
                    navigateToTimeSheets(status, isDisapproved, isShowOnlyDisapproved)
                  }
                  isLoading={timeEntriesCountLoading}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                <br />
                <ExpenseStatsForAllFirms
                  allFirmsAmountCount={allFirmsAmountCount}
                  allFirmsTimesheetCountDic={allFirmsExpenseCountDic}
                  amountCount={amountCount}
                  timesheetCountDic={expenseCountDic}
                  navigateToTimeSheets={(status: any) =>
                    navigateToExpenses(status)
                  }
                  isLoading={expenseCountLoading}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                <TopFirmsAndCategoryForExpense
                  topCategories={topCategoriesData}
                  topFirms={topFirmsData}
                />
              </Grid> */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ProfessionalLevelHourStatsForFirms
                  label={"Professional Level"}
                  allProfessionalHourStats={allFirmsProfessionalHoursStatsData}
                  professionalHourStats={professionalHoursStatsData}
                  onSort={(sortBy?: any) => onSortProfessionalLevel(sortBy)}
                  onExport={() => onExportDialogOpen("professional-level")}
                  sortBy={state.searchObj.professionalLevelSortBy}
                  sortOrder={state.searchObj.professionalLevelSortOrder}
                  isLoading={professionalLevelLoading}
                ></ProfessionalLevelHourStatsForFirms>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CategoryHourStatsForFirms
                  allCategoryHourStats={allFirmsCategoryHoursStatsData}
                  categoryHourStats={categoryHoursStatsData}
                  label={"Time Category"}
                  onSort={(sortBy?: any) => onSortCategoryHour(sortBy)}
                  onExport={() => onExportDialogOpen("time-category")}
                  sortBy={state.searchObj.categoryHourSortBy}
                  sortOrder={state.searchObj.categoryHourSortOrder}
                  isLoading={timeCategoryLoading}
                ></CategoryHourStatsForFirms>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                <CategoryAmountStatsForFirms
                  allCategoryHourStats={allFirmsCategoryAmountStatsData}
                  categoryHourStats={categoryAmountStatsData}
                  label={"Expense Category"}
                  onSort={(sortBy?: any) => onSortCategoryAmount(sortBy)}
                  onExport={() => onExportDialogOpen("expense-category")}
                  sortBy={state.searchObj.categoryAmountSortBy}
                  sortOrder={state.searchObj.categoryAmountSortOrder}
                  isLoading={expenseCategoryLoading}
                ></CategoryAmountStatsForFirms>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </section>
      {isExportDialogOpen && (
        <ExportDialog
          exportFor={'timesheetOrExpense'}
          handleDialogClose={(data?) => handleCloseExportDialog(data)}
        />
      )}
      {isFilterDialogOpen && (
        <CustomDrawer
          title={"Filter"}
          onClose={() => setFilterDialogOpen(false)}
        >
          <section className="p-24">
            <Filter
              isPopup={true}
              data={[...filterData]}
              onChange={(data: IFilter[]) => {
                onFilterChange(data);
              }}
              typeOfFilters="timesheets"
              selectedFilters={state.searchObj}
              loadedFilter={loadedFilter}
              dateRangePickerMinMaxObj={dateRangePickerMinMaxObj}
            />
          </section>
        </CustomDrawer>
      )}
    </React.Fragment>
  );
};

export default withConfirmDialogContext(FirmsDashboardMain);
