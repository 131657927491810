import * as React from "react";
import { useState, useEffect } from "react";
import Loading from "../common/Loading";
import CustomDialogDrawer from "../common/CustomDialogDrawer";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { NOTIFICATION_TYPES } from "../../Constant";
import { StyledTableCell, StyledTableRow } from "../../services/UtilService";
import { useHistory } from "react-router-dom";
import { OpenInNew } from "mdi-material-ui";

interface NotificationsListDialogProps {
  handleDialogClose: Function;
}

const mainList = [
  {
    message: "25 time entries require attention!",
    type: "attention",
  },
  {
    message: "150 new time entries were added!",
    type: "new entries added",
  },
  {
    message: "5 items require action!",
    type: "action requested",
  },
  {
    message: "2 firms were added!",
    type: "firm added",
  },
  {
    message: "8 users were added!",
    type: "user added",
  },
  {
    message: "25 time entries require attention!",
    type: "attention",
  },
  {
    message: "150 new time entries were added!",
    type: "new entries added",
  },
  {
    message: "5 items require action!",
    type: "action requested",
  },
  {
    message: "2 firms were added!",
    type: "firm added",
  },
  {
    message: "8 users were added!",
    type: "user added",
  },
  {
    message: "25 time entries require attention!",
    type: "attention",
  },
  {
    message: "150 new time entries were added!",
    type: "new entries added",
  },
  {
    message: "5 items require action!",
    type: "action requested",
  },
  {
    message: "2 firms were added!",
    type: "firm added",
  },
  {
    message: "8 users were added!",
    type: "user added",
  },
];

const NotificationsListDialog: React.FunctionComponent<
  NotificationsListDialogProps
> = ({ handleDialogClose }) => {
  const history = useHistory();
  const [notificationsList, setNotificationsList] = useState(mainList);
  const [isLoading, setLoading] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const onTypeChange = (type: string) => {
    let newList = [];
    setSelectedFilter(type);
    if (type === "all") {
      newList = [...mainList];
      setNotificationsList(newList);
    } else {
      newList = mainList.filter((e) => e.type === type);
      setNotificationsList(newList);
    }
  };

  const onNotificationClick = (type: string) => {
    switch (type) {
      case "attention":
        handleDialogClose();
        history.push({
          pathname: `/timesheets-summary`,
          state: {
            status: "attention",
          },
        });
        break;
      case "new entries added":
        handleDialogClose();
        history.push({
          pathname: `/timesheets-summary`,
          state: {
            status: "new",
          },
        });
        break;
      case "action requested":
        handleDialogClose();
        history.push({
          pathname: `/timesheets-summary`,
          state: {
            status: "attention",
          },
        });
        break;
      case "firm added":
        handleDialogClose();
        history.push({
          pathname: `/timesheets-summary`,
        });
        break;
      case "user added":
        handleDialogClose();
        history.push({
          pathname: `/access-management`,
        });
        break;
      default:
        handleDialogClose();
        break;
    }
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <CustomDialogDrawer
        variant="mini"
        title={"Notifications"}
        onClose={() => handleDialogClose()}
        handleOpen={true}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} sm={8} lg={6}>
                <Select
                  //   variant="outlined"
                  fullWidth
                  value={selectedFilter}
                  onChange={(event: any) => {
                    let value = event.target.value;
                    onTypeChange(value);
                  }}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  {Object.keys(NOTIFICATION_TYPES).map((x, index) => (
                    <MenuItem value={x} key={`notification_type_${index}`}>
                      {NOTIFICATION_TYPES[x]}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {/* <TableContainer className="notifications-table-container moz-table-pb">
              <Table stickyHeader size="small" className="custom-table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Message</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notificationsList && notificationsList.length > 0 ? (
                    notificationsList.map((notification, notificationIndex) => (
                      <StyledTableRow key={notificationIndex}>
                        <StyledTableCell>
                          {notification.message || ""}
                        </StyledTableCell>
                        <StyledTableCell>
                          {notification.type
                            ? NOTIFICATION_TYPES[notification?.type]
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            size="small"
                            onClick={(e) => onNotificationClick(notification.type)}
                          >
                            <OpenInNew />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        No notifications found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer> */}
            <List style={{border:"1px solid #e0e0e0"}}>
              {notificationsList &&
                notificationsList.length > 0 &&
                notificationsList.map((notification, notificationIndex) => (
                  <React.Fragment key={notificationIndex}>
                    <ListItem
                      style={{ cursor: "pointer" }}
                      onClick={() => onNotificationClick(notification.type)}
                    >
                      <ListItemText
                        primary={NOTIFICATION_TYPES[notification?.type]}
                        secondary={notification?.message}
                      />
                    </ListItem>
                    {notificationsList.length !== notificationIndex + 1 && (
                      <Divider component="li" />
                    )}
                  </React.Fragment>
                ))}
            </List>
          </Grid>
        </Grid>
      </CustomDialogDrawer>
    </React.Fragment>
  );
};

export default NotificationsListDialog;
