import { ICategoryHourStats, IFilter, IFirmTimesheetMetrics, IFirmTotalFees, IFirmTotalHours, ILogin, IProfessionalHourStats, IStandardAPIResponse, IUserDashboard } from "../vm";
import { httpClient } from "./UtilService";

export async function getTimesheetReport(
  searchObj: any
): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`reports/timesheet-status-count`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getTimesheetHoursFeeReport(
  searchObj: any
): Promise<IStandardAPIResponse<any>> {
  try {
    let res = await httpClient<any>(`reports/timesheet-total-timespent-and-fee`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getExpenseReport(
  searchObj: any
): Promise<IStandardAPIResponse<string>> {
  try {
    let res = await httpClient<string>(`expense-report`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getDashboardUsers(
  searchObj: any
): Promise<IStandardAPIResponse<Array<IUserDashboard>>> {
  try {
    let res = await httpClient<Array<IUserDashboard>>(
      `dash-users`,
      "POST",
      searchObj
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getDashboardUserFilter(
): Promise<IStandardAPIResponse<Array<IFilter>>> {
  try {
    let res = await httpClient<Array<IFilter>>(
      `dash-users-filter`,
      "GET"
    );
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getProfessionalLevelHourDetails(
  searchObj: any
): Promise<IStandardAPIResponse<IProfessionalHourStats[]>> {
  try {
    let res = await httpClient<IProfessionalHourStats[]>(`reports/professional-hours-stats`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getCategoryHourStatsDetails(
  searchObj: any
): Promise<IStandardAPIResponse<ICategoryHourStats[]>> {
  try {
    let res = await httpClient<ICategoryHourStats[]>(`reports/category-hours-stats`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}

export async function getFirmServiceFees(
  searchObj: any
): Promise<IStandardAPIResponse<IFirmTotalFees[]>> {
  try {
    let res = await httpClient<IFirmTotalFees[]>(`reports/firm-service-fees`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getFirmServiceHours(
  searchObj: any
): Promise<IStandardAPIResponse<IFirmTotalHours[]>> {
  try {
    let res = await httpClient<IFirmTotalHours[]>(`reports/firm-service-hours`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}
export async function getFirmTimesheetMetrics(
  searchObj: any
): Promise<IStandardAPIResponse<IFirmTimesheetMetrics[]>> {
  try {
    let res = await httpClient<IFirmTimesheetMetrics[]>(`reports/firm-timesheet-metrics`, "POST", searchObj);
    return res;
  } catch (err) {
    return err && err.response ? err.response.data : undefined;
  }
}