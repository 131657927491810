import * as React from "react";
import { Grid, Button, Typography, Chip } from "@material-ui/core";
import moment from "moment";
// import SelectedFiltersDialog from "./SelectedFiltersDialog";

export const SEARCH_OBJECT_LABEL_DICT_BY_ORDER = {
    descriptionSearchFilters: "Description Keyword Search",
    workType: "Work Types",
    startDate: "Time Period",
    statuses: "Status",
    showDisapproved: "Show Disapproved",
    showOnlyDisapproved: "Show Only Disapproved",
    'firms': 'Firm',
    'persons': "Resource",
    professionalLevel: "Professional Level",
    'categoryCode': "Time Category",
    approvedBy: "Approved By",
    keyword: "Keywords",
    min: "Total Time Spent",
    minWordCount: "Word Count",
    noteId: "Note"
};

const FilterUI = (props: any) => {
    return (
        <>
            <Grid item>
                <Typography variant="subtitle2">
                    {SEARCH_OBJECT_LABEL_DICT_BY_ORDER[props.propertyName]}
                </Typography>
            </Grid>
            {props.children}
        </>
        // <Grid item>
        //     <Grid container spacing={1} alignItems="center">
        //         <Grid item>
        //             <Typography variant="subtitle2">
        //                 {SEARCH_OBJECT_LABEL_DICT_BY_ORDER[props.propertyName]}
        //             </Typography>
        //         </Grid>
        //         <Grid item>
        //             <Grid container spacing={1}>
        //                 {props.children}
        //             </Grid>
        //         </Grid>
        //     </Grid>
        // </Grid>
    );
};

const getLabel = (min: number, max: number) => {
    let label = "";
    if (min && max) {
        label = `min: ${min}, max: ${max}`;
    } else if (min) {
        label = `min: ${min}`;
    } else if (max) {
        label = `min: 0, max: ${max}`;
    }
    return label;
};

export const getMinMaxLabel = (searchObj: any, props: "min" | "minWordCount") => {
    let label = "";
    if (props === "min") {
        label = getLabel(searchObj.min, searchObj.max);
    } else {
        label = getLabel(searchObj.minWordCount, searchObj.maxWordCount);
    }
    return label;
}

export const filterSearchObject = (searchObject: any, props: string) => {
    return Array.isArray(searchObject[props])
        ? searchObject[props]?.length > 0
        : typeof searchObject[props] === 'boolean'
            ? searchObject[props] === true
            : props === 'min'
                ? (searchObject[props] || (searchObject.max && searchObject.max !== undefined))
                : props === 'minWordCount'
                    ? (searchObject[props] || (searchObject.maxWordCount && searchObject.maxWordCount !== undefined))
                    : searchObject[props];
}

interface ISelectedFilterProps {
    searchObject: any;
    firmDict: any;
    personDict: any;
    categoryDict: any;
    workTypeDict: any;
    professionalLevelDict: any;
    statusDict: any;
    approvedByDict: any;
    keywordDict: any;
    noteList: any[];
};

const SelectedFilters: React.FC<ISelectedFilterProps> = ({ searchObject, firmDict, personDict, categoryDict, workTypeDict, professionalLevelDict, statusDict, approvedByDict, keywordDict, noteList }) => {
    // const [arrayTypeDict] = React.useState({
    //     workType: workTypeDict,
    //     statuses: statusDict,
    //     'firms': firmDict,
    //     'persons': personDict,
    //     professionalLevel: professionalLevelDict,
    //     'categoryCode': categoryDict,
    //     approvedBy: approvedByDict,
    //     keyword: keywordDict,
    // });
    // const [isAllFiltersDialogOpen, setAllFiltersDialogOpen] = React.useState(false as boolean);
    // const [shouldShowBtn, setShouldShowBtn] = React.useState(false as boolean);

    // React.useEffect(() => {
    //     let show = false;
    //     if (document.getElementById('inner-div')?.getBoundingClientRect().height > 40) show = true;
    //     (show);setShouldShowBtn
    // }, [searchObject])

    return (
        <React.Fragment>
            <Grid container justify="space-between" alignItems="center" style={{ paddingTop: 16, paddingBottom: 8 }}>
                {/* <Grid item style={{ width: 'calc(100% - 140px)', height: 40, overflow: 'hidden' }}> */}
                <Grid item>
                    <Grid container spacing={1} alignItems="center" id='inner-div'>
                        {Object.keys(SEARCH_OBJECT_LABEL_DICT_BY_ORDER).filter((d) => filterSearchObject(searchObject, d)).map((d) => {
                            let ui = undefined;
                            switch (true) {
                                case ['firms', 'persons', 'workType', 'statuses', 'professionalLevel', 'categoryCode', 'approvedBy', 'keyword'].includes(d):
                                    ui = (
                                        <FilterUI
                                            propertyName={d}
                                        >
                                            {searchObject[d].map((ele) => (
                                                <Grid item>
                                                    <Chip label={d === 'firms' ? firmDict[ele]?.lawFirmShortName : d === 'persons' ? personDict[ele]?.name : d === 'categoryCode' ? categoryDict[ele]?.label : d === 'workType' ? workTypeDict[ele]?.label : d === 'statuses' ? statusDict[ele] : d === 'approvedBy' ? approvedByDict[ele]?.label : d === 'professionalLevel' ? professionalLevelDict[ele]?.label : keywordDict[ele]} variant="outlined" />
                                                </Grid>
                                            ))}
                                        </FilterUI>
                                    );
                                    break;
                                case ['showDisapproved', 'showOnlyDisapproved'].includes(d):
                                    ui = (
                                        <FilterUI
                                            propertyName={d}
                                        >
                                            <Grid item>
                                                <Chip label={searchObject[d] ? "Yes" : "No"} variant="outlined" />
                                            </Grid>
                                        </FilterUI>
                                    );
                                    break;
                                case d === 'descriptionSearchFilters':
                                    ui = (
                                        <React.Fragment>
                                            {searchObject[d].filter(x => x.search).length > 0 && (
                                                <FilterUI
                                                    propertyName={d}
                                                >
                                                    {searchObject[d].filter(x => x.search).map((ele) => (
                                                        <Grid item>
                                                            <Chip label={`${ele.search} - ${ele.isExcluded ? 'Excluded' : 'Included'}`} variant="outlined" />
                                                        </Grid>
                                                    ))}
                                                </FilterUI>
                                            )}
                                        </React.Fragment>
                                    );
                                    break;
                                case d === 'startDate':
                                    ui = (
                                        <FilterUI
                                            propertyName={d}
                                        >
                                            <Grid item>
                                                <Chip label={`${moment(searchObject.startDate).format('MM/DD/YYYY')} - ${moment(searchObject.endDate).format('MM/DD/YYYY')}`} variant="outlined" />
                                            </Grid>
                                        </FilterUI>
                                    );
                                    break;
                                case ['min', 'minWordCount'].includes(d):
                                    ui = (
                                        <FilterUI
                                            propertyName={d}
                                        >
                                            <Grid item>
                                                <Chip label={getMinMaxLabel(searchObject, d as any)} variant="outlined" />
                                            </Grid>
                                        </FilterUI>
                                    );
                                    break;
                                case d === 'noteId':
                                    ui = (
                                        <FilterUI
                                            propertyName={d}
                                        >
                                            <Grid item>
                                                <Chip label={noteList.find((ele) => ele.value === searchObject[d])?.label || "N/A"} variant="outlined" />
                                            </Grid>
                                        </FilterUI>
                                    );
                                    break;
                                default:
                                    break;
                            }
                            return ui;
                        })}
                    </Grid>
                </Grid>
                {/* {shouldShowBtn && (
                    <Grid item>
                        <Button variant="text" color="primary" onClick={() => setAllFiltersDialogOpen(true)}>Selected Filters</Button>
                    </Grid>
                )} */}
            </Grid>
            {/* {isAllFiltersDialogOpen && (
                <SelectedFiltersDialog
                    searchObject={searchObject}
                    firmDict={firmDict}
                    personDict={personDict}
                    categoryDict={categoryDict}
                    workTypeDict={workTypeDict}
                    approvedByDict={approvedByDict}
                    professionalLevelDict={professionalLevelDict}
                    keywordDict={keywordDict}
                    statusDict={statusDict}
                    handleClose={() => setAllFiltersDialogOpen(false)}
                />
            )} */}
        </React.Fragment>
    )
};

export default SelectedFilters;