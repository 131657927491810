import {
    Grid,
    Typography, Card, CardContent
} from "@material-ui/core";
import * as React from "react";
import Loading from "../common/Loading";
import { fetchAppVersion } from "../../services/auth/AuthService";
export interface AboutMainProps { }

const AboutMain: React.FC<AboutMainProps> = ({
}) => {

    const [appVersion, setAppVersion] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        getAppVersion()
    }, []);

    const getAppVersion =async()=>{
        setIsLoading(true);
        const result = await fetchAppVersion();
        if(result && result.isSuccess){
            setIsLoading(false);
            setAppVersion(result?.data);
        }else{
            setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
            {isLoading && <Loading/>}
            <section className="p-16">
                <div className="text-center">
                    <Typography variant="h5">About</Typography>
                </div>
                <br />
                <Card >
                    <CardContent className="rules-content">
                        <Typography variant="h6" align="center">Version {appVersion || "N/A"}</Typography>
                        <br/> 
                        <Typography variant="subtitle1" align="center">© Copyright {new Date().getFullYear()} TortEra</Typography> 
                    </CardContent>
                </Card>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                    </Grid>
                </Grid>
            </section>
        </React.Fragment>
    );
};

export default AboutMain;
