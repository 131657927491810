import * as React from "react";
import { ResponsiveBar } from '@nivo/bar';

interface IBarChartProps {
    data: Array<any>;
    keys: Array<string>;
    xAxisLabel?: string;
    yAxisLabel?: string;
    nivoColor?: "nivo" | "category10" | "accent" | "dark2" | "paired" | "pastel1" | "pastel2" | "set1" | "set3";
    layout?: "vertical" | "horizontal";
    groupMode?: "stacked" | "grouped";
    indexBy?: string;
    getXAxisValueFormatter?: Function;
    getYAxisValueFormatter?: Function;
    getValueFormatter?: Function;
    axisLeftTickRotation?: number;
    axisBottomTickRotation?: number;
    marginLeft?: number;
    marginBottom?: number;
    axisBottomLegendOffset?: number;
    axisLeftLegendOffset?: number;
    axisBottomLegendPosition?: 'start' | 'middle' | 'end';
    axisLeftLegendPosition?: 'start' | 'middle' | 'end';
    showLabel?: boolean;
    ranges?: any[];
    binName?: string;
    isLogScale?: boolean;
}

const BarChart: React.FC<IBarChartProps> = ({ data, keys, xAxisLabel, yAxisLabel, nivoColor, layout, groupMode, indexBy, getYAxisValueFormatter, getValueFormatter, axisBottomTickRotation, marginBottom, axisBottomLegendOffset, axisBottomLegendPosition, showLabel, getXAxisValueFormatter, axisLeftTickRotation, axisLeftLegendPosition, axisLeftLegendOffset, marginLeft, ranges = [], binName, isLogScale }) => {
    console.log("ranges", ranges)
    return (
        <ResponsiveBar
            data={data}
            keys={keys}
            gridYValues={ranges && ranges.length > 0 ? ranges : undefined}
            tooltip={(event: any) => {
                console.log(event);
                return (
                    <div style={{ background: "#fff", padding: "4px 8px" }}>
                        {event.data.isStacked ? (
                            <>
                                {binName ? event.id.replace(binName, "") : event.id} - <strong>{event.data[event.id + "formattedValue"]}</strong>
                            </>
                        ) : (
                            <>
                                {event.indexValue} - <strong>{event.data.formattedValue}</strong>
                            </>
                        )}
                    </div>
                )
            }}
            indexBy={indexBy || "id"}
            margin={{ top: 50, right: 50, bottom: marginBottom || 70, left: marginLeft || 80 }}
            padding={0.3}
            layout={layout || "vertical"}
            groupMode={groupMode || "stacked"}
            valueScale={{ type: isLogScale ? 'symlog' : 'linear' }}
            indexScale={{ type: 'band', round: true }}
            // valueFormat={(v:any) => getValueFormatter ? getValueFormatter(v) : v}
            // colors={{ scheme: nivoColor ? nivoColor : "set3" }}
            colors={(event: any) => {
                if (event.data.isStacked) {
                    return event.data[event.id + "CLR_code"]
                }
                return event.data.colorCode
            }} // use this if you want to use your own color
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={xAxisLabel ? {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: axisBottomTickRotation || 0,
                legend: xAxisLabel,
                legendPosition: axisBottomLegendPosition || 'middle',
                legendOffset: axisBottomLegendOffset || 48,
                format: (v) => getXAxisValueFormatter ? getXAxisValueFormatter(v) : v,
            } : {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: axisBottomTickRotation || 0,
                format: (v) => getXAxisValueFormatter ? getXAxisValueFormatter(v) : v,
            }}
            axisLeft={yAxisLabel ? {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: axisLeftTickRotation || 0,
                legend: yAxisLabel,
                legendPosition: axisLeftLegendPosition || 'middle',
                legendOffset: axisLeftLegendOffset || -55,
                format: (v) => getYAxisValueFormatter ? getYAxisValueFormatter(v) : v,
            } : {
                tickValues: ranges.length > 0 ? ranges : undefined,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: axisLeftTickRotation || 0,
                format: (v) => getYAxisValueFormatter ? getYAxisValueFormatter(v) : v,
            }}
            enableLabel={showLabel || false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ],

            }}
            // legends={[
            //     {
            //         dataFrom: 'keys',
            //         anchor: 'top',
            //         direction: 'row',
            //         justify: false,
            //         translateX: 20,
            //         translateY: -40,
            //         itemsSpacing: 2,
            //         itemWidth: 100,
            //         itemHeight: 30,
            //         itemDirection: 'left-to-right',
            //         itemOpacity: 0.85,
            //         symbolSize: 20,
            //         effects: [
            //             {
            //                 on: 'hover',
            //                 style: {
            //                     itemOpacity: 1
            //                 }
            //             }
            //         ]
            //     }
            // ]}
            barAriaLabel={function (e: any) { return e.id + e.formattedValue + e.indexValue }}
        />
    );
};

export default BarChart;
