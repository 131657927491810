import * as React from "react";
import { ResponsivePie } from '@nivo/pie';

interface IPieChartProps {
    data: Array<any>;
    enableArcLinkLabels?: boolean;
    showLegends?: boolean;
    enableArcLabels?: boolean;
    isDonutChart?: boolean;
    valueFormat?:Function;
    // xAxisLabel?: string;
    // yAxisLabel?: string;
    // nivoColor?: "nivo" | "category10" | "accent" | "dark2" | "paired" | "pastel1" | "pastel2" | "set1";
    // layout?: "vertical" | "horizontal";
    // groupMode?: "stacked" | "grouped";
    // indexBy?: string;
    // getXAxisValueFormatter?: Function;
    // getYAxisValueFormatter?: Function;
    // getValueFormatter?: Function;
    // axisLeftTickRotation?: number;
    // axisBottomTickRotation?: number;
    // marginLeft?: number;
    // marginBottom?: number;
    // axisBottomLegendOffset?: number;
    // axisLeftLegendOffset?: number;
    // axisBottomLegendPosition?: 'start' | 'middle' | 'end';
    // axisLeftLegendPosition?: 'start' | 'middle' | 'end';
    // showLabel?: boolean;
}

const PieChart: React.FC<IPieChartProps> = ({ data, enableArcLinkLabels, showLegends = true, enableArcLabels = true, isDonutChart=true,valueFormat
    // xAxisLabel, yAxisLabel, nivoColor, layout, groupMode, indexBy, getYAxisValueFormatter, getValueFormatter, axisBottomTickRotation, marginBottom, axisBottomLegendOffset, axisBottomLegendPosition, showLabel, getXAxisValueFormatter, axisLeftTickRotation, axisLeftLegendPosition, axisLeftLegendOffset, marginLeft 
}) => {
    return (
        <ResponsivePie
            data={data}
            enableArcLabels={enableArcLabels}
            margin={{ top: 20, right: 20, bottom: 80, left: 20 }}
            innerRadius={isDonutChart?0.5:0}
            valueFormat={(v:any)=>valueFormat?valueFormat(v):v}
            padAngle={0.7}
            cornerRadius={0}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            // colors={{ scheme: 'pastel2' }}
            colors={(event: any) => {
                console.log(event)
                return event.data.color
            }}
            enableArcLinkLabels={enableArcLinkLabels || false}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        2
                    ]
                ]
            }}
            // defs={[
            //     {
            //         id: 'dots',
            //         type: 'patternDots',
            //         background: 'inherit',
            //         color: 'rgba(255, 255, 255, 0.3)',
            //         size: 4,
            //         padding: 1,
            //         stagger: true
            //     },
            //     {
            //         id: 'lines',
            //         type: 'patternLines',
            //         background: 'inherit',
            //         color: 'rgba(255, 255, 255, 0.3)',
            //         rotation: -45,
            //         lineWidth: 6,
            //         spacing: 10
            //     }
            // ]}
            // fill={[
            //     {
            //         match: {
            //             id: 'ruby'
            //         },
            //         id: 'dots'
            //     },
            //     {
            //         match: {
            //             id: 'c'
            //         },
            //         id: 'dots'
            //     },
            //     {
            //         match: {
            //             id: 'go'
            //         },
            //         id: 'dots'
            //     },
            //     {
            //         match: {
            //             id: 'python'
            //         },
            //         id: 'dots'
            //     },
            //     {
            //         match: {
            //             id: 'scala'
            //         },
            //         id: 'lines'
            //     },
            //     {
            //         match: {
            //             id: 'lisp'
            //         },
            //         id: 'lines'
            //     },
            //     {
            //         match: {
            //             id: 'elixir'
            //         },
            //         id: 'lines'
            //     },
            //     {
            //         match: {
            //             id: 'javascript'
            //         },
            //         id: 'lines'
            //     }
            // ]}
            legends={showLegends ? [
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ] : []}
        />
    );
};

export default PieChart;
